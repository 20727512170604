import { useCallback, useEffect } from 'react';
import {
  AchievementByIdDocument,
  UploadPromoCodesInput,
  useUploadPromoCodesMutation,
} from 'schema/serverTypes';

import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FORM_ERROR, SubmissionErrors } from 'final-form';

type UseAwardEditModalFormProps = {
  onClose: () => void;
};

export const useAwardCsvModalForm = ({ onClose }: UseAwardEditModalFormProps) => {
  const { awardId } = useParams<{ awardId: string }>();

  const initialValues: UploadPromoCodesInput = {
    achievementId: awardId,
    fileId: '',
  };

  const [uploadPromoCodesMutation, { data, loading, error }] = useUploadPromoCodesMutation();

  const onSubmit = useCallback(
    async (values: UploadPromoCodesInput) => {
      const input = values;

      try {
        return await uploadPromoCodesMutation({
          variables: { input },
          refetchQueries: [
            {
              query: AchievementByIdDocument,
              variables: { id: awardId },
            },
          ],
        });
      } catch {
        error && toast.error('Ошибка загрузки файла');
        return { [FORM_ERROR]: 'Ошибка загрузки' } as SubmissionErrors;
      }
    },
    [uploadPromoCodesMutation, awardId, error]
  );

  useEffect(() => {
    if (data !== undefined) {
      onClose();
    }
  }, [data, onClose]);

  useEffect(() => {
    if (error) {
      toast.error('Ошибка загрузки файла');
    }
  }, [error]);

  return {
    onSubmit,
    initialValues,
    loading,
  };
};
