import palette from '../palette';

export default {
  root: {
    '&:hover': {
      color: palette.primary.main,
      opacity: 1,
    },
  },
};
