import React, { useCallback } from 'react';
import {
  Button,
  createStyles,
  DialogActions,
  Grid,
  makeStyles,
  MenuItem,
  Theme,
} from '@material-ui/core';
import { Field } from 'react-final-form';
import { SelectField } from '../../SelectField';
import { required } from '../../validation';
import { RedButton } from '../../../Buttons';
import { AutoFocusedForm } from '../../AutoFocusedForm';
import { Skill, useSkillCategoriesQuery } from 'schema';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accessButton: {
      marginLeft: '15px',
      marginRight: '15px',
      color: theme.palette.common.white,
      backgroundColor: theme.palette.success.dark,
      '&:hover': {
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.success.light,
      },
    },
  })
);

interface EditSkillsFormProps {
  skills: Skill[];
  openForm: (open: boolean) => void;
  handleClose: () => void;
  skillsPush: (value: Skill) => void;
}

export const EditSkillsForm = (props: EditSkillsFormProps) => {
  const { skills, openForm, handleClose, skillsPush } = props;
  const styles = useStyles();

  const { data } = useSkillCategoriesQuery();

  const getItems = useCallback(() => {
    const items = [];

    for (const category of data?.skillCategories ?? []) {
      items.push({ ...category });
      for (const skill of category.skills) {
        items.push(skill);
      }
    }

    return items;
  }, [data]);

  const onSubmit = useCallback(
    (values) => {
      const isSelected = skills.filter((skill) => skill.id === values.skill);

      if (!isSelected.length) {
        const skill = getItems()
          .filter((item) => item.id === values.skill)
          .map((skill) => {
            return {
              id: skill.id,
              title: skill.title,
              description: skill.__typename === 'Skill' ? skill.description : '',
              portfolioIsRequired: skill.__typename === 'Skill' ? skill.portfolioIsRequired : false,
            };
          });
        skillsPush({ ...skill[0] });
        openForm(false);
        return;
      }
      toast.error('Такой навык уже выбран, выберете другой');
      openForm(false);
    },
    [getItems, skillsPush, openForm, skills]
  );

  return (
    <AutoFocusedForm
      onSubmit={onSubmit}
      initialValues={[]}
      render={({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Grid container>
              <Grid item lg={12} md={12} xl={12} xs={12}>
                <Field
                  component={SelectField}
                  name="skill"
                  label="Категория навыка"
                  validate={required}
                  fullWidth
                >
                  {getItems().map((item) => {
                    return (
                      <MenuItem
                        key={item.id}
                        value={item.id}
                        disabled={item.__typename === 'SkillCategory'}
                      >
                        {item.title}
                      </MenuItem>
                    );
                  })}
                </Field>
              </Grid>
            </Grid>
            <DialogActions>
              <Button
                className={styles.accessButton}
                size="medium"
                type="submit"
                variant="contained"
              >
                Сохранить
              </Button>
              <RedButton
                color="primary"
                size="medium"
                type="button"
                variant="contained"
                onClick={handleClose}
              >
                Отменить
              </RedButton>
            </DialogActions>
          </form>
        );
      }}
    />
  );
};
