import React, { useMemo } from 'react';

import { DataGrid } from 'components';
import { ColumnConfig, DataConnection, useQueryVariables } from 'hooks';
import { City, CitySortInput, SortEnumType, useCitiesQuery } from 'schema/serverTypes';
import { DataGridProps } from 'components/DataGrid/types';
import { Route } from 'react-router-dom';
import { CreateCityPage } from 'pages/cities/CitiesPage/CreateCityPage';
import { CityActionsButton } from './CityActionsButton';
import { createStyles, makeStyles, Typography } from '@material-ui/core';
import { Star } from '@material-ui/icons';
import { toast } from 'react-toastify';
import { EditCityPage } from '../EditCityPage';

const useStyles = makeStyles(() =>
  createStyles({
    actionButtonsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    orderContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    star: {
      color: 'gold',
    },
  })
);

const getDataKey = (city: City) => city.id;

export const CitiesGrid = () => {
  const classes = useStyles();
  const config = useMemo(() => {
    const columns: ColumnConfig<City, CitySortInput>[] = [
      {
        id: 'order',
        header: 'Порядок показа',
        style: { width: '10%' },
        render: ({ order }: City) => {
          return (
            <div className={classes.orderContainer}>
              <Typography variant="body1">{order > 0 ? order : null}</Typography>
            </div>
          );
        },
      },
      {
        id: 'default',
        header: 'Показывать по умолчанию',
        style: { width: '10%' },
        render: (params: City) => (params.default ? <Star className={classes.star} /> : ''),
      },
      {
        id: 'city',
        header: 'Город',
        render: ({ name }: City) => name,
      },
      {
        id: 'action',
        render: ({ id }: City) => {
          return (
            <div className={classes.actionButtonsContainer}>
              <CityActionsButton cityId={id} />
            </div>
          );
        },
      },
    ];
    return {
      columns,
    };
  }, [classes.actionButtonsContainer, classes.orderContainer, classes.star]);

  const defaultOrderBy: CitySortInput = {
    default: SortEnumType.Desc,
    order: SortEnumType.Asc,
    name: SortEnumType.Asc,
  };

  const {
    onSort,
    onSearch,
    onPage,
    variables,
    extractPagedData,
    columns,
    sortDirection,
    sortBy,
  } = useQueryVariables<City, CitySortInput>(config, defaultOrderBy);

  const { data, loading, error } = useCitiesQuery({ variables, fetchPolicy: 'cache-and-network' });

  if (error) {
    toast.error('Ошибка загрузки справочника городов');
    return null;
  }
  if (error) {
    toast.error('Ошибка загрузки справочника городов');
    return null;
  }

  const entities = data?.cities as DataConnection<City>;
  const { data: cities, count, startCursor, endCursor, page, rowsPerPage } = extractPagedData(
    entities
  );

  const gridProps: DataGridProps<City> = {
    data: cities,
    onSort,
    onPage,
    onSearch,
    columns,
    getDataKey,
    count,
    startCursor: startCursor,
    endCursor: endCursor,
    page,
    rowsPerPage,
    sortDirection,
    sortBy,
    loading,
  };

  return (
    <>
      <DataGrid<City> {...gridProps} />
      <Route exact path="/cities/add" component={CreateCityPage} />
      <Route component={EditCityPage} exact path={`/cities/edit/:id`} />
    </>
  );
};
