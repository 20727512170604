import MuiButton from './MuiButton';
import MuiCardHeader from './MuiCardHeader';
import MuiIconButton from './MuiIconButton';
import MuiPaper from './MuiPaper';
import MuiTableCell from './MuiTableCell';
import MuiTableRow from './MuiTableRow';
import MuiTableHead from './MuiTableHead';
import MuiTypography from './MuiTypography';
import MuiTab from './MuiTab';
import MuiTabs from './MuiTabs';
import MuiDivider from './MuiDivider';

export default {
  MuiButton,
  MuiCardHeader,
  MuiDivider,
  MuiIconButton,
  MuiPaper,
  MuiTableCell,
  MuiTableRow,
  MuiTableHead,
  MuiTypography,
  MuiTabs,
  MuiTab,
};
