import React from 'react';
import { Benefactor, Skill } from 'schema';
import { AutoFocusedForm, EditSkillsField, required, TextField } from 'components';
import { Card, CardActions, CardContent, CardHeader, Divider, Grid } from '@material-ui/core';
import { useEditBenefactorSkillsForm } from './useEditBenefactorSkillsForm';
import { MutableButton } from 'ui/MutableButton';
import { RedButton } from 'components/Buttons';
import { useBackRoute } from 'hooks';
import { useStyles } from '../useStyles';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';

interface EditBenefactorFormProps {
  benefactor: Benefactor;
}

export const EditBenefactorSkillsForm = (props: EditBenefactorFormProps) => {
  const { benefactor } = props;
  const styles = useStyles();

  const { onCancel } = useBackRoute();

  const { onSubmit, initialValues, loading } = useEditBenefactorSkillsForm(benefactor, onCancel);

  return (
    <AutoFocusedForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, pristine }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card className={styles.primary}>
              <CardHeader title="Навыки" />
              <Divider />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FieldArray<Skill>
                      name="skills"
                      component={EditSkillsField}
                      validate={required}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Field name="portfolio" label="Ссылка на портфолио" component={TextField} />
                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Field
                      name="experience"
                      label="Описание опыта"
                      component={TextField}
                      multiline
                      rows={5}
                      rowsMax={50}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <MutableButton
                  className={styles.accessButton}
                  loading={loading}
                  disabled={pristine}
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  Сохранить
                </MutableButton>
                <RedButton
                  color="primary"
                  size="medium"
                  type="button"
                  variant="contained"
                  onClick={onCancel}
                >
                  Отменить
                </RedButton>
              </CardActions>
            </Card>
          </form>
        );
      }}
    />
  );
};
