import { useCallback } from 'react';
import {
  useSelectFoundationEventApplicationMutation,
  useDeclineFoundationEventApplicationMutation,
  ApplicationsDocument,
  useConvertFoundationEventApplicationToAttendanceMutation,
  ConvertFoundationEventApplicationToAttendanceInput,
  AttendanceDocument,
} from 'schema/serverTypes';
import { toast } from 'react-toastify';
import { ApplicationsQueryContext } from './QueryContextProvider';

export const useApplicationsActions = (
  applicationId: string,
  close: () => void,
  variables: ApplicationsQueryContext
) => {
  const [
    selectRole,
    { loading: selectLoading, error: selectError },
  ] = useSelectFoundationEventApplicationMutation();

  const primeRole = useCallback(async () => {
    await selectRole({
      variables: {
        input: {
          applicationId,
          primary: true,
        },
      },
      refetchQueries: [
        {
          query: ApplicationsDocument,
          variables,
        },
      ],
    });
    close();
  }, [selectRole, applicationId, close, variables]);

  const secondRole = useCallback(async () => {
    await selectRole({
      variables: {
        input: {
          applicationId,
          primary: false,
        },
      },
      refetchQueries: [
        {
          query: ApplicationsDocument,
          variables,
        },
      ],
    });
    close();
  }, [selectRole, applicationId, close, variables]);

  const [
    declineApplicationMutation,
    { loading: declineLoading, error: declineError },
  ] = useDeclineFoundationEventApplicationMutation();

  const declineApplication = useCallback(async () => {
    await declineApplicationMutation({
      variables: {
        input: { applicationId },
      },
      refetchQueries: [
        {
          query: ApplicationsDocument,
          variables,
        },
      ],
    });
    close();
  }, [declineApplicationMutation, applicationId, close, variables]);

  const [
    convertFoundationEventApplicationToAttendance,
    { loading: convertFoundationLoading, error: convertFoundationError },
  ] = useConvertFoundationEventApplicationToAttendanceMutation();

  const convertFoundation = useCallback(async () => {
    const input = { applicationId } as ConvertFoundationEventApplicationToAttendanceInput;
    await convertFoundationEventApplicationToAttendance({
      variables: {
        input,
      },
      refetchQueries: [
        {
          query: ApplicationsDocument,
          variables,
        },
        { query: AttendanceDocument, variables },
      ],
    });
    close();
  }, [convertFoundationEventApplicationToAttendance, applicationId, close, variables]);

  if (selectError) {
    toast.error('Произошла ошибка изменения роли');
  }

  if (declineError) {
    toast.error('Произошла ошибка при отклонении заявки');
  }

  if (convertFoundationError) {
    toast.error('Произошла ошибка отметки об участии в событие');
  }

  const loading = selectLoading || declineLoading || convertFoundationLoading;
  const error = selectError || declineError || convertFoundationError;

  return {
    primeRole,
    secondRole,
    declineApplication,
    convertFoundation,
    loading,
    error,
  };
};
