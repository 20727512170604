import { useCallback, useEffect } from 'react';
import {
  UpdateBenefactorDepositAccountForSpecificWardsInput,
  useUpdateBenefactorDepositAccountForSpecificWardsMutation,
} from 'schema';
import { toast } from 'react-toastify';

interface InitialValuesProps {
  title: string;
}

export const useEditTitle = (accountId: string, title: string, handleCloseTitle: () => void) => {
  const [
    updateAccount,
    { data, loading, error },
  ] = useUpdateBenefactorDepositAccountForSpecificWardsMutation();

  const onSubmit = useCallback(
    async ({ title }: InitialValuesProps) => {
      const inputValues: UpdateBenefactorDepositAccountForSpecificWardsInput = {
        title,
        id: accountId,
      };

      await updateAccount({
        variables: {
          input: inputValues,
        },
      });
    },
    [accountId, updateAccount]
  );

  useEffect(() => {
    if (error) {
      toast.error('Ошибка при изменении названия');
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      toast.success('Название успешно изменено');
      handleCloseTitle();
    }
  }, [data, handleCloseTitle]);

  const initialValues: InitialValuesProps = {
    title,
  };
  return {
    initialValues,
    onSubmit,
    loading,
  };
};
