import { CurrentTokenInformation } from 'components';

const AuthKey = 'auth';

const get = () => {
  try {
    const userString = localStorage.getItem('auth');
    if (userString === null) {
      return undefined;
    }
    return JSON.parse(userString) as CurrentTokenInformation;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

const save = (tokens: CurrentTokenInformation) => {
  try {
    localStorage.setItem(AuthKey, JSON.stringify(tokens));
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

const clear = () => {
  try {
    localStorage.removeItem(AuthKey);
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export const authStorage = {
  get,
  save,
  clear,
};
