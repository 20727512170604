import { TitledToolbar } from '../../../components';
import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useStoryByIdQuery } from '../../../schema';
import { EditStoryForm } from './EditStoryForm';
import { useParams } from 'react-router-dom';
import { Loading } from '../../../ui/Loading';
import { toast } from 'react-toastify';

export const EditStoryPage = () => {
  const { storyId } = useParams<{ storyId: string }>();
  const { data, error, loading } = useStoryByIdQuery({ variables: { id: storyId } });

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <TitledToolbar title={`Редактирование истории ${data?.storyById?.title}`} />
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <EditStoryForm story={data} />
        </Grid>
      </Grid>
    </>
  );
};
