import { makeStyles } from '@material-ui/styles';
import { createStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTableCell-root': {
        padding: 10,
      },
      '& .MuiTableCell-root:first-child': {
        paddingLeft: 0,
        fontWeight: 700,
      },
      '& .MuiTableCell-root:last-child': {
        paddingRight: 0,
        textAlign: 'right',
      },
      '&:last-child .MuiTableCell-root': {
        borderBottom: 0,
      },
    },
  })
);
