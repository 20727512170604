import { useCallback, useEffect, useMemo } from 'react';
import { useAddOneTimeNeedMutation, useAddGrocerySetNeedMutation, Ward } from 'schema/serverTypes';
import { useTabs, useBackRoute } from 'hooks';
import { required, useMeContext } from 'components';
import { NeedFormValues, getOneTimeNeed } from '../types';
import { WardByIdDocument } from 'schema/serverTypes';
import { toast } from 'react-toastify';

const createInitialValues = (wardId: string): NeedFormValues => ({
  oneTime: {
    wardId,
    amount: '',
    title: '',
    description: '',
    targetDate: '',
  },
  regularNeed: '',
});

export const useAddNeedForm = (ward: Ward) => {
  const { onCancel } = useBackRoute();
  const { user } = useMeContext();

  const refetchQueries = useMemo(() => {
    return [
      {
        query: WardByIdDocument,
        variables: { id: ward.id, permission: user?.permissions.wards.edit },
      },
    ];
  }, [user?.permissions.wards.edit, ward.id]);

  const { tabIndex, onChangeTab, onChangeTabIndex, a11yProps } = useTabs(0);
  const [
    addOneTimeNeed,
    { data: oneTimeData, loading: oneTimeLoading, error: oneTimeError },
  ] = useAddOneTimeNeedMutation();
  const [
    addGrocerySetNeed,
    { data: grocerySetData, loading: grocerySetLoading, error: grocerySetError },
  ] = useAddGrocerySetNeedMutation();

  const data = tabIndex === 1 ? oneTimeData : grocerySetData;
  const loading = tabIndex === 1 ? oneTimeLoading : grocerySetLoading;
  const error = tabIndex === 1 ? oneTimeError : grocerySetError;

  useEffect(() => {
    if (data !== undefined) {
      onCancel();
    }
  }, [data, onCancel]);

  useEffect(() => {
    if (error !== undefined) {
      toast.error('Ошибка добавления потребности');
      onCancel();
    }
  }, [error, onCancel]);

  const onSubmit = useCallback(
    (values: NeedFormValues) => {
      const input = getOneTimeNeed(values.oneTime);
      if (tabIndex === 1 && input !== undefined) {
        addOneTimeNeed({
          refetchQueries,
          variables: { input },
        });
      } else if (values.regularNeed !== '') {
        const input = { wardId: ward.id };
        addGrocerySetNeed({
          refetchQueries,
          variables: { input },
        });
      }
    },
    [addOneTimeNeed, addGrocerySetNeed, ward.id, tabIndex, refetchQueries]
  );

  const requiredOnTab1 = useCallback(
    (value: any) => {
      if (tabIndex === 0) {
        return required(value);
      }
      return undefined;
    },
    [tabIndex]
  );

  const requiredOnTab2 = useCallback(
    (value: any) => {
      if (tabIndex === 1) {
        return required(value);
      }
      return undefined;
    },
    [tabIndex]
  );

  return {
    tabIndex,
    onChangeTab,
    onChangeTabIndex,
    initialValues: createInitialValues(ward.id),
    onSubmit,
    onCancel,
    data,
    loading,
    error,
    requiredOnTab1,
    requiredOnTab2,
    a11yProps,
  };
};
