import React, {
  createContext,
  useContext,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { authStorage, userStorage } from 'helpers';
import { useMeLazyQuery } from 'schema';
import { useBeforeUnload } from 'hooks';
import { CurrentUserContext, CurrentUserInformation } from './types';

export const MeContext = createContext<CurrentUserContext>({
  login: () => {},
  logout: () => {},
});

interface AppState {
  user: CurrentUserInformation;
  remember: Boolean;
}

interface LoginDataState {
  remember: Boolean;
}

export const MeContextProvider: FC = ({ children }) => {
  const persistedUser = userStorage.get();
  const [currentUser, setCurrentUser] = useState<AppState | undefined>(
    persistedUser ? { user: persistedUser, remember: true } : undefined
  );
  const [loginData, setLoginData] = useState<LoginDataState | undefined>();

  const [getMe, { data }] = useMeLazyQuery({ fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (data && loginData) {
      const user = {
        id: data.me.user.id,
        username: data.me.user.displayName as string,
        permissions: data.me.permissions,
        type: data.me.user.__typename,
        foundationId:
          data.me.user.__typename === 'FoundationRepresentative'
            ? data.me.user.foundation?.id
            : undefined,
      };
      userStorage.save(user);
      setCurrentUser({ user, remember: loginData.remember });
    }
  }, [data, loginData]);

  const login = useCallback(
    (remember: boolean) => {
      getMe();
      setLoginData({ remember });
    },
    [getMe, setLoginData]
  );

  const logout = useCallback(() => {
    userStorage.clear();
    authStorage.clear();
    setCurrentUser(undefined);
  }, [setCurrentUser]);

  const context: CurrentUserContext = useMemo(() => {
    return {
      user: currentUser?.user,
      login,
      logout,
    };
  }, [currentUser, login, logout]);

  const onUnload = useCallback(
    (_) => {
      if (!currentUser?.remember) {
        userStorage.clear();
        authStorage.clear();
      }
      return false;
    },
    [currentUser]
  );
  useBeforeUnload(onUnload);
  return <MeContext.Provider value={context}>{children}</MeContext.Provider>;
};

export const useMeContext = () => useContext(MeContext);
