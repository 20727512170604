import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    dialogContentContainer: {
      minWidth: '600px',
    },
    tableContainer: {
      marginBottom: '20px',
    },

    totalAmount: {
      fontSize: '16px',
      fontWeight: 'bold',
    },
    header: {
      fontSize: '18px',
      fontWeight: 'bold',
    },
  })
);
