import React from 'react';
import {
  Grid,
  Card,
  CardHeader,
  Divider,
  CardContent,
  MenuItem,
  Button,
  CardActions,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import { AutoFocusedForm, CheckboxField, required, SelectField, TextField } from 'components';
import { Field } from 'react-final-form';
import { useVersionForm } from './useVersionForm';
import { ClientApplication } from 'schema/serverTypes';
import { Alert } from '@material-ui/lab/';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    header: {
      fontWeight: 'bolder',
    },
    divider: {},
    content: {},
    actions: {
      justifyContent: 'flex-end',
    },
  })
);

export interface CreateVersionFormProps {
  onClose: () => void;
}

export const CreateVersionForm = ({ onClose }: CreateVersionFormProps) => {
  const styles = useStyles();

  const { onSubmit, initialValues } = useVersionForm(onClose);
  return (
    <AutoFocusedForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      subscription={{
        submitError: true,
      }}
      render={({ handleSubmit, pristine, submitError }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card className={styles.root}>
              <CardHeader title="Добавление версии" />
              <Divider className={styles.divider} />
              <CardContent className={styles.content}>
                <Grid container spacing={4}>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Field
                      name="client"
                      component={SelectField}
                      label="Клиент *"
                      validate={required}
                    >
                      <MenuItem value={ClientApplication.Ios}>{ClientApplication.Ios}</MenuItem>
                      <MenuItem value={ClientApplication.Android}>
                        {ClientApplication.Android}
                      </MenuItem>
                    </Field>
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Field
                      name="major"
                      label="Версия (major) *"
                      component={TextField}
                      validate={required}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Field
                      name="minor"
                      label="Версия (minor) *"
                      component={TextField}
                      validate={required}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Field
                      name="patch"
                      label="Версия (patch) *"
                      component={TextField}
                      validate={required}
                    />
                  </Grid>
                </Grid>
                <Grid item lg={4} md={8} xl={8} xs={12}>
                  <CheckboxField label="Обязательная" labelPlacement="end" name="mandatory" />
                </Grid>
                {submitError && <Alert severity="error">{submitError}</Alert>}
              </CardContent>
              <CardActions className={styles.actions}>
                <Button
                  color="primary"
                  disabled={pristine}
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  Добавить
                </Button>
                <Button onClick={onClose} size="medium" type="button" variant="contained">
                  Отменить
                </Button>
              </CardActions>
            </Card>
          </form>
        );
      }}
    />
  );
};
