import React from 'react';
import clsx from 'clsx';
import { Theme, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, LinearProgress } from '@material-ui/core';
import { AmountLabel } from '../../../components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
    },
    content: {
      alignItems: 'center',
      display: 'flex',
    },
    title: {
      fontWeight: 500,
    },
    caption: {},
    progress: {
      marginTop: theme.spacing(3),
      height: '8px',
      borderRadius: '10px',
    },
    difference: {
      marginTop: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
    },
    differenceIcon: {
      color: theme.palette.error.dark,
    },
    differenceValue: {
      marginRight: theme.spacing(1),
    },
    green: {
      color: theme.palette.success.dark,
    },
    red: {
      color: theme.palette.error.dark,
    },
  })
);

export interface CounterProps {
  title: string;
  value: number;
  previousValue?: number;
  showCurrency?: boolean;
  progress?: boolean;
  difference?: string;
  duration?: number;
  className?: string;
}

export const Counter = (props: CounterProps) => {
  const classes = useStyles();
  const {
    className,
    title,
    value,
    previousValue,
    showCurrency,
    progress,
    duration,
    ...rest
  } = props;

  let difference = null;

  if (previousValue !== undefined && previousValue !== 0) {
    difference = (value / previousValue) * 100;
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              {title}
            </Typography>
            <Typography variant="h3">
              <AmountLabel value={value} showCurrency={showCurrency} />
            </Typography>
          </Grid>
        </Grid>
        {progress ? (
          <LinearProgress className={classes.progress} value={value} variant="determinate" />
        ) : null}
        {difference ? (
          <div className={classes.difference}>
            <Typography className={classes.differenceValue} variant="body2">
              {value > 1 ? (
                <span className={classes.green}>{difference.toFixed(2)}%</span>
              ) : (
                <span className={classes.red}>{difference.toFixed(2)}%</span>
              )}
            </Typography>
          </div>
        ) : null}
      </CardContent>
    </Card>
  );
};
