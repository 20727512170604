import React from 'react';
import { WardProps } from '../types';
import { ImmediateHelpButton } from './ImmediateHelpButton';
import { SubscriptionTransferButton } from './SubscriptionTransferButton';

export const QuickActionButtons = (props: WardProps) => {
  const { ward } = props;
  const { deactivationInformation } = ward;

  return deactivationInformation === undefined || deactivationInformation === null ? (
    <>
      <SubscriptionTransferButton ward={ward} />
      <ImmediateHelpButton ward={ward} />
    </>
  ) : null;
};
