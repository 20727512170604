const isStoryEditedKey = 'isStoryEdited';

const get = (id: string) => {
  try {
    const isEditedStory = localStorage.getItem(`${isStoryEditedKey}${id}`);

    return JSON.parse(isEditedStory!);
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

const save = (id: string, value: boolean) => {
  try {
    localStorage.setItem(`${isStoryEditedKey}${id}`, JSON.stringify(value));
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

const clear = (id: string) => {
  try {
    localStorage.removeItem(`${isStoryEditedKey}${id}`);
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export const isStoryEditedStorage = {
  get,
  save,
  clear,
};
