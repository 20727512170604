import React from 'react';
import { SystemAccount } from 'schema';
import { PropertiesTable, PropertiesTableRow } from 'ui/PropertiesTable';

export const ProjectAccountInformation = ({ account }: { account: SystemAccount }) => {
  return (
    <PropertiesTable>
      <PropertiesTableRow caption="Баланс" value={account.amount} isAmount />
    </PropertiesTable>
  );
};
