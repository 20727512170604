import React from 'react';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { FillAccountPopup } from './FillAccountPopup';
import { Minimal } from 'layouts';
import { RouteWithLayout } from 'components';
import { IAccountProps } from 'pages/components';

export const FillAccount = ({ account }: IAccountProps) => {
  return (
    <>
      <Button
        color="primary"
        variant="contained"
        component={Link}
        to={`/account/${account!.id}/fill`}
      >
        Перевести средства на счёт
      </Button>

      <RouteWithLayout
        exact
        path="/account/:id/fill"
        component={FillAccountPopup}
        account={account}
        layout={Minimal}
      />
    </>
  );
};
