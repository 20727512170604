import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { BrowserRouter } from 'react-router-dom';
import theme from 'theme';
import 'assets/scss/index.scss';
import client from 'schema/client';
import { ApolloProvider } from '@apollo/client';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/ru';
import 'react-toastify/dist/ReactToastify.css';
import AppRouter from 'components/AppRouter';
import { MeContextProvider } from 'components';

moment.locale('ru');

function App() {
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={'ru'}>
        <ApolloProvider client={client}>
          <BrowserRouter>
            <MeContextProvider>
              <AppRouter />
            </MeContextProvider>
          </BrowserRouter>
        </ApolloProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

export default App;
