import { useCallback } from 'react';
import { AddWardInput, useAddWardMutation, Gender, MaritalStatus } from 'schema';
import { useCancelRoute } from 'hooks';

type InitialValues = AddWardInput;

const initialValues: InitialValues = {
  firstName: '',
  middleName: '',
  lastName: '',
  dateOfBirth: '',
  gender: Gender.Female,
  photoId: '',
  story: '',
  address: '',
  publicCoordinates: '',
  maritalStatus: MaritalStatus.Single,
  profession: '',
  rank: '',
  passportSeries: '',
  passportNumber: '',
  passportIssueDate: '',
  passportIssuer: '',
  passportIssuerCode: '',
  placeOfBirth: '',
  comments: '',
  phoneNumber: '',
  city: '',
  categories: [],
};

const useAddWardForm = () => {
  const [mutate, { loading, data, error }] = useAddWardMutation({ errorPolicy: 'all' });

  const onSubmit = useCallback(
    async ({ passportIssueDate, publicCoordinates, categories, ...rest }: InitialValues) => {
      return await mutate({
        variables: {
          input: {
            categories,
            publicCoordinates: publicCoordinates.split(', ').reverse().join(', '),
            passportIssueDate: passportIssueDate !== '' ? passportIssueDate : null,
            ...rest,
          },
        },
      });
    },
    [mutate]
  );

  const { onCancel } = useCancelRoute('/wards');

  return {
    initialValues,
    onSubmit,
    onCancel,
    loading,
    data,
    error,
  };
};

export default useAddWardForm;
