import React, { useEffect, useState } from 'react';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import { PaymentMethod } from '../../../../../../../../schema';
import { RedButton } from '../../../../../../../../components/Buttons';
import { toast } from 'react-toastify';

type Props = {
  availablePaymentMethods: PaymentMethod[] | undefined;
  handleClose: () => void;
  setActiveStep: (updateFunction: (prevStep: number) => number) => void;
};

export const PaymentMethodsStep = ({
  availablePaymentMethods,
  handleClose,
  setActiveStep,
}: Props) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');

  useEffect(() => {
    if (availablePaymentMethods && availablePaymentMethods.length) {
      setSelectedPaymentMethod(availablePaymentMethods[0].title);
    } else {
      toast.error('Отсутствуют доступные способы оплаты');
    }
  }, [availablePaymentMethods]);

  const handleBack = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
  };

  return (
    <>
      {availablePaymentMethods ? (
        <>
          <DialogContent>
            <FormControl component="fieldset">
              <RadioGroup
                value={selectedPaymentMethod}
                onChange={(event) => setSelectedPaymentMethod(event.target.value)}
              >
                {availablePaymentMethods?.map((method, index) => (
                  <FormControlLabel
                    key={index}
                    value={method.title}
                    control={<Radio />}
                    label={method.title}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleBack}>Назад</Button>
            <Button variant="contained" color="primary" onClick={handleNext}>
              Далее
            </Button>
            <RedButton onClick={handleClose}>Отменить</RedButton>
          </DialogActions>{' '}
        </>
      ) : null}
    </>
  );
};
