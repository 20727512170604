import React from 'react';
import { IconButton, Popover, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { MoreVertOutlined } from '@material-ui/icons';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import { usePopover } from 'hooks';
import { useFoundationActions } from './useFoundationActions';

export interface FoundationActionsButtonProps {
  userId: string;
  disabled: boolean;
  foundationId: string;
}

export const FoundationActionsButton = (props: FoundationActionsButtonProps) => {
  const { userId, disabled, foundationId } = props;
  const { id, onClick, onClose, open, anchorEl } = usePopover(userId);
  const { onDelete, onEdit, onEnableOrDisable, loading } = useFoundationActions(
    userId,
    disabled,
    onClose,
    foundationId
  );

  return (
    <>
      <IconButton aria-describedby={id} aria-label="Действия" onClick={onClick}>
        <MoreVertOutlined />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List component="nav" aria-label="Основный действия над представителем фондов">
          <ListItem button onClick={onEdit} disabled={loading}>
            <ListItemIcon>
              <EditOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Изменить" />
          </ListItem>
          <ListItem button onClick={onEnableOrDisable} disabled={loading}>
            <ListItemIcon>{disabled ? <CheckCircleIcon /> : <BlockIcon />}</ListItemIcon>
            <ListItemText primary={disabled ? 'Активировать' : 'Заблокировать'} />
          </ListItem>
          <ListItem button onClick={onDelete} disabled={loading}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText primary="Удалить" />
          </ListItem>
        </List>
      </Popover>
    </>
  );
};
