import React, { useEffect } from 'react';
import { IconButton, Popover, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { MoreVertOutlined } from '@material-ui/icons';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import { usePopover } from 'hooks';
import { useCityActions } from './useCityActions';
import { DeleteErrorCityPage } from '../DeleteErrorCityPage';
import { Route, useHistory } from 'react-router-dom';

export interface CityActionsButtonProps {
  cityId: string;
}

export const CityActionsButton = (props: CityActionsButtonProps) => {
  const { cityId } = props;
  const { id, onClick, onClose, open, anchorEl } = usePopover(cityId);
  const { onDelete, onEdit, loading, deleteData } = useCityActions(cityId, onClose);
  const history = useHistory();

  useEffect(() => {
    if (deleteData?.deleteCity.errors) {
      deleteData?.deleteCity.errors && history.push(`/cities/delete/${cityId}`);
    }
  }, [deleteData, history, cityId]);

  return (
    <>
      <IconButton aria-describedby={id} aria-label="Действия" onClick={onClick}>
        <MoreVertOutlined />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List component="nav" aria-label="Основный действия над городами">
          <ListItem button onClick={onEdit} disabled={loading}>
            <ListItemIcon>
              <EditOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Изменить" />
          </ListItem>
          <ListItem button onClick={onDelete} disabled={loading}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText primary="Удалить" />
          </ListItem>
        </List>
      </Popover>
      <Route exact path={`/cities/delete/${cityId}`}>
        <DeleteErrorCityPage
          message={`${deleteData?.deleteCity.errors?.map(({ message }) => message)}`}
        />
      </Route>
    </>
  );
};
