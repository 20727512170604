import { useCallback, useEffect } from 'react';
import {
  AddStoryBlockInput,
  StoryBlockDocument,
  useAddStoryBlockMutation,
} from 'schema/serverTypes';

import { FORM_ERROR, SubmissionErrors } from 'final-form';
import { toast } from 'react-toastify';

const initialValues: AddStoryBlockInput = {
  label: '',
  title: '',
};

export const useAddBlockStoryModalForm = (onClose: () => void) => {
  const [addStoryBlock, { data, loading, error }] = useAddStoryBlockMutation();

  const onSubmit = useCallback(
    async (values: AddStoryBlockInput) => {
      const { label, title } = values;
      const input: AddStoryBlockInput = {
        label,
        title,
      };
      try {
        return await addStoryBlock({
          variables: { input },
          refetchQueries: [
            {
              query: StoryBlockDocument,
            },
          ],
        });
      } catch {
        error && toast.error('Ошибка добавления блока историй');
        return { [FORM_ERROR]: 'Ошибка добавления' } as SubmissionErrors;
      }
    },
    [addStoryBlock, error]
  );

  useEffect(() => {
    if (data !== undefined) {
      onClose();
    }
  }, [data, onClose]);

  return { initialValues, onSubmit, loading };
};
