import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardContent } from '@material-ui/core';
import { LayoutPageGrid } from 'ui/LayoutGrid/LayoutGrid';
import { usePermissionPage } from 'hooks';
import { FoundationsGrid } from './FoundationsGrid';
import { useMeContext } from 'components';

export const FoundationsPage = () => {
  const { user } = useMeContext();

  usePermissionPage('/ward', user?.permissions.foundations.edit);

  return (
    <LayoutPageGrid
      title="Фонды-партнеры"
      tools={
        <Button color="primary" variant="contained" component={Link} to="/foundations/create">
          Добавить фонд
        </Button>
      }
    >
      <Card>
        <CardContent>
          <FoundationsGrid />
        </CardContent>
      </Card>
    </LayoutPageGrid>
  );
};
