import React, { useMemo } from 'react';
import { BenefactorAchievement, useAchievementByIdQuery } from 'schema/serverTypes';
import { useQueryVariables, ColumnConfig, DataConnection } from 'hooks';
import { DataGrid } from 'components';
import { DataGridProps } from 'components/DataGrid/types';
import { Link, useParams } from 'react-router-dom';
import { useStyles } from '../useStyles';
import { Typography } from '@material-ui/core';
import { formatDateTime } from '../../../../helpers';

const getDataKey = (subscription: BenefactorAchievement) => subscription.id;

export const BenefactorAchievementCard = () => {
  const classes = useStyles();
  const { awardId } = useParams<{ awardId: string }>();

  const config = useMemo(() => {
    const columns: ColumnConfig<BenefactorAchievement>[] = [
      {
        id: 'displayName',
        header: 'Имя пользователя',
        render: (benefactorAchievement: BenefactorAchievement) => (
          <Link
            className={classes.link}
            to={`/benefactors/manage/${benefactorAchievement.benefactor?.id}`}
          >
            {benefactorAchievement.benefactor?.displayName}
          </Link>
        ),
      },
      {
        id: 'receivedAt',
        header: 'Дата получения награды',
        render: (benefactorAchievement: BenefactorAchievement) => (
          <Typography>{formatDateTime(benefactorAchievement.receivedAt)}</Typography>
        ),
      },
    ];
    return {
      columns,
    };
  }, [classes]);

  const { onPage, variables, extractPagedData, columns } = useQueryVariables<BenefactorAchievement>(
    config
  );

  const { data } = useAchievementByIdQuery({
    variables: { id: awardId, ...variables },
  });

  const entities = data?.achievementById?.benefactors as DataConnection<BenefactorAchievement>;
  const {
    data: benefactorAchievement,
    count,
    startCursor,
    endCursor,
    page,
    rowsPerPage,
  } = extractPagedData(entities);

  const gridProps: DataGridProps<BenefactorAchievement> = {
    data: benefactorAchievement,
    onPage,
    columns,
    getDataKey,
    count,
    startCursor: startCursor,
    endCursor: endCursor,
    page,
    rowsPerPage,
    onSort: () => {},
    onSearch: () => {},
  };

  return <DataGrid<BenefactorAchievement> {...gridProps} />;
};
