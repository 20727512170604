import { useCallback, useEffect, useMemo } from 'react';
import {
  AdministratorsKind,
  FoundationRepresentative,
  Moderator,
  SystemAdministrator,
  UpdateFoundationRepresentativeInput,
  UpdateModeratorInput,
  UpdateSystemAdministratorInput,
  useUpdateFoundationRepresentativeMutation,
  useUpdateModeratorMutation,
  useUpdateSystemAdministratorMutation,
} from 'schema/serverTypes';
import { getAdministratorKind } from 'helpers';
import { toast } from 'react-toastify';
import { kindNames } from 'schema';

export interface UpdateAdministratorInputValues {
  displayName: string;
}

const createInitialValues = (user: SystemAdministrator | Moderator | FoundationRepresentative) => {
  const initialValues: UpdateAdministratorInputValues = {
    displayName: user.displayName || '',
  };
  return initialValues;
};

export const useModeratorForm = (
  user: SystemAdministrator | Moderator | FoundationRepresentative,
  onClose: () => void
) => {
  const [
    updateModerator,
    { data: dataModerator, error: errorModerator, loading: loadingModerator },
  ] = useUpdateModeratorMutation();
  const [
    updateFoundationRepresentative,
    { data: dataFoundation, error: errorFoundation, loading: loadingFoundation },
  ] = useUpdateFoundationRepresentativeMutation();
  const [
    updateSystemAdministrator,
    { data: dataAdministrator, error: errorAdministrator, loading: loadingAdministrator },
  ] = useUpdateSystemAdministratorMutation();

  const loading = loadingModerator || loadingFoundation || loadingAdministrator || false;
  const error = errorModerator || errorFoundation || errorAdministrator || false;

  const initialValues = useMemo(() => {
    return createInitialValues(user);
  }, [user]);

  const onSubmit = useCallback(
    async (values: UpdateAdministratorInputValues) => {
      const { displayName } = values;
      if (displayName !== user.displayName) {
        const input: UpdateSystemAdministratorInput &
          UpdateModeratorInput &
          UpdateFoundationRepresentativeInput = {
          id: user.id,
          displayName: displayName,
        };
        const createVariables = {
          variables: { input },
        };
        const kind = getAdministratorKind(user.__typename);
        kind === kindNames[AdministratorsKind.Moderators] &&
          (await updateModerator(createVariables));
        kind === kindNames[AdministratorsKind.SystemAdministrators] &&
          (await updateSystemAdministrator(createVariables));
        kind === kindNames[AdministratorsKind.FoundationRepresentatives] &&
          (await updateFoundationRepresentative(createVariables));
      }
      onClose();
    },
    [
      user.id,
      user.displayName,
      user.__typename,
      updateModerator,
      updateFoundationRepresentative,
      updateSystemAdministrator,
      onClose,
    ]
  );

  useEffect(() => {
    error && toast.error('Ошибка добавления пользователя');
    dataModerator?.updateModerator.errors &&
      toast.error(`${dataModerator?.updateModerator.errors.map((error) => error.message)}`);
    dataFoundation?.updateFoundationRepresentative.errors &&
      toast.error(
        `${dataFoundation?.updateFoundationRepresentative.errors.map((error) => error.message)}`
      );
    dataAdministrator?.updateSystemAdministrator.errors &&
      toast.error(
        `${dataAdministrator?.updateSystemAdministrator.errors.map((error) => error.message)}`
      );
  }, [error, dataModerator, dataFoundation, dataAdministrator]);

  useEffect(() => {
    if (
      dataModerator !== undefined ||
      dataFoundation !== undefined ||
      dataAdministrator !== undefined
    ) {
      onClose();
    }
  }, [dataModerator, dataFoundation, dataAdministrator, onClose]);

  return {
    initialValues,
    onSubmit,
    loading,
  };
};
