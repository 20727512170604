import React from 'react';
import {
  Avatar,
  Card,
  Button,
  makeStyles,
  Theme,
  CardContent,
  Typography,
  Divider,
  Box,
  createStyles,
  CardHeader,
} from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { NeedProps, NeedIcon } from 'components';
import { getNeedName, getNeedDescription, isOneTimeNeed, isRegularNeed } from 'schema';
import { ArchiveNeedButton } from './ArchiveNeedButton';
import { WardProps } from 'pages/wards/types';
import { PropertiesTable, PropertiesTableRow } from 'ui/PropertiesTable';
import { RegularNeedPeriods } from './RegularNeedPeriods/RegularNeedPeriods';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    buttons: {
      display: 'flex',
      justifyContent: 'center',
    },
    avatar: {
      width: theme.spacing(14),
      height: theme.spacing(14),
      marginBottom: theme.spacing(2),
    },
    tableInfoHeader: {
      borderBottom: 'none',
    },
    tableInfo: {
      textAlign: 'right',
      borderBottom: 'none',
      color: theme.palette.text.secondary,
    },
    divider: {
      borderBottom: theme.spacing(1),
      width: '100%',
      marginBottom: theme.spacing(1),
    },
    story: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      textAlign: 'left',
    },
    buttonsContainer: {
      display: 'flex',
      flexDirection: 'row-reverse',
      '& button': {
        marginLeft: theme.spacing(1),
      },
      marginBottom: theme.spacing(1),
    },
    headerLabel: {
      fontWeight: 'bolder',
    },
    headerStatus: {
      marginTop: theme.spacing(1),
    },
    statusLabel: {
      fontWeight: 'bolder',
    },
  })
);

interface ManageNeedStatusProps extends NeedProps, WardProps {}

export const WardNeedStatus = ({ need }: ManageNeedStatusProps) => {
  const classes = useStyles();

  const { pathname } = useLocation();
  const editUrl = pathname.replace('status', 'edit');
  const isOneTimeNeedType = isOneTimeNeed(need);
  const needType = isOneTimeNeedType ? 'Разовая' : 'Регулярная';
  const amountSuffix = isOneTimeNeedType ? '₽' : '₽/мес.';

  return (
    <>
      <Card>
        <CardContent className={classes.content}>
          <Avatar className={classes.avatar}>
            <NeedIcon need={need} />
          </Avatar>
          <Typography component="div" variant="h5" align="center" className={classes.headerLabel}>
            {getNeedName(need)}
          </Typography>
          <CardContent>
            <Button color="primary" variant="contained" component={Link} to={editUrl}>
              Редактировать
            </Button>
          </CardContent>
        </CardContent>
        <CardContent className={classes.content}>
          <PropertiesTable>
            <PropertiesTableRow caption="ID" value={need.id} />
            <PropertiesTableRow caption="Тип потребности" value={needType} />
            <PropertiesTableRow
              caption="Сумма потребности"
              value={`${need.requiredAmount} ${amountSuffix}`}
            />
          </PropertiesTable>
          <Divider className={classes.divider} />
          <Box className={classes.story}>{getNeedDescription(need)}</Box>

          <Divider className={classes.divider} />
          <Box className={classes.story}>
            <ArchiveNeedButton needId={need.id} />
          </Box>
        </CardContent>
      </Card>

      {isRegularNeed(need) && (
        <Card>
          <CardHeader title="Периоды потребности" />
          <CardContent>
            <RegularNeedPeriods />
          </CardContent>
        </Card>
      )}
    </>
  );
};
