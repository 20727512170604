import React from 'react';
import { AutoFocusedForm, CsvField } from 'components';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import { Loading } from 'ui/Loading';
import { Field } from 'react-final-form';
import { useAwardCsvModalForm } from './useAwardCsvModalForm';
import { MutableButton } from '../../../../ui/MutableButton';
import { RedButton } from '../../../../components/Buttons';
import { useStyles } from '../useStyles';

interface AwardEditModalFormProps {
  onClose: () => void;
}

export const AwardCsvModalForm = ({ onClose }: AwardEditModalFormProps) => {
  const styles = useStyles();

  const { onSubmit, loading, initialValues } = useAwardCsvModalForm({
    onClose,
  });

  if (loading) {
    return <Loading />;
  }

  return (
    <AutoFocusedForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, pristine }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card className={styles.root}>
              <CardHeader title={`Загрузить CSV файл`} />
              <Divider />
              <CardContent className={styles.content}>
                <Grid container>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Field name="fileId" component={CsvField} />
                    <Typography variant="body2">Файл с промокодами</Typography>
                  </Grid>
                </Grid>
              </CardContent>

              <CardActions className={styles.actions}>
                <MutableButton
                  loading={false}
                  className={styles.accessButton}
                  color="primary"
                  disabled={pristine}
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  Сохранить
                </MutableButton>
                <RedButton onClick={onClose} size="medium" type="button" variant="contained">
                  Отменить
                </RedButton>
              </CardActions>
            </Card>
          </form>
        );
      }}
    />
  );
};
