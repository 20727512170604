import { Modal } from 'components';
import Maybe from 'graphql/tsutils/Maybe';
import { useBackRoute } from 'hooks';
import React from 'react';
import { BenefactorFoundationComment } from './BenefactorFoundationComment';

export interface BenefactorFoundationCommentProps {
  foundationComment: Maybe<string> | undefined;
}

export const BenefactorFoundationCommentModal = ({
  foundationComment,
}: BenefactorFoundationCommentProps) => {
  const { onCancel } = useBackRoute();

  return (
    <Modal onClose={onCancel} aria-labelledby="Отзыв на помощника">
      <BenefactorFoundationComment foundationComment={foundationComment} />
    </Modal>
  );
};
