import React, { FC } from 'react';
import { useStyles } from './useStyles';
import { TitledToolbar } from 'components';
import { LayoutPageGridProps } from './types';

export const LayoutGrid: FC = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.layout}>{children}</div>;
};

export const LayoutPageGrid: FC<LayoutPageGridProps> = ({ children, title, tools, iconButton }) => {
  return (
    <>
      <TitledToolbar title={title} iconButton={iconButton}>
        {tools}
      </TitledToolbar>
      <LayoutContentGrid>{children}</LayoutContentGrid>
    </>
  );
};

export const LayoutContentGrid: FC = ({ children }) => {
  return <div className="layout-container">{children}</div>;
};
