import { useCallback, useContext, useEffect } from 'react';
import {
  AddClientVersionInput,
  ClientApplication,
  useAddClientVersionMutation,
  ClientVersionsDocument,
} from 'schema/serverTypes';
import { ClientVersionsQueryContext, QueryVariablesContext } from '../../QueryContextProvider';
import { FORM_ERROR, SubmissionErrors } from 'final-form';

interface AddVersionValues {
  client: string;
  major: string;
  minor: string;
  patch: string;
  mandatory: boolean;
}

const initialValues: AddVersionValues = {
  client: ClientApplication.Ios,
  major: '0',
  mandatory: false,
  minor: '0',
  patch: '0',
};

export const useVersionForm = (onClose: () => void) => {
  const [createVersionMutation, { data, loading }] = useAddClientVersionMutation();

  const variables = useContext<ClientVersionsQueryContext>(QueryVariablesContext);

  const onSubmit = useCallback(
    (values: AddVersionValues) => {
      const { client, major, mandatory, minor, patch } = values;
      const input: AddClientVersionInput = {
        client:
          client === ClientApplication.Ios ? ClientApplication.Ios : ClientApplication.Android,
        major: parseInt(major, 10),
        mandatory,
        minor: parseInt(minor, 10),
        patch: parseInt(patch, 10),
      };
      return createVersionMutation({
        variables: { input },
        refetchQueries: [
          {
            query: ClientVersionsDocument,
            variables,
          },
        ],
      }).catch(() => {
        return { [FORM_ERROR]: 'Ошибка добавления' } as SubmissionErrors;
      });
    },
    [createVersionMutation, variables]
  );

  useEffect(() => {
    if (data !== undefined) {
      onClose();
    }
  }, [data, onClose]);

  return { initialValues, onSubmit, loading };
};
