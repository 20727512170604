import React from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { useFoundationByIdQuery, useFoundationPermissionsByIdQuery } from 'schema';
import { Loading } from 'ui/Loading';
import { toast } from 'react-toastify';
import { RouteWithLayout } from 'components';
import { Layout } from 'layouts';
import { ViewFoundationPage } from '../ViewFoundationPage';
import { AddOrEditFoundationPage } from '../AddOrEditFoundationPage';
import { AddOrEditEventPage } from '../AddOrEditEventPage';
import { EventContainer } from '../EventPage/EventContainer';

export const FoundationContainer = () => {
  const { id } = useParams<{ id: string }>();

  const variables = {
    id,
  };

  const {
    data: permissions,
    loading: permissionsLoading,
    error: permissionsError,
  } = useFoundationPermissionsByIdQuery({
    variables,
  });

  const { data, loading, error } = useFoundationByIdQuery({
    variables,
  });

  if (loading || permissionsLoading) {
    return <Loading />;
  }

  if (error || permissionsError) {
    toast.error('Ошибка загрузки фонда');
    return null;
  }

  const foundation = data?.foundationById;

  if (foundation === null) {
    toast.warn('Фонд не найден');
    return null;
  }

  return (
    <Switch>
      <RouteWithLayout
        path="/foundations/manage/:id/event/create"
        layout={Layout}
        component={AddOrEditEventPage}
        createEvents={permissions?.me.permissions.foundation.createEvents}
        foundationId={foundation?.id}
      />
      <Route path="/foundations/manage/:id/event/:eventId" component={EventContainer} />
      <RouteWithLayout
        exact
        path="/foundations/manage/:id/edit"
        component={AddOrEditFoundationPage}
        foundation={foundation}
        permission={permissions?.me.permissions.foundation.edit}
        layout={Layout}
      />
      <RouteWithLayout
        path="/foundations/manage/:id"
        foundation={foundation}
        permissions={permissions?.me.permissions.foundation}
        component={ViewFoundationPage}
        layout={Layout}
      />
    </Switch>
  );
};
