import React from 'react';
import { Tabs, Tab, Box, makeStyles, Theme, createStyles } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { useTabs } from 'hooks';
import { TabPanel } from 'components';
import theme from 'theme';
import { ApplicationsGrid } from './ApplicationsGrid';
import { AttendanceGrid } from './AttendanceGrid';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      width: '100%',
    },
  })
);

interface MemberManagementBlockProps {
  eventId: string;
  selectApplications: boolean;
  rateAttendees: boolean;
}

export const MemberManagementBlock = (props: MemberManagementBlockProps) => {
  const { eventId, selectApplications, rateAttendees } = props;
  const styles = useStyles();

  const { tabIndex, onChangeTab, onChangeTabIndex, a11yProps } = useTabs();

  return (
    <>
      <Box mb={1} className={styles.content}>
        <Tabs
          value={tabIndex}
          onChange={onChangeTab}
          indicatorColor="primary"
          textColor="primary"
          aria-label="tabs"
        >
          <Tab label="Заявки на участие" {...a11yProps(0)} />
          <Tab label="Участники" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={tabIndex}
        onChangeIndex={onChangeTabIndex}
        className={styles.content}
      >
        <TabPanel value={tabIndex} index={0} dir={theme.direction}>
          <ApplicationsGrid eventId={eventId} selectApplications={selectApplications} />
        </TabPanel>
        <TabPanel value={tabIndex} index={1} dir={theme.direction}>
          <AttendanceGrid rateAttendees={rateAttendees} eventId={eventId} />
        </TabPanel>
      </SwipeableViews>
    </>
  );
};
