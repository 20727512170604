import React from 'react';
import { makeStyles, Theme, Grid, createStyles, Divider, Typography } from '@material-ui/core';

import { NeedInfoItemProps } from './types';
import { getNeedName, isOneTimeNeed, OneTimeNeed } from '../../../../schema';
import { Link, useLocation } from 'react-router-dom';
import { NeedIcon, Progress } from '../../../../components';
import { NeedCollectedAmountLabel } from './NeedCollectedAmountLabel';
import DocumentIcon from '@material-ui/icons/InsertDriveFile';
import moment from 'moment';
import { NeedMenuList } from './NeedMenuList';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      textAlign: 'left',
      color: theme.palette.text.secondary,
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      marginRight: theme.spacing(1),
      overflow: 'initial',
      width: 40,
      height: 'auto',
    },
    control: {
      paddingTop: '0 !important',
      paddingBottom: '0 !important',
    },
  })
);

export const NeedInfoItem = ({ need, ward }: NeedInfoItemProps) => {
  const classes = useStyles();
  const { requiredAmount } = need;
  // const collected = need.reduce((prev, cur) => cur.amount + prev, 0);

  const disabled = isOneTimeNeed(need) ? moment(need.targetDate as string) <= moment() : false;
  // const account = isOneTimeNeed(need) ? accounts[0] : null;

  const { pathname } = useLocation();

  const url = `${pathname}/needs/${need.id}/information`;

  return (
    <>
      <Grid item className={classes.paper} xs={4}>
        <Link to={url}>
          <NeedIcon need={need} className={classes.icon} />
        </Link>
        <Link to={url}>
          <Typography variant={'h5'}>{getNeedName(need)}</Typography>
        </Link>
      </Grid>

      <Grid item className={classes.paper} xs>
        {!!(need as OneTimeNeed)?.documents?.length && <DocumentIcon />}
      </Grid>

      <Grid item className={classes.paper} xs={4}>
        <NeedCollectedAmountLabel need={need} />
      </Grid>

      {!disabled && (
        <Grid item className={classes.control} xs>
          <NeedMenuList need={need} ward={ward} />
        </Grid>
      )}
      {isOneTimeNeed(need) ? (
        <Grid item xs={12}>
          <Progress total={requiredAmount} nonSubscription={need.account.amount} />
        </Grid>
      ) : null}

      <Divider />
    </>
  );
};
