import React, { useCallback } from 'react';
import { toast } from 'react-toastify';

import {
  Document,
  ProjectExpense,
  ProjectExpensesDocument,
  useUpdateProjectExpenseDocumentsMutation,
} from '../../../../schema';
import { ManageDocumentsForm, useMeContext } from '../../../../components';
import { Loading } from '../../../../ui/Loading';

type Props = {
  projectExpense: ProjectExpense;
};

export const ProjectExpenseDocuments = ({ projectExpense }: Props) => {
  const { documents, id: projectId } = projectExpense;
  const [
    updateProjectExpenseDocuments,
    { loading, error },
  ] = useUpdateProjectExpenseDocumentsMutation();
  const { user } = useMeContext();

  const onSave = useCallback(
    async (documents: Document[]) => {
      const files = documents.map((document) => {
        const {
          file: { id },
          title,
        } = document;
        return { id, title };
      });

      try {
        await updateProjectExpenseDocuments({
          variables: {
            input: {
              id: projectId,
              documents: files,
            },
          },
          refetchQueries: [
            {
              query: ProjectExpensesDocument,
              variables: { permission: user?.permissions?.project?.manageExpenses },
            },
          ],
        });
      } catch {
        error && toast.error('Ошибка загрузки документов');
      }
    },
    [updateProjectExpenseDocuments, projectId, user, error]
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <ManageDocumentsForm documents={documents} onSave={onSave} />
    </>
  );
};
