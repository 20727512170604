import React from 'react';
import { Benefactor } from 'schema';
import { AutoFocusedForm, DateField, required, TextField } from 'components';
import { Card, CardActions, CardContent, CardHeader, Divider, Grid } from '@material-ui/core';
import { useEditBenefactorPersonalDataForm } from './useEditBenefactorPersonalDataForm';
import { Field } from 'react-final-form';
import { MutableButton } from 'ui/MutableButton';
import { RedButton } from 'components/Buttons';
import { useBackRoute } from 'hooks';
import { useStyles } from '../useStyles';

interface EditBenefactorFormProps {
  benefactor: Benefactor;
}

export const EditBenefactorPersonalDataForm = (props: EditBenefactorFormProps) => {
  const { benefactor } = props;
  const styles = useStyles();

  const { onCancel } = useBackRoute();

  const { onSubmit, initialValues, loading } = useEditBenefactorPersonalDataForm(
    benefactor,
    onCancel
  );

  return (
    <AutoFocusedForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, pristine }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card className={styles.primary}>
              <CardHeader title="Персональные данные" />
              <Divider />
              <CardContent>
                <Grid container spacing={4}>
                  <Grid item lg={6} md={6} xl={6} xs={12}>
                    <Field
                      name="firstName"
                      label="Имя *"
                      component={TextField}
                      validate={required}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} xl={6} xs={12}>
                    <Field
                      name="lastName"
                      label="Фамилия *"
                      component={TextField}
                      validate={required}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item lg={6} md={6} xl={6} xs={12}>
                    <Field name="middleName" label="Отчество" component={TextField} />
                  </Grid>
                  <Grid item lg={6} md={6} xl={6} xs={12}>
                    <Field
                      name="birthday"
                      label="Дата рождения *"
                      fullWidth={true}
                      component={DateField}
                      returnTime={false}
                      validate={required}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Field
                      name="about"
                      label="Информация о пользователе"
                      component={TextField}
                      multiline
                      rows={5}
                      rowsMax={50}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item lg={6} md={6} xl={6} xs={12}>
                    <Field
                      name="vkUsername"
                      label="Имя пользователя в соцсети VK"
                      component={TextField}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <MutableButton
                  className={styles.accessButton}
                  loading={loading}
                  disabled={pristine}
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  Сохранить
                </MutableButton>
                <RedButton
                  color="primary"
                  size="medium"
                  type="button"
                  variant="contained"
                  onClick={onCancel}
                >
                  Отменить
                </RedButton>
              </CardActions>
            </Card>
          </form>
        );
      }}
    />
  );
};
