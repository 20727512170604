import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  BenefactorByIdDocument,
  useAddWardsToTheBenefactorDepositAccountForSpecificWardsMutation,
} from 'schema';
import { toast } from 'react-toastify';

export const useAddWardsModal = (
  id: string,
  accountId: string,
  handleCloseAddWards: () => void,
  permission: boolean
) => {
  const [selectedWards, setSelectedWards] = useState<string[]>([]);

  const refetchQueries = useMemo(() => {
    return [
      {
        query: BenefactorByIdDocument,
        variables: { id, permission: permission },
      },
    ];
  }, [id, permission]);

  const [
    addWards,
    { data, loading, error },
  ] = useAddWardsToTheBenefactorDepositAccountForSpecificWardsMutation({
    refetchQueries,
  });

  const handleGetAll = (wards: string[]) => {
    setSelectedWards(wards);
  };

  const handleChangeSelectedWards = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = e.target.name.toString();

    if (!selectedWards.includes(id)) {
      setSelectedWards([...selectedWards, id]);
      return;
    }

    setSelectedWards(selectedWards.filter((ward) => ward !== id));
  };

  useEffect(() => {
    if (error) {
      toast.error('Ошибка добавления подопечного');
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      toast.success('Подопечный успешно добавлен');
      handleCloseAddWards();
    }
  }, [data, handleCloseAddWards]);

  const handleAddWards = useCallback(async () => {
    await addWards({
      variables: {
        input: {
          wardIds: selectedWards,
          id: accountId,
        },
      },
    });
  }, [accountId, selectedWards, addWards]);

  return {
    handleAddWards,
    loading,
    selectedWards,
    handleGetAll,
    handleChangeSelectedWards,
  };
};
