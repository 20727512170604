import React from 'react';
import { createStyles, IconButton } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { makeStyles } from '@material-ui/styles';
import { ScreenStoryPropsFragment } from '../../../../../schema';
import { useScreensStorage } from '../../useScreensStorage';
import { useParams } from 'react-router-dom';
import { useIsEditStoryStorage } from '../../useIsEditStoryStorage';

type CopyScreenStoryButtonProps = {
  setScreenCards: (screen: ScreenStoryPropsFragment[]) => void;
  screenCards: ScreenStoryPropsFragment[];
  cardIndex: number;
  setIsStoryEdited: (isEdited: boolean) => void;
};
const CopyScreenStoryButton = ({
  screenCards,
  setScreenCards,
  cardIndex,
  setIsStoryEdited,
}: CopyScreenStoryButtonProps) => {
  const useStyles = makeStyles(() =>
    createStyles({
      copyButton: {
        color: screenCards[cardIndex].image ? 'white' : '',
        position: 'absolute',
        bottom: 0,
        right: 0,
      },
    })
  );
  const classes = useStyles();
  const { storyId } = useParams<{ storyId: string }>();
  const { updateScreensStorageHistory } = useScreensStorage();
  const { updateIsEditStoryStorageHistory } = useIsEditStoryStorage({ id: storyId });

  const copyCard = (index: number) => {
    const copiedCard = screenCards[index];
    const updatedCards = [...screenCards, copiedCard];
    updateScreensStorageHistory(updatedCards);
    setScreenCards(updatedCards);
    setIsStoryEdited(true);
    updateIsEditStoryStorageHistory(true);
  };

  return (
    <IconButton onClick={() => copyCard(cardIndex)} className={classes.copyButton}>
      <FileCopyIcon />
    </IconButton>
  );
};

export default CopyScreenStoryButton;
