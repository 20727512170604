import React from 'react';
import { LayoutPageGrid } from '../../../ui/LayoutGrid';
import { Button, Card, CardContent, createStyles } from '@material-ui/core';
import { useMeContext } from '../../../components';
import { makeStyles } from '@material-ui/styles';
import { SpecialProjectsGrid } from './SpecialProjectsGrid';

const useStyles = makeStyles(() =>
  createStyles({
    buttonsContainer: {
      display: 'flex',
      gap: '20px',
    },
  })
);

export const SpecialProjectsPage = () => {
  const { user } = useMeContext();
  const classes = useStyles();
  const isAllowed = user?.permissions.specialProjects.manage;

  return (
    <>
      <LayoutPageGrid
        title="Спецпроекты"
        tools={
          isAllowed ? (
            <div className={classes.buttonsContainer}>
              <Button color="primary" variant="contained" href={'/special-projects/create'}>
                Создать спецпроект
              </Button>
              <Button color="primary" variant="contained" href={'/special-projects/order'}>
                Упорядочить спецпроекты
              </Button>
            </div>
          ) : (
            <></>
          )
        }
      >
        <Card>
          <CardContent>
            <SpecialProjectsGrid />
          </CardContent>
        </Card>
      </LayoutPageGrid>
    </>
  );
};
