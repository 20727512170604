import React, { useState } from 'react';
import {
  CardContent,
  Avatar,
  Divider,
  Button,
  Typography,
  Box,
  Grid,
  IconButton,
} from '@material-ui/core';
import { CreateDepositAccount, BenefactorBlockedControl, DonateMoneyForm } from '../';
import { useStyles } from './useStyles';
import {
  BenefactorExtendedPropsFragment,
  BenefactorPaymentInformationFragment,
  BenefactorPermissions,
  CloudPaymentsSavedCardsPropsFragment,
  SubscriptionIntentUnion,
} from 'schema';
import { useBackRoute, usePermissionPage } from 'hooks';
import { Link, Route, useHistory } from 'react-router-dom';
import { BenefactorData, EditEmail, EditPhoneNumber } from './components';
import { AccountsDepositsName } from './types';
import { AmountLabel, Modal, RouteWithoutLayout, useMeContext } from 'components';
import AddIcon from '@material-ui/icons/Add';
import { capitalizeFirst } from 'helpers';
import EditIcon from '@material-ui/icons/Edit';
import { CreditCard, CheckCircle, Error } from '@material-ui/icons';
import { RecoverSubscriptions } from './components/RecoverSubscriptions';

export const getString = (
  value: string | undefined | null,
  defaultValue: string | undefined = undefined
) => {
  return value === undefined || value === null ? defaultValue : value;
};

export interface BenefactorCardProps {
  permissions: BenefactorPermissions;
  benefactor: BenefactorExtendedPropsFragment;
  paymentInformation?: BenefactorPaymentInformationFragment;
}

export const BenefactorCard = (props: BenefactorCardProps) => {
  const { benefactor, paymentInformation, permissions } = props;
  const classes = useStyles();
  const { onCancel } = useBackRoute();
  const history = useHistory();

  const { user } = useMeContext();

  usePermissionPage('/wards', permissions.viewPersonalData);

  const url = benefactor.photo?.url;
  const displayName = getString(benefactor.displayName, '');
  const [openPhone, setOpenPhone] = useState<boolean>(false);
  const [openEmail, setOpenEmail] = useState<boolean>(false);
  const [isOpenSubscription, setIsOpenSubscription] = useState<boolean>(false);

  const handleClickOpenPhone = () => {
    setOpenPhone(true);
  };
  const handleClickOpenEmail = () => {
    setOpenEmail(true);
  };

  const handleClickOpenSubscription = () => {
    setIsOpenSubscription(true);
  };

  const handleClosePhone = () => {
    setOpenPhone(false);
  };
  const handleCloseEmail = () => {
    setOpenEmail(false);
  };

  const handleCloseSubscription = () => {
    setIsOpenSubscription(false);
  };

  const handleGoAddAccount = () => {
    history.push(`/benefactors/manage/${benefactor?.id}/add-account`);
  };

  const getIconOfCard = (card: CloudPaymentsSavedCardsPropsFragment) => {
    if (card.failuresCount) {
      return <Error className={classes.errorIconColor} />;
    } else if (card.id === benefactor.defaultCloudPaymentsSavedCard?.id) {
      return <CheckCircle className={classes.defaultCardColor} />;
    } else {
      return <CreditCard className={classes.iconColor} />;
    }
  };

  return (
    <>
      <CardContent className={classes.content}>
        <Avatar alt={displayName} src={url} className={classes.avatar} />
        {permissions.edit && (
          <>
            <CardContent className={classes.buttons}>
              <Button
                color="primary"
                variant="contained"
                component={Link}
                to={`/benefactors/manage/${benefactor.id}/edit`}
              >
                Редактировать
              </Button>
              <BenefactorBlockedControl benefactor={benefactor} />
            </CardContent>
            <CardContent className={classes.buttons}>
              <Button
                color="primary"
                variant="contained"
                className={classes.button}
                onClick={handleClickOpenPhone}
              >
                Редактировать номер телефона
              </Button>
              <Button
                color="primary"
                variant="contained"
                className={classes.button}
                onClick={handleClickOpenEmail}
              >
                Редактировать email
              </Button>
              {benefactor.intentsToRecoverCancelledSubscriptions.length ? (
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  onClick={handleClickOpenSubscription}
                >
                  Восстановить подписки
                </Button>
              ) : null}
            </CardContent>
          </>
        )}
        <BenefactorData
          permissions={permissions}
          benefactor={benefactor}
          paymentInformation={paymentInformation}
        />
        {permissions.viewPaymentInformation && benefactor?.cloudPaymentsSavedCards.length > 0 && (
          <>
            <Divider className={classes.divider} />
            <Typography component="div" variant="h5" align="center" className={classes.headerLabel}>
              Привязанные карты
            </Typography>
            <Box className={classes.accounts} textAlign="start">
              {benefactor?.cloudPaymentsSavedCards?.map((card) => (
                <Grid container key={card.id} className={classes.account}>
                  <Grid xs={1} item>
                    {getIconOfCard(card)}
                  </Grid>
                  <Grid xs={10} item>
                    <Typography variant="h5">{`${card.cardType} ${card.maskedCardNumber}, ${card.cardExpirationDate}`}</Typography>
                  </Grid>
                  {card.failuresCount ? (
                    <Grid xs={6} item>
                      <Typography variant="h5">
                        Количество неуспешных попыток списания: {card.failuresCount}
                      </Typography>
                    </Grid>
                  ) : null}
                </Grid>
              ))}
            </Box>
          </>
        )}
        {user?.permissions?.accounts?.viewDepositAccounts && (
          <>
            <Divider className={classes.divider} />
            <Typography component="div" variant="h5" align="center" className={classes.headerLabel}>
              Счета-депозиты
            </Typography>
            <Box className={classes.accounts} textAlign="start">
              {paymentInformation?.depositAccounts?.map((account) => (
                <Grid container key={account.id} className={classes.account}>
                  <Grid xs={6} item>
                    <Link to={`/account/${account.id}`}>
                      <Typography variant="h5">
                        {account.__typename === 'BenefactorDepositAccountForSpecificWards'
                          ? account.title
                          : `${capitalizeFirst(AccountsDepositsName[account.__typename])}`}
                      </Typography>
                    </Link>
                  </Grid>
                  <Grid xs={4} item alignItems="center">
                    <AmountLabel value={account.amount || 0} showCurrency={true} />
                  </Grid>
                  <Grid xs={1} item>
                    {account.__typename === 'BenefactorDepositAccountForSpecificWards' && (
                      <Link to={`/benefactors/manage/${benefactor?.id}/account/${account.id}/`}>
                        <IconButton>
                          <EditIcon />
                        </IconButton>
                      </Link>
                    )}
                  </Grid>
                  <Grid xs={1} item>
                    {user?.permissions?.accounts?.manageDepositAccounts && (
                      <Link to={`/benefactors/manage/${benefactor?.id}/donate/${account.id}/`}>
                        <IconButton>
                          <AddIcon />
                        </IconButton>
                      </Link>
                    )}
                  </Grid>
                </Grid>
              ))}
            </Box>
          </>
        )}
        {permissions.viewPaymentInformation && (
          <CardContent>
            <Button color="primary" variant="contained" onClick={handleGoAddAccount}>
              Создать счет
            </Button>
          </CardContent>
        )}
        <Route path="/benefactors/manage/:id/donate/:accountId/">
          <Modal
            onClose={onCancel}
            aria-labelledby="Занести чемодан денег"
            aria-describedby="Используйте эту форму, чтобы занести чемодан денег"
          >
            <DonateMoneyForm
              benefactor={benefactor}
              permission={permissions.viewPaymentInformation}
            />
          </Modal>
        </Route>
        <RouteWithoutLayout
          exact
          path="/benefactors/manage/:id/add-account"
          component={CreateDepositAccount}
          componentProps={{
            benefactorId: benefactor?.id,
            permissions: user?.permissions?.accounts?.manageDepositAccounts,
          }}
        />
      </CardContent>
      {openPhone && (
        <EditPhoneNumber
          openPhone={openPhone}
          handleClosePhone={handleClosePhone}
          phoneNumber={benefactor.phoneNumber}
          benefactorId={benefactor.id}
        />
      )}
      {openEmail && (
        <EditEmail
          userMail={benefactor.email}
          benefactorId={benefactor.id}
          openEmail={openEmail}
          handleCloseEmail={handleCloseEmail}
        />
      )}
      {isOpenSubscription && (
        <RecoverSubscriptions
          benefactorId={benefactor.id}
          isOpen={isOpenSubscription}
          handleClose={handleCloseSubscription}
          cloudPaymentsSavedCards={benefactor.cloudPaymentsSavedCards}
          intentsToRecoverCancelledSubscriptions={
            (benefactor.intentsToRecoverCancelledSubscriptions as unknown) as SubscriptionIntentUnion[]
          }
        />
      )}
    </>
  );
};
