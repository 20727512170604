import React from 'react';
import { Card, CardContent, IconButton, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useStyles } from './useStyles';
import { Link } from 'react-router-dom';

type Props = {
  path: string;
  text: string;
};
export const AddCard = ({ path, text }: Props) => {
  const classes = useStyles();

  return (
    <Link to={path}>
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <Typography className={classes.text}>{text}</Typography>
          <IconButton>
            <AddIcon />
          </IconButton>
        </CardContent>
      </Card>
    </Link>
  );
};
