import { WardDeactivationReason } from '../../schema';

export interface DeactivationReasonInformation {
  reason: WardDeactivationReason;
  description: string;
  allowedToUse: boolean;
}

export const getDeactivationReason = (reason: WardDeactivationReason) =>
  DeactivationReasons.find((x) => x.reason === reason)?.description;

export const DeactivationReasons: DeactivationReasonInformation[] = [
  {
    reason: WardDeactivationReason.Death,
    description: 'Смерть',
    allowedToUse: false,
  },
  {
    reason: WardDeactivationReason.Refusal,
    description: 'Отказ от помощи',
    allowedToUse: true,
  },
  {
    reason: WardDeactivationReason.Discontinuation,
    description: 'Отключение от проекта',
    allowedToUse: true,
  },
];
