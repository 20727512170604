import React from 'react';
import { FormControl, FormHelperText, InputLabel, Select, SelectProps } from '@material-ui/core';
import { FieldRenderProps } from 'react-final-form-hooks';
import { withStyles, StyledComponentProps } from '@material-ui/core/styles';

const styles = {
  root: {
    width: '100%',
    minWidth: '200px',
  },
};

export type SelectFieldProps = Omit<SelectProps, 'name' | 'onChange' | 'value'> &
  FieldRenderProps &
  StyledComponentProps;

export const SelectField = withStyles(styles)((props: SelectFieldProps) => {
  const { input, meta, children, classes, label, multiple, ...rest } = props;
  const error = !meta.valid && meta.touched;
  const helperText = !meta.valid && meta.touched ? meta.error : null;
  const labelId = `label-${input.name}`;
  const selectId = `select-${input.name}`;
  return (
    <FormControl variant="outlined" className={classes?.root} error={error}>
      {label !== undefined ? <InputLabel id={labelId}>{label}</InputLabel> : null}
      <Select
        {...rest}
        name={input.name}
        labelId={labelId}
        id={selectId}
        value={input.value}
        onChange={input.onChange}
        onBlur={input.onBlur}
        onFocus={input.onFocus}
        label={label}
        multiple={multiple}
      >
        {children}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
});
