import React, { useCallback, useEffect } from 'react';
import { AutoFocusedForm } from 'components';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  createStyles,
  Divider,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { MutableButton } from 'ui/MutableButton';
import { RedButton } from 'components/Buttons';
import { Loading } from 'ui/Loading';
import {
  DeleteStoryInput,
  StoryBlockDocument,
  useDeleteStoryMutation,
  useStoryByIdQuery,
} from '../../../../schema';
import { toast } from 'react-toastify';
import { FORM_ERROR, SubmissionErrors } from 'final-form';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 500,
    },
    header: {
      fontWeight: 'bolder',
    },
    content: {
      margin: '10px',
    },
    actions: {
      justifyContent: 'flex-end',
    },
    accessButton: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.success.dark,
      '&:hover': {
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.success.light,
      },
    },
  })
);

interface DeleteBlockStoryModalFormProps {
  onClose: () => void;
}

export const DeleteStoryModalForm = ({ onClose }: DeleteBlockStoryModalFormProps) => {
  const styles = useStyles();
  const { storyId } = useParams<{ storyId: string }>();
  const { data: storyData, loading: storyLoading } = useStoryByIdQuery({
    variables: { id: storyId },
  });
  const [deleteStory, { data, error, loading }] = useDeleteStoryMutation();

  const onSubmit = useCallback(async () => {
    const input: DeleteStoryInput = {
      id: storyId,
    };
    try {
      return await deleteStory({
        variables: { input },
        refetchQueries: [
          {
            query: StoryBlockDocument,
          },
        ],
      });
    } catch {
      error && toast.error('Ошибка удаления истории');
      return { [FORM_ERROR]: 'Ошибка удаления истории' } as SubmissionErrors;
    }
  }, [error, deleteStory, storyId]);

  useEffect(() => {
    if (data !== undefined) {
      onClose();
    }
  }, [data, onClose]);

  useEffect(() => {
    if (data?.deleteStory.success === false) {
      toast.error('Ошибка удаления истории');
    }
  }, [data?.deleteStory.success]);

  if (loading || storyLoading) {
    return <Loading />;
  }

  return (
    <AutoFocusedForm
      onSubmit={onSubmit}
      render={({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card className={styles.root}>
              <CardHeader title={`Подтвердите удаление истории`} />
              <Divider />
              <CardContent>
                <Typography>{`Вы действительно хотите удалить историю ${storyData?.storyById?.title}?`}</Typography>
              </CardContent>
              <CardActions className={styles.actions}>
                <MutableButton
                  className={styles.accessButton}
                  loading={loading}
                  color="primary"
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  Да, удалить
                </MutableButton>
                <RedButton
                  onClick={onClose}
                  disabled={loading}
                  size="medium"
                  type="button"
                  variant="contained"
                >
                  Нет, не удалять
                </RedButton>
              </CardActions>
            </Card>
          </form>
        );
      }}
    />
  );
};
