import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { AutoFocusedForm, required, TextField } from 'components';
import { Field } from 'react-final-form';
import { MutableButton } from 'ui/MutableButton';
import { RedButton } from 'components/Buttons';
import React from 'react';
import { useEditTitle } from './useEditTitle';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accessButton: {
      marginLeft: '15px',
      marginRight: '15px',
      color: theme.palette.common.white,
      backgroundColor: theme.palette.success.dark,
      '&:hover': {
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.success.light,
      },
    },
    header: {
      fontSize: '18px',
      fontWeight: 'bold',
    },
  })
);

interface Props {
  title: string;
  openEditTitle: boolean;
  handleCloseTitle: () => void;
}

export const EditAccountTitle = ({ openEditTitle, handleCloseTitle, title }: Props) => {
  const styles = useStyles();
  const { accountId } = useParams<{ accountId: string }>();

  const { onSubmit, initialValues, loading } = useEditTitle(accountId, title, handleCloseTitle);

  return (
    <Dialog open={openEditTitle} onClose={handleCloseTitle} maxWidth="xs">
      <DialogTitle disableTypography className={styles.header}>
        Редактировать название
      </DialogTitle>
      <DialogContent>
        <AutoFocusedForm
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit, pristine }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Field
                  name="title"
                  label="Название *"
                  fullWidth
                  component={TextField}
                  validate={required}
                />
                <DialogActions>
                  <MutableButton
                    className={styles.accessButton}
                    loading={loading}
                    disabled={pristine}
                    size="medium"
                    type="submit"
                    variant="contained"
                  >
                    Сохранить
                  </MutableButton>
                  <RedButton
                    color="primary"
                    size="medium"
                    type="button"
                    variant="contained"
                    onClick={handleCloseTitle}
                  >
                    Отменить
                  </RedButton>
                </DialogActions>
              </form>
            );
          }}
        />
      </DialogContent>
    </Dialog>
  );
};
