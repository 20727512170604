import React from 'react';
import { FormControlLabel, MenuItem, Radio } from '@material-ui/core';
import { Field } from 'react-final-form';
import { CsvField, RadioGroupField, RadioGroupFieldProps, required, SelectField } from 'components';
import { BroadcastNotificationRecipientsLabels } from '../../enumsObjects';

export enum TypeRecipient {
  Condition = 'Условие',
  FromFile = 'Из файла с идентификаторами',
}

export type BroadcastRecipientFieldProps = RadioGroupFieldProps<TypeRecipient> & {
  selectedValue: string;
  setSelectedValue: (value: string) => void;
};

export const BroadcastRecipientField = (props: BroadcastRecipientFieldProps) => {
  const {
    selectedValue,
    setSelectedValue,
    input: { name },
  } = props;

  return (
    <RadioGroupField<TypeRecipient> {...props} row={true}>
      <FormControlLabel
        value={TypeRecipient.Condition}
        label={TypeRecipient.Condition}
        labelPlacement="end"
        control={
          <Field
            name={name}
            type="radio"
            render={({ input }) => {
              const { type, ...restInput } = input;
              return (
                <Radio
                  defaultChecked
                  {...restInput}
                  onChange={(event) => setSelectedValue(event.target.value)}
                />
              );
            }}
          />
        }
      />
      <FormControlLabel
        value={TypeRecipient.FromFile}
        label={TypeRecipient.FromFile}
        labelPlacement="end"
        control={
          <Field
            name={name}
            type="radio"
            render={({ input }) => {
              const { type, ...restInput } = input;
              return (
                <Radio {...restInput} onChange={(event) => setSelectedValue(event.target.value)} />
              );
            }}
          />
        }
      />
      {selectedValue === '' ? null : selectedValue === TypeRecipient.Condition ? (
        <Field name="recipients" component={SelectField} label="Получатели *" validate={required}>
          {Object.entries(BroadcastNotificationRecipientsLabels).map((entry, key) => (
            <MenuItem value={entry[0]} key={key}>
              {entry[1]}
            </MenuItem>
          ))}
        </Field>
      ) : (
        <Field
          label="Список прикреплённых документов *"
          name="fileWithListOfRecipients"
          component={CsvField}
          validate={required}
        />
      )}
    </RadioGroupField>
  );
};
