import React, { useCallback, useState } from 'react';
import { FieldRenderProps } from 'react-final-form-hooks';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import Autocomplete, {
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
  AutocompleteInputChangeReason,
  RenderInputParams,
  AutocompleteProps,
} from '@material-ui/lab/Autocomplete';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { useAddressSuggestions, OptionType } from './useAddressSuggestions';
import { UseAutocompleteSingleProps } from '@material-ui/lab/useAutocomplete';
import { AddressSuggestionMode } from 'schema';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    option: {
      fontSize: 15,
      '& > span': {
        marginRight: theme.spacing(1),
        fontSize: 18,
      },
    },
  })
);

interface AddressSuggestionTypes {
  mode?: AddressSuggestionMode;
  needDetermineCoordinates?: 'hasRegion' | 'hasCity' | 'hasHouse' | 'hasCoordinates';
}

export type AddressFieldProps = FieldRenderProps &
  AutocompleteProps<string> &
  UseAutocompleteSingleProps<string> &
  TextFieldProps &
  AddressSuggestionTypes;

export const AddressField = (props: AddressFieldProps) => {
  const classes = useStyles();

  const { input, meta, label, mode, needDetermineCoordinates } = props;
  const { value: address } = input;

  const [valueText, setValueText] = useState(address.trimEnd());

  const suggestions = useAddressSuggestions(valueText, 10, 500, mode, needDetermineCoordinates);

  const onChange = useCallback(
    (
      _event: React.ChangeEvent<{}>,
      option: OptionType | null,
      reason: AutocompleteChangeReason,
      _details?: AutocompleteChangeDetails<OptionType | null>
    ) => {
      if (reason === 'blur') {
        input.onBlur();
      } else if (reason === 'clear') {
        input.onChange('');
        setValueText('');
      } else if (reason === 'select-option') {
        const value: string = option !== null ? option?.value : '';
        input.onChange(value);
        setValueText('');
      }
    },
    [input]
  );

  const onInputChange = useCallback(
    (_event: React.ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => {
      if (reason === 'clear') {
        input.onChange('');
        setValueText('');
      } else if (reason === 'input') {
        input.onChange('');
        setValueText(value);
      }
    },
    [input]
  );

  const error = !meta.valid && meta.touched;
  const helperText = !meta.valid && meta.touched ? meta.error : null;

  const onRenderInput = useCallback(
    (params: RenderInputParams) => {
      return (
        <TextField
          {...params}
          error={error}
          label={label}
          helperText={helperText}
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            onBlur: input.onBlur,
            onFocus: input.onFocus,
            autoComplete: 'new-password',
          }}
        />
      );
    },
    [error, helperText, input, label]
  );

  return (
    <Autocomplete
      options={suggestions}
      classes={classes}
      inputValue={input.value || valueText}
      getOptionSelected={() => true}
      getOptionLabel={(option) => option.label}
      renderOption={(option) => option.label}
      onChange={onChange}
      onInputChange={onInputChange}
      renderInput={onRenderInput}
      filterOptions={(options) => options}
      noOptionsText="Не найдено..."
    />
  );
};
