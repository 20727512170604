import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  Divider,
  CardContent,
  Button,
  CardActions,
  makeStyles,
  createStyles,
  List,
  ListItem,
} from '@material-ui/core';
import { AutoFocusedForm } from 'components';
import { useUpdateOrderCategoriesForm } from './useUpdateOrderCategoryiesForm';
import { arrayMove, SortableContainer } from 'react-sortable-hoc';
import { CategoryPropsFragment } from '../../../../schema';
import { SortableCategory } from './SortableCategory';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minWidth: 700,
    },
    header: {
      fontWeight: 'bolder',
    },
    actions: {
      justifyContent: 'flex-end',
    },

    categoryContainer: {
      position: 'relative',
      padding: '10px',
      width: '100%',
    },
    sortableContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
    },
  })
);

type UpdateOrderCategoriesFormProps = {
  onClose: () => void;
  categories?: CategoryPropsFragment[] | null;
};

export const UpdateOrderCategoriesForm = ({
  onClose,
  categories,
}: UpdateOrderCategoriesFormProps) => {
  const styles = useStyles();
  const [ids, setIds] = useState<string[]>([]);
  const [sortableCategories, setSortableCategories] = useState<
    CategoryPropsFragment[] | undefined | null
  >(categories);
  const { loading, onSubmit } = useUpdateOrderCategoriesForm({ onClose, ids });

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    const newOrderCategories = arrayMove(sortableCategories!, oldIndex, newIndex);
    setIds(newOrderCategories.map(({ id }) => id));
    setSortableCategories(newOrderCategories);
  };

  const SortableCategoryList = SortableContainer(
    ({ categories }: { categories: CategoryPropsFragment[] | undefined | null }) => {
      return (
        <div className={styles.sortableContainer}>
          {categories &&
            categories?.map(({ id, name }, index) => (
              <ListItem key={id}>
                <SortableCategory name={name} index={index} />
              </ListItem>
            ))}
        </div>
      );
    }
  );

  return (
    <AutoFocusedForm
      onSubmit={onSubmit}
      render={({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card className={styles.root}>
              <CardHeader title="Удаление категории" />
              <Divider />
              <CardContent>
                <List>
                  <SortableCategoryList
                    categories={sortableCategories}
                    onSortEnd={onSortEnd}
                    axis="xy"
                    useDragHandle={true}
                  />
                </List>
              </CardContent>
              <CardActions className={styles.actions}>
                <Button
                  disabled={loading}
                  color="primary"
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  ОК
                </Button>
                <Button onClick={onClose} size="medium" type="button" variant="contained">
                  Отменить
                </Button>
              </CardActions>
            </Card>
          </form>
        );
      }}
    />
  );
};
