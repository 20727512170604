import React from 'react';
import { NeedsTabProps } from './types';
import { Grid, Typography, makeStyles, Theme, createStyles, Divider, Box } from '@material-ui/core';
import { isRegularNeed, isOneTimeNeed, Ward } from 'schema';
import { NeedInfoItem } from './NeedInfoItem';
import { AmountLabel, Progress } from 'components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: 'hidden',
    },
    subtitle: {
      paddingTop: theme.spacing(2),
    },
    gray: {
      color: theme.palette.text.secondary,
    },
    spacer: {
      marginTop: 5,
    },
  })
);

export const NeedsTab = (props: NeedsTabProps) => {
  const classes = useStyles();
  const { needs, ward, active } = props;

  const regularNeeds = needs.filter(isRegularNeed);
  const oneTimeNeeds = needs.filter(isOneTimeNeed);

  const regularNeedsView =
    regularNeeds.length > 0 ? (
      <>
        <Grid item xs={12}>
          <Typography variant="subtitle2" className={classes.subtitle}>
            Регулярные
          </Typography>
        </Grid>
        {active && <SubscriptionsInfo ward={ward} />}
        {active && <WalletInfo ward={ward} />}
        {regularNeeds.map((need) => {
          return <NeedInfoItem key={need.id} need={need} ward={ward} />;
        })}
      </>
    ) : null;

  const oneTimeNeedsView =
    oneTimeNeeds.length > 0 ? (
      <>
        <Grid item xs={12}>
          <Typography variant="subtitle2" className={classes.subtitle}>
            Разовые
          </Typography>
        </Grid>
        <Divider />
        {oneTimeNeeds.map((need) => {
          return <NeedInfoItem key={need.id} need={need} ward={ward} />;
        })}
      </>
    ) : null;

  return (
    <Grid container spacing={3}>
      {regularNeedsView}
      {oneTimeNeedsView}
    </Grid>
  );
};

interface SubscriptionsInfoProps {
  ward: Ward;
}

const SubscriptionsInfo = (props: SubscriptionsInfoProps) => {
  const classes = useStyles();
  const { ward } = props;
  const {
    countsAndAmounts: { activeRegularNeedsRequiredAmount, activeSubscriptionsAmount },
    regularNeeds: { existingSubscriptionsAmount, newSubscriptionsAmount },
  } = ward;

  return (
    <>
      <Grid item xs={6}>
        <Box mb={1}>
          <Typography variant="h5">Подписки</Typography>
        </Box>
        <Box mb={1}>
          <Typography component="span">
            <AmountLabel value={activeSubscriptionsAmount} showCurrency={false} />
            &nbsp;из&nbsp;
          </Typography>
          <Typography component="span" className={classes.gray}>
            <AmountLabel value={activeRegularNeedsRequiredAmount} showCurrency={false} /> ₽
          </Typography>
        </Box>
        <Progress
          total={activeRegularNeedsRequiredAmount}
          subscription={existingSubscriptionsAmount}
          nonSubscription={newSubscriptionsAmount}
        />
        <Grid item xs={12} sm container className={classes.spacer}>
          <Grid item xs={9}>
            <Typography>Подписки прошлых месяцев</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography align="right">
              <AmountLabel value={existingSubscriptionsAmount} showCurrency={false} />
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm container>
          <Grid item xs={9}>
            <Typography>Подписки этого месяца</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography align="right">
              <AmountLabel value={newSubscriptionsAmount} showCurrency={false} />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

interface WalletInfoProps {
  ward: Ward;
}

const WalletInfo = (props: WalletInfoProps) => {
  const classes = useStyles();
  const { ward } = props;
  const {
    countsAndAmounts: {
      walletSubscriptionDonationsAmount,
      walletNonSubscriptionDonationsAmount,
      maximumAllowedRegularNeedsAmount,
    },
  } = ward;

  return (
    <>
      <Grid item xs={6}>
        <Box mb={1}>
          <Typography variant="h5">Счёт-кошелёк</Typography>
        </Box>
        <Box mb={1}>
          <Typography component="span">
            <AmountLabel
              value={walletSubscriptionDonationsAmount + walletNonSubscriptionDonationsAmount}
              showCurrency={false}
            />
            &nbsp;из&nbsp;
          </Typography>
          <Typography component="span" className={classes.gray}>
            <AmountLabel value={maximumAllowedRegularNeedsAmount} showCurrency={false} /> ₽
          </Typography>
        </Box>
        <Progress
          total={maximumAllowedRegularNeedsAmount}
          subscription={walletSubscriptionDonationsAmount ?? 0}
          nonSubscription={walletNonSubscriptionDonationsAmount ?? 0}
        />
        <Grid item xs={12} sm container className={classes.spacer}>
          <Grid item xs={9}>
            <Typography>Подписка</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography align="right">
              <AmountLabel value={walletSubscriptionDonationsAmount} />
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm container>
          <Grid item xs={9}>
            <Typography>Разовые платежи</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography align="right">
              <AmountLabel value={walletNonSubscriptionDonationsAmount} />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
