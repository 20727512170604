import React, { useCallback } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { useEnableSubscriptionTransferToWardMutation } from 'schema/serverTypes';
import { MutableButton } from 'ui/MutableButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      '&:hover': {
        borderColor: theme.palette.primary.main,
      },
      marginRight: theme.spacing(1),
    },
  })
);

export const EnableSubscriptionTransferButton = (props: { id: string }) => {
  const classes = useStyles();
  const { id } = props;
  const [
    enableSubscriptionTransferToWard,
    { loading },
  ] = useEnableSubscriptionTransferToWardMutation({
    variables: {
      input: {
        wardId: id,
      },
    },
  });
  const onClick = useCallback(async () => {
    await enableSubscriptionTransferToWard();
  }, [enableSubscriptionTransferToWard]);

  return (
    <MutableButton
      loading={loading}
      className={classes.button}
      variant="outlined"
      disabled={loading}
      onClick={onClick}
    >
      Разрешить перенос подписок
    </MutableButton>
  );
};
