import React from 'react';
import { Switch, useParams } from 'react-router-dom';
import { RouteWithLayout } from 'components';
import { Layout } from 'layouts';
import { EventPage } from '../EventPage';
import { AddOrEditEventPage } from '../../AddOrEditEventPage';
import { useEventPermissionsByIdQuery, useFoundationEventByIdQuery } from 'schema';
import { Loading } from 'ui/Loading';
import { toast } from 'react-toastify';
import { EventReportPage } from '../../EventReportPage';

export const EventContainer = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const variables = {
    id: eventId,
  };

  const {
    data: permissions,
    loading: permissionsLoading,
    error: permissionsError,
  } = useEventPermissionsByIdQuery({
    variables,
  });

  const { data, loading, error } = useFoundationEventByIdQuery({
    variables,
  });

  if (loading || permissionsLoading) {
    return <Loading />;
  }

  if (error || permissionsError) {
    toast.error('Ошибка загрузки события');
    return null;
  }

  const event = data?.foundationEventById;

  if (event === null) {
    toast.warn('Событие не найдено или у Вас нет прав на его просмотр');
    return null;
  }

  return (
    <Switch>
      <RouteWithLayout
        path="/foundations/manage/:id/event/:eventId/edit"
        layout={Layout}
        component={AddOrEditEventPage}
        foundationId={event?.foundation?.id}
        event={event}
        edit={permissions?.me.permissions.foundationEvent.edit}
      />
      <RouteWithLayout
        path="/foundations/manage/:id/event/:eventId/report"
        layout={Layout}
        component={EventReportPage}
        report={event?.report}
        eventId={event?.id}
        eventTitle={event?.details.title}
        permissions={permissions?.me.permissions.foundationEvent.edit}
      />
      <RouteWithLayout
        path="/foundations/manage/:id/event/:eventId"
        layout={Layout}
        component={EventPage}
        event={event}
        permissions={permissions?.me.permissions.foundationEvent}
      />
    </Switch>
  );
};
