import React, { FC } from 'react';

import { LayoutPageGridProps } from '../LayoutGrid/types';
import { TitledToolbar } from '../../components';
import { useStyles } from './useStyles';

export const CardGrid: FC = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.layout}>{children}</div>;
};

export const CardPageGrid: FC<LayoutPageGridProps> = ({ children, title, tools, iconButton }) => {
  return (
    <>
      <TitledToolbar title={title} iconButton={iconButton}>
        {tools}
      </TitledToolbar>
      <CardContentGrid>{children}</CardContentGrid>
    </>
  );
};

export const CardContentGrid: FC = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.layout}>{children}</div>;
};
