import React from 'react';
import { FormControlLabel, Radio } from '@material-ui/core';
import { Field } from 'react-final-form';
import { RadioGroupField, RadioGroupFieldProps } from 'components';
import { Gender } from 'schema/serverTypes';

export type GenderFieldProps = RadioGroupFieldProps<Gender>;

export const GenderField = (props: GenderFieldProps) => {
  const {
    input: { name },
  } = props;

  return (
    <RadioGroupField<Gender> {...props} row={true}>
      <FormControlLabel
        value={Gender.Male}
        label="Мужской"
        labelPlacement="end"
        control={
          <Field
            name={name}
            type="radio"
            render={({ input }) => {
              const { type, ...restInput } = input;
              return <Radio {...restInput} />;
            }}
          />
        }
      />
      <FormControlLabel
        value={Gender.Female}
        label="Женский"
        labelPlacement="end"
        control={
          <Field
            name={name}
            type="radio"
            render={({ input }) => {
              const { type, ...restInput } = input;
              return <Radio {...restInput} />;
            }}
          />
        }
      />
    </RadioGroupField>
  );
};
