import { useBackRoute } from '../../../../hooks';
import { Backdrop, Fade, Modal } from '@material-ui/core';
import React from 'react';
import { useStyles } from '../useStyles';
import { BenefactorStatusDeleteModalForm } from './BenefactorStatusDeleteModalForm';

export const BenefactorStatusDeleteModal = () => {
  const styles = useStyles();
  const { onCancel } = useBackRoute();

  return (
    <Modal
      open={true}
      onClose={onCancel}
      aria-labelledby="Удаление статуса"
      aria-describedby="Используйте эту форму для удаление статуса"
      className={styles.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={true}>
        <BenefactorStatusDeleteModalForm onClose={onCancel} />
      </Fade>
    </Modal>
  );
};
