import { WardProps } from 'pages';
import {
  Ward,
  AddOneTimeNeedInput,
  AccountQuery,
  Need,
  RegularNeedPeriod,
  ProjectExpense,
} from 'schema';

export interface EditWardProps {
  ward: Ward;
}

export interface NeedFormProps {
  required: (value: any) => string | undefined;
}

export type OneTimeNeedValues = Omit<AddOneTimeNeedInput, 'requiredAmount' | 'icon'> & {
  amount?: string;
  icon?: number;
};

export interface NeedFormValues {
  oneTime: OneTimeNeedValues;
  regularNeed: string;
}

export const getOneTimeNeed = (values: OneTimeNeedValues): AddOneTimeNeedInput | undefined => {
  const { amount, icon, url, title, description, targetDate } = values;
  if (
    amount === undefined ||
    icon === undefined ||
    title === undefined ||
    description === undefined ||
    targetDate === undefined
  ) {
    return undefined;
  }

  const amountNumber = Number(amount);
  if (isNaN(amountNumber)) {
    return undefined;
  }

  return {
    wardId: values.wardId,
    requiredAmount: amountNumber,
    title,
    icon,
    url,
    description,
    targetDate,
  };
};

export interface IAccountProps {
  account: AccountQuery['account'];
  need?: Need;
  period?: RegularNeedPeriod;
  project?: ProjectExpense;
}

export interface AddNeedFormProps extends WardProps {}
