import React from 'react';
import { Button, Card, CardContent } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { LayoutPageGrid } from 'ui/LayoutGrid/LayoutGrid';
import { CitiesGrid } from './CitiesGrid';
import { usePermissionPage } from 'hooks';
import { useMeContext } from 'components';

export const CitiesPage = () => {
  const { user } = useMeContext();

  usePermissionPage('/wards', user?.permissions.broadcastNotifications.view);

  return (
    <LayoutPageGrid
      title="Города"
      tools={
        <Button color="primary" variant="contained" component={Link} to="/cities/add">
          Добавить
        </Button>
      }
    >
      <Card>
        <CardContent>
          <CitiesGrid />
        </CardContent>
      </Card>
    </LayoutPageGrid>
  );
};
