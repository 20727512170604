import { useCallback, useEffect, useState } from 'react';
import { usePopover } from 'hooks';
import { DocumentItemProps } from './FileItem';

export const useFile = (props: DocumentItemProps & { fileName: string | undefined | null }) => {
  const { file, onRemove, onUpdate, index, fileName } = props;
  const [initialValue, setInitialValue] = useState(file);
  const popover = usePopover(file.file.id);
  const namePopover = usePopover(file.file.id);
  const { onClose } = popover;
  const { onClick, onClose: nameOnClose } = namePopover;

  const onDelete = useCallback(() => {
    onRemove(index);
    onClose();
  }, [index, onRemove, onClose]);

  const url = file.file.url;

  const onDownload = useCallback(() => {
    if (url !== undefined) {
      const a = document.createElement('a');
      a.target = '_blank';
      a.href = url;
      a.click();
      a.remove();
    }
  }, [url]);

  const onEdit = useCallback(
    (e) => {
      onClick(e);
    },
    [onClick]
  );

  const onSave = useCallback(() => {
    setInitialValue({ ...initialValue, title: fileName });
    nameOnClose();
    onClose();
  }, [initialValue, fileName, nameOnClose, onClose]);

  const onCancel = useCallback(() => {
    nameOnClose();
    onClose();
  }, [nameOnClose, onClose]);

  useEffect(() => {
    onUpdate(index, initialValue);
  }, [index, onUpdate, initialValue]);

  return {
    onDelete,
    onDownload,
    onEdit,
    onSave,
    onCancel,
    popover,
    namePopover,
  };
};
