import React from 'react';
import { PropertiesTable, PropertiesTableRow } from '../../../../ui/PropertiesTable';
import { StoryByIdQuery } from '../../../../schema';
import { Typography } from '@material-ui/core';
import { formatDateTime } from '../../../../helpers';

type StoryInfoCardProps = {
  data?: StoryByIdQuery;
};

export const StoryInfoCard = ({ data }: StoryInfoCardProps) => {
  return (
    <>
      <PropertiesTable>
        <PropertiesTableRow
          caption="Название истории"
          value={<Typography>{data?.storyById?.title}</Typography>}
        />
        <PropertiesTableRow
          caption="Информация о создании"
          value={formatDateTime(data?.storyById?.created.timestamp)}
        />
        <PropertiesTableRow
          caption="Информация об обновлении"
          value={formatDateTime(data?.storyById?.updated.timestamp)}
        />{' '}
        <PropertiesTableRow
          caption="Статус публикации"
          value={
            data?.storyById?.published !== null
              ? formatDateTime(data?.storyById?.published?.timestamp)
              : 'Не опубликована'
          }
        />
      </PropertiesTable>
    </>
  );
};
