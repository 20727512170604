import React from 'react';
import { CardContent } from '@material-ui/core';
import {
  SystemAccount,
  ProjectExpenseAccount,
  Account,
  SpecialProjectAccount,
  RegularNeedPeriodAccount,
} from 'schema';
import { IAccountProps } from 'pages/components';
import { ProjectExpenseAccountInformation } from './AccountInformationComponents/ProjectExpenseAccountInformation';
import { InvisibleCard } from 'ui/InvisibleCard';
import { ProjectAccountInformation } from './AccountInformationComponents/ProjectAccountInformation';
import { GenericAccountInformation } from './AccountInformationComponents/GenericAccountInformation';
import { SpecialProjectAccountInformation } from './AccountInformationComponents/SpecialProjectAccounttInformation';
import { RegularNeedAccountInformation } from './AccountInformationComponents/RegularNeedAccountInformation';

export const AccountInformation = ({ account }: IAccountProps) => {
  const type = account?.__typename;

  const ExtendedInformation = () => {
    switch (type) {
      case 'ProjectExpenseAccount':
        return <ProjectExpenseAccountInformation account={account as ProjectExpenseAccount} />;
      case 'SystemAccount':
        return <ProjectAccountInformation account={account as SystemAccount} />;
      case 'SpecialProjectAccount':
        return <SpecialProjectAccountInformation account={account as SpecialProjectAccount} />;
      case 'RegularNeedPeriodAccount':
        return <RegularNeedAccountInformation account={account as RegularNeedPeriodAccount} />;
      default:
        return <GenericAccountInformation account={(account as unknown) as Account} />;
    }
  };

  return (
    <InvisibleCard>
      <CardContent>
        <ExtendedInformation />
      </CardContent>
    </InvisibleCard>
  );
};
