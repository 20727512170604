import React, { useCallback } from 'react';
import { makeStyles, Theme, Button, createStyles } from '@material-ui/core';
import { useArchiveNeedMutation } from 'schema/serverTypes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
      '&:hover': {
        borderColor: theme.palette.error.main,
      },
    },
  })
);

interface ArchiveNeedButtonProps {
  needId: string;
}

export const ArchiveNeedButton = ({ needId }: ArchiveNeedButtonProps) => {
  const classes = useStyles();
  const [archive, { loading }] = useArchiveNeedMutation();
  const onClick = useCallback(() => {
    archive({
      variables: { input: { needId } },
    });
  }, [archive, needId]);

  return (
    <Button className={classes.button} variant="outlined" disabled={loading} onClick={onClick}>
      Удалить
    </Button>
  );
};
