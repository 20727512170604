import { useCallback, useEffect, useMemo } from 'react';
import { ProjectExpensesDocument, useAddProjectExpenseMutation } from 'schema/serverTypes';
import { useBackRoute } from 'hooks';
import moment from 'moment';
import { AddProjectExpenseValues } from './types';
import { toast } from 'react-toastify';

const createInitialValues = (): AddProjectExpenseValues => ({
  title: '',
  requiredAmount: '',
  referenceDate: '',
});

export const useAddProjectExpenseForm = () => {
  const { onCancel } = useBackRoute();

  const refetchQueries = useMemo(() => {
    return [
      {
        query: ProjectExpensesDocument,
      },
    ];
  }, []);

  const [addProjectExpense, { data, loading, error }] = useAddProjectExpenseMutation();

  useEffect(() => {
    if (error) {
      toast.error('Произошла ошибка добавления расхода');
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      onCancel();
    }
  }, [data, onCancel]);

  const onSubmit = useCallback(
    (values: AddProjectExpenseValues) => {
      const input = {
        ...values,
        requiredAmount: parseFloat(values.requiredAmount.toString()),
        referenceDate: moment(values.referenceDate).format('YYYY-MM-DD'),
      };
      addProjectExpense({
        refetchQueries,
        variables: { input },
        awaitRefetchQueries: true,
      });
    },
    [addProjectExpense, refetchQueries]
  );

  return {
    initialValues: createInitialValues(),
    onSubmit,
    onCancel,
    data,
    loading,
    error,
  };
};
