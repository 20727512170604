import React from 'react';

import { usePermissionPage } from 'hooks';
import { useMeContext } from 'components';
import { BlockStoriesPage } from '../Blocks/BlocksStoriesPage';

export const Stories = () => {
  const { user } = useMeContext();

  usePermissionPage('/dashboard', user?.permissions.stories.view);

  return (
    <>
      <BlockStoriesPage />
    </>
  );
};
