import React from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  createStyles,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { DeleteScreenStoryButton } from './DeleteScreenStoryButton';
import { ScreenStoryPropsFragment } from '../../../../../schema';
import CopyScreenStoryButton from './CopyScreenStoryButton';
import { SortableElement } from 'react-sortable-hoc';
import { DragHandleCard } from './DragHandleCard';

type ScreenStoryCardProps = {
  setScreenCards: (screen: ScreenStoryPropsFragment[]) => void;
  screenCards: ScreenStoryPropsFragment[];
  editScreen: () => void;
  cardIndex: number;
  setIsStoryEdited: (isEdited: boolean) => void;
  index: number;
} & ScreenStoryPropsFragment;

export const ScreenStoryCard = SortableElement(
  ({
    title,
    text,
    image,
    buttonTitle,
    buttonUrl,
    setScreenCards,
    cardIndex,
    screenCards,
    setIsStoryEdited,
    editScreen,
  }: ScreenStoryCardProps) => {
    const useStyles = makeStyles(() =>
      createStyles({
        card: {
          color: image ? 'white' : 'black',
          position: 'relative',
          width: '200px',
          height: '300px',
          backgroundImage: image ? `url(${image.url})` : '',
          backgroundSize: 'cover',
          zIndex: 1,
        },
        cardTitle: {
          color: image ? 'white' : 'black',
          fontSize: '18px',
          zIndex: 1,
        },
        cardDescription: {
          color: image ? 'white' : 'black',
          fontSize: '14px',
          zIndex: 1,
        },
        cardButton: {
          minWidth: '140px',
          maxWidth: '200px',
          zIndex: 1,
        },
        content: {
          height: '42%',
          cursor: 'pointer',
          zIndex: 1,
        },

        overlay: {
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))',
          zIndex: -1,
        },
      })
    );
    const classes = useStyles();
    return (
      <Card className={classes.card}>
        {image && <div className={classes.overlay} />}
        <CardHeader
          classes={{
            title: classes.cardTitle,
          }}
          title={title}
          action={
            <DeleteScreenStoryButton
              setScreenCards={setScreenCards}
              cardIndex={cardIndex}
              screenCards={screenCards}
              setIsStoryEdited={setIsStoryEdited}
            />
          }
        />
        <CardContent className={classes.content} onClick={editScreen}>
          <Typography className={classes.cardDescription}>{text}</Typography>
        </CardContent>
        <CardActions>
          {buttonTitle && (
            <Button
              href={buttonUrl!}
              className={classes.cardButton}
              color={'primary'}
              variant="contained"
            >
              {buttonTitle}
            </Button>
          )}

          <CopyScreenStoryButton
            setScreenCards={setScreenCards}
            cardIndex={cardIndex}
            screenCards={screenCards}
            setIsStoryEdited={setIsStoryEdited}
          />
          <DragHandleCard />
        </CardActions>
      </Card>
    );
  }
);
