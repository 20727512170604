import { useCallback, useEffect, useMemo } from 'react';
import {
  BankTransferPaymentIntentInput,
  BenefactorByIdDocument,
  BenefactorDepositAccountDonationIntentInput,
  BenefactorExtendedPropsFragment,
  PlaceOrderInput,
  usePlaceOrderDepositMutation,
} from 'schema';
import { FORM_ERROR, SubmissionErrors } from 'final-form';
import { toast } from 'react-toastify';

type DonateMoneyValues = BankTransferPaymentIntentInput &
  BenefactorDepositAccountDonationIntentInput;

export const useDonateMoneyForm = (
  accountId: string,
  benefactor: BenefactorExtendedPropsFragment,
  onCancel: () => void
) => {
  const refetchQueries = useMemo(() => {
    return [
      {
        query: BenefactorByIdDocument,
        variables: { id: benefactor.id, permission: true },
      },
    ];
  }, [benefactor.id]);

  const currentDateTime = new Date();

  const [donate, { data, loading }] = usePlaceOrderDepositMutation({
    refetchQueries,
  });

  const onSubmit = useCallback(
    async (values: DonateMoneyValues) => {
      const { amount, transferDate, counterpartyName, purpose, documentNumber } = values;
      const input: PlaceOrderInput = {
        benefactorId: benefactor.id,
        currentDateTime,
        donationIntents: [
          { depositToBenefactorAccount: { accountId, amount: Number(amount), transferDate } },
        ],
        paymentIntent: {
          bankTransfer: { counterpartyName, transferDate, purpose, documentNumber },
        },
      };

      return donate({ variables: { input } }).catch(() => {
        return { [FORM_ERROR]: 'Не удалось добавить платеж' } as SubmissionErrors;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountId, benefactor.id, donate]
  );

  useEffect(() => {
    if (data !== undefined && data?.placeOrder.errors) {
      toast.error(data.placeOrder.errors[0].message);
    }
  }, [data]);

  useEffect(() => {
    if (data !== undefined && data?.placeOrder.order) {
      onCancel();
    }
  }, [data, onCancel]);

  const initialValues = useMemo(() => {
    return {
      accountId,
      amount: 0,
      transferDate: '',
      counterpartyName: '',
      documentNumber: '',
      purpose: '',
    };
  }, [accountId]);

  return {
    initialValues,
    onSubmit,
    loading,
  };
};
