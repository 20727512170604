import { useCallback, useEffect } from 'react';
import {
  AddFoundationInput,
  FoundationPropsFragment,
  useAddFoundationMutation,
  useUpdateFoundationMutation,
} from 'schema';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

type InitialValues = {
  address: string;
  name: string;
  description: string;
  logoId: string;
};

export const useAddOrEditFoundationForm = (foundation?: FoundationPropsFragment) => {
  const history = useHistory();
  const [
    updateFoundationMutation,
    { loading: updateLoading, data: updateData, error: updateError },
  ] = useUpdateFoundationMutation();
  const [
    addFoundationMutation,
    { loading: addLoading, data: addData, error: addError },
  ] = useAddFoundationMutation();

  const data = foundation ? updateData : addData;
  const loading = foundation ? updateLoading : addLoading;
  const error = foundation ? updateError : addError;

  const onSubmit = useCallback(
    async (values: AddFoundationInput) => {
      const { logoId, address, ...rest } = values;

      const inputValue: AddFoundationInput = {
        ...rest,
        address:
          foundation && foundation.address.displayText === address
            ? foundation.address.fullAddress
            : address,
        logoId: logoId ? logoId : undefined,
      };

      foundation
        ? await updateFoundationMutation({
            variables: {
              input: {
                ...inputValue,
                id: foundation.id,
              },
            },
          })
        : await addFoundationMutation({
            variables: {
              input: inputValue,
            },
          });
    },
    [updateFoundationMutation, addFoundationMutation, foundation]
  );

  const initialValues: InitialValues = {
    name: foundation?.name || '',
    description: foundation?.description || '',
    logoId: foundation?.logo?.id || '',
    address: foundation?.address.displayText || '',
  };

  useEffect(() => {
    if (error) {
      toast.error(`Ошибка ${foundation ? 'изменения' : 'добавления'} фонда`);
    }

    if (data) {
      toast.success(`Фонд ${foundation ? 'изменен' : 'добавлен'}`);

      history.push(
        `/foundations/manage/${addData?.addFoundation.foundation?.id || foundation?.id}`
      );
    }
  }, [error, data, foundation, history, addData]);

  return {
    initialValues,
    onSubmit,
    loading,
  };
};
