import { BroadcastNotificationRecipients, BroadcastNotificationStatus } from '../../schema';

export const BroadcastNotificationRecipientsLabels = {
  [BroadcastNotificationRecipients.Everyone]: 'Все жертвователи',
  [BroadcastNotificationRecipients.NeverDonated]: 'Жертвователи, ни разу не перечислявшие денег',
  [BroadcastNotificationRecipients.Donated]:
    'Жертвователи, хоть раз перечислившие деньги (на фонд или на подопечных)',
  [BroadcastNotificationRecipients.DonatedToWards]:
    'Жертвователи, хоть раз перечислившие деньги на подопечных',
  [BroadcastNotificationRecipients.DonatedToProject]:
    'Жертвователи, хоть раз перечислившие деньги на проект',
  [BroadcastNotificationRecipients.WithoutSubscriptions]:
    'Жертвователи, ни разу не оформлявшие подписку',
  [BroadcastNotificationRecipients.WithActiveSubscriptions]:
    'Жертвователи с действующими подписками на проект ИЛИ на подопечных',
  [BroadcastNotificationRecipients.WithActiveWardSubscriptions]:
    'Жертвователи с действующими подписками на подопечных',
  [BroadcastNotificationRecipients.WithActiveProjectSubscriptions]:
    ' Жертвователи с действующими подписками на проект',
  [BroadcastNotificationRecipients.WithoutActiveSubscriptions]:
    'Жертвователи без действующих подписок',
  [BroadcastNotificationRecipients.WithoutActiveButWithCancelledSubscriptions]:
    'Жертвователи без действующих подписок, но имеющие отменённые подписки (на проект или на подопечных)',
  [BroadcastNotificationRecipients.WithoutActiveButWithCancelledWardSubscriptions]:
    'Жертвователи без действующих подписок, но имеющие отменённые подписки на подопечных',
  [BroadcastNotificationRecipients.WithoutActiveButWithCancelledProjectSubscriptions]:
    'Жертвователи без действующих подписок, но имеющие отменённые подписки на проект',
  [BroadcastNotificationRecipients.WithActiveProjectAndWardsSubscriptions]:
    'Жертвователи с действующими подписками на проект И на подопечных',
  [BroadcastNotificationRecipients.WithActiveProjectButWithoutActiveWardsSubscriptions]:
    'Жертвователи с действующими подписками на проект, но без действующих подписок на подопечных',
  [BroadcastNotificationRecipients.WithActiveWardsButWithoutActiveProjectSubscriptions]:
    'Жертвователи с действующими подписками на подопечных, но без действующих подписок на проект',
};

export const BroadcastNotificationStatusLabels = {
  [BroadcastNotificationStatus.Created]: 'Уведомление создано',
  [BroadcastNotificationStatus.Populating]:
    'Происходит создание уведомлений для конкретных получателей',
  [BroadcastNotificationStatus.Populated]: 'Уведомления для конкретных получателей созданы',
  [BroadcastNotificationStatus.Error]: 'Возникла ошибка при отправке',
};

/*export const BroadcastNotificationTargetScreenLabels = {
  [BroadcastNotificationTargetScreen.DonateWardsReserve]: 'Пожертвование в резерв подопечных',
};*/
