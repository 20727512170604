import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    buttons: {
      display: 'flex',
      justifyContent: 'center',
    },
    avatar: {
      width: theme.spacing(14),
      height: theme.spacing(14),
      marginBottom: theme.spacing(2),
    },
    tableInfoHeader: {
      borderBottom: 'none',
    },
    tableInfo: {
      textAlign: 'right',
      borderBottom: 'none',
      color: theme.palette.text.secondary,
    },
    divider: {
      borderBottom: theme.spacing(1),
      width: '100%',
      marginBottom: theme.spacing(1),
    },
    story: {
      textAlign: 'left',
      marginBottom: theme.spacing(1),
    },
    buttonsContainer: {
      display: 'flex',
      flexDirection: 'row-reverse',
      '& button': {
        marginLeft: theme.spacing(1),
      },
      marginBottom: theme.spacing(1),
    },
    headerLabel: {
      fontWeight: 'bolder',
    },
    headerAge: {
      marginTop: theme.spacing(1),
    },
    accountsList: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      columnGap: theme.spacing(1),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  })
);
