import React from 'react';
import { isOneTimeNeed, Need, RegularNeed } from '../../../../schema';
import { CollectedAmountLabel } from './CollectedAmountLabel';
import moment from 'moment';

type Props = {
  need: Need;
};

export const NeedCollectedAmountLabel = ({ need }: Props) => {
  const { requiredAmount } = need;
  const collected = (need as RegularNeed)?.periods?.nodes?.reduce(
    (prev, cur) => cur?.account?.amount! + prev,
    0
  );

  const endDate = isOneTimeNeed(need) ? moment(need.targetDate as string) : null;

  return (
    <CollectedAmountLabel
      total={requiredAmount}
      collected={isOneTimeNeed(need) ? need?.account?.amount : collected!}
      date={endDate}
      format="fullDate"
    />
  );
};
