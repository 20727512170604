import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';

export default {
  black,
  white,
  primary: {
    contrastText: white,
    darker: colors.lightBlue[700],
    dark: colors.lightBlue[500],
    main: colors.lightBlue[300],
    light: colors.lightBlue[50],
  },
  secondary: {
    contrastText: white,
    dark: colors.lightBlue[500],
    main: colors.lightBlue[300],
    light: colors.lightBlue[100],
  },
  success: {
    contrastText: white,
    dark: colors.green[700],
    main: colors.green[500],
    light: colors.green[50],
  },
  info: {
    contrastText: white,
    dark: colors.lightBlue[500],
    main: colors.lightBlue[300],
    light: colors.lightBlue[100],
  },
  warning: {
    contrastText: white,
    dark: colors.amber[900],
    main: colors.amber[700],
    light: colors.amber[500],
  },
  error: {
    contrastText: white,
    dark: colors.red[700],
    main: colors.red[500],
    light: colors.red[50],
  },
  text: {
    primary: colors.grey[900],
    secondary: colors.grey[500],
    link: colors.blue[600],
  },
  background: {
    default: colors.grey[50],
    paper: white,
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200],
};
