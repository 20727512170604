import React from 'react';
import { Card, CardActions, CardContent, CardHeader } from '@material-ui/core';
import { InvisibleCard } from '../../../../../ui/InvisibleCard';
import { AccountActions, AccountDonations } from '../../../../account/components';
import { OneTimeNeed } from '../../../../../schema';
import { OneTimeNeedInformation } from './OneTimeNeedInformation';
import { OneTimeNeedDocuments } from './OneTimeNeedDocuments';

type Props = {
  need: OneTimeNeed;
};

/*const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    buttons: {
      display: 'flex',
      justifyContent: 'center',
    },
    avatar: {
      width: theme.spacing(14),
      height: theme.spacing(14),
      marginBottom: theme.spacing(2),
    },
    tableInfoHeader: {
      borderBottom: 'none',
    },
    tableInfo: {
      textAlign: 'right',
      borderBottom: 'none',
      color: theme.palette.text.secondary,
    },
    divider: {
      borderBottom: theme.spacing(1),
      width: '100%',
      marginBottom: theme.spacing(1),
    },
    story: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      textAlign: 'left',
    },
    buttonsContainer: {
      display: 'flex',
      flexDirection: 'row-reverse',
      '& button': {
        marginLeft: theme.spacing(1),
      },
      marginBottom: theme.spacing(1),
    },
    headerLabel: {
      fontWeight: 'bolder',
    },
    headerStatus: {
      marginTop: theme.spacing(1),
    },
    statusLabel: {
      fontWeight: 'bolder',
    },
  })
);*/

const OneTimeNeedWardPage = ({ need }: Props) => {
  return (
    <>
      <Card>
        <CardHeader title="Информация о потребности" />
        <CardContent>
          <InvisibleCard>
            <CardContent>
              <OneTimeNeedInformation need={need} />
            </CardContent>
            <CardActions>
              <AccountActions account={need.account} need={need} />
            </CardActions>
          </InvisibleCard>
        </CardContent>
      </Card>
      <Card>
        <CardHeader title="Пожертвования" />
        <CardContent>
          <AccountDonations id={need.account.id} />
        </CardContent>
        <CardContent>
          <OneTimeNeedDocuments oneTimeNeed={need} />
        </CardContent>
      </Card>
    </>
  );
};

export default OneTimeNeedWardPage;
