import React, { useCallback } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { ManageWardPublicityButtonProps } from './types';
import { useUnpublishWardMutation } from 'schema/serverTypes';
import { MutableButton } from 'ui/MutableButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
      '&:hover': {
        borderColor: theme.palette.error.main,
      },
    },
  })
);

export const UnpublishWardButton = ({ id }: ManageWardPublicityButtonProps) => {
  const classes = useStyles();
  const [unpublish, { loading }] = useUnpublishWardMutation();
  const onClick = useCallback(() => {
    unpublish({
      variables: { input: { id } },
    });
  }, [unpublish, id]);

  return (
    <MutableButton
      className={classes.button}
      variant="outlined"
      loading={loading}
      onClick={onClick}
    >
      Скрыть
    </MutableButton>
  );
};
