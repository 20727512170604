import React from 'react';
import { Modal, useMeContext } from 'components';
import { useBackRoute, usePermissionPage } from 'hooks';
import { RouteComponentProps } from 'react-router-dom';
import { UniversalNeed } from 'schema';
import { EditNeedForm } from './EditNeedForm';
import { AddNeedFormProps } from './types';

interface EditedRouteParams {
  needId: string;
}

export interface EditNeedPageProps
  extends AddNeedFormProps,
    RouteComponentProps<EditedRouteParams> {}

export const EditNeedPage = ({ ward, match }: EditNeedPageProps) => {
  const { onCancel } = useBackRoute();
  const { needId } = match.params;
  const need: UniversalNeed | undefined = ward.needs.find((t) => t.id === needId);
  const { user } = useMeContext();

  usePermissionPage('/ward', user?.permissions.wards.manageNeeds);

  if (need === undefined) {
    return null;
  }

  return (
    <Modal
      onClose={onCancel}
      aria-labelledby="Редактирование модератора"
      aria-describedby="Используйте эту форму для редактирования модератора"
    >
      <EditNeedForm ward={ward} need={need} />
    </Modal>
  );
};
