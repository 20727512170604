import { createStyles, makeStyles, Typography } from '@material-ui/core';
import { formatDate } from 'helpers';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ColumnConfig, DataConnection, useQueryVariables } from 'hooks';
import {
  ApplicationsQueryVariables,
  FoundationEventApplication,
  FoundationEventApplicationFilterInput,
  FoundationEventApplicationSortInput,
  FoundationEventApplicationStatus,
  useApplicationsQuery,
} from 'schema';
import { DataGrid, DataGridProps } from 'components';
import { ApplicationsSearch } from './ApplicationsSearch';
import { ApplicationsActionsButton } from './ApplicationActionsButton';
import { toast } from 'react-toastify';
import { ApplicationsStatus } from './types';
import { QueryContextProvider } from './QueryContextProvider';
import { Star } from '@material-ui/icons';

const useStyles = makeStyles(() =>
  createStyles({
    nameContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    skillContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
    },
    starRate: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    star: {
      color: 'gold',
    },
  })
);

interface ApplicationsGridProps {
  eventId: string;
  selectApplications: boolean;
}

const getDataKey = (application: FoundationEventApplication) => application.id;

export const ApplicationsGrid = (props: ApplicationsGridProps) => {
  const { eventId, selectApplications } = props;
  const styles = useStyles();

  const showRating = (rating: number | null | undefined) =>
    rating === undefined || rating === null ? 'n/a' : rating.toFixed(2);

  const config = useMemo(() => {
    const columns: ColumnConfig<
      FoundationEventApplication,
      FoundationEventApplicationSortInput
    >[] = [
      {
        id: 'displayName',
        header: 'Имя',
        render: (application) => {
          return (
            <div className={styles.nameContainer}>
              <Link to={`/benefactors/manage/${application.benefactor.id}`}>
                <Typography variant="body1">{application.benefactor.displayName}</Typography>
              </Link>
            </div>
          );
        },
      },
      {
        id: 'benefactorRating',
        header: 'Рейтинг',
        render: (application) => {
          return (
            <div className={styles.nameContainer}>
              <Typography variant="body1" className={styles.starRate}>
                <Star className={styles.star} />
                {showRating(application.benefactorRating)}
              </Typography>
            </div>
          );
        },
      },
      {
        id: 'skills',
        header: 'Навыки',
        render: (application) => {
          return (
            <div className={styles.skillContainer}>
              <Typography variant="body1">
                {application.skill.map((skill) => skill.title).join(', ')}
              </Typography>
            </div>
          );
        },
      },
      {
        id: 'status',
        header: 'Статус заявки',
        render: (application) => {
          return (
            <div className={styles.nameContainer}>
              <Typography variant="body1">{ApplicationsStatus[application.status]}</Typography>
            </div>
          );
        },
      },
      {
        id: 'createdAt',
        header: 'Дата подачи заявки',
        render: (application) => {
          return formatDate(application.created.timestamp);
        },
      },
      {
        id: 'action',
        render: (application) => {
          return (
            selectApplications &&
            application.status !== FoundationEventApplicationStatus.Revoked &&
            application.status !== FoundationEventApplicationStatus.Declined && (
              <ApplicationsActionsButton
                attendance={application.attendance}
                applicationId={application.id}
              />
            )
          );
        },
      },
    ];
    return {
      columns,
    };
  }, [styles, selectApplications]);

  const {
    onSort,
    onSearch,
    onPage,
    variables,
    extractPagedData,
    columns,
    sortDirection,
    sortBy,
  } = useQueryVariables<FoundationEventApplication>(config);

  const createVariables: ApplicationsQueryVariables = {
    ...variables,
    id: eventId,
  };

  const { data, loading, error } = useApplicationsQuery({
    variables: createVariables,
  });

  if (error) {
    toast.error('Ошибка загрузки заявок');
    return null;
  }

  const entities = data?.foundationEventById?.applications as DataConnection<
    FoundationEventApplication
  >;
  const { data: application, count, startCursor, endCursor, page, rowsPerPage } = extractPagedData(
    entities
  );

  const gridProps: DataGridProps<
    FoundationEventApplication,
    FoundationEventApplicationFilterInput
  > = {
    data: application,
    onSort,
    onPage,
    onSearch,
    columns,
    getDataKey,
    count,
    startCursor: startCursor,
    endCursor: endCursor,
    page,
    searchComponent: ApplicationsSearch,
    rowsPerPage,
    sortDirection,
    sortBy,
    loading,
  };

  return (
    <QueryContextProvider variables={createVariables}>
      <DataGrid<FoundationEventApplication, FoundationEventApplicationFilterInput> {...gridProps} />
    </QueryContextProvider>
  );
};
