import React from 'react';
import {
  AutoFocusedForm,
  PhotoField,
  positiveNumber,
  required,
  StorySelect,
  TextField,
} from 'components';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { Loading } from 'ui/Loading';
import { Field } from 'react-final-form';
import { MutableButton } from '../../../ui/MutableButton';
import { RedButton } from '../../../components/Buttons';
import { useAddBenefactorStatusesForm } from './useAddBenefactorStatusesForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 500,
    },
    header: {
      fontWeight: 'bolder',
    },
    content: {
      margin: '10px',
    },
    actions: {
      justifyContent: 'flex-end',
    },
    accessButton: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.success.dark,
      '&:hover': {
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.success.light,
      },
    },
  })
);

interface AddAwardModalFormProps {
  onClose: () => void;
}

export const AddBenefactorStatusModalForm = ({ onClose }: AddAwardModalFormProps) => {
  const styles = useStyles();

  const {
    onSubmit,
    initialValues,
    loading: benefactorStatusLoading,
  } = useAddBenefactorStatusesForm(onClose);

  if (benefactorStatusLoading) {
    return <Loading />;
  }

  return (
    <AutoFocusedForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, pristine }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card className={styles.root}>
              <CardHeader title="Добавить новый статус" />
              <Divider />
              <CardContent className={styles.content}>
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Field
                      name="title"
                      component={TextField}
                      label="Название статуса"
                      validate={required}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Field
                      name="description"
                      component={TextField}
                      multiline
                      rows={3}
                      label="Описание статуса"
                      validate={required}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <StorySelect
                      name="storyId"
                      label="История с описанием статуса"
                      storyLabel={'statuses'}
                      validate={required}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} xl={6} xs={6}>
                    <Field
                      name="coins"
                      type="number"
                      component={TextField}
                      label="Количество монет"
                      validate={positiveNumber}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item lg={6} md={6} xl={6} xs={6}>
                    <Field name="imageId" component={PhotoField} validate={required} />
                    <Typography variant="body2">Изображение статуса</Typography>
                  </Grid>
                </Grid>
              </CardContent>

              <CardActions className={styles.actions}>
                <MutableButton
                  className={styles.accessButton}
                  loading={benefactorStatusLoading}
                  color="primary"
                  disabled={pristine}
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  Сохранить
                </MutableButton>
                <RedButton onClick={onClose} size="medium" type="button" variant="contained">
                  Отменить
                </RedButton>
              </CardActions>
            </Card>
          </form>
        );
      }}
    />
  );
};
