import React from 'react';
import { Button } from '@material-ui/core';
import { Link, Route } from 'react-router-dom';
import { CreateReportPopup } from './CreateReportPopup';

export const CreateReport = () => {
  return (
    <>
      <Button color="primary" variant="contained" component={Link} to={`/company/create-report`}>
        Добавить отчёт
      </Button>

      <Route exact path="/company/create-report" component={CreateReportPopup} />
    </>
  );
};
