import React from 'react';
import { Modal } from 'components';
import Maybe from 'graphql/tsutils/Maybe';
import { useBackRoute } from 'hooks';
import { AttendeeComment } from './AttendeeComment';

export interface AttendeeCommentProps {
  attendeeComment: Maybe<string> | undefined;
}

export const AttendeeCommentModal = ({ attendeeComment }: AttendeeCommentProps) => {
  const { onCancel } = useBackRoute();

  return (
    <Modal onClose={onCancel} aria-labelledby="Оценка комментарий участник">
      <AttendeeComment attendeeComment={attendeeComment} />
    </Modal>
  );
};
