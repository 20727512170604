import React from 'react';
import { Modal, makeStyles, Theme, createStyles, Backdrop, Fade } from '@material-ui/core';
import { ChangeUserPasswordForm } from './ChangeUserPasswordForm';
import { useCancelRoute } from 'hooks';
import { useParams } from 'react-router-dom';
import { useUserByIdQuery } from 'schema';
import { EditFormOrEmpty } from 'components/hoc';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    form: {
      width: '25%',
      padding: '15px',
      backgroundColor: theme.palette.background.paper,
      border: '0px',
      borderRadius: '2px',
      boxShadow: theme.shadows[5],
    },
  })
);

const ChangePasswordForm = EditFormOrEmpty(ChangeUserPasswordForm);

export const ChangeUserPasswordPage = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();

  const { onCancel } = useCancelRoute('/moderators');
  const { data, error, loading } = useUserByIdQuery({ variables: { id } });

  return (
    <Modal
      open={true}
      onClose={onCancel}
      aria-labelledby="Измененние пароля"
      aria-describedby="Используйте эту форму для изменения пароля пользователя"
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={true}>
        <div className={classes.form}>
          <ChangePasswordForm
            data={data}
            onClose={onCancel}
            hasError={error !== undefined}
            loading={loading}
          />
        </div>
      </Fade>
    </Modal>
  );
};
