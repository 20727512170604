import { useCallback, useEffect } from 'react';
import {
  CategoriesDocument,
  CategoryPropsFragment,
  UpdateCategoryInput,
  useUpdateCategoryMutation,
} from 'schema/serverTypes';

import { FORM_ERROR, SubmissionErrors } from 'final-form';
import { toast } from 'react-toastify';
import { QueryVariables } from '../../../../../hooks';

type UseCategoryProps = {
  onClose: () => void;
  variables?: QueryVariables<{}, {}>;
  category?: CategoryPropsFragment | null;
};
export const useEditCategoryForm = ({ onClose, variables, category }: UseCategoryProps) => {
  const [updateCategory, { data, loading, error }] = useUpdateCategoryMutation();
  const initialValues: UpdateCategoryInput = {
    id: category?.id ?? '',
    name: category?.name ?? '',
    imageId: category?.image?.id ?? '',
  };

  const onSubmit = useCallback(
    async (values: UpdateCategoryInput) => {
      const input: UpdateCategoryInput = values;
      try {
        return await updateCategory({
          variables: { input },
          refetchQueries: [
            {
              query: CategoriesDocument,
              variables,
            },
          ],
        });
      } catch {
        error && toast.error('Ошибка при редактировании категории');
        return { [FORM_ERROR]: 'Ошибка редактирования' } as SubmissionErrors;
      }
    },
    [updateCategory, error, variables]
  );

  useEffect(() => {
    if (data?.updateCategory.category?.id) {
      toast.success('Категория обновлена');
      onClose();
    } else if (data?.updateCategory.errors) {
      const message = data.updateCategory.errors.map((err) => err.message);
      toast.error(message[0]);
    }
  }, [data, onClose]);

  return { initialValues, onSubmit, loading };
};
