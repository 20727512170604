import {
  GrocerySetNeed,
  Need,
  OneTimeNeed,
  RegularNeed,
  RegularNeedPropsFragment,
} from '../serverTypes';

export function isOneTimeNeed(need: Need): need is OneTimeNeed {
  return (need as OneTimeNeed).title !== undefined;
}

export function isRegularNeed(need: Need): need is RegularNeed {
  return isGrocerySetNeed(need);
}

export function isGrocerySetNeed(need: RegularNeedPropsFragment): need is GrocerySetNeed {
  return need.__typename === 'GrocerySetNeed';
}
