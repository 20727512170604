import { useCallback, useEffect } from 'react';
import {
  AchievementByIdDocument,
  AchievementByIdQuery,
  UpdateAchievementWithMaterialPrizesInput,
  UpdateAchievementWithPromoCodesInput,
  UpdateAchievementWithUnlimitedNumberOfPrizesInput,
  useUpdateAchievementWithMaterialPrizesMutation,
  useUpdateAchievementWithPromoCodesMutation,
  useUpdateAchievementWithUnlimitedNumberOfPrizesMutation,
} from 'schema/serverTypes';

import { FORM_ERROR, SubmissionErrors } from 'final-form';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

type UseAwardEditModalFormProps = {
  onClose: () => void;
  achievement: AchievementByIdQuery | undefined;
};

export const useAwardEditModalForm = ({ onClose, achievement }: UseAwardEditModalFormProps) => {
  const initialValuesWithMaterialPrizes: UpdateAchievementWithMaterialPrizesInput = {
    id: achievement?.achievementById?.id,
    topImageId: achievement?.achievementById?.topImage?.id ?? null,
    title: achievement?.achievementById?.title ?? '',
    imageId: achievement?.achievementById?.image.id ?? '',
    isHighlighted: achievement?.achievementById?.isHighlighted ?? false,
    topPosition: achievement?.achievementById?.topPosition,
    storyId: achievement?.achievementById?.story?.id,
    description: achievement?.achievementById?.description ?? '',
    totalNumberOfPrizes:
      achievement?.achievementById?.__typename === 'AchievementWithMaterialPrizes'
        ? achievement?.achievementById?.totalNumberOfPrizes
        : 0,
    numberOfGrantedPrizes:
      achievement?.achievementById?.__typename === 'AchievementWithMaterialPrizes'
        ? achievement?.achievementById?.numberOfGrantedPrizes
        : 0,
  };

  const initialValuesWithPromoCodes: UpdateAchievementWithPromoCodesInput = {
    id: achievement?.achievementById?.id,
    title: achievement?.achievementById?.title ?? '',
    topImageId: achievement?.achievementById?.topImage?.id ?? null,
    imageId: achievement?.achievementById?.image.id ?? '',
    isHighlighted: achievement?.achievementById?.isHighlighted ?? false,
    topPosition: achievement?.achievementById?.topPosition,
    storyId: achievement?.achievementById?.story?.id,
    description: achievement?.achievementById?.description ?? '',
  };

  const initialValuesWithUnlimitedNumberOfPrizes: UpdateAchievementWithUnlimitedNumberOfPrizesInput = {
    id: achievement?.achievementById?.id,
    title: achievement?.achievementById?.title ?? '',
    topImageId: achievement?.achievementById?.topImage?.id ?? null,
    imageId: achievement?.achievementById?.image.id ?? '',
    isHighlighted: achievement?.achievementById?.isHighlighted ?? false,
    topPosition: achievement?.achievementById?.topPosition,
    storyId: achievement?.achievementById?.story?.id,
    description: achievement?.achievementById?.description ?? '',
  };

  const { awardId } = useParams<{ awardId: string }>();

  const [
    updateAchievementWithMaterialPrizes,
    {
      data: dataWithMaterialPrizes,
      loading: loadingWithMaterialPrizes,
      error: errorWithMaterialPrizes,
    },
  ] = useUpdateAchievementWithMaterialPrizesMutation();

  const [
    updateAchievementWithUnlimitedNumberOfPrizes,
    {
      data: dataWithUnlimitedNumberOfPrizes,
      loading: loadingWithUnlimitedNumberOfPrizes,
      error: errorWithUnlimitedNumberOfPrizes,
    },
  ] = useUpdateAchievementWithUnlimitedNumberOfPrizesMutation();

  const [
    updateAchievementWithPromoCodes,
    { data: dataWithPromoCodes, loading: loadingWithPromoCodes, error: errorWithPromoCodes },
  ] = useUpdateAchievementWithPromoCodesMutation();

  const getInitialValues = () => {
    switch (achievement?.achievementById?.__typename) {
      case 'AchievementWithMaterialPrizes':
        return initialValuesWithMaterialPrizes;
      case 'AchievementWithPromoCodes':
        return initialValuesWithPromoCodes;
      case 'AchievementWithUnlimitedNumberOfPrizes':
        return initialValuesWithUnlimitedNumberOfPrizes;
    }
  };

  const getLoading = () => {
    switch (achievement?.achievementById?.__typename) {
      case 'AchievementWithMaterialPrizes':
        return loadingWithMaterialPrizes;
      case 'AchievementWithPromoCodes':
        return loadingWithPromoCodes;
      case 'AchievementWithUnlimitedNumberOfPrizes':
        return loadingWithUnlimitedNumberOfPrizes;
      default:
        return loadingWithMaterialPrizes;
    }
  };

  const onSubmit = useCallback(
    async (
      values:
        | UpdateAchievementWithMaterialPrizesInput
        | UpdateAchievementWithPromoCodesInput
        | UpdateAchievementWithUnlimitedNumberOfPrizesInput
    ) => {
      switch (achievement?.achievementById?.__typename) {
        case 'AchievementWithMaterialPrizes':
          const {
            topPosition,
            totalNumberOfPrizes,
            numberOfGrantedPrizes,
          } = values as UpdateAchievementWithMaterialPrizesInput;
          const inputWithMaterialPrizes = {
            ...values,
            topPosition: topPosition === null ? null : Number(topPosition),
            totalNumberOfPrizes: Number(totalNumberOfPrizes),
            numberOfGrantedPrizes: Number(numberOfGrantedPrizes),
          };
          try {
            return await updateAchievementWithMaterialPrizes({
              variables: { input: inputWithMaterialPrizes },
              refetchQueries: [
                {
                  query: AchievementByIdDocument,
                  variables: { id: awardId },
                },
              ],
            });
          } catch {
            errorWithMaterialPrizes && toast.error('Ошибка обновления награды');
            return { [FORM_ERROR]: 'Ошибка обновления' } as SubmissionErrors;
          }

        case 'AchievementWithPromoCodes':
          const inputWithPromoCodes = {
            ...values,
            topPosition: values.topPosition === null ? null : Number(values.topPosition),
          };
          try {
            return await updateAchievementWithPromoCodes({
              variables: { input: inputWithPromoCodes },
              refetchQueries: [
                {
                  query: AchievementByIdDocument,
                  variables: { id: awardId },
                },
              ],
            });
          } catch {
            errorWithPromoCodes && toast.error('Ошибка обновления награды');
            return { [FORM_ERROR]: 'Ошибка обновления' } as SubmissionErrors;
          }

        case 'AchievementWithUnlimitedNumberOfPrizes':
          try {
            return await updateAchievementWithUnlimitedNumberOfPrizes({
              variables: {
                input: {
                  ...values,
                  topPosition: values.topPosition === null ? null : Number(values.topPosition),
                },
              },
              refetchQueries: [
                {
                  query: AchievementByIdDocument,
                  variables: { id: awardId },
                },
              ],
            });
          } catch {
            errorWithUnlimitedNumberOfPrizes && toast.error('Ошибка обновления награды');
            return { [FORM_ERROR]: 'Ошибка обновления' } as SubmissionErrors;
          }
      }
    },
    [
      achievement,
      awardId,
      errorWithMaterialPrizes,
      errorWithPromoCodes,
      errorWithUnlimitedNumberOfPrizes,
      updateAchievementWithMaterialPrizes,
      updateAchievementWithPromoCodes,
      updateAchievementWithUnlimitedNumberOfPrizes,
    ]
  );

  useEffect(() => {
    if (
      dataWithMaterialPrizes !== undefined ||
      dataWithPromoCodes !== undefined ||
      dataWithUnlimitedNumberOfPrizes !== undefined
    ) {
      onClose();
    }
  }, [dataWithMaterialPrizes, dataWithPromoCodes, dataWithUnlimitedNumberOfPrizes, onClose]);

  return {
    getInitialValues,
    getLoading,
    onSubmit,
    dataWithMaterialPrizes,
    dataWithPromoCodes,
    dataWithUnlimitedNumberOfPrizes,
  };
};
