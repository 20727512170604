import { useCallback, useEffect, useMemo } from 'react';
import {
  CompanyReportsDocument,
  CreateLimitedLiabilityCompanyReportInput,
  useCreateCompanyReportMutation,
} from 'schema/serverTypes';
import { useBackRoute } from 'hooks';

const createInitialValues = (): CreateLimitedLiabilityCompanyReportInput => ({
  title: '',
  referenceDate: '',
});

export const useCreateReportForm = () => {
  const { onCancel } = useBackRoute();

  const refetchQueries = useMemo(() => {
    return [
      {
        query: CompanyReportsDocument,
      },
    ];
  }, []);

  const [createCompanyReport, { data, loading, error }] = useCreateCompanyReportMutation();

  useEffect(() => {
    if (data !== undefined || error !== undefined) {
      onCancel();
    }
  }, [data, error, onCancel]);

  const onSubmit = useCallback(
    (values: CreateLimitedLiabilityCompanyReportInput) => {
      const input = {
        ...values,
      };
      createCompanyReport({
        refetchQueries,
        variables: { input },
        awaitRefetchQueries: true,
      });
    },
    [createCompanyReport, refetchQueries]
  );

  return {
    initialValues: createInitialValues(),
    onSubmit,
    onCancel,
    data,
    loading,
    error,
  };
};
