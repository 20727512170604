import React from 'react';
import { Checkbox, FormControlLabel, FormControlLabelProps } from '@material-ui/core';
import { Field } from 'react-final-form';

export type CheckboxFieldProps = Omit<FormControlLabelProps, 'control'> & { name: string };

export function CheckboxField(props: CheckboxFieldProps) {
  const { label, labelPlacement, name } = props;

  return (
    <FormControlLabel
      label={label}
      labelPlacement={labelPlacement}
      control={
        <Field name={name} type="checkbox" render={({ input }) => <Checkbox {...input} />} />
      }
    />
  );
}
