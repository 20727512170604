import { useCallback, useEffect } from 'react';
import {
  AchievementsDocument,
  AddAchievementWithMaterialPrizesInput,
  AddAchievementWithPromoCodesInput,
  AddAchievementWithUnlimitedNumberOfPrizesInput,
  useAddAchievementWithMaterialPrizesMutation,
  useAddAchievementWithPromoCodesMutation,
  useAddAchievementWithUnlimitedNumberOfPrizesMutation,
} from 'schema/serverTypes';

import { FORM_ERROR, SubmissionErrors } from 'final-form';
import { toast } from 'react-toastify';
import { TypeAwardEnum } from './types';
import { QueryVariables } from '../../../hooks';

const initialValuesWithMaterialPrizes: AddAchievementWithMaterialPrizesInput = {
  title: '',
  coins: 0,
  imageId: '',
  isHighlighted: false,
  topPosition: null,
  storyId: '',
  totalNumberOfPrizes: 0,
  description: '',
  topImageId: null,
};

const initialValuesWithPromoCodes: AddAchievementWithPromoCodesInput = {
  title: '',
  coins: 0,
  imageId: '',
  isHighlighted: false,
  topPosition: null,
  storyId: '',
  description: '',
  topImageId: null,
};

const initialValuesWithUnlimitedNumberOfPrizes: AddAchievementWithUnlimitedNumberOfPrizesInput = {
  title: '',
  coins: 0,
  imageId: '',
  isHighlighted: false,
  topPosition: null,
  storyId: '',
  description: '',
  topImageId: null,
};

type UseAddAwardModalFormProps = {
  onClose: () => void;
  selectedTypeAward: TypeAwardEnum;
  variables?: QueryVariables<{}, {}>;
};

export const useAddAwardModalForm = ({
  onClose,
  selectedTypeAward,
  variables,
}: UseAddAwardModalFormProps) => {
  const [
    addAchievementWithMaterialPrizes,
    {
      data: dataWithMaterialPrizes,
      loading: loadingWithMaterialPrizes,
      error: errorWithMaterialPrizes,
    },
  ] = useAddAchievementWithMaterialPrizesMutation();
  const [
    addAchievementWithUnlimitedNumberOfPrizes,
    {
      data: dataWithUnlimitedNumberOfPrizes,
      loading: loadingWithUnlimitedNumberOfPrizes,
      error: errorWithUnlimitedNumberOfPrizes,
    },
  ] = useAddAchievementWithUnlimitedNumberOfPrizesMutation();
  const [
    addAchievementWithPromoCodes,
    { data: dataWithPromoCodes, loading: loadingWithPromoCodes, error: errorWithPromoCodes },
  ] = useAddAchievementWithPromoCodesMutation();

  const getInitialValues = ():
    | AddAchievementWithMaterialPrizesInput
    | AddAchievementWithPromoCodesInput
    | AddAchievementWithUnlimitedNumberOfPrizesInput => {
    switch (selectedTypeAward) {
      case TypeAwardEnum.AchievementWithMaterialPrizes:
        return initialValuesWithMaterialPrizes;
      case TypeAwardEnum.AchievementWithPromoCodes:
        return initialValuesWithPromoCodes;
      case TypeAwardEnum.AchievementWithUnlimitedNumberOfPrizes:
        return initialValuesWithUnlimitedNumberOfPrizes;
    }
  };

  const getLoading = () => {
    switch (selectedTypeAward) {
      case TypeAwardEnum.AchievementWithMaterialPrizes:
        return loadingWithMaterialPrizes;
      case TypeAwardEnum.AchievementWithPromoCodes:
        return loadingWithPromoCodes;
      case TypeAwardEnum.AchievementWithUnlimitedNumberOfPrizes:
        return loadingWithUnlimitedNumberOfPrizes;
      default:
        return loadingWithMaterialPrizes;
    }
  };

  const onSubmit = useCallback(
    async (
      values:
        | AddAchievementWithMaterialPrizesInput
        | AddAchievementWithPromoCodesInput
        | AddAchievementWithUnlimitedNumberOfPrizesInput
    ) => {
      switch (selectedTypeAward) {
        case TypeAwardEnum.AchievementWithMaterialPrizes:
          const {
            coins,
            topPosition,
            totalNumberOfPrizes,
          } = values as AddAchievementWithMaterialPrizesInput;
          const inputWithMaterialPrizes = {
            ...values,
            coins: Number(coins),
            topPosition: topPosition === null ? null : Number(topPosition),
            totalNumberOfPrizes: Number(totalNumberOfPrizes),
          };
          try {
            return await addAchievementWithMaterialPrizes({
              variables: { input: inputWithMaterialPrizes },
              refetchQueries: [
                {
                  query: AchievementsDocument,
                  variables,
                },
              ],
            });
          } catch {
            errorWithMaterialPrizes && toast.error('Ошибка добавления награды');
            return { [FORM_ERROR]: 'Ошибка добавления' } as SubmissionErrors;
          }

        case TypeAwardEnum.AchievementWithPromoCodes:
          const inputWithPromoCodes = {
            ...values,
            coins: Number(values.coins),
            topPosition: values.topPosition === null ? null : Number(values.topPosition),
          };
          try {
            return await addAchievementWithPromoCodes({
              variables: { input: inputWithPromoCodes },
              refetchQueries: [
                {
                  query: AchievementsDocument,
                  variables,
                },
              ],
            });
          } catch {
            errorWithPromoCodes && toast.error('Ошибка добавления награды');
            return { [FORM_ERROR]: 'Ошибка добавления' } as SubmissionErrors;
          }

        case TypeAwardEnum.AchievementWithUnlimitedNumberOfPrizes:
          try {
            return await addAchievementWithUnlimitedNumberOfPrizes({
              variables: {
                input: {
                  ...values,
                  coins: Number(values.coins),
                  topPosition: values.topPosition === null ? null : Number(values.topPosition),
                },
              },
              refetchQueries: [
                {
                  query: AchievementsDocument,
                  variables,
                },
              ],
            });
          } catch {
            errorWithUnlimitedNumberOfPrizes && toast.error('Ошибка добавления награды');
            return { [FORM_ERROR]: 'Ошибка добавления' } as SubmissionErrors;
          }
      }
    },
    [
      addAchievementWithMaterialPrizes,
      addAchievementWithPromoCodes,
      addAchievementWithUnlimitedNumberOfPrizes,
      errorWithMaterialPrizes,
      errorWithPromoCodes,
      errorWithUnlimitedNumberOfPrizes,
      selectedTypeAward,
      variables,
    ]
  );

  useEffect(() => {
    if (
      dataWithMaterialPrizes !== undefined ||
      dataWithPromoCodes !== undefined ||
      dataWithUnlimitedNumberOfPrizes !== undefined
    ) {
      onClose();
    }
  }, [dataWithMaterialPrizes, dataWithPromoCodes, dataWithUnlimitedNumberOfPrizes, onClose]);

  return {
    getInitialValues,
    getLoading,
    onSubmit,
    dataWithMaterialPrizes,
    dataWithPromoCodes,
    dataWithUnlimitedNumberOfPrizes,
  };
};
