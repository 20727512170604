import { Button, Theme, withStyles } from '@material-ui/core';

export const RedButton = withStyles((theme: Theme) => {
  return {
    root: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.error.dark,
      '&:hover': {
        color: theme.palette.error.dark,
        backgroundColor: theme.palette.error.light,
      },
    },
  };
})(Button);
