import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, Step, StepLabel, Stepper } from '@material-ui/core';

import {
  CloudPaymentsSavedCardPaymentIntentInput,
  CloudPaymentsSavedCardsPropsFragment,
  NewOrderInformation,
  PlaceOrderPayload,
  ProjectSubscriptionIntentInput,
  SubscriptionIntentUnion,
  WardsReserveSubscriptionIntentInput,
  WardsSubscriptionIntent,
  WardsSubscriptionIntentInput,
} from '../../../../../../../schema';
import {
  PaymentDetailsStep,
  ConfirmNewSubscriptionsStep,
  PaymentMethodsStep,
  PlaceOrderStep,
  ResultPlaceOrderStep,
} from './steps';
import { useStyles } from './useStyles';

interface RecoverSubscriptionsProps {
  isOpen: boolean;
  cloudPaymentsSavedCards: CloudPaymentsSavedCardsPropsFragment[];
  intentsToRecoverCancelledSubscriptions: SubscriptionIntentUnion[];
  handleClose: () => void;
  benefactorId: string;
}

export const RecoverSubscriptions = ({
  isOpen,
  handleClose,
  intentsToRecoverCancelledSubscriptions,
  cloudPaymentsSavedCards,
  benefactorId,
}: RecoverSubscriptionsProps) => {
  const styles = useStyles();

  const [activeStep, setActiveStep] = useState<number>(0);
  const [newOrderInformation, setNewOrderInformation] = useState<NewOrderInformation | undefined>(
    undefined
  );

  const [placeOrderData, setPlaceOrderData] = useState<PlaceOrderPayload>();

  const [wardsSubscriptionIntentInput, setWardsSubscriptionIntentInput] = useState<
    WardsSubscriptionIntentInput
  >({
    wardsSubscriptionsAmounts: [],
    isCommissionCoveredByBenefactor: true,
    commissionPercentage: 0,
  });

  const [projectSubscriptionIntentInput, setProjectSubscriptionIntentInput] = useState<
    ProjectSubscriptionIntentInput
  >({ amount: 0 });

  const [wardsReserveSubscriptionIntentInput, setWardsReserveSubscriptionIntentInput] = useState<
    WardsReserveSubscriptionIntentInput
  >({
    amount: 0,
    isCommissionCoveredByBenefactor: true,
    commissionPercentage: 0,
  });

  const [paymentIntentInput, setPaymentIntentInput] = useState<
    CloudPaymentsSavedCardPaymentIntentInput | undefined
  >(undefined);

  const [lastStepName, setLastStepName] = useState<string>('Готово :-)');

  useEffect(() => {
    if (placeOrderData?.errors) {
      setLastStepName('Ошибка :-(');
    } else {
      setLastStepName('Готово :-)');
    }
  }, [placeOrderData]);

  const steps = [
    'Подтвердите новый заказ',
    'Выберите способ оплаты',
    'Детали оплаты',
    'Подтверждение заказа',
    lastStepName,
  ];

  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (
          <ConfirmNewSubscriptionsStep
            projectSubscriptionIntentInput={projectSubscriptionIntentInput}
            setProjectSubscriptionIntentInput={setProjectSubscriptionIntentInput}
            wardsSubscriptionIntentInput={wardsSubscriptionIntentInput}
            setWardsSubscriptionIntentInput={setWardsSubscriptionIntentInput}
            wardsReserveSubscriptionIntentInput={wardsReserveSubscriptionIntentInput}
            setWardsReserveSubscriptionIntentInput={setWardsReserveSubscriptionIntentInput}
            benefactorId={benefactorId}
            setNewOrderInformation={setNewOrderInformation}
            handleClose={handleClose}
            setActiveStep={setActiveStep}
            subscriptions={
              (intentsToRecoverCancelledSubscriptions as unknown) as WardsSubscriptionIntent[]
            }
          />
        );
      case 1:
        return (
          <PaymentMethodsStep
            setActiveStep={setActiveStep}
            availablePaymentMethods={newOrderInformation?.availablePaymentMethods}
            handleClose={handleClose}
          />
        );
      case 2:
        return (
          <PaymentDetailsStep
            setPaymentIntentInput={setPaymentIntentInput}
            setActiveStep={setActiveStep}
            handleClose={handleClose}
            cloudPaymentsSavedCards={cloudPaymentsSavedCards}
          />
        );
      case 3:
        return (
          <PlaceOrderStep
            projectSubscriptionIntentInput={projectSubscriptionIntentInput}
            setPlaceOrderData={setPlaceOrderData}
            benefactorId={benefactorId}
            paymentMethod={newOrderInformation!.availablePaymentMethods[0]}
            handleClose={handleClose}
            setActiveStep={setActiveStep}
            wardsSubscriptionIntentInput={wardsSubscriptionIntentInput}
            wardsReserveSubscriptionIntentInput={wardsReserveSubscriptionIntentInput}
            paymentIntentInput={paymentIntentInput!}
          />
        );
      case 4:
        return <ResultPlaceOrderStep handleClose={handleClose} placeOrderData={placeOrderData} />;
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle className={styles.header} disableTypography>
        Восстановить подписки
      </DialogTitle>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {getStepContent(activeStep)}
    </Dialog>
  );
};
