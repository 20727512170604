export enum AccountsDepositsName {
  BenefactorDepositAccountWithoutConditions = 'без условий',
  BenefactorDepositAccountForProjectNeeds = 'на проект',
  BenefactorDepositAccountForAllWards = 'на подопечных',
  OneTimeNeedAccount = '',
  ProjectExpenseAccount = '',
  SystemAccount = '',
  RegularNeedsAccount = '',
  SpecialProjectAccount = '',
  RegularNeedPeriodAccount = '',
}
