import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import { createStyles, makeStyles } from '@material-ui/core';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

const useStyles = makeStyles(() =>
  createStyles({
    dragHandle: {
      position: 'absolute',
      bottom: '10px',
      right: '5px',
      cursor: 'move',
    },
  })
);

export const DragHandleProject = SortableHandle(() => {
  const styles = useStyles();
  return <DragIndicatorIcon className={styles.dragHandle} />;
});
