import React from 'react';
import { Switch, useParams } from 'react-router-dom';
import {
  BenefactorByIdQuery,
  BenefactorExtendedPropsFragment,
  BenefactorPaymentInformationFragment,
  BenefactorPermissions,
  useBenefactorByIdQuery,
  useBenefactorByIdWithoutPermissionQuery,
  useBenefactorPermissionsByIdQuery,
} from 'schema';
import { Loading } from 'ui/Loading';
import { toast } from 'react-toastify';
import { RouteWithLayout, useMeContext } from 'components';
import { BenefactorManagePage } from '../BenefactorManagePage';
import { Layout } from 'layouts';
import { EditBenefactorPage } from '../EditBenefactorPage';
import { EditSpecialAccountPage } from '../EditSpecialAccountPage';
import { BenefactorTransactionPage } from '../BenefactorTransactionPage';

export const BenefactorContainer = () => {
  const { id } = useParams<{ id: string }>();
  const { user } = useMeContext();
  const viewDepositAccountsPermission = user?.permissions.accounts.viewDepositAccounts ?? false;

  const {
    data: permissionsData,
    loading: permissionsLoading,
    error: permissionsError,
  } = useBenefactorPermissionsByIdQuery({ variables: { id } });

  const useBenefactorQuery = (id: string, hasPermission: boolean) => {
    return hasPermission
      ? // eslint-disable-next-line react-hooks/rules-of-hooks
        useBenefactorByIdQuery({
          variables: {
            id,
            permission: viewDepositAccountsPermission,
          },
        })
      : // eslint-disable-next-line react-hooks/rules-of-hooks
        useBenefactorByIdWithoutPermissionQuery({
          variables: {
            id,
          },
        });
  };

  const { data, loading, error } = useBenefactorQuery(id, viewDepositAccountsPermission);

  if (permissionsLoading || loading) {
    return <Loading />;
  }

  if (permissionsError || error) {
    toast.error('Произошла ошибка загрузки');
    return null;
  }

  const permissions = permissionsData?.me.permissions.benefactor as BenefactorPermissions;
  const benefactor = data?.benefactorById as BenefactorExtendedPropsFragment;
  const paymentInformation = (data as BenefactorByIdQuery)
    ?.paymentInformation as BenefactorPaymentInformationFragment;

  if (benefactor === null) {
    toast.warn('Жертвователь не найден');
    return null;
  }

  return (
    <Switch>
      <RouteWithLayout
        exact
        path="/benefactors/manage/:id/account/:accountId"
        component={EditSpecialAccountPage}
        layout={Layout}
        accounts={paymentInformation?.depositAccounts}
        permissions={viewDepositAccountsPermission}
      />
      <RouteWithLayout
        exact
        path="/benefactors/manage/:id/edit"
        component={EditBenefactorPage}
        benefactor={benefactor}
        permissions={permissions}
        layout={Layout}
      />
      <RouteWithLayout
        exact
        path="/benefactors/manage/:id/order/:orderId"
        component={BenefactorTransactionPage}
        benefactor={benefactor}
        layout={Layout}
      />
      <RouteWithLayout
        path="/benefactors/manage/:id"
        component={BenefactorManagePage}
        benefactor={benefactor}
        paymentInformation={paymentInformation}
        permissions={permissions}
        layout={Layout}
      />
    </Switch>
  );
};
