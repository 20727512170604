import React from 'react';
import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  MenuItem,
  Theme,
} from '@material-ui/core';
import { MutableButton } from 'ui/MutableButton';
import { RedButton } from 'components/Buttons';
import { AutoFocusedForm, SelectField } from 'components';
import { Field } from 'react-final-form';
import { useAddBenefactorInList } from './useAddBenefactorInList';
import { useBenefactorsWithDepositAccountsQuery } from 'schema';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      fontSize: '18px',
      fontWeight: 'bold',
    },
    accessButton: {
      marginLeft: '15px',
      marginRight: '15px',
      color: theme.palette.common.white,
      backgroundColor: theme.palette.success.dark,
      '&:hover': {
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.success.light,
      },
    },
  })
);

interface AddBenefactorInListProps {
  wardId: string;
  isOpen: boolean;
  handleCloseWindow: () => void;
}

export const AddBenefactorInList = ({
  wardId,
  isOpen,
  handleCloseWindow,
}: AddBenefactorInListProps) => {
  const styles = useStyles();

  const { data: benefactorsWithDeposit } = useBenefactorsWithDepositAccountsQuery();

  const { onSubmit, initialValues, loading } = useAddBenefactorInList(wardId, handleCloseWindow);

  return (
    <>
      <Dialog open={isOpen} onClose={handleCloseWindow} maxWidth="xs">
        <DialogTitle disableTypography className={styles.header}>
          Добавить жертвователя
        </DialogTitle>
        <DialogContent>
          <AutoFocusedForm
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({ handleSubmit, pristine }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Field name="id" component={SelectField} label="Счет">
                    {benefactorsWithDeposit?.benefactorsWithDepositAccounts?.nodes?.map(
                      ({ benefactor }) => {
                        return benefactor?.depositAccounts.map((account) => {
                          if (account.__typename === 'BenefactorDepositAccountForSpecificWards') {
                            return (
                              <MenuItem key={account.id} value={account.id}>
                                {`${benefactor.displayName} - ${account.title}`}
                              </MenuItem>
                            );
                          }
                          return null;
                        });
                      }
                    )}
                  </Field>
                  <DialogActions>
                    <MutableButton
                      className={styles.accessButton}
                      loading={loading}
                      disabled={pristine}
                      size="medium"
                      type="submit"
                      variant="contained"
                    >
                      Сохранить
                    </MutableButton>
                    <RedButton
                      color="primary"
                      size="medium"
                      type="button"
                      variant="contained"
                      onClick={handleCloseWindow}
                    >
                      Отменить
                    </RedButton>
                  </DialogActions>
                </form>
              );
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
