import React from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { WardPropsFragment } from 'schema/serverTypes';
import { useWardPassedAwayForm } from './useWardPassedAwayForm';
import { AutoFocusedForm, DateField, required, TextField } from 'components';
import { Field } from 'react-final-form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 500,
    },
    header: {
      fontWeight: 'bolder',
    },
    divider: {},
    content: {},
    actions: {
      justifyContent: 'flex-end',
    },
  })
);

export interface WardPassedAwayFormProps {
  ward: WardPropsFragment;
  onClose: () => void;
}

export const WardPassedAwayForm = ({ ward, onClose }: WardPassedAwayFormProps) => {
  const styles = useStyles();

  const { initialValues, onSubmit } = useWardPassedAwayForm(ward, onClose);

  return (
    <AutoFocusedForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      subscription={{ submitError: true }}
      validate={(values) => {
        const errors: any = {};

        if (values.fullName !== ward.publicInformation.name.fullName) {
          errors.fullName = 'Введённое имя не совпадает с полным именем подопечного';
        }

        return errors;
      }}
      render={({ handleSubmit, pristine, submitting }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card className={styles.root}>
              <CardHeader title="Данные смерти подопечного" />
              <Divider className={styles.divider} />
              <CardContent className={styles.content}>
                <Grid container spacing={4}>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Field
                      name="fullName"
                      label="Полное имя подопечного *"
                      component={TextField}
                      validate={required}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Field
                      name="dateOfDeath"
                      label="Дата смерти *"
                      fullWidth={true}
                      component={DateField}
                      validate={required}
                      returnTime={false}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions className={styles.actions}>
                <Button
                  color="primary"
                  disabled={pristine || submitting}
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  Отметить как умершего
                </Button>
                &nbsp;
                <Button onClick={onClose} size="medium" type="button" variant="contained">
                  Отменить
                </Button>
              </CardActions>
            </Card>
          </form>
        );
      }}
    />
  );
};
