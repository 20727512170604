import { useCallback, useEffect, useMemo } from 'react';
import {
  useAddWardsToTheBenefactorDepositAccountForSpecificWardsMutation,
  WardByIdDocument,
} from 'schema';
import { toast } from 'react-toastify';
import { useMeContext } from '../../../../components';

interface InitialValuesType {
  id: string;
}

export const useAddBenefactorInList = (wardId: string, handleCloseWindow: () => void) => {
  const { user } = useMeContext();
  const refetchQueries = useMemo(() => {
    return [
      {
        query: WardByIdDocument,
        variables: { id: wardId, permission: user?.permissions.accounts.viewDepositAccounts },
      },
    ];
  }, [wardId, user]);

  const [
    addBenefactorToTheList,
    { loading, data, error },
  ] = useAddWardsToTheBenefactorDepositAccountForSpecificWardsMutation({
    refetchQueries,
  });

  const onSubmit = useCallback(
    async ({ id }: InitialValuesType) => {
      await addBenefactorToTheList({
        variables: {
          input: {
            wardIds: [wardId],
            id,
          },
        },
      });
    },
    [wardId, addBenefactorToTheList]
  );

  useEffect(() => {
    if (error) {
      toast.error('Ошибка добавления жертвователя');
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      toast.success('Жертвователь успешно добавлен');
      handleCloseWindow();
    }
  }, [data, handleCloseWindow]);

  const initialValues: InitialValuesType = {
    id: '',
  };

  return {
    initialValues,
    onSubmit,
    loading,
  };
};
