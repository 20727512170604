import React, { useState } from 'react';
import {
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
  Popover,
  List,
  makeStyles,
  Theme,
  createStyles,
  Paper,
  TextField,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import { MoreVertOutlined } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import { useFile } from './useFile';
import { Document } from 'schema';
import { shortDescription } from 'helpers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      padding: theme.spacing(1),
      position: 'absolute',
      bottom: '0',
      left: '0',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'white',
      cursor: 'pointer',
    },
    button: {
      width: '12px',
      height: '12px',
      backgroundColor: 'white',
      '&:hover': {
        backgroundColor: 'rgba(255,255,255, 0.7)',
      },
    },
    editField: {
      minWidth: '600px',
      display: 'flex',
      padding: theme.spacing(2),
    },
  })
);

export type DocumentItemProps = {
  file: Document;
  onRemove: (index: number) => Document;
  onUpdate: (index: number, file: Document) => void;
  index: number;
};

export const DocumentItem = (props: DocumentItemProps) => {
  const { file } = props;
  const styles = useStyles();
  const [fileName, setFileName] = useState<string | undefined | null>(file.title);

  const { onEdit, onCancel, onSave, onDelete, onDownload, popover, namePopover } = useFile({
    ...props,
    fileName,
  });

  const { id, onClick, onClose, anchorEl, open } = popover;

  const { id: nameId, anchorEl: nameAnchorEl, onClose: nameOnClose, open: nameOpen } = namePopover;

  return (
    <Paper className={styles.title}>
      <Popover
        id={nameId}
        open={nameOpen}
        anchorEl={nameAnchorEl}
        onClose={nameOnClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Paper className={styles.editField}>
          <TextField
            variant="outlined"
            label="Название *"
            fullWidth
            value={fileName}
            onChange={(e) => setFileName(e.target.value)}
          />
          <IconButton onClick={onSave} color="primary">
            <DoneIcon />
          </IconButton>
          <IconButton onClick={onCancel} color="primary">
            <CloseIcon />
          </IconButton>
        </Paper>
      </Popover>
      <ListItemText onClick={onEdit}>{shortDescription(file.title, 15)}</ListItemText>
      <ListItemSecondaryAction>
        <IconButton
          className={styles.button}
          aria-describedby={id}
          aria-label="Действия"
          onClick={onClick}
        >
          <MoreVertOutlined />
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={onClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <List component="nav" aria-label="Основный действия с документом">
            <ListItem button onClick={onEdit}>
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <ListItemText primary="Переименовать" />
            </ListItem>
            <ListItem button onClick={onDownload}>
              <ListItemIcon>
                <GetAppIcon />
              </ListItemIcon>
              <ListItemText primary="Скачать" />
            </ListItem>
            <ListItem button onClick={onDelete}>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText primary="Удалить" />
            </ListItem>
          </List>
        </Popover>
      </ListItemSecondaryAction>
    </Paper>
  );
};
