import React from 'react';
import { RedButton } from '../../../../../components/Buttons';
import { useScreensStorage } from '../../useScreensStorage';
import { useIsEditStoryStorage } from '../../useIsEditStoryStorage';

type CancelStoryChangesButtonProps = {
  setIsStoryEdited: (isStoryEdited: boolean) => void;
  setScreenCardsLoaded: (isScreensCardLoaded: boolean) => void;
  id: string;
};

export const CancelStoryChangesButton = ({
  id,
  setIsStoryEdited,
  setScreenCardsLoaded,
}: CancelStoryChangesButtonProps) => {
  const { clearScreensStorageHistory } = useScreensStorage();
  const { clearIsEditStoryStorageHistory } = useIsEditStoryStorage({ id: id });

  const handleClick = () => {
    clearScreensStorageHistory();
    setIsStoryEdited(false);
    clearIsEditStoryStorageHistory();
    setScreenCardsLoaded(false);
  };

  return <RedButton onClick={handleClick}>Отменить изменения</RedButton>;
};
