import { setContext } from '@apollo/client/link/context';
import { authStorage } from 'helpers';

export const authLink = setContext((_, { headers, ...rest }) => {
  const tokens = authStorage.get();

  if (tokens) {
    const token = tokens?.access_token;
    return {
      ...rest,
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  }

  return {
    headers,
    ...rest,
  };
});
