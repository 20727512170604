import { useCallback, useEffect } from 'react';
import { RestInPeaceInput, useRestInPeaceMutation, WardPropsFragment } from 'schema/serverTypes';
import { FORM_ERROR } from 'final-form';

interface RestInPeaceFormValues {
  dateOfDeath: string;
  fullName: string;
}

const initialValues: RestInPeaceFormValues = {
  dateOfDeath: '',
  fullName: '',
};

export const useWardPassedAwayForm = (ward: WardPropsFragment, onClose: () => void) => {
  const [restInPeaceMutation, { data }] = useRestInPeaceMutation();

  const onSubmit = useCallback(
    (values: RestInPeaceFormValues) => {
      const input: RestInPeaceInput = { wardId: ward.id, dateOfDeath: values.dateOfDeath };

      return restInPeaceMutation({
        variables: { input },
      }).catch(() => {
        return { [FORM_ERROR]: 'Ошибка сервера' };
      });
    },
    [ward.id, restInPeaceMutation]
  );

  useEffect(() => {
    if (data !== undefined) {
      onClose();
    }
  });

  return {
    initialValues,
    onSubmit,
  };
};
