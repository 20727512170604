import React from 'react';
import { Button, Card, CardContent } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { LayoutPageGrid } from 'ui/LayoutGrid/LayoutGrid';
import { NotificationsGrid } from './NotificationsGrid';
import { usePermissionPage } from 'hooks';
import { useMeContext } from 'components';

export const NotificationsPage = () => {
  const { user } = useMeContext();

  usePermissionPage('/wards', user?.permissions.broadcastNotifications.view);

  return (
    <LayoutPageGrid
      title="Уведомления"
      tools={
        <Button color="primary" variant="contained" component={Link} to="/notifications/add">
          Добавить
        </Button>
      }
    >
      <Card>
        <CardContent>
          <NotificationsGrid />
        </CardContent>
      </Card>
    </LayoutPageGrid>
  );
};
