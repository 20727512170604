import { isOneTimeNeed, isGrocerySetNeed } from './needTypeGuards';
import { Need } from '../serverTypes';

export const getNeedName = (need: Need) => {
  if (isOneTimeNeed(need)) {
    return need.title;
  }

  if (isGrocerySetNeed(need)) {
    return 'Продуктовая корзина';
  }
};
