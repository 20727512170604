import React from 'react';
import palette from 'theme/palette';
import { CompleteIcon } from '../../../../components';

export interface VersionMandatoryLabelProps {
  mandatory: boolean;
}

export const VersionMandatoryLabel = ({ mandatory }: VersionMandatoryLabelProps) => {
  return <>{mandatory && <CompleteIcon htmlColor={palette.success.main} />}</>;
};
