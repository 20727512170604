import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Theme, colors, Grow, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: colors.grey[200],
      height: 4,
      borderRadius: 3,
      position: 'relative',
    },
    subscription: {
      backgroundColor: colors.deepPurple['A400'],
      height: 4,
      borderRadius: 3,
      position: 'absolute',
      top: 0,
    },
    single: {
      backgroundColor: colors.red[500],
      height: 4,
      borderRadius: 3,
      position: 'absolute',
      top: 0,
      left: 0,
    },
  })
);

export interface ProgressProps {
  total: number;
  subscription?: number;
  nonSubscription?: number;
}

export const Progress = ({ total, subscription = 0, nonSubscription = 0 }: ProgressProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Grow in timeout={1300} style={{ transformOrigin: '0 0 0' }}>
        <div
          className={classes.single}
          style={{ width: `${((subscription + nonSubscription) / total) * 100}%` }}
        />
      </Grow>
      <Grow in timeout={800} style={{ transformOrigin: '0 0 0' }}>
        <div
          className={classes.subscription}
          style={{ width: `${(subscription / total) * 100}%` }}
        />
      </Grow>
    </Box>
  );
};
