import React, { useState } from 'react';
import {
  AutoFocusedForm,
  PhotoField,
  positiveNumber,
  required,
  SelectField,
  StorySelect,
  TextField,
} from 'components';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  Typography,
} from '@material-ui/core';
import { Loading } from 'ui/Loading';
import { useAddAwardModalForm } from './useAddAwardModalForm';
import { TypeAwardEnum } from './types';
import { Field } from 'react-final-form';
import { MutableButton } from '../../../ui/MutableButton';
import { RedButton } from '../../../components/Buttons';
import { QueryVariables } from '../../../hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 500,
      maxWidth: 1000,
    },
    header: {
      fontWeight: 'bolder',
    },
    content: {
      display: 'flex',
      maxHeight: 650,
      margin: '10px',
    },
    images: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: 350,
      gap: '15px',
    },
    actions: {
      justifyContent: 'flex-end',
    },
    accessButton: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.success.dark,
      '&:hover': {
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.success.light,
      },
    },
  })
);

interface AddAwardModalFormProps {
  onClose: () => void;
  variables?: QueryVariables<{}, {}>;
}

export const AddAwardModalForm = ({ onClose, variables }: AddAwardModalFormProps) => {
  const styles = useStyles();
  const [selectedTypeAward, setSelectedTypeAward] = useState<TypeAwardEnum>(
    TypeAwardEnum.AchievementWithMaterialPrizes
  );
  const { onSubmit, getInitialValues, getLoading } = useAddAwardModalForm({
    onClose,
    selectedTypeAward,
    variables,
  });
  const initialValues = getInitialValues();

  if (getLoading()) {
    return <Loading />;
  }

  return (
    <AutoFocusedForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, pristine }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card className={styles.root}>
              <CardHeader title={`Добавить новую награду`} />
              <Divider />
              <CardContent className={styles.content}>
                <Box className={styles.images}>
                  <Grid container>
                    <Grid item lg={9} md={9} xl={9} xs={9}>
                      <Field name="imageId" component={PhotoField} validate={required} />
                      <Typography variant="body2">Изображение достижения</Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item lg={9} md={9} xl={9} xs={9}>
                      <Field name="topImageId" component={PhotoField} />
                      <Typography variant="body2">Изображение списка TOP-N историй</Typography>
                    </Grid>
                  </Grid>
                </Box>

                <Grid container spacing={1}>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <span>Тип награды:</span>
                    <Select
                      variant={'outlined'}
                      fullWidth
                      value={selectedTypeAward}
                      name="type"
                      displayEmpty
                      placeholder={'Выберите тип награды'}
                      onChange={(event) =>
                        setSelectedTypeAward(event.target.value as TypeAwardEnum)
                      }
                    >
                      <MenuItem value={TypeAwardEnum.AchievementWithMaterialPrizes}>
                        С материальными призами
                      </MenuItem>
                      <MenuItem value={TypeAwardEnum.AchievementWithUnlimitedNumberOfPrizes}>
                        С неограниченным количеством наград
                      </MenuItem>
                      <MenuItem value={TypeAwardEnum.AchievementWithPromoCodes}>
                        С промо-кодами
                      </MenuItem>
                    </Select>
                  </Grid>

                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Field
                      name="title"
                      component={TextField}
                      label="Название награды"
                      validate={required}
                    />
                  </Grid>

                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Field
                      name="description"
                      component={TextField}
                      multiline
                      rows={3}
                      label="Описание награды"
                      validate={required}
                    />
                  </Grid>

                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <StorySelect
                      storyLabel="achievements"
                      name="storyId"
                      label="История с описанием награды"
                      validate={required}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} xl={6} xs={6}>
                    <Field
                      name="coins"
                      type="number"
                      component={TextField}
                      label="Количество монет"
                      validate={positiveNumber}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} xl={6} xs={6}>
                    <Field
                      name="topPosition"
                      type="number"
                      component={TextField}
                      label="Номер в топе"
                    />
                  </Grid>

                  <Grid item lg={6} md={6} xl={6} xs={6}>
                    <Field
                      name="isHighlighted"
                      component={SelectField}
                      label="Достижение выделяется?"
                      validate={required}
                    >
                      <MenuItem value={(true as unknown) as string}>Да</MenuItem>
                      <MenuItem value={(false as unknown) as string}>Нет</MenuItem>
                    </Field>
                  </Grid>
                  {selectedTypeAward === TypeAwardEnum.AchievementWithMaterialPrizes && (
                    <Grid item lg={6} md={6} xl={6} xs={6}>
                      <Field
                        name="totalNumberOfPrizes"
                        type="number"
                        component={TextField}
                        label="Общее количество призов"
                        validate={required}
                      />
                    </Grid>
                  )}
                </Grid>
              </CardContent>

              <CardActions className={styles.actions}>
                <MutableButton
                  className={styles.accessButton}
                  loading={getLoading()}
                  color="primary"
                  disabled={pristine}
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  Добавить награду
                </MutableButton>
                <RedButton onClick={onClose} size="medium" type="button" variant="contained">
                  Отменить
                </RedButton>
              </CardActions>
            </Card>
          </form>
        );
      }}
    />
  );
};
