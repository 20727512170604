import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dragHandle: {
      top: '0',
      left: '0',
      width: '100%',
      height: 'calc(100% - 50px)',
      position: 'absolute',
    },
  })
);

export const DragHandle = SortableHandle(() => {
  const styles = useStyles();
  return <div className={styles.dragHandle} />;
});
