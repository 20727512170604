import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { List, Theme, createStyles } from '@material-ui/core';
import { TopNavButton } from '../TopNavButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1, 0),
    },
  })
);

export type TopNavProps = {
  pages: {
    title: string;
    href: string;
    permissions: boolean | undefined;
    type?: string;
    selectButton?: boolean;
    screens?: { title: string; href: string }[];
  }[];
  className?: string;
};

export const TopNav = (props: TopNavProps) => {
  const { pages, className } = props;
  const classes = useStyles();

  return (
    <List className={clsx(classes.root, className)}>
      {pages.map((page) => (
        <TopNavButton
          key={page.title}
          title={page.title}
          href={page.href}
          type={page.type}
          permissions={page.permissions}
          selectButton={page.selectButton}
          screens={page.screens}
        />
      ))}
    </List>
  );
};
