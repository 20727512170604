import React from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  MenuItem,
  Theme,
} from '@material-ui/core';
import { WardPropsFragment } from 'schema/serverTypes';
import { useWardDeactivationForm } from './useWardDeactivationForm';
import { AutoFocusedForm, required, SelectField, TextField } from 'components';
import { Field } from 'react-final-form';

import { DeactivationReasons } from '../../deactivationReasons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 500,
    },
    header: {
      fontWeight: 'bolder',
    },
    divider: {},
    content: {},
    actions: {
      justifyContent: 'flex-end',
    },
  })
);

export interface WardDeactivationFormProps {
  ward: WardPropsFragment;
  onClose: () => void;
}

export const WardDeactivationForm = ({ ward, onClose }: WardDeactivationFormProps) => {
  const styles = useStyles();

  const { initialValues, onSubmit } = useWardDeactivationForm(ward, onClose);

  return (
    <AutoFocusedForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      subscription={{ submitError: true }}
      validate={(values) => {
        const errors: any = {};

        if (values.fullName !== ward.publicInformation.name.fullName) {
          errors.fullName = 'Введённое имя не совпадает с полным именем подопечного';
        }

        return errors;
      }}
      render={({ handleSubmit, pristine, submitting }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card className={styles.root}>
              <CardHeader title="Деактивация подопечного - необратимо!" />
              <Divider className={styles.divider} />
              <CardContent className={styles.content}>
                <Grid container spacing={4}>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Field
                      name="fullName"
                      label="Полное имя подопечного *"
                      component={TextField}
                      validate={required}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Field name="reason" label="Причина деактивации *" component={SelectField}>
                      {DeactivationReasons.filter((reason) => reason.allowedToUse).map((reason) => (
                        <MenuItem key={reason.reason} value={reason.reason}>
                          {reason.description}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions className={styles.actions}>
                <Button
                  color="primary"
                  disabled={pristine || submitting}
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  Деактивировать
                </Button>
                &nbsp;
                <Button onClick={onClose} size="medium" type="button" variant="contained">
                  Отменить
                </Button>
              </CardActions>
            </Card>
          </form>
        );
      }}
    />
  );
};
