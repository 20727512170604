import React from 'react';
import { LayoutPageGrid } from 'ui/LayoutGrid';
import { Button, Card, CardContent } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { EventsGrid } from './EventsGrid';

interface FoundationEventsProps {
  url: string;
  permission: boolean;
  foundationId: string;
}

export const FoundationEvents = ({ url, permission, foundationId }: FoundationEventsProps) => {
  return (
    <LayoutPageGrid
      title="События"
      tools={
        permission ? (
          <Button color="primary" variant="contained" component={Link} to={`${url}/event/create`}>
            Добавить событие
          </Button>
        ) : (
          <></>
        )
      }
    >
      <Card>
        <CardContent>
          <EventsGrid url={url} foundationId={foundationId} />
        </CardContent>
      </Card>
    </LayoutPageGrid>
  );
};
