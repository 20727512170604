import React from 'react';
import { Theme, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Amounts, Statistics } from './components';
import { usePermissionPage } from 'hooks';
import { useMeContext } from 'components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
    },
    amounts: {
      marginTop: theme.spacing(2),
    },
  })
);

export const DashboardPage = () => {
  const classes = useStyles();

  const { user } = useMeContext();

  usePermissionPage('/wards', user?.permissions.users.createAndEditModerator);

  return (
    <div className={classes.root}>
      <Statistics />
      <Amounts className={classes.amounts} />
    </div>
  );
};
