import React from 'react';
import { Backdrop, Fade, Modal } from '@material-ui/core';
import { useBackRoute } from 'hooks';
import { BenefactorStatusEditModalForm } from './BenefactorStatusEditModalForm';
import { BenefactorStatusByIdQuery } from '../../../../schema';
import { useStyles } from '../useStyles';

type AwardEditModalProps = {
  status: BenefactorStatusByIdQuery | undefined;
};

export const BenefactorStatusEditModal = ({ status }: AwardEditModalProps) => {
  const styles = useStyles();

  const { onCancel } = useBackRoute();

  return (
    <Modal
      open={true}
      onClose={onCancel}
      aria-labelledby="Редактирование статуса"
      aria-describedby="Используйте эту форму для редактирования статуса"
      className={styles.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={true}>
        <BenefactorStatusEditModalForm status={status} onClose={onCancel} />
      </Fade>
    </Modal>
  );
};
