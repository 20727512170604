import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React from 'react';
import { AddWardsGrid } from './AddWardsGrid';
import { MutableButton } from 'ui/MutableButton';
import { RedButton } from 'components/Buttons';
import { useParams } from 'react-router-dom';
import { useAddWardsModal } from './useAddWardsModal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accessButton: {
      marginLeft: '15px',
      marginRight: '15px',
      color: theme.palette.common.white,
      backgroundColor: theme.palette.success.dark,
      '&:hover': {
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.success.light,
      },
    },
    header: {
      fontSize: '18px',
      fontWeight: 'bold',
    },
    dialog: {
      '& .MuiPaper-root': {
        width: '100%',
        maxWidth: '1200px',
        height: '80%',
        maxHeight: '1200px',
        padding: theme.spacing(2),
      },
    },
  })
);

interface Props {
  openAddWards: boolean;
  handleCloseAddWards: () => void;
  permission: boolean;
}

export const AddWardsModal = ({ openAddWards, handleCloseAddWards, permission }: Props) => {
  const styles = useStyles();
  const { accountId, id } = useParams<{ accountId: string; id: string }>();

  const {
    handleAddWards,
    loading,
    handleChangeSelectedWards,
    handleGetAll,
    selectedWards,
  } = useAddWardsModal(id, accountId, handleCloseAddWards, permission);

  return (
    <Dialog open={openAddWards} onClose={handleCloseAddWards} fullWidth className={styles.dialog}>
      <DialogTitle disableTypography className={styles.header}>
        Добавить подопечных
      </DialogTitle>
      <DialogContent>
        <AddWardsGrid
          handleAddWard={handleChangeSelectedWards}
          selectedWards={selectedWards}
          handleGetAll={handleGetAll}
        />
      </DialogContent>
      <DialogActions>
        <MutableButton
          className={styles.accessButton}
          onClick={handleAddWards}
          loading={loading}
          disabled={!selectedWards.length}
          size="medium"
          type="submit"
          variant="contained"
        >
          Добавить
        </MutableButton>
        <RedButton
          color="primary"
          size="medium"
          type="button"
          variant="contained"
          onClick={handleCloseAddWards}
        >
          Отменить
        </RedButton>
      </DialogActions>
    </Dialog>
  );
};
