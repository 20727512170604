import React from 'react';
import { isOneTimeNeed, isGrocerySetNeed } from 'schema';
import { OneTimeNeedIcon, OneTimeNeedIconNumber } from './OneTimeNeedIcon';
import { NeedProps, UtkonosIcon } from 'components';

export interface NeedIconProps extends NeedProps {
  className?: string;
}

export const NeedIcon = (props: NeedIconProps) => {
  const { need, ...rest } = props;
  if (isOneTimeNeed(need)) {
    return <OneTimeNeedIcon {...rest} number={need.icon as OneTimeNeedIconNumber} />;
  }
  if (isGrocerySetNeed(need)) {
    return <UtkonosIcon {...rest} />;
  }
  return null;
};
