import React from 'react';
import { AchievementByIdQuery } from '../../../../schema';
import { PropertiesTable, PropertiesTableRow } from '../../../../ui/PropertiesTable';
import {
  Avatar,
  Box,
  Button,
  CardContent,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { useMeContext } from '../../../../components';
import { Link } from 'react-router-dom';

type Props = {
  data: AchievementByIdQuery | undefined;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '20px',
    },
    link: {
      color: theme.palette.primary.dark,
    },
    avatar: {
      width: theme.spacing(14),
      height: theme.spacing(14),
    },
    deleteButton: {
      background: theme.palette.warning.dark,
    },
    buttonContainer: {
      display: 'flex',
      gap: '10px',
      marginTop: 'auto',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
  })
);

export const AwardCard = ({ data }: Props) => {
  const classes = useStyles();
  const { user } = useMeContext();

  const permissionsManage = user?.permissions.achievements.manage;

  return (
    <CardContent className={classes.content}>
      <Avatar src={data?.achievementById?.image.url} className={classes.avatar} />
      <PropertiesTable>
        <PropertiesTableRow caption="Название награды" value={data?.achievementById?.title} />
        <PropertiesTableRow caption="Количество баллов" value={data?.achievementById?.coins} />

        {data?.achievementById?.__typename === 'AchievementWithMaterialPrizes' ||
          (data?.achievementById?.__typename === 'AchievementWithPromoCodes' && (
            <PropertiesTableRow
              caption="Общее количество призов"
              value={data.achievementById.totalNumberOfPrizes}
            />
          ))}
        {data?.achievementById?.__typename === 'AchievementWithMaterialPrizes' ||
          (data?.achievementById?.__typename === 'AchievementWithPromoCodes' && (
            <PropertiesTableRow
              caption="Количество выданных призов"
              value={data.achievementById.numberOfGrantedPrizes}
            />
          ))}
        <PropertiesTableRow caption="Описание" value={data?.achievementById?.description} />
        <PropertiesTableRow
          caption="История"
          value={
            <Link
              className={classes.link}
              to={`/block/${data?.achievementById?.story?.storyBlock.id}/story/${data?.achievementById?.story?.id}`}
            >
              {data?.achievementById?.story?.title ?? ''}
            </Link>
          }
        />
      </PropertiesTable>

      {permissionsManage && (
        <Box className={classes.buttonContainer}>
          <Button
            color="primary"
            variant="contained"
            component={Link}
            to={`/award/${data?.achievementById?.id}/edit`}
          >
            Редактировать
          </Button>
          <Button
            color="primary"
            variant="contained"
            component={Link}
            to={`/award/${data?.achievementById?.id}/publish`}
          >
            {data?.achievementById?.published ? 'Снять с публикации' : 'Опубликовать'}
          </Button>
          {data?.achievementById?.__typename === 'AchievementWithPromoCodes' && (
            <Button
              color="primary"
              variant="contained"
              component={Link}
              to={`/award/${data.achievementById.id}/csv`}
            >
              Загрузить файл с промокодами
            </Button>
          )}
          <Button
            variant="contained"
            className={classes.deleteButton}
            component={Link}
            to={`/award/${data?.achievementById?.id}/delete`}
          >
            Удалить
          </Button>
        </Box>
      )}
    </CardContent>
  );
};
