import React from 'react';
import { Modal, makeStyles, Theme, createStyles, Backdrop, Fade } from '@material-ui/core';
import { CreateFoundationRepresentativeForm } from './CreateFoundationRepresentativeForm';
import { useBackRoute, usePermissionPage } from 'hooks';
import { useMeContext } from 'components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    form: {
      width: '25%',
      padding: '15px',
      backgroundColor: theme.palette.background.paper,
      border: '0px',
      borderRadius: '2px',
      boxShadow: theme.shadows[5],
    },
  })
);

interface EditedRouteParams {
  id: string;
}

export const CreateFoundationRepresentativePage = ({ id }: EditedRouteParams) => {
  const classes = useStyles();
  const { user } = useMeContext();

  usePermissionPage('/foundations', user?.permissions.users.createAndEditFoundationRepresentative);

  const { onCancel } = useBackRoute();

  return (
    <Modal
      open={true}
      onClose={onCancel}
      aria-labelledby="Добавление представителя фонда"
      aria-describedby="Используйте эту форму для создания нового представителя фонда"
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={true}>
        <div className={classes.form}>
          <CreateFoundationRepresentativeForm onClose={onCancel} id={id} />
        </div>
      </Fade>
    </Modal>
  );
};
