import React, { useMemo } from 'react';
import { useStoryBlockByIdQuery, useStoryBlockQuery } from '../../schema';
import { SelectField } from '../form';
import { MenuItem } from '@material-ui/core';
import { Field, FieldProps } from 'react-final-form';
import { Loading } from '../../ui/Loading';

type Props = {
  storyLabel: string;
} & FieldProps<any, any>;

export const StorySelect = (props: Props) => {
  const { storyLabel, label, validate, ...rest } = props;
  const { data: blocksStoryData, loading: storyBlockLoading } = useStoryBlockQuery();

  const achievementBlockId = useMemo(() => {
    if (blocksStoryData?.storyBlocks) {
      return blocksStoryData?.storyBlocks.find(({ label }) => label === storyLabel);
    }
  }, [blocksStoryData?.storyBlocks, storyLabel]);

  const { data: storyData, loading: storiesLoading } = useStoryBlockByIdQuery({
    variables: { id: achievementBlockId?.id },
  });

  if (storyBlockLoading || storiesLoading) {
    return <Loading />;
  }

  return (
    <Field {...rest} type="text" component={SelectField} label={label} validate={validate}>
      {storyData?.storyBlockById?.stories?.nodes?.map(({ id, title }) => (
        <MenuItem key={id} value={id}>
          {title}
        </MenuItem>
      ))}
    </Field>
  );
};
