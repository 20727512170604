import React from 'react';
import { Card, CardActions, CardContent, CardHeader } from '@material-ui/core';
import { InvisibleCard } from '../../../../../ui/InvisibleCard';
import { LayoutPageGrid } from '../../../../../ui/LayoutGrid';
import { useParams } from 'react-router-dom';
import {
  Need,
  RegularNeedPeriod,
  RegularNeedPeriodAccount,
  useRegularNeedPeriodByIdQuery,
  Ward,
} from '../../../../../schema';
import { toast } from 'react-toastify';
import { RegularNeedPeriodInformation } from './RegularNeedPeriodInformation';
import { Loading } from '../../../../../ui/Loading';
import { AccountActions, AccountDonations } from '../../../../account/components';
import { RegularNeedPeriodDocuments } from './RegularNeedPeriodDocuments';
import { Breadcrumbs } from '../Breadcrumbs';
import moment from 'moment';

type Props = {
  ward: Ward;
};

export const RegularNeedPeriodPage = ({ ward }: Props) => {
  const { periodId, needId } = useParams<{ needId: string; periodId: string }>();
  const { data, error, loading } = useRegularNeedPeriodByIdQuery({ variables: { id: periodId } });
  const { needs } = ward;
  const need = needs.find((x) => x.id === needId)!;

  if (error) {
    toast.error('Ошибка загрузки периода');
    return null;
  }
  if (loading) {
    return <Loading />;
  }

  const period = data?.regularNeedPeriodById;

  return (
    <>
      <Breadcrumbs ward={ward} need={need} period={period as RegularNeedPeriod} />
      <LayoutPageGrid title={`Период ${moment(period?.start).format('MMMM YYYY')}`}>
        <Card>
          <CardHeader title={`Информация о периоде`} />
          <CardContent>
            <InvisibleCard>
              <CardContent>
                <RegularNeedPeriodInformation period={data?.regularNeedPeriodById} />
              </CardContent>
              <CardActions>
                <AccountActions
                  period={period as RegularNeedPeriod}
                  need={period?.need as Need}
                  account={period?.account as RegularNeedPeriodAccount}
                />
              </CardActions>
            </InvisibleCard>
          </CardContent>
        </Card>
        <Card>
          <CardHeader title="Пожертвования" />
          <CardContent>
            <AccountDonations id={period?.account?.id} />
          </CardContent>
          <CardContent>
            <RegularNeedPeriodDocuments period={period as RegularNeedPeriod} />
          </CardContent>
        </Card>
      </LayoutPageGrid>
    </>
  );
};
