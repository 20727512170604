export const IsNotEmptyMessage = 'Поле не должно быть пустым';
export const regExp = /^(?!\s*$).+/;

export const createNotEmpty = (errorMessage: string | undefined = IsNotEmptyMessage) => (
  value: any
) => {
  if (!regExp.test(value)) {
    return errorMessage;
  }

  if (value === undefined || value === null) {
    return errorMessage;
  }

  if (typeof value === 'string' && value === '') {
    return errorMessage;
  }
};

export const notEmpty = createNotEmpty();
