import React from 'react';
import { counter, moneyCounter, CountersGroupDefinition } from './CountersGroup';
import { StatisticsQuery, useStatisticsQuery } from '../../../schema';
import { CountersRow } from './CountersRow';

const groups: CountersGroupDefinition<StatisticsQuery>[] = [
  {
    title: 'ДЕНЬ',
    subtitle: (e) => e.statistics.today.start,
    counters: [
      [
        moneyCounter(
          'Средства',
          (e) => e.statistics.today.transactionsAmount,
          (e) => e.statistics.yesterday.transactionsAmount
        ),
        counter(
          'Транзакции',
          (e) => e.statistics.today.completedTransactionsCount,
          (e) => e.statistics.yesterday.completedTransactionsCount
        ),
      ],
    ],
  },
  {
    title: 'НЕДЕЛЯ',
    subtitle: (e) => e.statistics.thisWeek.start + ' — ' + e.statistics.thisWeek.end,
    counters: [
      [
        moneyCounter(
          'Средства',
          (e) => e.statistics.thisWeek.transactionsAmount,
          (e) => e.statistics.lastWeek.transactionsAmount
        ),
        counter(
          'Транзакции',
          (e) => e.statistics.thisWeek.completedTransactionsCount,
          (e) => e.statistics.lastWeek.completedTransactionsCount
        ),
      ],
    ],
  },
  {
    title: 'МЕСЯЦ',
    subtitle: (e) => e.statistics.thisMonth.start + ' — ' + e.statistics.thisMonth.end,
    counters: [
      [
        moneyCounter(
          'Средства',
          (e) => e.statistics.thisMonth.transactionsAmount,
          (e) => e.statistics.lastMonth.transactionsAmount
        ),
        counter(
          'Транзакции',
          (e) => e.statistics.thisMonth.completedTransactionsCount,
          (e) => e.statistics.lastMonth.completedTransactionsCount
        ),
      ],
    ],
  },
];

export interface StatisticsProps {
  className?: string;
}

export const Statistics = (props: StatisticsProps) => {
  return (
    <CountersRow
      groups={groups}
      useQuery={useStatisticsQuery}
      width={4}
      innerWidth={6}
      className={props.className}
    />
  );
};
