import React, { useCallback } from 'react';
import { KeyboardDateTimePicker, KeyboardDateTimePickerProps } from '@material-ui/pickers';
import { FieldRenderProps } from 'react-final-form-hooks';
import type { Moment } from 'moment';
import moment from 'moment';

export type DateTimeFieldProps = Omit<KeyboardDateTimePickerProps, 'value' | 'onChange'> &
  FieldRenderProps & { returnTime: boolean };

export const DateTimeField = (props: DateTimeFieldProps) => {
  const {
    input,
    meta,
    invalidDateMessage = 'Неверный формат даты и времени',
    format = 'DD.MM.YYYY HH:mm',
    variant = 'inline',
    inputVariant = 'outlined',
    autoOk = true,
    ...rest
  } = props;

  const handleOnChange = useCallback(
    (date: Moment | null) => {
      date?.parseZone();
      input.onChange(date?.utc(false));
    },
    [input]
  );

  const getVisibleValue = (value: string) => {
    return moment(value).utc(false);
  };

  const value = input.value ? getVisibleValue(input.value) : null;

  return (
    <KeyboardDateTimePicker
      {...rest}
      {...input}
      variant={variant}
      inputVariant={inputVariant}
      onChange={handleOnChange}
      value={value}
      ampm={false}
      format={format}
      invalidDateMessage={invalidDateMessage}
      autoOk={autoOk}
    />
  );
};
