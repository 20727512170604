import { AdministratorsKind } from 'schema/serverTypes';

export const kinds = [
  AdministratorsKind.SystemAdministrators,
  AdministratorsKind.FoundationRepresentatives,
  AdministratorsKind.Moderators,
];

export const kindNames = {
  [AdministratorsKind.SystemAdministrators]: 'Системный администратор',
  [AdministratorsKind.FoundationRepresentatives]: 'Представитель фонда',
  [AdministratorsKind.Moderators]: 'Модератор',
};
