import React, { useCallback } from 'react';
import { WardProps } from '../types';
import { Card, CardContent } from '@material-ui/core';
import { Breadcrumbs } from './Breadcrumbs';
import { ManageDocumentsForm } from 'components';
import { Document, useUpdateWardDocumentsMutation } from 'schema/serverTypes';
import { LayoutPageGrid } from 'ui/LayoutGrid/LayoutGrid';

export const WardDocumentsPage = ({ ward }: WardProps) => {
  const [mutate] = useUpdateWardDocumentsMutation();

  const onSave = useCallback(
    (documents: Document[]) => {
      mutate({
        variables: {
          input: {
            id: ward.id,
            documents: documents.map((document) => {
              const {
                file: { id },
                title,
              } = document;
              return { id, title };
            }),
          },
        },
      });
    },
    [mutate, ward.id]
  );

  return (
    <>
      <Breadcrumbs ward={ward} />
      <LayoutPageGrid title="Документы подопечного">
        <Card>
          <CardContent>
            <ManageDocumentsForm
              documents={ward.privateInformation?.documents || []}
              onSave={onSave}
            />
          </CardContent>
        </Card>
      </LayoutPageGrid>
    </>
  );
};
