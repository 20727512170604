import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  TextField,
  Typography,
  Theme,
  Snackbar,
  Paper,
  createStyles,
} from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { useResetPasswordForm } from './useResetPasswordForm';

export interface ResetPasswordFormProps {
  code: string;
}

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    form: {
      paddingLeft: 30,
      paddingRight: 30,
      paddingTop: 36,
      paddingBottom: 32,
      [theme.breakpoints.down('sm')]: {
        paddingTop: 5,
        paddingBottom: 4,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    title: {
      marginTop: theme.spacing(3),
      fontWeight: 'bolder',
    },
    textField: {
      marginTop: theme.spacing(4),
    },
    signInButton: {
      margin: theme.spacing(2, 0),
    },
  })
);

export const ResetPasswordForm = (props: ResetPasswordFormProps) => {
  const classes = useStyles();

  const { code } = props;

  const {
    handleSubmit,
    open,
    handleClose,
    fields: { email, password, confirmPassword },
    disabled,
  } = useResetPasswordForm(code);

  return (
    <Paper>
      <form className={classes.form} onSubmit={handleSubmit}>
        <Snackbar
          open={open}
          autoHideDuration={1000}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={handleClose}
        >
          <Alert severity="error" onClose={handleClose}>
            Не удалось сбросить пароль!
          </Alert>
        </Snackbar>
        <Typography className={classes.title} variant="h4">
          Сброс пароля
        </Typography>
        <TextField
          className={classes.textField}
          error={!email.meta.valid && email.meta.touched}
          fullWidth
          helperText={!email.meta.valid && email.meta.touched ? email.meta.error : null}
          label="Email"
          type="text"
          variant="outlined"
          {...email.input}
        />
        <TextField
          className={classes.textField}
          error={!password.meta.valid && password.meta.touched}
          fullWidth
          helperText={!password.meta.valid && password.meta.touched ? password.meta.error : null}
          label="Пароль"
          type="password"
          variant="outlined"
          {...password.input}
        />
        <TextField
          className={classes.textField}
          error={!confirmPassword.meta.valid && confirmPassword.meta.touched}
          fullWidth
          helperText={
            !confirmPassword.meta.valid && confirmPassword.meta.touched
              ? confirmPassword.meta.error
              : null
          }
          label="Подтверждение пароля"
          type="password"
          variant="outlined"
          {...confirmPassword.input}
        />
        <Button
          className={classes.signInButton}
          color="primary"
          disabled={disabled}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          Сбросить пароль
        </Button>
      </form>
    </Paper>
  );
};
