import React from 'react';
import { Typography, Box, makeStyles, Theme, createStyles } from '@material-ui/core';

export interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: 'hidden',
    },
  })
);

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...rest } = props;
  const classes = useStyles();

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...rest}
    >
      <Box className={classes.root}>{children}</Box>
    </Typography>
  );
};
