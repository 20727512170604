import React from 'react';
import { Backdrop, createStyles, Fade, makeStyles, Modal, Theme } from '@material-ui/core';
import { useBackRoute } from 'hooks';
import { EditBlockStoryModalForm } from './EditBlockStoryModalForm';
import { StoryBlock } from '../../../../schema';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    form: {
      padding: '15px',
      backgroundColor: theme.palette.background.paper,
      border: '0px',
      borderRadius: '2px',
      boxShadow: theme.shadows[5],
    },
  })
);

type Props = {
  blockStory: StoryBlock;
};

export const EditBlockStoryModal = ({ blockStory }: Props) => {
  const styles = useStyles();

  const { onCancel } = useBackRoute();

  return (
    <Modal
      open={true}
      onClose={onCancel}
      aria-labelledby="Добавление блока историй"
      aria-describedby="Используйте эту форму для добавления блока историй"
      className={styles.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={true}>
        <EditBlockStoryModalForm blockStory={blockStory} onClose={onCancel} />
      </Fade>
    </Modal>
  );
};
