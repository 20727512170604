import React from 'react';
import { Typography } from '@material-ui/core';
import { DataGrid } from 'components';
import { ProjectExpense } from 'schema';
import { useAccounts } from './useAccounts';
import { Loading } from 'ui/Loading';

export const ProjectAccounts = () => {
  const { donations, gridProps, loading } = useAccounts();

  if (loading) {
    return <Loading />;
  }

  if (!donations.length) {
    return <Typography>Нет счетов</Typography>;
  }

  return (
    <>
      <DataGrid<ProjectExpense> {...gridProps} />
    </>
  );
};
