import React from 'react';
import { createStyles, IconButton, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { FileInformation, Maybe } from '../../../../../schema';

type DeleteStoryButtonProps = {
  id: string;
  image?: Maybe<FileInformation>;
  handleClickDeleteButton: (storyId: string) => void;
};

export const DeleteStoryButton = ({
  id,
  image,
  handleClickDeleteButton,
}: DeleteStoryButtonProps) => {
  const useStyles = makeStyles(() =>
    createStyles({
      icon: { color: image ? 'white' : '' },
    })
  );
  const classes = useStyles();

  return (
    <IconButton className={classes.icon} onClick={() => handleClickDeleteButton(id)}>
      <DeleteIcon />
    </IconButton>
  );
};
