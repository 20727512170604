import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { red } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    iconColor: {
      color: theme.palette.text.secondary,
    },
    defaultCardColor: {
      color: theme.palette.success.dark,
    },
    errorIconColor: {
      color: theme.palette.error.main,
    },
    buttons: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    button: {
      margin: theme.spacing(0.7),
    },
    avatar: {
      width: theme.spacing(14),
      height: theme.spacing(14),
    },
    tableInfoHeader: {
      borderBottom: 'none',
    },
    tableInfo: {
      textAlign: 'right',
      borderBottom: 'none',
    },
    divider: {
      borderBottom: theme.spacing(1),
      width: '100%',
      marginBottom: theme.spacing(1),
    },
    headerLabel: {
      fontWeight: 'bolder',
      marginTop: theme.spacing(2),
    },
    deleteButton: {
      color: red[500],
    },
    disableButton: {
      color: theme.palette.error.main,
    },
    transactionErrors: {
      color: theme.palette.error.main,
    },
    blueText: {
      color: theme.palette.primary.main,
    },
    accessButton: {
      marginLeft: '15px',
      marginRight: '15px',
      color: theme.palette.common.white,
      backgroundColor: theme.palette.success.dark,
      '&:hover': {
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.success.light,
      },
    },
    header: {
      fontSize: '18px',
      fontWeight: 'bold',
    },
    alarm: {
      marginBottom: '20px',
    },
    accounts: {
      width: '100%',
      margin: '20px 0',
    },
    account: {
      minHeight: theme.spacing(4),
      alignItems: 'center',
    },
  })
);
