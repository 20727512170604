import React from 'react';
import { PropertiesTable, PropertiesTableRow } from '../../../../../ui/PropertiesTable';
import { RegularNeedPeriodAccount } from '../../../../../schema';

type Props = {
  account: RegularNeedPeriodAccount;
};

export const RegularNeedAccountInformation = ({ account }: Props) => {
  return (
    <PropertiesTable>
      <PropertiesTableRow caption="Баланс" value={account.amount} isAmount />
    </PropertiesTable>
  );
};
