import { onError } from '@apollo/client/link/error';
import { authStorage, userStorage } from 'helpers';

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach((error) => {
      const { message, locations, path, extensions } = error;
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Code: ${extensions?.code}`
      );
      if (extensions?.code === 'AUTH_NOT_AUTHENTICATED') {
        authStorage.clear();
        userStorage.clear();
        window.location.href = '/login';
      }
    });

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});
