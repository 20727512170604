import React from 'react';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import { makeStyles, Theme, Button, createStyles } from '@material-ui/core';
import { Gender, WardPropsFragment } from 'schema';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: theme.palette.common.black,
      borderColor: theme.palette.common.black,
      '&:hover': {
        borderColor: theme.palette.common.black,
      },
      marginLeft: theme.spacing(1),
    },
  })
);

interface PassedAwayButtonProps {
  ward: WardPropsFragment;
}

export const PassedAwayButton = (props: PassedAwayButtonProps) => {
  const classes = useStyles();
  const { ward } = props;

  if (ward.deactivationInformation != null) {
    return null;
  }

  const title =
    ward.publicInformation.gender === Gender.Female ? 'Подопечная умерла' : 'Подопечный умер';
  const link = `/wards/manage/${ward.id}/edit/rip`;

  return (
    <Button
      className={classes.button}
      variant="outlined"
      startIcon={<NotificationsActiveIcon />}
      component={Link}
      to={link}
    >
      {title}
    </Button>
  );
};
