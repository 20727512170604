import React from 'react';
import { RequiringHelpButton } from './RequiringHelpButton';
import { NotRequiringHelpButton } from './NotRequiringHelpButton';
import { WardProps } from '../../types';

export const ImmediateHelpButton = (props: WardProps) => {
  const {
    ward: { id, immediateHelpRequired },
  } = props;

  if (immediateHelpRequired) {
    return <NotRequiringHelpButton id={id} />;
  }
  return <RequiringHelpButton id={id} />;
};
