import { useCallback, useEffect } from 'react';
import { useUpdateWardMutation, UpdateWardInput, WardPropsFragment } from 'schema';
import { useCancelRoute } from 'hooks';

type InitialValues = Omit<UpdateWardInput, 'documentIds'>;

const mapToInput = (ward: WardPropsFragment) => {
  const { publicInformation, privateInformation, id } = ward;
  const { name, photo } = publicInformation;
  const { firstName, lastName, middleName } = name;

  const input: InitialValues = {
    id,
    firstName,
    lastName,
    middleName,
    passportIssueDate: privateInformation?.passport?.dateOfIssue,
    passportIssuerCode: privateInformation?.passport?.issuerCode,
    passportIssuer: privateInformation?.passport?.issuer,
    passportNumber: privateInformation?.passport?.number,
    passportSeries: privateInformation?.passport?.series,
    placeOfBirth: privateInformation?.passport?.placeOfBirth,
    publicCoordinates: `${publicInformation.publicLatitude}, ${publicInformation.publicLongitude}`,
    address: privateInformation?.address.displayText || '',
    comments: privateInformation?.comments || '',
    dateOfBirth: publicInformation.dateOfBirth,
    disabilityGroup: privateInformation?.disabilityGroup,
    gender: publicInformation.gender,
    maritalStatus: privateInformation?.maritalStatus,
    photoId: photo.id,
    profession: privateInformation?.profession || '',
    rank: privateInformation?.rank || '',
    story: publicInformation.story,
    phoneNumber: privateInformation?.phoneNumber || '',
    city: publicInformation.city || '',
    categories: publicInformation.categories.map(({ id }) => id) || [],
  };

  return input;
};

export const useEditWardForm = (ward: WardPropsFragment) => {
  const { onCancel } = useCancelRoute(`/wards/manage/${ward.id}`);

  const [mutate, { loading, data }] = useUpdateWardMutation();

  const initialValues = mapToInput(ward);

  const onSubmit = useCallback(
    ({ passportIssueDate, publicCoordinates, ...rest }: InitialValues) => {
      mutate({
        variables: {
          input: {
            publicCoordinates: publicCoordinates.split(', ').reverse().join(', '),
            passportIssueDate: passportIssueDate !== '' ? passportIssueDate : null,
            ...rest,
          },
        },
      });
    },
    [mutate]
  );

  useEffect(() => {
    if (data !== undefined) {
      onCancel();
    }
  }, [data, onCancel]);

  return {
    initialValues,
    onSubmit,
    onCancel,
    loading,
  };
};
