import React from 'react';
import {
  Card,
  Button,
  CardActions,
  CardContent,
  Avatar,
  Typography,
  Divider,
  Box,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ViewWardProps } from './types';
import { MapPanel } from 'components/MapPanel';
import { QuickActionButtons, WardInfo } from '../components';
import { ViewWardPublicityButton } from './components';
import moment from 'moment';
import { useStyles } from './useStyles';
import { RouteWithoutLayout, useMeContext } from 'components';
import { AddNeedPage, EditNeedPage } from '../../components';
import { LayoutPageGrid } from 'ui/LayoutGrid';
import { PropertiesTable, PropertiesTableRow } from 'ui/PropertiesTable';
import { InvisibleCard } from 'ui/InvisibleCard';
import { AuditTable } from 'components/AuditTable/AuditTable';
import { BenefactorsListPage } from '../BenefactorsListPage';
import { getDeactivationReason } from '../deactivationReasons';

export const ViewWardPage = ({ ward }: ViewWardProps) => {
  const classes = useStyles();
  const {
    id,
    publicInformation,
    privateInformation,
    deactivationInformation,
    created,
    updated,
    benefactorDepositAccounts,
  } = ward;
  const {
    name: { displayName },
    photo: { url },
    city,
    story,
    publicLatitude,
    publicLongitude,
    dateOfBirth,
    dateOfDeath,
  } = publicInformation;

  const { user } = useMeContext();

  const editWard = user?.permissions.wards.edit;
  const privateWard = user?.permissions.wards.viewPrivateInformation;
  const benefactorsDeposits = user?.permissions.accounts.viewDepositAccounts;
  const baseUrl = `/wards/manage/${id}`;
  const editUrl = `${baseUrl}/edit`;
  const addNeedUrl = `${baseUrl}/needs/add`;
  const manageDocumentsUrl = `${baseUrl}/documents`;
  const formattedDateOfBirth = moment(dateOfBirth as string).format('DD.MM.YYYY');
  const lifetime =
    dateOfDeath === null
      ? formattedDateOfBirth
      : `${formattedDateOfBirth} - ${moment(dateOfDeath as string).format('DD.MM.YYYY')}`;
  const formattedDeactivation = ward.deactivationInformation
    ? `${getDeactivationReason(ward.deactivationInformation.reason)} (${moment(
        deactivationInformation?.date as string
      ).format('DD.MM.YYYY HH:mm:ss')})`
    : null;

  return (
    <>
      <LayoutPageGrid
        title={displayName}
        tools={editWard ? <QuickActionButtons ward={ward} /> : <></>}
      >
        <Card>
          <CardContent>
            <InvisibleCard>
              <CardContent className={classes.content}>
                <Avatar alt={displayName} src={url} className={classes.avatar} />
                <Typography
                  component="span"
                  variant="subtitle2"
                  align="center"
                  className={classes.headerAge}
                >
                  {moment(dateOfBirth as string).fromNow(true)} ({lifetime})
                </Typography>
              </CardContent>
              {editWard ? (
                <CardActions className={classes.buttons}>
                  <Button color="primary" variant="contained" component={Link} to={editUrl}>
                    Редактировать
                  </Button>
                  {!ward.deactivationInformation && <ViewWardPublicityButton ward={ward} />}
                </CardActions>
              ) : (
                <></>
              )}
              <CardContent className={classes.content}>
                <MapPanel latitude={publicLatitude} longitude={publicLongitude} height={250} />

                <Divider className={classes.divider} />

                {privateWard ? (
                  <>
                    <PropertiesTable>
                      <PropertiesTableRow
                        caption="Адрес"
                        value={privateInformation?.address.displayText}
                        isAmount
                      />
                      {privateInformation?.profession && (
                        <PropertiesTableRow
                          caption="Профессия"
                          value={privateInformation?.profession}
                          isAmount
                        />
                      )}
                      {privateInformation?.rank && (
                        <PropertiesTableRow
                          caption="Звание / чин"
                          value={privateInformation?.rank}
                          isAmount
                        />
                      )}
                      {privateInformation?.disabilityGroup && (
                        <PropertiesTableRow
                          caption="Инвалидность"
                          value={`Группа ${privateInformation?.disabilityGroup}`}
                          isAmount
                        />
                      )}
                      {publicInformation.categories && (
                        <PropertiesTableRow
                          caption={'Категории'}
                          value={publicInformation.categories.map(({ name }) => name).join(', ')}
                        />
                      )}
                      <PropertiesTableRow caption="Город" value={city} isAmount />
                      <PropertiesTableRow
                        caption="Документы"
                        value={
                          <Button
                            color="primary"
                            variant="contained"
                            component={Link}
                            to={manageDocumentsUrl}
                          >
                            Посмотреть{' '}
                            {privateInformation && privateInformation?.documents.length > 0
                              ? `(${privateInformation?.documents.length})`
                              : null}
                          </Button>
                        }
                        isAmount
                      />
                      {deactivationInformation && (
                        <PropertiesTableRow caption="Деактивирован" value={formattedDeactivation} />
                      )}
                    </PropertiesTable>
                    <Divider />
                    <AuditTable
                      items={[
                        { caption: 'Добавил', marker: created },
                        { caption: 'Обновил', marker: updated },
                      ]}
                    />
                  </>
                ) : (
                  <></>
                )}

                <Divider className={classes.divider} />
                <Box className={classes.story}>
                  <Typography>{story}</Typography>
                </Box>

                {!ward.deactivationInformation && benefactorsDeposits && (
                  <>
                    <Divider className={classes.divider} />
                    <Typography
                      component="div"
                      variant="h5"
                      align="center"
                      className={classes.headerLabel}
                    >
                      Жертвователи
                    </Typography>
                    <Box className={classes.accountsList}>
                      {benefactorDepositAccounts?.map((benefactor, index) => {
                        if (index === benefactorDepositAccounts.length - 1) {
                          return (
                            <Typography key={benefactor.benefactor.id}>
                              {`${benefactor.benefactor.displayName}.`}
                            </Typography>
                          );
                        }

                        return (
                          <Typography key={benefactor.benefactor.id}>
                            {`${benefactor.benefactor.displayName}, `}
                          </Typography>
                        );
                      })}
                    </Box>
                    <CardActions className={classes.buttons}>
                      <Button
                        color="primary"
                        variant="contained"
                        component={Link}
                        to={`${baseUrl}/benefactors-list`}
                      >
                        Управлять
                      </Button>
                    </CardActions>
                  </>
                )}
              </CardContent>
            </InvisibleCard>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <WardInfo ward={ward} addNeedUrl={addNeedUrl} />
          </CardContent>
        </Card>
      </LayoutPageGrid>
      <RouteWithoutLayout
        exact
        path="/wards/manage/:id/needs/add"
        component={AddNeedPage}
        componentProps={{ ward }}
      />
      <RouteWithoutLayout
        exact
        path="/wards/manage/:id/needs/:needId/edit"
        component={EditNeedPage}
        componentProps={{ ward }}
      />
      <RouteWithoutLayout
        exact
        path="/wards/manage/:id/benefactors-list"
        component={BenefactorsListPage}
        componentProps={{ ward }}
      />
    </>
  );
};
