import React from 'react';
import { Card, CardContent, CardHeader, createStyles, makeStyles } from '@material-ui/core';

import { AuditMarkerPropsFragment, FileInformation, Maybe } from '../../../../../schema';

import { DeleteStoryButton } from './DeleteStoryButton';
import { PublishedStatusButton } from './PublishedStatusButton';
import { DragHandleCard } from '../../../StoryPage/components/ScreenStoryCard/DragHandleCard';
import { SortableElement } from 'react-sortable-hoc';

type StoryCardProps = {
  id: string;
  title: string;
  index: number;
  published?: Maybe<{ __typename?: 'AuditMarker' } & AuditMarkerPropsFragment>;
  image?: Maybe<FileInformation>;
  handleClickCard: (storyId: string) => void;
  handleClickDeleteButton: (storyId: string) => void;
};

export const StoryCard = SortableElement(
  ({ id, title, published, handleClickCard, image, handleClickDeleteButton }: StoryCardProps) => {
    const useStyles = makeStyles(() =>
      createStyles({
        card: {
          color: image ? 'white' : 'black',
          position: 'relative',
          width: '200px',
          height: '300px',
          backgroundImage: image ? `url(${image.url})` : '',
          backgroundSize: 'cover',
          zIndex: 1,
        },
        cardTitle: {
          color: image ? 'white' : 'black',
          zIndex: 1,
        },
        content: {
          cursor: 'pointer',
          height: '80%',
          zIndex: 1,
        },
        overlay: {
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))',
          zIndex: -1,
        },
      })
    );
    const classes = useStyles();

    return (
      <Card className={classes.card}>
        {image && <div className={classes.overlay} />}
        <CardHeader
          classes={{ title: classes.cardTitle }}
          title={title}
          action={
            <DeleteStoryButton
              handleClickDeleteButton={handleClickDeleteButton}
              image={image}
              id={id}
            />
          }
        />
        <CardContent className={classes.content} onClick={() => handleClickCard(id)} />

        <PublishedStatusButton id={id} published={published} />
        <DragHandleCard />
      </Card>
    );
  }
);
