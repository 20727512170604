import React from 'react';
import { useParams } from 'react-router-dom';
import { Modal, useMeContext } from 'components';
import { useCancelRoute, usePermissionPage } from 'hooks';
import { useUserByIdQuery } from 'schema';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { EditFormOrEmpty } from 'components/hoc';
import { EditModeratorForm } from '../../moderators/ModeratorsPage/EditModeratorPage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    form: {
      width: '100%',
      padding: '25px',
      backgroundColor: theme.palette.background.paper,
      border: '0px',
      borderRadius: '2px',
      boxShadow: theme.shadows[5],
    },
  })
);

interface EditedRouteParams {
  id: string;
}

const EditFoundationRepresentative = EditFormOrEmpty(EditModeratorForm);

export const EditFoundationRepresentativePage = ({ id }: EditedRouteParams) => {
  const styles = useStyles();

  const { user } = useMeContext();

  usePermissionPage(
    `/foundations/manage/${id}`,
    user?.permissions.users.createAndEditFoundationRepresentative
  );

  const { userId } = useParams<{ userId: string }>();
  const { data, error, loading } = useUserByIdQuery({ variables: { id: userId } });

  const { onCancel } = useCancelRoute(`/foundations/manage/${id}`);

  return (
    <Modal
      onClose={onCancel}
      aria-labelledby="Редактирование представителя фонда"
      aria-describedby="Используйте эту форму для редактирования представителя фонда"
      className={styles.modal}
    >
      <div className={styles.form}>
        <EditFoundationRepresentative
          onClose={onCancel}
          loading={loading}
          hasError={error !== undefined}
          data={data}
        />
      </div>
    </Modal>
  );
};
