import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { getTokens } from './getTokens';
import { authStorage, userStorage } from 'helpers';
import moment from 'moment';
import { CurrentTokenInformation } from 'components';
import { ResponseType } from '../types';

const isTokenExpired = () => {
  const tokens = authStorage.get();
  if (!tokens) {
    return true;
  }

  const { requestAt, expires_in } = tokens;
  const expirationDate = moment(requestAt as string)
    .utc()
    .add(moment.duration(expires_in, 'second'));
  const now = moment().utc();

  return now >= expirationDate;
};

const getAccessToken = () => {
  const tokens = authStorage.get();
  return tokens?.access_token;
};

const getRefreshToken = () => {
  const tokens = authStorage.get();
  if (tokens?.refresh_token) {
    const form = {
      grant_type: 'refresh_token',
      refresh_token: tokens?.refresh_token,
    };
    return form;
  }
};

export const refreshTokenLink = new TokenRefreshLink({
  isTokenValidOrUndefined: () => !isTokenExpired() || typeof getAccessToken() !== 'string',
  fetchAccessToken: () => {
    return getTokens(getRefreshToken());
  },
  handleFetch: (access_token) => {
    const tokens = authStorage.get();
    if (tokens) {
      const newTokens: CurrentTokenInformation = {
        ...tokens,
        access_token,
      };
      authStorage.save(newTokens);
    }
  },
  handleResponse: () => async (response: Response) => {
    const json = await response;
    const data = json as ResponseType;

    const tokens = authStorage.get();
    if (tokens && data) {
      const access_token = data?.access_token;
      const newTokens: CurrentTokenInformation = {
        ...tokens,
        requestAt: new Date(),
        ...data,
      };
      authStorage.save(newTokens);
      console.log(`new token: ${access_token}`);
      return {
        access_token,
      };
    }
    return undefined;
  },
  handleError: (err) => {
    console.log('Your refresh token is invalid. Try to login again.');
    console.error(err);
    authStorage.clear();
    userStorage.clear();
    window.location.href = '/login';
  },
});
