import React from 'react';
import {
  BenefactorExtendedPropsFragment,
  BenefactorPaymentInformationFragment,
  BenefactorPermissions,
} from 'schema';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import { BenefactorCard, BenefactorHelp } from './components';
import { LayoutPageGrid } from 'ui/LayoutGrid/LayoutGrid';

interface BenefactorManagePageProps {
  benefactor: BenefactorExtendedPropsFragment;
  paymentInformation: BenefactorPaymentInformationFragment;
  permissions: BenefactorPermissions;
}

export const BenefactorManagePage = (props: BenefactorManagePageProps) => {
  const { benefactor, paymentInformation, permissions } = props;

  return (
    <>
      <LayoutPageGrid title={benefactor?.displayName}>
        <Card>
          <CardHeader title="Информация о жертвователе" />
          <BenefactorCard
            benefactor={benefactor}
            permissions={permissions}
            paymentInformation={paymentInformation}
          />
        </Card>
        <Card>
          <CardHeader title="Помощь" />
          <CardContent>
            <BenefactorHelp benefactor={benefactor} permissions={permissions} />
          </CardContent>
        </Card>
      </LayoutPageGrid>
    </>
  );
};
