import React, { useCallback, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { useStoryBlockQuery } from '../../../../schema';
import { Loading } from '../../../../ui/Loading';
import EditIcon from '@material-ui/icons/Edit';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CardPageGrid } from '../../../../ui/CardGrid';
import { AddCard } from '../../components/AddCard';
import { AddBlockStoryModal } from '../AddBlockStoryModal';
import { EditBlockStoryModal } from '../EditBlockStory';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      width: '220px',
    },

    content: {
      cursor: 'pointer',
      width: '100%',
      height: '100%',
    },
  })
);

export const BlockStoriesPage = () => {
  const { data, loading, error } = useStoryBlockQuery();
  const classes = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    if (error) {
      toast.error('Ошибка загрузки блоков историй');
    }
  }, [error]);

  const handleClickEditButton = useCallback(
    (id: string) => {
      history.push(`/stories/block/${id}/edit/`);
    },
    [history]
  );

  const handleClickCard = useCallback(
    (blockId: string) => {
      history.push(`/block/${blockId}/stories`);
    },
    [history]
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <CardPageGrid title="Список блоков историй">
        {data?.storyBlocks.map(({ id, title, label, stories }) => (
          <Card key={id} className={classes.card}>
            <CardHeader
              title={title}
              action={
                <IconButton onClick={() => handleClickEditButton(id)}>
                  <EditIcon />
                </IconButton>
              }
            />
            <CardContent className={classes.content} onClick={() => handleClickCard(id)}>
              <Typography>{label}</Typography>
              <Typography>
                Количество историй: {stories!.nodes!.length > 0 ? stories?.nodes?.length : null}
              </Typography>
            </CardContent>
          </Card>
        ))}
        <AddCard path={`${pathname}/block/add`} text={'Добавить блок'} />
      </CardPageGrid>

      <Route path="/stories/block/add" component={AddBlockStoryModal} />
      <Route exact path="/stories/block/:id/edit/" component={EditBlockStoryModal} />
    </>
  );
};
