import React from 'react';
import { LayoutPageGrid } from 'ui/LayoutGrid';
import { FoundationEventReport } from 'schema';
import { usePermissionPage } from 'hooks';
import { EventReportForm } from './EventReportForm';

interface EventReportPageProps {
  eventId: string;
  eventTitle: string;
  permissions: boolean;
  report: FoundationEventReport;
}

export const EventReportPage = (props: EventReportPageProps) => {
  const { permissions, report, eventTitle, eventId } = props;

  usePermissionPage('/foundations', permissions);
  return (
    <LayoutPageGrid title={`Отчет о ${eventTitle}`}>
      <EventReportForm eventId={eventId} report={report} />
    </LayoutPageGrid>
  );
};
