import React from 'react';
import { AutoFocusedForm, DateField, Modal, TextField } from 'components';
import { required } from 'components';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { useAddProjectExpenseForm } from './';
import { Field } from 'react-final-form';
import { MutableButton } from 'ui/MutableButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '500px',
    },
    header: {
      fontWeight: 'bolder',
    },
    divider: {},
    content: {},
    actions: {
      justifyContent: 'flex-end',
    },
  })
);

export const AddProjectExpensePopup = () => {
  const classes = useStyles();

  const { onCancel, initialValues, loading, onSubmit } = useAddProjectExpenseForm();

  return (
    <Modal
      onClose={onCancel}
      aria-labelledby="Добавление расхода"
      aria-describedby="Используйте эту форму для Добавление расхода"
    >
      <AutoFocusedForm
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, pristine }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Card className={classes.root}>
                <CardHeader title="Добавление расхода" />
                <Divider className={classes.divider} />
                <CardContent className={classes.content}>
                  <Grid container spacing={4}>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <Field
                        name="title"
                        component={TextField}
                        label="Краткое описание расходов"
                        validate={required}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={4}>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <Field
                        name="requiredAmount"
                        component={TextField}
                        label="Требуемая (потраченная) сумма"
                        validate={required}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={4}>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <Field
                        name="referenceDate"
                        label="Дата отчётного периода"
                        fullWidth={true}
                        component={DateField}
                        returnTime={false}
                        validate={required}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions className={classes.actions}>
                  <MutableButton
                    loading={loading}
                    color="primary"
                    disabled={pristine}
                    size="medium"
                    type="submit"
                    variant="contained"
                  >
                    Сохранить
                  </MutableButton>
                  <Button
                    onClick={onCancel}
                    disabled={loading}
                    size="medium"
                    type="button"
                    variant="contained"
                  >
                    Отменить
                  </Button>
                </CardActions>
              </Card>
            </form>
          );
        }}
      />
    </Modal>
  );
};
