import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  useDeleteUserMutation,
  useEnableOrDisableUserMutation,
  FoundationByIdDocument,
} from 'schema/serverTypes';
import { toast } from 'react-toastify';

export const useFoundationActions = (
  id: string,
  disabled: boolean,
  close: () => void,
  foundationId: string
) => {
  const history = useHistory();

  const onEdit = useCallback(() => {
    close();
    history.push(`/foundations/manage/${foundationId}/edit-user/${id}`);
  }, [id, history, close, foundationId]);

  const [
    deleteUser,
    { data: deleteData, loading: deleteLoading, error: deleteError },
  ] = useDeleteUserMutation({
    variables: { input: { id } },
    refetchQueries: [
      {
        query: FoundationByIdDocument,
        variables: {
          id: foundationId,
        },
      },
    ],
  });

  const onDelete = useCallback(async () => {
    await deleteUser({ variables: { input: { id } } });
    close();
  }, [deleteUser, id, close]);

  const [
    enableOrDisableUser,
    { data: enableOrDisableData, loading: enableOrDisableLoading, error: enableOrDisableError },
  ] = useEnableOrDisableUserMutation();

  const onEnableOrDisable = useCallback(async () => {
    await enableOrDisableUser({
      variables: {
        input: {
          id,
          disabled: !disabled,
        },
      },
    });
    close();
  }, [enableOrDisableUser, id, disabled, close]);

  if (enableOrDisableError || enableOrDisableData?.enableOrDisableUser.errors) {
    deleteError && toast.error('Произошла ошибка изменения статуса');
    enableOrDisableData?.enableOrDisableUser.errors &&
      toast.error(
        `${enableOrDisableData?.enableOrDisableUser.errors.map((error) => error.message)}`
      );
  }

  if (deleteError || deleteData?.deleteUser.errors) {
    deleteError && toast.error('Произошла ошибка удаления');
    deleteData?.deleteUser.errors &&
      toast.error(`${deleteData?.deleteUser.errors.map((error) => error.message)}`);
  }

  const loading = deleteLoading || enableOrDisableLoading;
  const error = deleteError || enableOrDisableError;
  const success =
    deleteData?.deleteUser.success ||
    enableOrDisableData?.enableOrDisableUser.user !== undefined ||
    false;

  return {
    onEdit,
    onDelete,
    onEnableOrDisable,
    loading,
    error,
    success,
  };
};
