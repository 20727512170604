import {
  Benefactor,
  Skill,
  UpdateBenefactorSkillsInformationByAdministratorInput,
  useUpdateBenefactorSkillsByAdministratorMutation,
} from 'schema';
import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

interface InitialValuesType {
  skills: Array<Skill | null>;
  portfolio?: string | null;
  experience?: string | null;
}

export const useEditBenefactorSkillsForm = (benefactor: Benefactor, onCancel: () => void) => {
  const [
    skillsUpdate,
    { data, error, loading },
  ] = useUpdateBenefactorSkillsByAdministratorMutation();

  const onSubmit = useCallback(
    async (values: InitialValuesType) => {
      const isSkillsNeedRequired = values.skills.filter((skill) => skill?.portfolioIsRequired);
      const inputValues: UpdateBenefactorSkillsInformationByAdministratorInput = {
        benefactorId: benefactor.id,
        skills: values.skills.map((item) => item?.id || ''),
      };

      if (!isSkillsNeedRequired.length) {
        await skillsUpdate({
          variables: {
            input: inputValues,
          },
        });
        return;
      }

      if (values.portfolio || values.experience) {
        await skillsUpdate({
          variables: {
            input: {
              ...inputValues,
              portfolio: values.portfolio,
              experience: values.experience,
            },
          },
        });
        return;
      }

      toast.error('Для выбранных навыков необходимо заполнить портфолио и/или опыт', {
        position: 'top-center',
      });
    },
    [skillsUpdate, benefactor]
  );

  useEffect(() => {
    if (error) {
      toast.error('Ошибка изменения навыков');
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      toast.success('Навыки пользователя успешно изменены');
      onCancel();
    }
  }, [data, onCancel]);

  const initialValues: InitialValuesType = {
    skills: benefactor.skillsInformation?.skills || [],
    experience: benefactor.skillsInformation?.experience,
    portfolio: benefactor.skillsInformation?.portfolio,
  };

  return {
    initialValues,
    onSubmit,
    loading,
  };
};
