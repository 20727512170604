import React from 'react';
import { ToastContainer } from 'react-toastify';
import { Redirect, Route, Switch } from 'react-router-dom';
import { RouteWithLayout } from '../RouteWithLayout';
import {
  AddWardPage,
  AddOrEditFoundationPage,
  BenefactorContainer,
  BenefactorsPage,
  CompanyContainer,
  DashboardPage,
  ForgotPasswordPage,
  FoundationContainer,
  FoundationsPage,
  LoginPage,
  ManageAccountPage,
  ModeratorsPage,
  NotificationsPage,
  ProjectPage,
  ResetPasswordPage,
  VersionsPage,
  WardContainer,
  WardsPage,
  AwardsPage,
  AwardManagePage,
  BenefactorStatusesPage,
  BenefactorStatusManagePage,
  SpecialProjectsPage,
  AddSpecialProjectPage,
  SpecialProjectContainer,
} from 'pages';
import { Layout, Minimal } from 'layouts';
import { ScrollTop } from '../ScrollTop';
import { CitiesPage } from 'pages/cities';
import { Stories } from '../../pages/stories/StoriesPage';
import { StoriesInBlockPage } from '../../pages/stories/StoriesInBlock/StoriesInBlockPage';
import StoryPage from '../../pages/stories/StoryPage/StoryPage';
import { EditStoryPage } from '../../pages/stories/EditStoryPage';
import ProjectExpensePage from '../../pages/project/ProjectExpensePage/ProjectExpensePage';
import { CategoriesPage } from '../../pages/categories';

const AppRouter = () => {
  return (
    <ScrollTop>
      <ToastContainer hideProgressBar />
      <Switch>
        <RouteWithLayout exact path="/login" component={LoginPage} layout={Minimal} />
        <RouteWithLayout
          exact
          path="/reset-password"
          component={ResetPasswordPage}
          layout={Minimal}
        />
        <RouteWithLayout
          exact
          path="/forgot-password"
          component={ForgotPasswordPage}
          layout={Minimal}
        />

        <RouteWithLayout exact path="/wards" component={WardsPage} layout={Layout} />
        <RouteWithLayout exact path="/wards/create" component={AddWardPage} layout={Layout} />
        <Route path="/wards/manage/:id" component={WardContainer} />

        <RouteWithLayout
          exact
          path="/special-projects/create"
          component={AddSpecialProjectPage}
          layout={Layout}
        />

        <Route path="/special-projects/manage/:id" component={SpecialProjectContainer} />
        <RouteWithLayout path="/special-projects" component={SpecialProjectsPage} layout={Layout} />

        <RouteWithLayout exact path="/foundations" component={FoundationsPage} layout={Layout} />
        <RouteWithLayout
          exact
          path="/foundations/create"
          component={AddOrEditFoundationPage}
          layout={Layout}
        />
        <Route path="/foundations/manage/:id" component={FoundationContainer} />

        <RouteWithLayout path="/moderators" component={ModeratorsPage} layout={Layout} />

        <Route path="/benefactors/manage/:id" component={BenefactorContainer} />

        <RouteWithLayout path="/benefactors" component={BenefactorsPage} layout={Layout} />
        <RouteWithLayout
          path={'/project/:projectId/projectExpense'}
          layout={Layout}
          component={ProjectExpensePage}
        />
        <RouteWithLayout path="/project" component={ProjectPage} layout={Layout} />

        <RouteWithLayout path="/company" component={CompanyContainer} layout={Layout} />

        <RouteWithLayout path="/account/:id" component={ManageAccountPage} layout={Layout} />

        <RouteWithLayout path="/versions" component={VersionsPage} layout={Layout} />
        <RouteWithLayout path="/notifications" component={NotificationsPage} layout={Layout} />
        <RouteWithLayout path="/categories" component={CategoriesPage} layout={Layout} />
        <RouteWithLayout path="/cities" component={CitiesPage} layout={Layout} />
        <RouteWithLayout path="/stories" component={Stories} layout={Layout} />
        <RouteWithLayout
          path="/block/:blockId/story/:storyId/manage"
          component={EditStoryPage}
          layout={Layout}
        />
        <RouteWithLayout
          path="/block/:blockId/story/:storyId"
          component={StoryPage}
          layout={Layout}
        />
        <RouteWithLayout
          path="/block/:blockId/stories"
          component={StoriesInBlockPage}
          layout={Layout}
        />

        <RouteWithLayout
          path="/benefactor-statuses/manage/:statusId"
          component={BenefactorStatusManagePage}
          layout={Layout}
        />

        <RouteWithLayout
          path="/benefactor-statuses"
          component={BenefactorStatusesPage}
          layout={Layout}
        />

        <RouteWithLayout path="/awards" component={AwardsPage} layout={Layout} />
        <RouteWithLayout path="/award/:awardId" component={AwardManagePage} layout={Layout} />

        <RouteWithLayout path="/dashboard" component={DashboardPage} layout={Layout} />

        <Redirect to="/dashboard" />
      </Switch>
    </ScrollTop>
  );
};

export default AppRouter;
