import React from 'react';
import { Route } from 'react-router-dom';
import { usePermissionPage } from 'hooks';
import { useMeContext } from 'components';
import { CategoriesGrid } from './CategoriesGrid';
import { CreateCategoryPage } from './CreateCategoryPage';
import { DeleteCategoryPage } from './DeleteCategory';

export const CategoriesPage = () => {
  const { user } = useMeContext();

  usePermissionPage('/categories', user?.permissions.categories.manage);

  return (
    <>
      <CategoriesGrid />
      <Route exact path="/categories/add" component={CreateCategoryPage} />
      <Route exact path={'/categories/delete/:id'} component={DeleteCategoryPage} />
    </>
  );
};
