import { useCallback, useContext } from 'react';
import {
  EventParticipationRequirementQueryContext,
  QueryVariablesContext,
} from '../QueryContextProvider';
import { RequirementType } from '../ParticipationRequirementField';
import { useSkillCategoriesQuery } from 'schema';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

interface InitialValuesTypes {
  id: string;
  quantity: number;
  unlimited: boolean;
  autoSelect: boolean;
  description?: string;
}

export const useAddOrEditRequirementModalForm = (onClose: () => void) => {
  const variables = useContext<EventParticipationRequirementQueryContext>(QueryVariablesContext);
  const params = useParams<{ id: string }>();

  const editSkill = variables?.requirements.find((item) => item.skill.id === params.id);

  const { data, loading } = useSkillCategoriesQuery();

  const getItems = () => {
    const items = [];

    for (const category of data?.skillCategories ?? []) {
      items.push({ ...category, isGroup: true });
      for (const skill of category.skills) {
        items.push(skill);
      }
    }

    return items;
  };

  const onSubmit = useCallback(
    (values: InitialValuesTypes) => {
      if (editSkill || !variables?.requirements.find((item) => item.skill.id === values.id)) {
        const skill = data?.skillCategories
          .map((category) => category.skills.flat(Infinity))
          .flat()
          .find((skill) => skill.id === values.id);

        const input: RequirementType = {
          skill: {
            id: skill.id,
            title: skill.title,
            description: skill.description,
            portfolioIsRequired: skill.portfolioIsRequired,
          },
          description: values.description,
          quantity: values.unlimited ? 0 : values.quantity,
          autoSelect: values.autoSelect,
          edit: editSkill,
        };
        variables?.handleChangeRequirements(input);
        onClose();
      } else {
        toast.error('Такой навык уже существует', { position: 'top-center' });
      }
    },
    [onClose, variables, data, editSkill]
  );

  const initialValues: InitialValuesTypes = {
    id: editSkill?.skill.id || '',
    quantity: editSkill?.quantity || 0,
    unlimited: false,
    autoSelect: editSkill?.autoSelect || false,
    description: editSkill?.description || undefined,
  };

  return { initialValues, onSubmit, loading, getItems, editSkill };
};
