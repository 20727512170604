import { AccountQuery, useAccountQuery } from 'schema';

type UseAccountProps = {
  id: string;
};

export const useAccount = ({ id }: UseAccountProps): AccountQuery['account'] => {
  const queryVariables = { id };
  const { data } = useAccountQuery({
    variables: queryVariables,
  });

  return data?.account || null;
};
