import { useState, useCallback } from 'react';
import { useForm, FormConfig } from 'react-final-form-hooks';
import { useRequiredField, useFormField, CurrentTokenInformation, useMeContext } from 'components';
import { LoginRequest } from 'pages/login/types';
import { authStorage } from 'helpers/authStorage';
import { getTokens } from 'schema/client/tokensLink/getTokens';
import { ResponseType } from 'schema/client/types';

type LoginFormConfig = FormConfig<LoginRequest>;

export const useLoginForm = () => {
  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const { login } = useMeContext();

  const onSubmit = useCallback(
    async (request: LoginRequest) => {
      const form = {
        grant_type: 'password',
        username: request.email,
        password: request.password,
      };
      const data: ResponseType = await getTokens(form);

      if (data === undefined) {
        return;
      }

      if (data.error) {
        setOpen(true);
        return;
      }

      const refresh_token = data?.refresh_token;
      const access_token = data?.access_token;

      if (access_token === undefined || refresh_token === undefined || refresh_token === null) {
        return;
      }

      const expires_in = data?.expires_in as number;
      const requestAt = new Date();

      const currentTokens: CurrentTokenInformation = {
        access_token,
        refresh_token,
        expires_in,
        requestAt,
      };

      authStorage.save(currentTokens);

      login(request.rememberMe);
    },
    [login]
  );

  const { form, handleSubmit, submitting, pristine, hasValidationErrors } = useForm({
    onSubmit,
    initialValues: {
      email: '',
      password: '',
      rememberMe: true,
    },
  } as LoginFormConfig);

  const email = useRequiredField('email', form);
  const password = useRequiredField('password', form);
  const rememberMe = useFormField('rememberMe', form);

  return {
    handleClose,
    fields: {
      email,
      password,
      rememberMe,
    },
    handleSubmit,
    disabled: submitting || pristine || hasValidationErrors,
    open,
  };
};
