import React from 'react';
import {
  Grid,
  Card,
  CardHeader,
  Divider,
  CardContent,
  CardActions,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import { AutoFocusedForm, CheckboxField, moreThanZero, required, TextField } from 'components';
import { Field } from 'react-final-form';
import { useEditCityForm } from './useEditCityForm';
import { City } from 'schema/serverTypes';
import { RedButton } from 'components/Buttons';
import { MutableButton } from 'ui/MutableButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { minWidth: '400px' },
    header: {
      fontWeight: 'bolder',
    },
    divider: {},
    content: {
      margin: '10px',
    },
    actions: {
      justifyContent: 'flex-end',
    },
    accessButton: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.success.dark,
      '&:hover': {
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.success.light,
      },
    },
  })
);

export interface EditCityFormProps {
  onClose: () => void;
  city: City;
}

export const EditCityForm = ({ onClose, city }: EditCityFormProps) => {
  const styles = useStyles();

  const { onSubmit, initialValues, loading } = useEditCityForm(city, onClose);

  return (
    <AutoFocusedForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, pristine }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card className={styles.root}>
              <CardHeader title="Редактирование города" />
              <Divider className={styles.divider} />
              <CardContent className={styles.content}>
                <Grid container spacing={4}>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Field
                      name="name"
                      label="Наименование города"
                      component={TextField}
                      validate={required}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item lg={6} md={6} xl={6} xs={6}>
                    <CheckboxField label={'Показывать по умолчанию?'} name="default" />
                  </Grid>
                  <Grid item lg={5} md={5} xl={5} xs={5}>
                    <Field
                      name="order"
                      label="Порядок показа города в списке"
                      component={TextField}
                      validate={moreThanZero}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions className={styles.actions}>
                <MutableButton
                  className={styles.accessButton}
                  loading={loading}
                  color="primary"
                  disabled={pristine}
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  Сохранить
                </MutableButton>
                <RedButton
                  onClick={onClose}
                  disabled={loading}
                  size="medium"
                  type="button"
                  variant="contained"
                >
                  Отменить
                </RedButton>
              </CardActions>
            </Card>
          </form>
        );
      }}
    />
  );
};
