import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const CompleteIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 Z M16.5135839,8.99690897 C16.082322,8.65189942 15.4530296,8.7218208 15.1080201,9.15308273 L15.1080201,9.15308273 L11.288,13.927 L9.29136171,12.3302423 L9.18854393,12.257966 C8.76496771,11.998128 8.20426821,12.0883281 7.88579786,12.4864161 C7.54078831,12.917678 7.61070968,13.5469704 8.04197162,13.8919799 L8.04197162,13.8919799 L10.8197494,16.1142021 L10.9225672,16.1864785 C11.3461434,16.4463164 11.9068429,16.3561163 12.2253133,15.9580284 L12.2253133,15.9580284 L16.6697577,10.4024728 L16.742034,10.299655 C17.001872,9.87607883 16.9116719,9.31537932 16.5135839,8.99690897 Z" />
    </SvgIcon>
  );
};
