import React, { useCallback } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { AlarmIcon } from 'components/Icons';
import { useMarkWardAsRequiringImmediateHelpMutation } from 'schema/serverTypes';
import { MutableButton } from 'ui/MutableButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
      '&:hover': {
        borderColor: theme.palette.error.main,
      },
    },
  })
);

export const RequiringHelpButton = (props: { id: string }) => {
  const classes = useStyles();
  const { id } = props;
  const [markAsRequiringHelp, { loading }] = useMarkWardAsRequiringImmediateHelpMutation({
    variables: {
      input: {
        id,
      },
    },
  });
  const onClick = useCallback(() => {
    markAsRequiringHelp();
  }, [markAsRequiringHelp]);

  return (
    <MutableButton
      loading={loading}
      className={classes.button}
      variant="outlined"
      startIcon={<AlarmIcon />}
      onClick={onClick}
    >
      Срочная помощь
    </MutableButton>
  );
};
