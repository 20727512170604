import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  primary: {
    marginBottom: '20px',
  },
  header: {
    fontWeight: 'bolder',
    marginBottom: '10px',
    marginTop: '20px',
  },
  avatar: {
    borderRadius: '0',
    width: '250px',
    height: '245px',
  },
  accessButton: {
    marginLeft: '15px',
    marginRight: '15px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.success.dark,
    '&:hover': {
      color: theme.palette.success.dark,
      backgroundColor: theme.palette.success.light,
    },
  },
  photo: {
    maxWidth: 249,
    maxHeight: 245,
  },
}));
