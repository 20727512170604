import React from 'react';
import { Card, CardActions, CardContent, Grid, Theme, Typography } from '@material-ui/core';
import { MutableButton } from 'ui/MutableButton';
import { RedButton } from 'components/Buttons';
import { AutoFocusedForm, FilesUploadField, required, TextField } from 'components';
import { makeStyles } from '@material-ui/styles';
import { Field } from 'react-final-form';
import { useEventReportForm } from './useEventReportForm';
import { Document, FoundationEventReport } from 'schema';
import { FieldArray } from 'react-final-form-arrays';

const useStyles = makeStyles((theme: Theme) => ({
  primary: {
    marginBottom: '20px',
  },
  accessButton: {
    marginLeft: '15px',
    marginRight: '15px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.success.dark,
    '&:hover': {
      color: theme.palette.success.dark,
      backgroundColor: theme.palette.success.light,
    },
  },
  header: {
    fontWeight: 'bolder',
    marginBottom: '10px',
    marginTop: '20px',
  },
}));

interface EventReportFormProps {
  report: FoundationEventReport;
  eventId: string;
}

export const EventReportForm = (props: EventReportFormProps) => {
  const { report, eventId } = props;
  const styles = useStyles();

  const { initialValues, onSubmit, loading, onCancel } = useEventReportForm(eventId, report);

  return (
    <AutoFocusedForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, pristine }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card className={styles.primary}>
              <CardContent>
                <Grid container spacing={4}>
                  <Grid item lg={8} md={8} xl={8} xs={12}>
                    <Field
                      name="text"
                      label="Отчет *"
                      component={TextField}
                      validate={required}
                      multiline
                      rows={5}
                      rowsMax={50}
                    />
                  </Grid>
                </Grid>
                <Typography variant="h5" component="h5" className={styles.header}>
                  Фотографии
                </Typography>
                <Grid container spacing={4}>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <FieldArray<Document>
                      label="Фотографии *"
                      name="images"
                      component={FilesUploadField}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <MutableButton
                  className={styles.accessButton}
                  loading={loading}
                  disabled={pristine}
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  Сохранить
                </MutableButton>
                <RedButton
                  color="primary"
                  size="medium"
                  type="button"
                  variant="contained"
                  onClick={onCancel}
                >
                  Отменить
                </RedButton>
              </CardActions>
            </Card>
          </form>
        );
      }}
    />
  );
};
