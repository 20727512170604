import React from 'react';
import { createContext } from 'react';
import { QueryClientVersionsArgs } from 'schema/serverTypes';

export type ClientVersionsQueryContext = QueryClientVersionsArgs | undefined;

export const QueryVariablesContext = createContext<ClientVersionsQueryContext>({ first: 10 });

export interface QueryContextProviderProps {
  variables: ClientVersionsQueryContext;
  children: React.ReactNode;
}

export const QueryContextProvider = (props: QueryContextProviderProps) => {
  const { children, variables } = props;
  return (
    <QueryVariablesContext.Provider value={variables}>{children}</QueryVariablesContext.Provider>
  );
};
