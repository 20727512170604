import React from 'react';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    description: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  })
);

interface InnerTextProps {
  loading: Boolean;
  accept: string[];
  maxSize: number;
}

export const InnerText = ({ loading, accept, maxSize }: InnerTextProps) => {
  const styles = useStyles();
  const size = maxSize ? maxSize / (1024 * 1024) : 5;
  const types = accept
    ?.map((type: string) =>
      type.replace('image/', '*.').replace('application/', '*.').toUpperCase()
    )
    .join(', ');

  if (loading) {
    return (
      <Typography variant="h6" align="center" color="textPrimary">
        Загружается...
      </Typography>
    );
  }
  return (
    <div className={styles.description}>
      <AddIcon fontSize="large" />
      <Typography variant="body1" align="center" color="textSecondary">
        Файлы в формате {types} до {size} МБ
      </Typography>
    </div>
  );
};
