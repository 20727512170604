import React from 'react';
import { AutoFocusedForm, notEmpty, TextField } from 'components';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { MutableButton } from 'ui/MutableButton';
import { RedButton } from 'components/Buttons';
import { Field } from 'react-final-form';
import { Loading } from 'ui/Loading';
import { useAddStoryModalForm } from './useAddStoryModalForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 500,
    },
    header: {
      fontWeight: 'bolder',
    },
    content: {
      margin: '10px',
    },
    actions: {
      justifyContent: 'flex-end',
    },
    accessButton: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.success.dark,
      '&:hover': {
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.success.light,
      },
    },
  })
);

interface AddBlockStoryModalFormProps {
  onClose: () => void;
}

export const AddStoryModalForm = ({ onClose }: AddBlockStoryModalFormProps) => {
  const styles = useStyles();
  const { onSubmit, initialValues, loading } = useAddStoryModalForm();

  if (loading) {
    return <Loading />;
  }

  return (
    <AutoFocusedForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, pristine }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card className={styles.root}>
              <CardHeader title={`Добавить историю в блок`} />
              <Divider />
              <CardContent className={styles.content}>
                <Grid container spacing={4}>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Field
                      name="title"
                      label="Заголовок истории"
                      component={TextField}
                      type="string"
                      validate={notEmpty}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions className={styles.actions}>
                <MutableButton
                  className={styles.accessButton}
                  loading={loading}
                  color="primary"
                  disabled={pristine}
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  Добавить историю
                </MutableButton>
                <RedButton
                  onClick={onClose}
                  disabled={loading}
                  size="medium"
                  type="button"
                  variant="contained"
                >
                  Отменить
                </RedButton>
              </CardActions>
            </Card>
          </form>
        );
      }}
    />
  );
};
