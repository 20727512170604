import React, { useMemo } from 'react';
import { ColumnConfig, DataConnection, useQueryVariables } from 'hooks';
import { LimitedLiabilityCompanyReport, useCompanyReportsQuery } from 'schema';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { CompleteIcon, DataGridProps } from 'components';
import { useStyles } from './useStyles';
import DocumentIcon from '@material-ui/icons/InsertDriveFile';
import moment from 'moment';

const getDataKey = (account: LimitedLiabilityCompanyReport) => account.id;

const getReferenceDate = (account: LimitedLiabilityCompanyReport) => {
  return moment(account.referenceDate as string).format('D MMMM YYYY');
};

export const useReports = () => {
  const classes = useStyles();

  const config = useMemo(() => {
    /**
     * Конфиг колонок для таблицы
     */
    const columns: ColumnConfig<LimitedLiabilityCompanyReport>[] = [
      {
        id: 'id',
        header: 'ID',
        orderBy: (kind) => ({
          displayName: kind,
        }),
        render: (report: LimitedLiabilityCompanyReport) => {
          return (
            <Link className={classes.link} to={`/company/report/${report.id}`}>
              {report.title}
            </Link>
          );
        },
      },
      {
        id: 'endDate',
        header: 'Дата закрытия',
        render: (report: LimitedLiabilityCompanyReport) => {
          return getReferenceDate(report);
        },
      },
      {
        id: 'hasDocuments',
        header: 'Документы',
        align: 'center',
        render: (report: LimitedLiabilityCompanyReport) => {
          return (
            !!report.documents.length && (
              <Link to={`/company/report/${report.id}`}>
                <DocumentIcon className={classes.iconColor} />
              </Link>
            )
          );
        },
      },
      {
        id: 'published',
        header: 'Опубликован',
        align: 'center',
        render: (report: LimitedLiabilityCompanyReport) => {
          return !!report.published?.timestamp && <CompleteIcon className={classes.iconColor} />;
        },
      },
      {
        id: 'actions',
        header: '',
        render: (report: LimitedLiabilityCompanyReport) => {
          return (
            <Button
              className={classes.button}
              color="primary"
              variant="contained"
              size="small"
              component={Link}
              to={`/company/report/${report.id}`}
            >
              Детали
            </Button>
          );
        },
      },
    ];

    return {
      columns,
    };
  }, [classes]);

  /**
   * Подготовка переменных запроса
   */
  const { variables, extractPagedData, ...restVariables } = useQueryVariables<
    LimitedLiabilityCompanyReport
  >(config);

  /**
   * Запрос
   */
  const { data, loading } = useCompanyReportsQuery({
    variables: {
      ...variables,
    },
  });

  /**
   * Парсинг данных
   */
  const entities = data?.limitedLiabilityCompany?.reports as DataConnection<
    LimitedLiabilityCompanyReport
  >;
  const { data: reports, count, startCursor, endCursor, page, rowsPerPage } = extractPagedData(
    entities
  );
  const balance = data?.limitedLiabilityCompany?.balance;

  /**
   * Свойства для компонента Grid
   */
  const gridProps: DataGridProps<LimitedLiabilityCompanyReport> = {
    ...restVariables,
    data: reports,
    getDataKey,
    count,
    startCursor: startCursor,
    endCursor: endCursor,
    page,
    rowsPerPage,
  };

  return { balance, reports, gridProps, loading };
};
