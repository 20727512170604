import { useCallback, useEffect } from 'react';
import {
  DeactivateWardInput,
  useDeactivateWardMutation,
  WardDeactivationReason,
  WardPropsFragment,
} from 'schema/serverTypes';
import { FORM_ERROR } from 'final-form';

import { DeactivationReasons } from '../../deactivationReasons';

interface DeactivationFormValues {
  reason: WardDeactivationReason;
  fullName: string;
}

const initialValues: DeactivationFormValues = {
  reason: DeactivationReasons.find((x) => x.allowedToUse)?.reason || WardDeactivationReason.Refusal,
  fullName: '',
};

export const useWardDeactivationForm = (ward: WardPropsFragment, onClose: () => void) => {
  const [deactivateWardMutation, { data }] = useDeactivateWardMutation();

  const onSubmit = useCallback(
    async (values: DeactivationFormValues) => {
      const input: DeactivateWardInput = { wardId: ward.id, reason: values.reason };

      try {
        return await deactivateWardMutation({
          variables: { input },
        });
      } catch (e) {
        return { [FORM_ERROR]: 'Ошибка сервера' };
      }
    },
    [ward.id, deactivateWardMutation]
  );

  useEffect(() => {
    if (data !== undefined) {
      onClose();
    }
  });

  return {
    initialValues,
    onSubmit,
  };
};
