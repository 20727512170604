import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  Typography,
  MenuItem,
  Theme,
  CardActions,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  ListItemText,
  Checkbox,
} from '@material-ui/core';
import { Field } from 'react-final-form';
import { useEditWardForm } from './useEditWardForm';
import {
  TextField,
  DateField,
  PhotoField,
  AutoFocusedForm,
  required,
  passportIssuerCode,
  passportNumber,
  passportSeries,
  SelectField,
  PhoneField,
  composeValidators,
  phoneNumber,
  AddressField,
} from 'components';
import { GenderField } from '../../components';
import {
  MaritalStatus,
  Gender,
  WardPropsFragment,
  useCitiesQuery,
  useCategoriesQuery,
} from 'schema';
import { AppCoordField } from '../../components/AppCoordField';
import { InvisibleCard } from 'ui/InvisibleCard';
import { MutableButton } from 'ui/MutableButton';
import { RedButton } from 'components/Buttons';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  primary: {
    marginBottom: '20px',
  },
  secondary: {
    marginBottom: '20px',
  },
  header: {
    fontWeight: 'bolder',
    marginBottom: '10px',
    marginTop: '20px',
  },
  privateHeader: {
    fontWeight: 'bolder',
    marginBottom: '20px',
  },
  subheader: {
    color: theme.palette.warning.main,
  },
  accessButton: {
    marginLeft: '15px',
    marginRight: '15px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.success.dark,
    '&:hover': {
      color: theme.palette.success.dark,
      backgroundColor: theme.palette.success.light,
    },
  },
}));

interface EditWardFormProps {
  ward: WardPropsFragment;
}

export const EditWardForm = ({ ward }: EditWardFormProps) => {
  const classes = useStyles();

  const { initialValues, onSubmit, onCancel, loading } = useEditWardForm(ward);

  const { data } = useCitiesQuery();

  const cities = data?.cities?.nodes;

  const { data: categoriesData } = useCategoriesQuery();
  const categories = categoriesData?.categories
    ?.slice()
    .map((category) => category)
    .sort((a, b) => {
      return a.order - b.order;
    });

  return (
    <AutoFocusedForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, pristine, values }) => {
        const genderValue = values.gender;
        return (
          <form onSubmit={handleSubmit}>
            <InvisibleCard>
              <CardContent>
                <Card className={classes.primary}>
                  <CardHeader
                    subheader="Информация ниже видна пользователям. Будьте внимательны при заполнении данных"
                    title="Основные сведения"
                    subheaderTypographyProps={{ className: classes.subheader }}
                  />
                  <Divider />
                  <CardContent>
                    <Typography variant="h5" component="h5" className={classes.header}>
                      Фото
                    </Typography>
                    <Grid container spacing={4}>
                      <Grid item lg={4} md={4} xl={3} xs={12}>
                        <Field
                          name="photoId"
                          component={PhotoField}
                          url={ward.publicInformation.photo.originalUrl}
                        />
                      </Grid>
                      <Grid item lg={8} md={8} xl={9} xs={12}>
                        <Typography variant="h5" component="h5" className={classes.privateHeader}>
                          Личные данные
                        </Typography>
                        <Grid container spacing={4}>
                          <Grid item lg={4} md={6} xl={4} xs={12}>
                            <Field
                              name="lastName"
                              label="Фамилия *"
                              component={TextField}
                              validate={required}
                            />
                          </Grid>
                          <Grid item lg={4} md={6} xl={4} xs={12}>
                            <Field
                              name="firstName"
                              label="Имя *"
                              component={TextField}
                              validate={required}
                            />
                          </Grid>
                          <Grid item lg={4} md={6} xl={4} xs={12}>
                            <Field
                              name="middleName"
                              label="Отчество *"
                              component={TextField}
                              validate={required}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={4}>
                          <Grid item lg={4} md={3} xl={4} xs={12}>
                            <Field
                              name="dateOfBirth"
                              label="Дата рождения *"
                              fullWidth={true}
                              component={DateField}
                              returnTime={false}
                              validate={required}
                            />
                          </Grid>
                          <Grid item lg={4} md={5} xl={4} xs={12}>
                            <Field
                              name="gender"
                              label="Пол *"
                              component={GenderField}
                              validate={required}
                              type="radio"
                            />
                          </Grid>
                          <Grid item lg={4} md={4} xl={4} xs={12}>
                            <Field
                              name="phoneNumber"
                              label="Телефон *"
                              component={PhoneField}
                              fullWidth={true}
                              validate={composeValidators(required, phoneNumber)}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={4}>
                          <Grid item lg={12} md={12} xl={12} xs={12}>
                            <Field
                              name="story"
                              label="История подопечного *"
                              component={TextField}
                              validate={required}
                              multiline
                              rows={7}
                              rowsMax={50}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={4}>
                          <Grid item lg={12} md={12} xl={12} xs={12}>
                            <Field
                              name="categories"
                              label={'Категория *'}
                              render={({ input }) => {
                                const { name, onChange, value } = input;
                                const selectedNames = categories
                                  ?.filter((category) => value.includes(category.id))
                                  .map((category) => category.name);
                                return (
                                  <FormControl variant="outlined" fullWidth>
                                    <InputLabel id={'label'}>Категория *</InputLabel>
                                    <Select
                                      name={name}
                                      labelId={'label'}
                                      value={value}
                                      multiple
                                      input={<OutlinedInput label="Категория *" />}
                                      renderValue={() => selectedNames?.join(', ')}
                                      onChange={onChange}
                                    >
                                      {categories?.map(({ name, id }) => (
                                        <MenuItem key={id} value={id}>
                                          <Checkbox checked={value.includes(id)} />
                                          <ListItemText primary={name} />
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                );
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Typography variant="h5" component="h5" className={classes.header}>
                          Адрес
                        </Typography>
                        <Grid container spacing={4}>
                          <Grid item lg={12} md={12} xl={12} xs={12}>
                            <Field
                              name="address"
                              label="Город, улица, дом, квартира *"
                              component={AddressField}
                              needDetermineCoordinates="hasCoordinates"
                              validate={required}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={4}>
                          <Grid item lg={12} md={12} xl={12} xs={12}>
                            <Field
                              name="city"
                              label="Город *"
                              component={SelectField}
                              validate={required}
                            >
                              {cities?.map(({ name, id }) => (
                                <MenuItem key={id} value={name}>
                                  {name}
                                </MenuItem>
                              ))}
                            </Field>
                          </Grid>
                        </Grid>
                        <Grid container spacing={4}>
                          <Grid item lg={12} md={12} xl={12} xs={12}>
                            <Field
                              name="publicCoordinates"
                              label="Широта и долгота *"
                              component={AppCoordField}
                              validate={required}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                <Card className={classes.secondary}>
                  <CardHeader
                    subheader="Информация ниже нужна только для внутреннего использования"
                    title="Дополнительные сведения"
                  />
                  <Divider />
                  <CardContent>
                    <Typography variant="h5" component="h5" className={classes.header}>
                      Общее
                    </Typography>
                    <Grid container spacing={4}>
                      <Grid item lg={6} md={6} xl={6} xs={12}>
                        <Field
                          name="maritalStatus"
                          component={SelectField}
                          label="Семейное положение"
                        >
                          <MenuItem value="">
                            <em>Не указан</em>
                          </MenuItem>
                          <MenuItem value={MaritalStatus.Married}>
                            {genderValue === Gender.Male ? 'женат' : 'замужем'}
                          </MenuItem>
                          <MenuItem value={MaritalStatus.Divorced}>
                            {genderValue === Gender.Male ? 'разведен' : 'разведена'}
                          </MenuItem>
                          <MenuItem value={MaritalStatus.Single}>
                            {genderValue === Gender.Male ? 'холост' : 'не замужем'}
                          </MenuItem>
                          <MenuItem value={MaritalStatus.Widowed}>
                            {genderValue === Gender.Male ? 'вдовец' : 'вдова'}
                          </MenuItem>
                          <MenuItem value={MaritalStatus.Separated}>в гражданском браке</MenuItem>
                        </Field>
                      </Grid>
                      <Grid item lg={6} md={6} xl={6} xs={12}>
                        <Field name="profession" label="Укажите профессию" component={TextField} />
                      </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                      <Grid item lg={6} md={6} xl={6} xs={12}>
                        <Field name="rank" label="Укажите звание/чин" component={TextField} />
                      </Grid>
                      <Grid item lg={6} md={6} xl={6} xs={12}>
                        <Field
                          name="disabilityGroup"
                          component={SelectField}
                          label="Группа инвалидности"
                        >
                          <MenuItem>
                            <em>Не указан</em>
                          </MenuItem>
                          <MenuItem value={1}>I группа</MenuItem>
                          <MenuItem value={2}>II группа</MenuItem>
                          <MenuItem value={3}>III группа</MenuItem>
                        </Field>
                      </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <Field
                          name="comments"
                          label="Примечание к подопечному"
                          component={TextField}
                          multiline
                          rows={5}
                          rowsMax={50}
                        />
                      </Grid>
                    </Grid>
                    <Typography variant="h5" component="h5" className={classes.header}>
                      Паспортные данные
                    </Typography>
                    <Grid container spacing={4}>
                      <Grid item lg={3} md={6} xl={3} xs={12}>
                        <Field
                          name="passportSeries"
                          label="Серия"
                          component={TextField}
                          validate={passportSeries}
                        />
                      </Grid>
                      <Grid item lg={3} md={6} xl={3} xs={12}>
                        <Field
                          name="passportNumber"
                          label="Номер"
                          component={TextField}
                          validate={passportNumber}
                        />
                      </Grid>
                      <Grid item lg={3} md={6} xl={3} xs={12}>
                        <Field
                          name="passportIssueDate"
                          label="Дата выдачи"
                          fullWidth={true}
                          component={DateField}
                        />
                      </Grid>
                      <Grid item lg={3} md={6} xl={3} xs={12}>
                        <Field
                          name="passportIssuerCode"
                          label="Код подразделения"
                          component={TextField}
                          validate={passportIssuerCode}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                      <Grid item lg={6} md={6} xl={6} xs={12}>
                        <Field name="passportIssuer" label="Кем выдан" component={TextField} />
                      </Grid>
                      <Grid item lg={6} md={6} xl={6} xs={12}>
                        <Field name="placeOfBirth" label="Место рождения" component={TextField} />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardActions>
                <MutableButton
                  className={classes.accessButton}
                  loading={loading}
                  color="primary"
                  disabled={pristine}
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  Сохранить
                </MutableButton>
                <RedButton
                  color="primary"
                  size="medium"
                  type="button"
                  variant="contained"
                  onClick={onCancel}
                >
                  Отменить
                </RedButton>
              </CardActions>
            </InvisibleCard>
          </form>
        );
      }}
    />
  );
};
