import {
  Document,
  OneTimeNeed,
  useUpdateOneTimeNeedDocumentsMutation,
  WardByIdDocument,
} from '../../../../../schema';
import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import { Loading } from '../../../../../ui/Loading';
import { ManageDocumentsForm, useMeContext } from '../../../../../components';
import { useParams } from 'react-router-dom';

type Props = {
  oneTimeNeed: OneTimeNeed;
};

export const OneTimeNeedDocuments = ({ oneTimeNeed }: Props) => {
  const { id } = useParams<{ id: string }>();
  const { documents, id: needId } = oneTimeNeed;
  const [updateOneTimeNeedDocuments, { loading, error }] = useUpdateOneTimeNeedDocumentsMutation();
  const { user } = useMeContext();

  const onSave = useCallback(
    async (documents: Document[]) => {
      const files = documents.map((document) => {
        const {
          file: { id },
          title,
        } = document;
        return { id, title };
      });

      try {
        await updateOneTimeNeedDocuments({
          variables: {
            input: {
              needId,
              documents: files,
            },
          },
          refetchQueries: [
            {
              query: WardByIdDocument,
              variables: { id, permission: user?.permissions?.wards.manageNeeds },
            },
          ],
        });
      } catch {
        error && toast.error('Ошибка загрузки документов');
      }
    },
    [updateOneTimeNeedDocuments, needId, id, user?.permissions, error]
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <ManageDocumentsForm documents={documents} onSave={onSave} />
    </>
  );
};
