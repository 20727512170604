import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FoundationEventAttendanceFilterInput } from 'schema';
import { Box, Button, Grid, MenuItem, Select } from '@material-ui/core';
import { SearchInput } from 'components';
import useDebouncedCallback from 'use-debounce/lib/useDebouncedCallback';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(1),
    },
    search: {
      paddingRight: theme.spacing(2),
    },
    selectLabel: {
      margin: '10px',
    },
    resetButton: {
      display: 'block',
      marginLeft: 'auto',
    },
  })
);

interface AttendanceSearchProps {
  onSearch: (input: FoundationEventAttendanceFilterInput | undefined) => void;
  onSearchName?: (benefactorSearch: string | undefined) => void;
}

interface FilterOptionsType {
  attendeeRating: string | number;
  attendeeComment: string | number;
  foundationRating: string | number;
  foundationComment: string | number;
}

export const AttendanceSearch = (props: AttendanceSearchProps) => {
  const { onSearch, onSearchName } = props;
  const styles = useStyles();

  const initialFilterOptions: FilterOptionsType = {
    attendeeRating: '',
    attendeeComment: '',
    foundationRating: '',
    foundationComment: '',
  };

  const [filterOptions, setFilterOptions] = useState<FilterOptionsType>(initialFilterOptions);

  const nameRef = useRef<HTMLInputElement>();

  const getName = useCallback(() => {
    const benefactorSearch = nameRef.current?.value || '';
    if (benefactorSearch !== '') {
      return benefactorSearch;
    }

    return undefined;
  }, []);

  const getWhere = useCallback(() => {
    const where: FoundationEventAttendanceFilterInput = {};

    if (filterOptions.attendeeRating !== '') {
      where.attendeeRating = { gte: filterOptions.attendeeRating as number };
    }

    if (filterOptions.foundationRating !== '') {
      where.foundationRating = { gte: filterOptions.foundationRating as number };
    }

    if (filterOptions.attendeeComment !== '') {
      where.attendeeComment = filterOptions.attendeeComment ? { neq: null } : { eq: null };
    }

    if (filterOptions.foundationComment !== '') {
      where.foundationComment = filterOptions.foundationComment ? { neq: null } : { eq: null };
    }

    if (!Object.keys(where).length) {
      return;
    }

    return where;
  }, [filterOptions]);

  const [handleOnNameChange] = useDebouncedCallback(() => {
    onSearchName !== undefined && onSearchName(getName());
  }, 1000);

  useEffect(() => {
    onSearch(getWhere());
  }, [onSearch, getWhere]);

  const handleOnFilterChange = useCallback(
    (event) => {
      const value = event.target.value;
      const name = event.target.name;
      setFilterOptions({
        ...filterOptions,
        [name]: value,
      });
    },
    [filterOptions]
  );

  console.log(onSearchName);

  const handleOnReset = useCallback(() => {
    if (nameRef && nameRef.current) {
      nameRef.current.value = '';
    }
    filterOptions.attendeeRating = '';
    filterOptions.attendeeComment = '';
    filterOptions.foundationComment = '';
    filterOptions.foundationRating = '';
    onSearchName !== undefined && onSearchName(undefined);
    onSearch(undefined);
  }, [onSearch, filterOptions, onSearchName]);

  return (
    <div className={styles.root}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <SearchInput
            inputRef={nameRef}
            className={styles.search}
            placeholder="Имя участника..."
            onChange={handleOnNameChange}
          />
        </Grid>
      </Grid>
      <Grid container spacing={4} alignItems="center">
        <Grid item lg={5} md={6} xl={4} xs={12}>
          <Box display="flex" alignItems="center">
            <h4 className={styles.selectLabel}>Оценка участника:</h4>
            <Select
              name="attendeeRating"
              value={filterOptions.attendeeRating}
              displayEmpty
              variant={'outlined'}
              onChange={handleOnFilterChange}
            >
              <MenuItem value="">Не важно</MenuItem>
              <MenuItem value={3}>Не менее 3х</MenuItem>
              <MenuItem value={4}>Не менее 4х</MenuItem>
            </Select>
          </Box>
        </Grid>
        <Grid item lg={5} md={6} xl={4} xs={12}>
          <Box display="flex" alignItems="center">
            <h4 className={styles.selectLabel}>Комментарий участника:</h4>
            <Select
              name="attendeeComment"
              value={filterOptions.attendeeComment}
              displayEmpty
              variant={'outlined'}
              onChange={handleOnFilterChange}
            >
              <MenuItem value="">Не важно</MenuItem>
              <MenuItem value={1}>Есть</MenuItem>
              <MenuItem value={0}>Нет</MenuItem>
            </Select>
          </Box>
        </Grid>
        <Grid item lg={4} md={6} xl={4} xs={12}>
          <Box display="flex" alignItems="center">
            <h4 className={styles.selectLabel}>Оценка организатора:</h4>
            <Select
              name="foundationRating"
              value={filterOptions.foundationRating}
              displayEmpty
              variant={'outlined'}
              onChange={handleOnFilterChange}
            >
              <MenuItem value="">Не важно</MenuItem>
              <MenuItem value={3}>Не менее 3х</MenuItem>
              <MenuItem value={4}>Не менее 4х</MenuItem>
            </Select>
          </Box>
        </Grid>
        <Grid item lg={5} md={6} xl={5} xs={12}>
          <Box display="flex" alignItems="center">
            <h4 className={styles.selectLabel}>Комментарий организатора:</h4>
            <Select
              name="foundationComment"
              value={filterOptions.foundationComment}
              displayEmpty
              variant={'outlined'}
              onChange={handleOnFilterChange}
            >
              <MenuItem value="">Не важно</MenuItem>
              <MenuItem value={1}>Есть</MenuItem>
              <MenuItem value={0}>Нет</MenuItem>
            </Select>
          </Box>
        </Grid>
        <Grid item lg={3} md={12} xl={6} xs={12}>
          <Button className={styles.resetButton} variant="contained" onClick={handleOnReset}>
            Сбросить фильтры
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
