import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  createStyles,
  Divider,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { RedButton } from 'components/Buttons';
import { useBackRoute } from 'hooks';
import React from 'react';
import { BenefactorFoundationCommentProps } from './BenefactorFoundationCommentModal';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      maxWidth: '600px',
      minWidth: '600px',
    },
    header: {
      fontWeight: 'bolder',
    },
    actions: {
      justifyContent: 'flex-end',
    },
  })
);

export const BenefactorFoundationComment = ({
  foundationComment,
}: BenefactorFoundationCommentProps) => {
  const styles = useStyles();
  const { onCancel } = useBackRoute();

  return (
    <Card className={styles.root}>
      <CardHeader className={styles.header} title="Отзыв" />
      <Divider />
      <CardContent>
        <Typography variant="h5" component={'p'}>
          {foundationComment}
        </Typography>{' '}
      </CardContent>
      <CardActions className={styles.actions}>
        <RedButton
          color="primary"
          size="medium"
          type="button"
          variant="contained"
          onClick={onCancel}
        >
          Закрыть
        </RedButton>
      </CardActions>
    </Card>
  );
};
