export const isInvalidUrl = 'Некорректый URL';
export const regExpUrl = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\\]))?/;

const isValidUrl = (errorMessage: string | undefined = isInvalidUrl) => (value: any) => {
  if (!regExpUrl.test(value)) {
    return errorMessage;
  }
};

export const validUrl = isValidUrl();
