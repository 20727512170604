import React, { useState, useCallback, useMemo } from 'react';
import {
  TextField,
  Modal,
  IconButton,
  Card,
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  InputAdornment,
  Link,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { MapPanel } from 'components/MapPanel';
import { FieldRenderProps } from 'react-final-form-hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    root: {
      maxWidth: '500px',
      backgroundColor: theme.palette.background.paper,
      border: '0px',
      borderRadius: '2px',
      boxShadow: theme.shadows[5],
    },
    text: {
      fontSize: 'medium',
    },
    divider: {
      marginTop: theme.spacing(2),
    },
    actions: {
      justifyContent: 'flex-end',
    },
  })
);

export type AppCoordFieldProps = FieldRenderProps;

export const AppCoordField = (props: AppCoordFieldProps) => {
  const styles = useStyles();
  const { input, meta, ...rest } = props;
  const error = !meta.valid && meta.touched;
  const helperText = !meta.valid && meta.touched ? meta.error : null;
  const coordinate = input.value
    .replace(' ', '')
    .split(',')
    .map((coord: string) => Number(coord));
  const [helpOpen, setHelpOpen] = useState(false);
  const onHelpOpen = useCallback(() => {
    setHelpOpen(true);
  }, [setHelpOpen]);
  const onHelpClose = useCallback(() => {
    setHelpOpen(false);
  }, [setHelpOpen]);
  const mapUrl = useMemo(() => {
    if (typeof input.value === 'string' && input.value !== '') {
      return `https://yandex.ru/maps/?z=15&l=map&size=650,250&pt=${input.value
        .split(',')
        .reverse()
        .join(',')}`;
    }
    return 'https://yandex.ru/maps';
  }, [input.value]);

  return (
    <>
      <TextField
        {...rest}
        error={error}
        helperText={helperText}
        fullWidth={true}
        name={input.name}
        variant="outlined"
        inputProps={{ ...input }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <IconButton aria-describedby="help" aria-label="Подсказка" onClick={onHelpOpen}>
                <HelpIcon id="help" />
              </IconButton>
              <Link href={mapUrl} target="blank">
                Открыть карту
              </Link>
            </InputAdornment>
          ),
        }}
      />
      <Modal
        className={styles.modal}
        open={helpOpen}
        onClose={onHelpClose}
        aria-labelledby="Подсказка по координатам"
        aria-describedby="Используйте эту подсказку для задания координат подопечного"
      >
        <Card className={styles.root}>
          <CardHeader
            title="Координаты в приложении"
            titleTypographyProps={{
              variant: 'h3',
            }}
          />
          <CardContent>
            <Grid container spacing={4}>
              <Grid item lg={12} md={12} xl={12} xs={12}>
                <Typography variant="caption" className={styles.text}>
                  Для обеспечения безопасности мы отображаем в приложении другой адрес
                  местопроживания подопечного. Для этого в указанное поле необходимо добавить
                  координаты (напр. 55.686332, 37.539038), которые вы сможете найти на Яндекс.Карте,
                  поставив точку в любое место на карте в пределах района, где на самом деле живет
                  подопечный.
                </Typography>
              </Grid>
            </Grid>
            <Divider className={styles.divider} />
          </CardContent>
          <CardActions className={styles.actions}>
            <Button
              onClick={onHelpClose}
              size="medium"
              type="button"
              variant="contained"
              color="primary"
            >
              Закрыть
            </Button>
          </CardActions>
        </Card>
      </Modal>
      <MapPanel latitude={coordinate[0]} longitude={coordinate[1]} height={350} />
    </>
  );
};
