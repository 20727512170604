import React from 'react';
import { Button, Card, CardContent } from '@material-ui/core';
import { BenefactorsGrid } from './BenefactorsGrid';
import { Link } from 'react-router-dom';
import { LayoutPageGrid } from 'ui/LayoutGrid/LayoutGrid';
import { usePermissionPage } from 'hooks';
import { useMeContext } from 'components';

export const BenefactorsPage = () => {
  const { user } = useMeContext();

  usePermissionPage('/wards', user?.permissions.benefactors.list);

  return (
    <LayoutPageGrid
      title="Пользователи"
      tools={
        user?.permissions.benefactors.create ? (
          <Button color="primary" variant="contained" component={Link} to="/benefactors/create">
            Добавить пользователя
          </Button>
        ) : (
          <></>
        )
      }
    >
      <Card>
        <CardContent>
          <BenefactorsGrid />
        </CardContent>
      </Card>
    </LayoutPageGrid>
  );
};
