import React, { useCallback } from 'react';
import {
  SpecialProjectByIdDocument,
  SpecialProjectsDocument,
  usePublishSpecialProjectMutation,
} from 'schema/serverTypes';
import { MutableButton } from 'ui/MutableButton';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const PublishWardButton = () => {
  const { id } = useParams();
  const [publish, { loading, error }] = usePublishSpecialProjectMutation();

  const onClick = useCallback(async () => {
    try {
      return await publish({
        variables: { input: { id } },
        refetchQueries: [
          { query: SpecialProjectByIdDocument, variables: { id } },
          {
            query: SpecialProjectsDocument,
            variables: { first: 50, includeClosedOrCancelled: false },
          },
        ],
      });
    } catch {
      return error && toast.error('Ошибка публикации спецпроекта');
    }
  }, [publish, id, error]);

  return (
    <MutableButton color="primary" variant="outlined" loading={loading} onClick={onClick}>
      Опубликовать
    </MutableButton>
  );
};
