import React, { useMemo } from 'react';
import { ColumnConfig, DataConnection, useQueryVariables } from 'hooks';
import {
  BenefactorApplicationsQueryVariables,
  FoundationEventApplication,
  FoundationEventApplicationFilterInput,
  FoundationEventApplicationSortInput,
  useBenefactorApplicationsQuery,
} from 'schema';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import { DataGrid, DataGridProps } from 'components';
import { formatDate } from 'helpers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nameContainer: {
      display: 'flex',
      alignItems: 'center',
    },
  })
);

interface BenefactorApplicationsProps {
  benefactorId: string;
}

const getDataKey = (application: FoundationEventApplication) => application.id;

export const BenefactorApplications = (props: BenefactorApplicationsProps) => {
  const { benefactorId } = props;
  const styles = useStyles();

  const config = useMemo(() => {
    const columns: ColumnConfig<
      FoundationEventApplication,
      FoundationEventApplicationSortInput
    >[] = [
      {
        id: 'title',
        header: 'Событие',
        render: (application) => {
          return (
            <div className={styles.nameContainer}>
              <Typography variant="body1">{application.event.details.title}</Typography>
            </div>
          );
        },
      },
      {
        id: 'name',
        header: 'Фонд',
        render: (application) => {
          return (
            <div className={styles.nameContainer}>
              <Typography variant="body1">{application.event.foundation?.name}</Typography>
            </div>
          );
        },
      },
      {
        id: 'createdAt',
        header: 'Дата подачи заявки',
        render: (application) => {
          return (
            <div className={styles.nameContainer}>
              <Typography variant="body1">{formatDate(application.created.timestamp)}</Typography>
            </div>
          );
        },
      },
    ];
    return {
      columns,
    };
  }, [styles]);

  const {
    onSort,
    onSearch,
    onPage,
    variables,
    extractPagedData,
    columns,
    sortDirection,
    sortBy,
  } = useQueryVariables<FoundationEventApplication>(config);

  const createVariables: BenefactorApplicationsQueryVariables = {
    ...variables,
    id: benefactorId,
  };

  const { data, loading, error } = useBenefactorApplicationsQuery({
    variables: createVariables,
  });

  if (error) {
    toast.error('Ошибка загрузки заявок');
    return null;
  }

  const entities = data?.benefactorById.foundationEventApplications as DataConnection<
    FoundationEventApplication
  >;
  const { data: application, count, startCursor, endCursor, page, rowsPerPage } = extractPagedData(
    entities
  );

  const gridProps: DataGridProps<
    FoundationEventApplication,
    FoundationEventApplicationFilterInput
  > = {
    data: application,
    onSort,
    onPage,
    onSearch,
    columns,
    getDataKey,
    count,
    startCursor: startCursor,
    endCursor: endCursor,
    page,
    rowsPerPage,
    sortDirection,
    sortBy,
    loading,
  };

  return (
    <DataGrid<FoundationEventApplication, FoundationEventApplicationFilterInput> {...gridProps} />
  );
};
