import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { CitiesDocument, useDeleteCityMutation } from 'schema/serverTypes';
import { toast } from 'react-toastify';

export const useCityActions = (id: string, close: () => void) => {
  const history = useHistory();

  const onEdit = useCallback(() => {
    close();
    history.push(`/cities/edit/${id}`);
  }, [id, history, close]);

  const [
    deleteCity,
    { data: deleteData, loading: deleteLoading, error: deleteError },
  ] = useDeleteCityMutation({
    variables: { input: { id } },
    refetchQueries: [
      {
        query: CitiesDocument,
      },
    ],
  });

  const onDelete = useCallback(async () => {
    await deleteCity({ variables: { input: { id } } });
    close();
  }, [deleteCity, id, close]);

  useEffect(() => {
    if (deleteError || deleteData?.deleteCity.errors) {
      deleteError && toast.error('Ошибка удаления города');
    }
  }, [deleteError, deleteData]);

  const loading = deleteLoading;
  const error = deleteError;
  const success = deleteData?.deleteCity.success;

  return {
    onEdit,
    onDelete,
    deleteData,
    loading,
    error,
    success,
  };
};
