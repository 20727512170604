import React, { useCallback, useMemo } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import {
  useEnableOrDisableUserMutation,
  BenefactorByIdDocument,
  BenefactorExtendedPropsFragment,
} from 'schema';
import { toast } from 'react-toastify';
import { MutableButton } from 'ui/MutableButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    unblockButton: {
      marginLeft: '10px',
      background: theme.palette.success.dark,
      color: '#fff',
      '&:hover': {
        background: theme.palette.success.main,
      },
    },
    blockButton: {
      marginLeft: '10px',
      background: theme.palette.warning.dark,
      color: '#fff',
      '&:hover': {
        background: theme.palette.warning.light,
      },
    },
  })
);

interface BenefactorBlockedControlProps {
  benefactor: BenefactorExtendedPropsFragment;
}

export const BenefactorBlockedControl = (props: BenefactorBlockedControlProps) => {
  const classes = useStyles();

  const { benefactor } = props;

  const { id } = benefactor;
  const disabled = benefactor.user?.disabled;

  const refetchQueries = useMemo(() => {
    return [
      {
        query: BenefactorByIdDocument,
        variables: { id },
      },
    ];
  }, [id]);

  const [enableOrDisableUser, { error, loading }] = useEnableOrDisableUserMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
  const onEnableOrDisable = useCallback(() => {
    enableOrDisableUser({
      variables: {
        input: {
          id,
          disabled: !disabled,
        },
      },
    });
  }, [id, disabled, enableOrDisableUser]);

  if (error) {
    toast.error('Произшла ошибка');
  }

  if (typeof disabled !== 'boolean') {
    return null;
  }

  const className = disabled ? classes.unblockButton : classes.blockButton,
    text = disabled ? 'Разблокировать' : 'Заблокировать';

  return (
    <MutableButton className={className} loading={loading} onClick={onEnableOrDisable}>
      {text}
    </MutableButton>
  );
};
