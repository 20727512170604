import React, { useMemo } from 'react';
import { AutoFocusedForm, positiveNumber, required, SelectField, TextField } from 'components';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  createStyles,
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
  MenuItem,
  Theme,
} from '@material-ui/core';
import { MutableButton } from 'ui/MutableButton';
import { RedButton } from 'components/Buttons';
import { useAddOrEditRequirementModalForm } from './useAddOrEditRequirementModalForm';
import { Field } from 'react-final-form';
import { Loading } from 'ui/Loading';
import { useEventPermissionsByIdQuery, useFoundationPermissionsByIdQuery } from 'schema';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    header: {
      fontWeight: 'bolder',
    },
    divider: {},
    content: {
      margin: '10px',
    },
    actions: {
      justifyContent: 'flex-end',
    },
    accessButton: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.success.dark,
      '&:hover': {
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.success.light,
      },
    },
  })
);

interface AddOrEditRequirementModalFormProps {
  onClose: () => void;
}

export const AddOrEditRequirementModalForm = ({ onClose }: AddOrEditRequirementModalFormProps) => {
  const styles = useStyles();
  const { eventId, id } = useParams<{ eventId: string; id: string }>();

  const { data: eventPermissions } = useEventPermissionsByIdQuery({
    variables: {
      id: eventId,
    },
  });

  const { data: foundationPermissions } = useFoundationPermissionsByIdQuery({
    variables: {
      id,
    },
  });

  const isPermissions: boolean = useMemo(() => {
    if (!eventId) {
      return foundationPermissions?.me.permissions.foundation.createEvents ?? false;
    }

    return eventPermissions?.me.permissions.foundationEvent.edit ?? false;
  }, [foundationPermissions, eventPermissions, eventId]);

  const {
    onSubmit,
    initialValues,
    loading,
    getItems,
    editSkill,
  } = useAddOrEditRequirementModalForm(onClose);

  if (loading) {
    return <Loading />;
  }

  return (
    <AutoFocusedForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, pristine, values }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card className={styles.root}>
              <CardHeader title={`${editSkill ? 'Редактирование' : 'Создание'} требования`} />
              <Divider className={styles.divider} />
              <CardContent className={styles.content}>
                <Grid container spacing={4}>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Field
                      disabled={!isPermissions}
                      component={SelectField}
                      name="id"
                      label="Категория навыка"
                      validate={required}
                    >
                      {getItems().map((item) => {
                        return (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            disabled={'isGroup' in item && item.isGroup}
                          >
                            {item.title}
                          </MenuItem>
                        );
                      })}
                    </Field>
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item lg={6} md={6} xl={6} xs={12}>
                    <Field
                      disabled={values.unlimited}
                      name="quantity"
                      label="Количество участников"
                      component={TextField}
                      type="number"
                      validate={positiveNumber}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} xl={6} xs={12}>
                    <FormControlLabel
                      label="Без ограничений"
                      labelPlacement="end"
                      control={
                        <Field
                          name="unlimited"
                          type="checkbox"
                          render={({ input }) => {
                            const { type, ...restInput } = input;
                            return <Checkbox {...restInput} />;
                          }}
                        />
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <FormControlLabel
                      label="Автоматически выбирать заявки для участия"
                      labelPlacement="end"
                      control={
                        <Field
                          name="autoSelect"
                          type="checkbox"
                          render={({ input }) => {
                            const { type, ...restInput } = input;
                            return <Checkbox {...restInput} />;
                          }}
                        />
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Field
                      name="description"
                      label="Коментарии"
                      component={TextField}
                      multiline
                      rows={5}
                      rowsMax={50}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions className={styles.actions}>
                <MutableButton
                  className={styles.accessButton}
                  loading={loading}
                  color="primary"
                  disabled={pristine}
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  Сохранить
                </MutableButton>
                <RedButton
                  onClick={onClose}
                  disabled={loading}
                  size="medium"
                  type="button"
                  variant="contained"
                >
                  Отменить
                </RedButton>
              </CardActions>
            </Card>
          </form>
        );
      }}
    />
  );
};
