import React from 'react';
import { Route, RouteProps, RouteComponentProps } from 'react-router-dom';

export interface LayoutProps {
  path?: string | string[];
}

export interface RouteWithLayoutProps extends RouteProps {
  layout: React.ComponentType<LayoutProps>;
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  [K: string]: any;
}

export const RouteWithLayout = (props: RouteWithLayoutProps) => {
  const { layout: Layout, component: Component, ...rest } = props;

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout path={rest.path}>
          <Component {...rest} {...matchProps} />
        </Layout>
      )}
    />
  );
};
