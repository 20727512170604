import React from 'react';
import { Backdrop, Fade, Modal } from '@material-ui/core';
import { useBackRoute } from 'hooks';
import { AwardEditModalForm } from './AwardEditModalForm';
import { AchievementByIdQuery } from '../../../../schema';
import { useStyles } from '../useStyles';

type AwardEditModalProps = {
  achievement: AchievementByIdQuery | undefined;
};

export const AwardEditModal = ({ achievement }: AwardEditModalProps) => {
  const styles = useStyles();

  const { onCancel } = useBackRoute();

  return (
    <Modal
      open={true}
      onClose={onCancel}
      aria-labelledby="Редактирование награды"
      aria-describedby="Используйте эту форму для редактирования награды"
      className={styles.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={true}>
        <AwardEditModalForm achievement={achievement} onClose={onCancel} />
      </Fade>
    </Modal>
  );
};
