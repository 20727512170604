import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import { createStyles, Theme, Typography } from '@material-ui/core';
import { ColumnConfig, DataConnection, useQueryVariables } from 'hooks';
import { FoundationEvent, FoundationEventFilterInput, useEventsQuery } from 'schema';
import { Link } from 'react-router-dom';
import { formatDate } from 'helpers';
import { DataGrid, DataGridProps } from 'components';
import { EventSearch } from './EventSearch';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nameContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    cancelled: {
      textDecoration: 'line-through',
    },
  })
);

interface EventsGridProps {
  url: string;
  foundationId: string;
}

const getDataKey = (event: FoundationEvent) => event.id;

export const EventsGrid = ({ url, foundationId }: EventsGridProps) => {
  const classes = useStyles();

  const config = useMemo(() => {
    const columns: ColumnConfig<FoundationEvent>[] = [
      {
        id: 'fullName',
        header: 'Название события',
        render: (event) => {
          const className = event.cancelled
            ? clsx(classes.nameContainer, classes.cancelled)
            : classes.nameContainer;

          return (
            <div className={className}>
              <Link to={`${url}/event/${event.id}`}>
                <Typography variant="body1">{event.details.title}</Typography>
              </Link>
            </div>
          );
        },
      },
      {
        id: 'City',
        header: 'Город',
        render: (event) => {
          return (
            <div className={classes.nameContainer}>
              <Typography variant="body1">{event.details.city}</Typography>
            </div>
          );
        },
      },
      {
        id: 'start',
        header: 'Начало',
        render: (event) => {
          return moment(event.details.start).utc(false).format('DD.MM.YYYY, HH:mm');
        },
      },
      {
        id: 'approvedBy',
        header: 'Утверждено',
        render: (event) => {
          return (
            <div className={classes.nameContainer}>
              <Typography variant="body1">{event.approved?.user?.displayName}</Typography>
              {event.approved && (
                <Typography variant="body1">{formatDate(event.approved.timestamp)}</Typography>
              )}
            </div>
          );
        },
      },
      {
        id: 'closedAt',
        header: 'Дата закрытия',
        render: (event) => {
          return event.closed && formatDate(event.closed.timestamp);
        },
      },
    ];
    return {
      columns,
    };
  }, [classes.nameContainer, classes.cancelled, url]);

  const {
    onSort,
    onSearch,
    onPage,
    variables,
    extractPagedData,
    columns,
    sortDirection,
    sortBy,
  } = useQueryVariables<FoundationEvent>(config);

  const { data, loading, error } = useEventsQuery({
    variables: {
      ...variables,
      id: foundationId,
    },
  });

  const entities = data?.foundationById?.events as DataConnection<FoundationEvent>;
  const { data: events, count, startCursor, endCursor, page, rowsPerPage } = extractPagedData(
    entities
  );

  if (error) {
    toast.error('Ошибка загрузки событий фонда');
    return null;
  }

  const gridProps: DataGridProps<FoundationEvent, FoundationEventFilterInput> = {
    data: events,
    onSort,
    onPage,
    onSearch,
    columns,
    getDataKey,
    count,
    startCursor: startCursor,
    endCursor: endCursor,
    page,
    searchComponent: EventSearch,
    rowsPerPage,
    sortDirection,
    sortBy,
    loading,
  };

  return <DataGrid<FoundationEvent, FoundationEventFilterInput> {...gridProps} />;
};
