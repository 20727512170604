import { parsePhoneNumber } from 'libphonenumber-js/max';

export const phoneNumber = (value: any) => {
  if (!value) return null;

  if (typeof value === 'string') {
    try {
      const phoneNumber = parsePhoneNumber('+' + value);
      if (!phoneNumber.isValid()) {
        return 'Неправильный номер телефона';
      }
    } catch (error) {
      return 'Неправильный номер телефона';
    }
  }
};
