import { useCallback, useEffect } from 'react';
import {
  UniversalNeed,
  isRegularNeed,
  isOneTimeNeed,
  useUpdateOneTimeNeedMutation,
  Ward,
  WardByIdDocument,
} from 'schema';
import { required as orininalRequired, useMeContext } from 'components';
import { useBackRoute } from 'hooks';
import { getOneTimeNeed, NeedFormValues } from '../types';

export const useEditNeedForm = (ward: Ward, need: UniversalNeed) => {
  const tabIndex = isRegularNeed(need) ? 0 : 1;
  const { onCancel } = useBackRoute();
  const { user } = useMeContext();

  const [updateNeed, { data, loading, error }] = useUpdateOneTimeNeedMutation();

  useEffect(() => {
    if (data !== undefined || error !== undefined) {
      onCancel();
    }
  }, [data, error, onCancel]);

  const onSubmit = useCallback(
    (values: NeedFormValues) => {
      const oneTimeNeedInput = getOneTimeNeed(values.oneTime);
      if (tabIndex === 1 && oneTimeNeedInput !== undefined) {
        const { title, description, icon, wardId } = oneTimeNeedInput;

        updateNeed({
          variables: {
            input: {
              needId: need.id,
              description,
              icon,
              title,
            },
          },
          refetchQueries: [
            {
              query: WardByIdDocument,
              variables: {
                id: wardId,
                permission: user?.permissions?.wards.manageNeeds,
              },
            },
          ],
        });
      } else if (values.regularNeed !== '') {
        onCancel();
      }
    },
    [tabIndex, updateNeed, need.id, user, onCancel]
  );

  const required = useCallback(
    (value: any) => {
      if (tabIndex === 0) {
        return orininalRequired(value);
      }
      return undefined;
    },
    [tabIndex]
  );

  const initialValues: NeedFormValues = isOneTimeNeed(need)
    ? {
        oneTime: {
          wardId: ward.id,
          amount: need.requiredAmount.toString(),
          title: need.title,
          description: need.description,
          targetDate: need.targetDate,
          icon: need.icon,
          url: need.url,
        },
        regularNeed: '',
      }
    : {
        oneTime: {
          wardId: ward.id,
          amount: '',
          title: '',
          description: '',
          targetDate: '',
        },
        regularNeed: '0',
      };

  return {
    onSubmit,
    onCancel,
    required,
    initialValues,
    loading,
    error,
  };
};
