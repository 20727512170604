import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, createStyles, makeStyles, Theme } from '@material-ui/core';
import { BenefactorExtendedPropsFragment, DonationsPaymentQuery } from 'schema';
import { formatDate } from '../../../../helpers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      marginBottom: theme.spacing(3),
    },
    link: {
      color: theme.palette.primary.dark,
    },
  })
);
type Props = {
  data: DonationsPaymentQuery;
  benefactor: BenefactorExtendedPropsFragment;
};

export const BreadcrumbsTransaction = ({ data, benefactor }: Props) => {
  const classes = useStyles();
  const benefactorUrl = `/benefactors/manage/${benefactor.id}`;

  return (
    <Box className={classes.content}>
      <Typography>
        <Link className={classes.link} to={benefactorUrl}>
          Пользователь {benefactor.displayName}
        </Link>{' '}
        / Заказ от {formatDate(data?.orderById!.created.timestamp)}
      </Typography>
    </Box>
  );
};
