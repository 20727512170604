import React from 'react';
import { usePermissionPage } from 'hooks';
import { LayoutPageGrid } from 'ui/LayoutGrid';
import { AddOrEditEventForm } from './AddOrEditEventForm/AddOrEditEventForm';
import { FoundationEvent } from 'schema';

interface AddEventPageTypes {
  createEvents?: boolean;
  edit?: boolean;
  foundationId: number;
  event?: FoundationEvent;
}

export const AddOrEditEventPage = (props: AddEventPageTypes) => {
  const { createEvents, foundationId, event, edit } = props;

  usePermissionPage('/foundations', createEvents || edit);
  return (
    <LayoutPageGrid title={`${event ? 'Редактирование' : 'Создание'} события`}>
      <div>
        <AddOrEditEventForm foundationId={foundationId} event={event} />
      </div>
    </LayoutPageGrid>
  );
};
