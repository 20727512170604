import React, { useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router";

const ScrollToTop = ({ children, location: { pathname } }: RouteComponentProps & { children?: React.ReactNode }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (children !== undefined) {
    return <>{children}</>;
  }

  return null;
};

export const ScrollTop = withRouter(ScrollToTop);
