import React from 'react';
import { CircularProgress, Typography } from '@material-ui/core';
import { DataGrid } from 'components';
import { Donation, QueryAccountArgs } from 'schema/serverTypes';
import { useDonations } from './useDonations';

export const AccountDonations = ({ id }: QueryAccountArgs) => {
  const { donations, gridProps, loading } = useDonations({ id });

  if (loading) {
    return <CircularProgress />;
  }

  if (!donations.length) {
    return <Typography>Нет пожертвований</Typography>;
  }

  return <DataGrid<Donation> {...gridProps} />;
};
