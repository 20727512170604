import React from 'react';

interface AmountLabelProps {
  value: number;
  showCurrency?: boolean;
  className?: string;
  commissionAmount?: number | null;
}

export const AmountLabel = (props: AmountLabelProps) => {
  const { value, showCurrency, className, commissionAmount } = props;
  const options = showCurrency ? { style: 'currency', currency: 'RUB' } : undefined;
  const amount = new Intl.NumberFormat('ru-RU', options).format(value);
  return (
    <span className={className}>
      {commissionAmount ? `${amount}(${commissionAmount})` : amount}
    </span>
  );
};
