import { useCallback, useEffect, useMemo } from 'react';
import { CompanyReportsDocument, useUpdateCompanyBalanceMutation } from 'schema/serverTypes';
import { useBackRoute } from 'hooks';

const createInitialValues = (): { balance: string } => ({
  balance: '',
});

export const useUpdateCompanyBalanceForm = () => {
  const { onCancel } = useBackRoute();

  const refetchQueries = useMemo(() => {
    return [
      {
        query: CompanyReportsDocument,
      },
    ];
  }, []);

  const [updateCompanyBalance, { data, loading, error }] = useUpdateCompanyBalanceMutation();

  useEffect(() => {
    if (data !== undefined || error !== undefined) {
      onCancel();
    }
  }, [data, error, onCancel]);

  const onSubmit = useCallback(
    (values) => {
      const input = {
        balance: parseFloat(values.balance),
      };
      updateCompanyBalance({
        refetchQueries,
        variables: { input },
        awaitRefetchQueries: true,
      });
    },
    [updateCompanyBalance, refetchQueries]
  );

  return {
    initialValues: createInitialValues(),
    onSubmit,
    onCancel,
    data,
    loading,
    error,
  };
};
