import React from 'react';
import { Project, ProjectQuery } from 'schema';
import { Loading } from 'ui/Loading';
import { PropertiesTable, PropertiesTableRow } from 'ui/PropertiesTable';

type Props = {
  data: ProjectQuery | undefined;
  loading: boolean;
};

export const ProjectRequirements = ({ data, loading }: Props) => {
  if (loading) {
    return <Loading />;
  }

  const { requirements } = data?.project as Project;

  return (
    <>
      <PropertiesTable>
        <PropertiesTableRow
          caption="Денег в фонде хватит на"
          value={requirements.remainingDuration}
        />
        <PropertiesTableRow
          caption="Планируемые расходы проекта"
          value={requirements.estimatedMonthlyExpensesAmount}
          isAmount={true}
        />
      </PropertiesTable>
    </>
  );
};
