import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Theme, createStyles } from '@material-ui/core';
import { DataGrid } from 'components';
import { useQueryVariables, ColumnConfig, DataConnection } from 'hooks';
import {
  FoundationFilterInput,
  FoundationSortInput,
  Foundation,
  useFoundationsQuery,
  SortEnumType,
} from 'schema/serverTypes';
import { DataGridProps } from 'components/DataGrid/types';
import { toast } from 'react-toastify';
import { formatDate } from 'helpers';
import { Link } from 'react-router-dom';
import { FoundationsSearch } from './FoundationsSearch';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nameContainer: {
      display: 'flex',
      alignItems: 'center',
    },
  })
);

const getDataKey = (foundation: Foundation) => foundation.id;

export const FoundationsGrid = () => {
  const classes = useStyles();

  const config = useMemo(() => {
    const columns: ColumnConfig<Foundation, FoundationSortInput>[] = [
      {
        id: 'fullName',
        header: 'Название фонда',
        orderBy: (kind) => ({
          name: kind,
        }),
        render: (foundation) => {
          return (
            <div className={classes.nameContainer}>
              <Link to={`/foundations/manage/${foundation.id}`}>
                <Typography variant="body1">{foundation.name}</Typography>
              </Link>
            </div>
          );
        },
      },
      {
        id: 'totalEventsCount',
        header: 'Всего событий',
        orderBy: (count) => ({
          eventsCounters: { total: count },
        }),
        render: (foundations) => {
          return (
            <div className={classes.nameContainer}>
              <Typography variant="body1">{foundations.eventsCounters.total}</Typography>
            </div>
          );
        },
      },
      {
        id: 'activeEventsCount',
        header: 'Активных событий',
        orderBy: (count) => ({
          eventsCounters: { active: count },
        }),
        render: (foundations) => {
          return (
            <div className={classes.nameContainer}>
              <Typography variant="body1">{foundations.eventsCounters.active}</Typography>
            </div>
          );
        },
      },
      {
        id: 'createdAt',
        header: 'Дата создания',
        orderBy: (date) => ({
          created: { timestamp: date },
        }),
        render: (foundation) => {
          return formatDate(foundation.created.timestamp);
        },
      },
    ];
    return {
      columns,
    };
  }, [classes.nameContainer]);

  const defaultOrderBy: FoundationSortInput = { created: { timestamp: SortEnumType.Desc } };

  const {
    onSort,
    onSearch,
    onPage,
    variables,
    extractPagedData,
    columns,
    sortDirection,
    sortBy,
  } = useQueryVariables<Foundation, FoundationSortInput>(config, defaultOrderBy);

  const { data, loading, error } = useFoundationsQuery({
    variables,
    fetchPolicy: 'cache-and-network',
  });

  if (error) {
    toast.error('Ошибка загрузки фондов');
    return null;
  }

  const entities = data?.foundations as DataConnection<Foundation>;
  const { data: foundations, count, startCursor, endCursor, page, rowsPerPage } = extractPagedData(
    entities
  );

  const gridProps: DataGridProps<Foundation, FoundationFilterInput> = {
    data: foundations,
    onSort,
    onPage,
    onSearch,
    columns,
    getDataKey,
    count,
    startCursor: startCursor,
    endCursor: endCursor,
    page,
    searchComponent: FoundationsSearch,
    rowsPerPage,
    sortDirection,
    sortBy,
    loading,
  };

  return <DataGrid<Foundation, FoundationFilterInput> {...gridProps} />;
};
