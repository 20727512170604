import React from 'react';
import { LayoutPageGrid } from 'ui/LayoutGrid';
import { ViewFoundationProps } from './types';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import { InvisibleCard } from 'ui/InvisibleCard';
import { useStyles } from './useStyles';
import { Link } from 'react-router-dom';
import { CreateFoundationRepresentativePage } from '../CreateFoundationRepresentativePage';
import { FoundationActionsButton } from './FoundationActionsButton';
import { EditFoundationRepresentativePage } from '../EditFoundationRepresentativePage';
import { RouteWithoutLayout, useMeContext } from 'components';
import { FoundationEvents } from '../components';
import { AuditTable } from '../../../components/AuditTable/AuditTable';
import { usePermissionPage } from 'hooks';

export const ViewFoundationPage = ({ foundation, permissions }: ViewFoundationProps) => {
  const classes = useStyles();
  const { id, name, created, updated, description, logo, representatives } = foundation;
  const { edit, addRepresentative, createEvents, viewRepresentatives } = permissions;
  const { user } = useMeContext();

  usePermissionPage('/foundations', edit);

  const baseUrl = `/foundations/manage/${id}`;
  const editUrl = `${baseUrl}/edit`;
  const editUserUrl = `${baseUrl}/edit-user`;
  const createUserUrl = `${baseUrl}/create-user`;

  return (
    <>
      <LayoutPageGrid title={name}>
        <Card>
          <CardContent>
            <InvisibleCard>
              <CardContent className={classes.content}>
                <Typography
                  component="div"
                  variant="h5"
                  align="center"
                  className={classes.headerLabel}
                >
                  Информация о фонде
                </Typography>
                <Avatar alt={name} src={logo?.url} className={classes.avatar} />
                <Typography component="div" align="left">
                  {description}
                </Typography>
              </CardContent>
              {edit ? (
                <CardActions className={classes.buttons}>
                  <Button color="primary" variant="contained" component={Link} to={editUrl}>
                    Редактировать
                  </Button>
                </CardActions>
              ) : (
                <></>
              )}
              <Divider className={classes.divider} />
              {viewRepresentatives ? (
                <CardContent className={classes.content}>
                  <>
                    <AuditTable
                      items={[
                        { caption: 'Добавил', marker: created },
                        { caption: 'Обновил', marker: updated },
                      ]}
                    />
                    <Divider className={classes.divider} />
                  </>
                  <Typography
                    component="div"
                    variant="h5"
                    align="center"
                    className={classes.headerLabel}
                  >
                    Представители фонда
                  </Typography>
                  {representatives?.nodes?.map((representative) => {
                    if (!representative) {
                      return null;
                    }
                    return (
                      <Grid container spacing={4} alignItems="center" key={representative.id}>
                        <Grid item lg={6} md={6} xl={6} xs={7}>
                          <Typography>{representative.displayName || ''}</Typography>
                        </Grid>
                        <Grid item lg={4} md={4} xl={4} xs={4}>
                          <Typography
                            className={!representative.disabled ? classes.active : classes.noActive}
                          >
                            {!representative.disabled ? 'Активирован' : 'Заблокирован'}
                          </Typography>
                        </Grid>
                        {user?.permissions.users.createAndEditFoundationRepresentative ? (
                          <Grid item lg={2} md={2} xl={2} xs={1}>
                            <Typography>
                              <FoundationActionsButton
                                userId={representative?.id}
                                disabled={representative?.disabled}
                                foundationId={id}
                              />
                            </Typography>
                          </Grid>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    );
                  })}
                  {addRepresentative ? (
                    <CardActions className={classes.buttons}>
                      <Button
                        color="primary"
                        variant="contained"
                        component={Link}
                        to={createUserUrl}
                      >
                        Добавить представителя
                      </Button>
                    </CardActions>
                  ) : (
                    <></>
                  )}
                </CardContent>
              ) : (
                <></>
              )}
            </InvisibleCard>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <FoundationEvents url={baseUrl} permission={createEvents} foundationId={id} />
          </CardContent>
        </Card>
      </LayoutPageGrid>
      <RouteWithoutLayout
        exact
        path={createUserUrl}
        component={CreateFoundationRepresentativePage}
        componentProps={{ id }}
      />
      <RouteWithoutLayout
        exact
        path={`${editUserUrl}/:userId`}
        component={EditFoundationRepresentativePage}
        componentProps={{ id }}
      />
    </>
  );
};
