import React, { useMemo } from 'react';
import {
  BenefactorAttendancesQueryVariables,
  FoundationEventAttendance,
  FoundationEventAttendanceFilterInput,
  FoundationEventAttendanceSortInput,
  useBenefactorAttendancesQuery,
} from 'schema';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { ColumnConfig, DataConnection, useQueryVariables } from 'hooks';
import { Star } from '@material-ui/icons';
import { toast } from 'react-toastify';
import { DataGrid, DataGridProps } from 'components';
import { shortDescription } from 'helpers';
import { BenefactorFoundationCommentModal } from '../FoundationComment';
import { Link, Route, useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nameContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    starRate: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    star: {
      color: 'gold',
    },
  })
);

interface BenefactorAttendancesProps {
  benefactorId: string;
}

const getDataKey = (attendance: FoundationEventAttendance) => attendance.id;

export const BenefactorAttendances = (props: BenefactorAttendancesProps) => {
  const { benefactorId } = props;
  const styles = useStyles();
  const location = useLocation();

  const config = useMemo(() => {
    const columns: ColumnConfig<FoundationEventAttendance, FoundationEventAttendanceSortInput>[] = [
      {
        id: 'title',
        header: 'Событие',
        render: (attendance) => {
          return (
            <div className={styles.nameContainer}>
              <Typography variant="body1">{attendance.foundationEvent.details.title}</Typography>
            </div>
          );
        },
      },
      {
        id: 'name',
        header: 'Фонд',
        render: (attendance) => {
          return (
            <div className={styles.nameContainer}>
              <Typography variant="body1">{attendance.foundationEvent.foundation?.name}</Typography>
            </div>
          );
        },
      },
      {
        id: 'benefactorRating',
        header: 'Рейтинг',
        render: (attendance) => {
          return (
            <div className={styles.nameContainer}>
              <Typography variant="body1" className={styles.starRate}>
                <Star className={styles.star} />
                {attendance.foundationRating ? attendance.foundationRating : 0}
              </Typography>
            </div>
          );
        },
      },
      {
        id: 'comment',
        header: 'Отзыв',
        render: (attendance) => {
          return (
            <div className={styles.nameContainer}>
              {attendance.foundationComment && (
                <>
                  {attendance.foundationComment.length > 40 ? (
                    <Link to={`${location.pathname}/${attendance.id}/comment`}>
                      <Typography variant="body1">
                        {shortDescription(attendance.foundationComment, 40)}
                      </Typography>
                    </Link>
                  ) : (
                    <Typography variant="body1">
                      {shortDescription(attendance.foundationComment, 40)}
                    </Typography>
                  )}
                </>
              )}
            </div>
          );
        },
      },
    ];
    return {
      columns,
    };
  }, [styles, location]);

  const {
    onSort,
    onSearch,
    onPage,
    variables,
    extractPagedData,
    columns,
    sortDirection,
    sortBy,
  } = useQueryVariables<FoundationEventAttendance>(config);

  const createVariables: BenefactorAttendancesQueryVariables = {
    ...variables,
    id: benefactorId,
  };

  const { data, loading, error } = useBenefactorAttendancesQuery({
    variables: createVariables,
  });

  if (error) {
    toast.error('Ошибка загрузки');
    return null;
  }

  const entities = data?.benefactorById.foundationEventAttendance as DataConnection<
    FoundationEventAttendance
  >;
  const { data: attendance, count, startCursor, endCursor, page, rowsPerPage } = extractPagedData(
    entities
  );

  const gridProps: DataGridProps<
    FoundationEventAttendance,
    FoundationEventAttendanceFilterInput
  > = {
    data: attendance,
    onSort,
    onPage,
    onSearch,
    columns,
    getDataKey,
    count,
    startCursor: startCursor,
    endCursor: endCursor,
    page,
    rowsPerPage,
    sortDirection,
    sortBy,
    loading,
  };

  return (
    <>
      <DataGrid<FoundationEventAttendance, FoundationEventAttendanceFilterInput> {...gridProps} />
      {attendance.map(({ foundationComment, id }) => (
        <Route exact path={`/benefactors/manage/:id/${id}/comment`}>
          <BenefactorFoundationCommentModal foundationComment={foundationComment} />
        </Route>
      ))}
    </>
  );
};
