import React, { useMemo } from 'react';

import { makeStyles } from '@material-ui/styles';
import { Typography, Theme, Avatar, createStyles } from '@material-ui/core';

import { AmountLabel, DataGrid, useMeContext } from 'components';
import { BenefactorsSearch } from './BenefactorsSearch';
import { useQueryVariables, ColumnConfig, DataConnection } from 'hooks';

import {
  Benefactor,
  BenefactorFilter,
  BenefactorSort,
  useBenefactorsQuery,
  useBenefactorsWithoutPermissionsQuery,
} from 'schema/serverTypes';
import { DataGridProps } from 'components/DataGrid/types';
import { Link, Route } from 'react-router-dom';
import { getInitials } from 'helpers';
import { AddBenefactorPage } from 'pages/benefactors/AddBenefactorPage';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nameContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    avatar: {
      marginRight: theme.spacing(2),
    },
    name: {
      fontWeight: 700,
    },
    link: {
      display: 'block',
      position: 'absolute',
      cursor: 'pointer',
      zIndex: 3,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
  })
);

const getDataKey = (user: Benefactor) => user.id;

export const BenefactorsGrid = () => {
  const classes = useStyles();

  const { user } = useMeContext();

  const config = useMemo(() => {
    const columns: ColumnConfig<Benefactor, BenefactorSort>[] = [
      {
        id: 'fullName',
        header: 'Имя',
        orderBy: (kind) => ({
          displayName: kind,
        }),
        render: (user: Benefactor) => {
          return (
            <>
              <Link to={`/benefactors/manage/${user.id}`} className={classes.link} />
              <div className={classes.nameContainer}>
                <Avatar className={classes.avatar} src={user.photo?.url}>
                  {getInitials(user.displayName)}
                </Avatar>
                <Typography variant="body1" className={classes.name}>
                  {user.displayName}
                </Typography>
              </div>
            </>
          );
        },
      },
      {
        id: 'phoneNumber',
        header: 'Телефон',
        render: (user) => (
          <>
            <Link to={`/benefactors/edit/${user.id}`} className={classes.link} />
            {user.phoneNumber}
          </>
        ),
        orderBy: (kind) => ({
          phoneNumber: kind,
        }),
      },
      {
        id: 'wardsCount',
        header: 'Подопечные',
        render: (user) => user.wardsCount,
      },
      {
        id: 'failedPaymentsCount',
        header: 'Ошибки транзакций',
        render: (user) => user.failedPaymentsCount,
      },
      {
        id: 'paymentsAmount',
        header: 'Сумма взносов',
        render: (user) => user.paymentsAmount && <AmountLabel value={user.paymentsAmount} />,
      },
    ];
    return {
      columns,
      rowsPerPage: 50,
    };
  }, [classes.nameContainer, classes.avatar, classes.name, classes.link]);

  const {
    onSort,
    onSearch,
    onPage,
    variables,
    extractPagedData,
    columns,
    sortDirection,
    sortBy,
  } = useQueryVariables<Benefactor, BenefactorSort, BenefactorFilter>(config);

  const viewDepositAccountsPermission = user?.permissions.accounts.viewDepositAccounts ?? false;

  const useBenefactorQuery = (hasPermission: boolean) => {
    return hasPermission
      ? // eslint-disable-next-line react-hooks/rules-of-hooks
        useBenefactorsQuery({
          variables: {
            ...variables,
            permission: viewDepositAccountsPermission,
          },
        })
      : // eslint-disable-next-line react-hooks/rules-of-hooks
        useBenefactorsWithoutPermissionsQuery({
          variables,
        });
  };

  const { data, loading, error } = useBenefactorQuery(viewDepositAccountsPermission);

  if (error) {
    toast.error('Ошибка загрузки жертвователей');
    return null;
  }

  const entities = data?.benefactors as DataConnection<Benefactor>;
  const { data: wards, count, startCursor, endCursor, page, rowsPerPage } = extractPagedData(
    entities
  );

  const gridProps: DataGridProps<Benefactor, BenefactorFilter> = {
    data: wards,
    onSort,
    onPage,
    onSearch,
    columns,
    getDataKey,
    count,
    startCursor: startCursor,
    endCursor: endCursor,
    page,
    rowsPerPage,
    sortDirection,
    sortBy,
    searchComponent: BenefactorsSearch,
    loading,
  };

  return (
    <>
      <DataGrid<Benefactor, BenefactorFilter> {...gridProps} />
      <Route path="/benefactors/create" exact>
        <AddBenefactorPage variables={variables} />
      </Route>
    </>
  );
};
