import React, { useMemo } from 'react';

import { DataGrid } from 'components';
import { useQueryVariables, ColumnConfig, DataConnection } from 'hooks';

import { ClientVersion, useClientVersionsQuery } from 'schema/serverTypes';
import { DataGridProps } from 'components/DataGrid/types';
import { QueryContextProvider } from '../../QueryContextProvider';
import { VersionMandatoryLabel } from './VersionMandatoryLabel';
import { CreateVersionPage } from '../../CreateVersionPage';
import { Route } from 'react-router-dom';

const getDataKey = (version: ClientVersion) => version.id;

export const VersionsGrid = () => {
  const config = useMemo(() => {
    const columns: ColumnConfig<ClientVersion>[] = [
      {
        id: 'client',
        header: 'Клиент',
        render: (version: ClientVersion) => version.client,
      },
      {
        id: 'fullVersion',
        header: 'Версия',
        render: (version: ClientVersion) => `${version.major}.${version.minor}.${version.patch}`,
      },
      {
        id: 'mandatory',
        header: 'Обязательность',
        style: { width: '5%' },
        render: (version: ClientVersion) => <VersionMandatoryLabel mandatory={version.mandatory} />,
      },
    ];
    return {
      columns,
    };
  }, []);

  const {
    onSort,
    onSearch,
    onPage,
    variables,
    extractPagedData,
    columns,
    sortDirection,
    sortBy,
  } = useQueryVariables<ClientVersion>(config);

  const { data } = useClientVersionsQuery({ variables });

  const entities = data?.clientVersions as DataConnection<ClientVersion>;
  const { data: wards, count, startCursor, endCursor, page, rowsPerPage } = extractPagedData(
    entities
  );

  const gridProps: DataGridProps<ClientVersion> = {
    data: wards,
    onSort,
    onPage,
    onSearch,
    columns,
    getDataKey,
    count,
    startCursor: startCursor,
    endCursor: endCursor,
    page,
    rowsPerPage,
    sortDirection,
    sortBy,
  };

  return (
    <QueryContextProvider variables={variables}>
      <DataGrid<ClientVersion> {...gridProps} />
      <Route exact path="/versions/add" component={CreateVersionPage} />
    </QueryContextProvider>
  );
};
