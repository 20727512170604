import { useCallback, useEffect } from 'react';
import {
  CategoriesDocument,
  DeleteCategoryInput,
  useDeleteCategoryMutation,
} from 'schema/serverTypes';

import { FORM_ERROR, SubmissionErrors } from 'final-form';
import { toast } from 'react-toastify';
import { QueryVariables } from '../../../../../hooks';
import { useParams } from 'react-router-dom';

type UseCategoryProps = {
  onClose: () => void;
  variables?: QueryVariables<{}, {}>;
};
export const useDeleteCategoryForm = ({ onClose, variables }: UseCategoryProps) => {
  const { id } = useParams<{ id: string }>();
  const [deleteCategory, { error, data, loading }] = useDeleteCategoryMutation();

  const onSubmit = useCallback(async () => {
    const input: DeleteCategoryInput = {
      id,
    };
    try {
      return await deleteCategory({
        variables: { input },
        refetchQueries: [
          {
            query: CategoriesDocument,
            variables,
          },
        ],
      });
    } catch {
      error && toast.error('Ошибка при удалении категории');
      return { [FORM_ERROR]: 'Ошибка удаления категории' } as SubmissionErrors;
    }
  }, [id, variables, deleteCategory, error]);

  useEffect(() => {
    if (data?.deleteCategory.success === true) {
      onClose();
    } else if (data?.deleteCategory.success === false) {
      toast.error('Ошибка удаления категории');
    }
  }, [onClose, data]);

  return { onSubmit, loading };
};
