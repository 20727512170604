import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  Typography,
  Theme,
  createStyles,
  Badge,
  withStyles,
  Checkbox,
  Button,
  Box,
} from '@material-ui/core';
import { DataGrid } from 'components';
import { AddWardsSearch } from './AddWardsSearch';
import { useQueryVariables, ColumnConfig, DataConnection } from 'hooks';
import { getInitials } from 'helpers';
import {
  SortEnumType,
  useWardsQuery,
  WardFilterInput,
  WardsGridWardFragment,
  WardSortInput,
} from 'schema/serverTypes';
import { Link } from 'react-router-dom';
import { DataGridProps } from 'components/DataGrid/types';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nameContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    avatar: {
      marginRight: theme.spacing(2),
    },
    statusAlarm: {
      color: theme.palette.error.main,
    },
    statusSuccess: {
      color: theme.palette.success.main,
    },
    statusWarning: {
      color: theme.palette.warning.main,
    },
    dateOfDeath: {
      textAlign: 'center',
    },
    getAllButton: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      padding: theme.spacing(2),
    },
  })
);

const wrapTableCellWithLink = (ward: WardsGridWardFragment, cell: React.ReactNode) => (
  <Link to={`/wards/manage/${ward.id}`}>{cell}</Link>
);

const getDataKey = (ward: WardsGridWardFragment) => ward.id;

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.main,
    border: '2px solid white',
    right: 22,
    width: 14,
    height: 14,
    borderRadius: '50%',
  },
  colorSecondary: {
    backgroundColor: theme.palette.text.secondary,
    color: theme.palette.text.secondary,
  },
}))(Badge);

interface Props {
  handleAddWard: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selectedWards: string[];
  handleGetAll: (wards: string[]) => void;
}

export const AddWardsGrid = ({ handleAddWard, selectedWards, handleGetAll }: Props) => {
  const classes = useStyles();

  const config = useMemo(() => {
    const columns: ColumnConfig<WardsGridWardFragment, WardSortInput>[] = [
      {
        id: 'fullName',
        header: 'ФИО',
        style: { width: '60%' },
        orderBy: (kind) => ({
          publicInformation: {
            name: {
              fullName: kind,
            },
          },
        }),
        render: (user: WardsGridWardFragment) => {
          return (
            <div className={classes.nameContainer}>
              {wrapTableCellWithLink(
                user,
                <StyledBadge
                  overlap="circle"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  variant="dot"
                >
                  <Avatar className={classes.avatar} src={user.publicInformation.photo.avatar}>
                    {getInitials(user.publicInformation.name.fullName)}
                  </Avatar>
                </StyledBadge>
              )}
              {wrapTableCellWithLink(
                user,
                <Typography variant="body1">{user.publicInformation.name.fullName}</Typography>
              )}
            </div>
          );
        },
      },
      {
        id: 'address',
        header: 'Населенный пункт',
        style: { width: '25%' },
        orderBy: (kind) => ({
          publicInformation: {
            city: kind,
          },
        }),
        render: (user) => user.publicInformation.city,
      },
      {
        id: 'choose',
        header: 'Выбрать',
        style: { width: '15%' },
        render: (user) => (
          <Checkbox
            name={user.id}
            onChange={handleAddWard}
            checked={selectedWards.includes(user.id)}
          />
        ),
      },
    ];
    return {
      columns,
      rowsPerPage: 30,
    };
  }, [classes, selectedWards, handleAddWard]);

  const defaultOrderBy: WardSortInput = { id: SortEnumType.Desc };

  const {
    onSort,
    onSearch,
    onPage,
    variables,
    extractPagedData,
    columns,
    sortDirection,
    sortBy,
  } = useQueryVariables<WardsGridWardFragment, WardSortInput, WardFilterInput>(
    config,
    defaultOrderBy
  );

  const { data, loading, error } = useWardsQuery({ variables, fetchPolicy: 'network-only' });

  if (error) {
    toast.error('Ошибка загрузки подопечных');
    return null;
  }

  const entities = data?.wards as DataConnection<WardsGridWardFragment>;
  const { data: wards, count, startCursor, endCursor, page, rowsPerPage } = extractPagedData(
    entities
  );

  const gridProps: DataGridProps<WardsGridWardFragment, WardFilterInput> = {
    data: wards,
    onSort,
    onPage,
    onSearch,
    columns,
    getDataKey,
    count,
    startCursor: startCursor,
    endCursor: endCursor,
    page,
    searchComponent: AddWardsSearch,
    rowsPerPage,
    sortDirection,
    sortBy,
    loading,
  };

  return (
    <>
      <DataGrid<WardsGridWardFragment, WardFilterInput> {...gridProps} />
      <Box className={classes.getAllButton}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleGetAll(wards?.map((item) => item.id ?? []))}
        >
          Выбрать всех
        </Button>
      </Box>
    </>
  );
};
