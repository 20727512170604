import React, { useMemo } from 'react';
import { PropertiesTable, PropertiesTableRow } from 'ui/PropertiesTable';
import { BenefactorCardProps, getString } from '../BenefactorCard';

export const BenefactorData = (props: BenefactorCardProps) => {
  const { benefactor, paymentInformation, permissions } = props;

  const { phoneNumber, wardsCount, amountOfDonationsInWardsReserveAccount, coins } = benefactor;

  const failedPaymentsCount = paymentInformation?.failedPaymentsCount;
  const paymentsAmount = paymentInformation?.paymentsAmount;
  const depositAccounts = paymentInformation?.depositAccounts;

  const allDepositsValue = useMemo(() => {
    return depositAccounts?.reduce((counter, item) => counter + item.amount, 0);
  }, [depositAccounts]);

  const email = getString(benefactor.email);
  return (
    <PropertiesTable>
      {permissions.viewPaymentInformation && depositAccounts && (
        <PropertiesTableRow caption="Баланс счетов-депозитов" value={allDepositsValue} isAmount />
      )}
      {permissions.viewPaymentInformation &&
        failedPaymentsCount !== undefined &&
        failedPaymentsCount !== null &&
        failedPaymentsCount > 0 && (
          <PropertiesTableRow caption="Ошибки транзакций" value={failedPaymentsCount} />
        )}
      {permissions.viewContactInformation && phoneNumber && (
        <PropertiesTableRow caption="Телефон" value={phoneNumber} />
      )}
      {permissions.viewContactInformation && email && (
        <PropertiesTableRow caption="Email" value={email} />
      )}

      {permissions.viewVolunteerInformation && (
        <PropertiesTableRow caption="Подопечные" value={wardsCount} />
      )}
      {permissions.viewPaymentInformation && (
        <PropertiesTableRow caption="Сумма взносов" value={paymentsAmount} isAmount />
      )}
      {permissions.viewPaymentInformation && (
        <PropertiesTableRow
          caption="Средства в резерве подопечных"
          value={amountOfDonationsInWardsReserveAccount}
          isAmount
        />
      )}
      {permissions.viewPersonalData && (
        <PropertiesTableRow caption="Набранные монеты" value={coins} />
      )}
    </PropertiesTable>
  );
};
