import React from 'react';
import { Card, CardActions, CardContent, CardHeader } from '@material-ui/core';
import { InvisibleCard } from '../../../ui/InvisibleCard';
import { AccountActions, AccountDonations } from '../../account/components';
import { ProjectExpense, ProjectExpenseAccount, useProjectExpensesQuery } from '../../../schema';
import { LayoutPageGrid } from '../../../ui/LayoutGrid';
import { useParams } from 'react-router-dom';
import { Loading } from '../../../ui/Loading';
import { toast } from 'react-toastify';
import { ProjectExpenseInformation } from './components/ProjectExpenseInformation';
import { ProjectExpenseDocuments } from './components/ProjectExpenseDocuments';

const ProjectExpensePage = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { data, loading, error } = useProjectExpensesQuery();

  const projectExpense = data?.project.expenses?.nodes?.find(
    ({ id }) => id === projectId
  ) as ProjectExpense;

  if (error) {
    toast.error('Не удалось загрузить проект');
    return null;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <LayoutPageGrid title={`Счёт расходов проекта: ${projectExpense?.title}`}>
      <Card>
        <CardHeader title={`Информация о проекте`} />
        <CardContent>
          <InvisibleCard>
            <CardContent>
              <ProjectExpenseInformation projectExpense={projectExpense} />
            </CardContent>
            <CardActions>
              <AccountActions
                account={projectExpense?.account as ProjectExpenseAccount}
                project={projectExpense}
              />
            </CardActions>
          </InvisibleCard>
        </CardContent>
      </Card>
      <Card>
        <CardHeader title="Пожертвования" />
        <CardContent>
          <AccountDonations id={projectExpense?.account?.id} />
        </CardContent>
        <CardContent>
          <ProjectExpenseDocuments projectExpense={projectExpense} />
        </CardContent>
      </Card>
    </LayoutPageGrid>
  );
};

export default ProjectExpensePage;
