import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { SpecialProjectPublicityButton } from '../ViewSpecialProjectPublicityButton';
import { SpecialProjectButtonProps } from '../types';
import { CloseSpecialProjectButton } from '../CloseSpecialProjectButton';
import { CancelSpecialProjectButton } from '../CancelSpecialProjectButton';
import { SendReportSpecialProjectButton } from '../SendReportSpecialProjectButton';

export const SpecialProjectActionButtons = ({ specialProject }: SpecialProjectButtonProps) => {
  const { id, cancelled, closed, published, reportSent } = specialProject;
  const baseUrl = `/special-projects/manage/${id}`;
  const editUrl = `${baseUrl}/edit`;
  return (
    <>
      {!cancelled && !closed && (
        <Button color="primary" variant="contained" component={Link} to={editUrl}>
          Редактировать
        </Button>
      )}
      {closed && reportSent === null && <SendReportSpecialProjectButton />}
      {!closed && !cancelled && <SpecialProjectPublicityButton specialProject={specialProject} />}
      {published && !cancelled && !closed && <CloseSpecialProjectButton />}
      {!closed && !cancelled && <CancelSpecialProjectButton />}
    </>
  );
};
