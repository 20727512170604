import React, { useCallback } from 'react';
import { ManageWardPublicityButtonProps } from './types';
import { usePublishWardMutation } from 'schema/serverTypes';
import { MutableButton } from 'ui/MutableButton';

export const PublishWardButton = (props: ManageWardPublicityButtonProps) => {
  const { id } = props;

  const [publish, { loading }] = usePublishWardMutation();
  const onClick = useCallback(() => {
    publish({
      variables: { input: { id } },
    });
  }, [publish, id]);

  return (
    <MutableButton color="primary" variant="outlined" loading={loading} onClick={onClick}>
      Опубликовать
    </MutableButton>
  );
};
