import { useCallback, useEffect } from 'react';
import {
  BenefactorByIdDocument,
  BenefactorsDocument,
  ChangePhoneNumberByAdministratorInput,
  useChangePhoneNumberByAdministratorMutation,
} from 'schema';
import { toast } from 'react-toastify';
import { useMeContext } from '../../../../../../../components';

interface InitialValuesType {
  phoneNumber: string;
}

export const useEditPhoneNumber = (
  benefactorId: string,
  handleClosePhone: () => void,
  phoneNumber?: string | null
) => {
  const [
    changePhoneNumber,
    { data, error, loading },
  ] = useChangePhoneNumberByAdministratorMutation();
  const { user } = useMeContext();

  const onSubmit = useCallback(
    async (values: InitialValuesType) => {
      const inputValues: ChangePhoneNumberByAdministratorInput = {
        phoneNumber: values.phoneNumber,
        userId: benefactorId,
      };

      await changePhoneNumber({
        variables: {
          input: inputValues,
        },
        refetchQueries: [
          {
            query: BenefactorByIdDocument,
            variables: { id: benefactorId, permission: user?.permissions.benefactors.manage },
          },
          {
            query: BenefactorsDocument,
            variables: { first: 50, permission: user?.permissions.benefactors.list },
          },
        ],
      });
    },
    [benefactorId, changePhoneNumber, user]
  );

  const initialValues: InitialValuesType = {
    phoneNumber: phoneNumber || '',
  };

  useEffect(() => {
    if (error) {
      toast.error('Ошибка при изменении номера телефона пользователя');
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      toast.success('Номер телефона пользователя успешно изменен');
      handleClosePhone();
    }
  }, [data, handleClosePhone]);

  return {
    onSubmit,
    initialValues,
    loading,
  };
};
