import React from 'react';
import clsx from 'clsx';
import {
  Modal as MuiModal,
  makeStyles,
  Theme,
  createStyles,
  Backdrop,
  Fade,
  ModalProps as MuiModalProps,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    form: {
      backgroundColor: theme.palette.background.paper,
      border: '0px',
      borderRadius: '2px',
      boxShadow: theme.shadows[5],
      outline: 'none',
    },
  })
);

export type ModalProps = Omit<MuiModalProps, 'open' | 'onClose'> & {
  open?: boolean;
  onClose: () => void;
};

export const Modal = (props: ModalProps) => {
  const classes = useStyles();
  const {
    open = true,
    BackdropComponent = Backdrop,
    BackdropProps = { timeout: 500 },
    className,
    children,
    ...rest
  } = props;
  return (
    <MuiModal
      open={open}
      className={clsx(classes.root, className)}
      BackdropComponent={BackdropComponent}
      BackdropProps={BackdropProps}
      {...rest}
    >
      <Fade in={true}>
        <div className={classes.form}>{children}</div>
      </Fade>
    </MuiModal>
  );
};
