import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  SpecialProjectByIdDocument,
  SpecialProjectsDocument,
  useCloseSpecialProjectMutation,
} from '../../../../../schema';
import { MutableButton } from '../../../../../ui/MutableButton';
import { toast } from 'react-toastify';

export const CloseSpecialProjectButton = () => {
  const { id } = useParams();
  const [close, { loading, error }] = useCloseSpecialProjectMutation();

  const handleClick = useCallback(async () => {
    try {
      return await close({
        variables: { input: { id } },
        refetchQueries: [
          { query: SpecialProjectByIdDocument, variables: { id } },
          {
            query: SpecialProjectsDocument,
            variables: { first: 50, includeClosedOrCancelled: false },
          },
        ],
      });
    } catch {
      return error && toast.error('Ошибка снятия с  публикации спецпроекта');
    }
  }, [close, id, error]);

  return (
    <MutableButton color="primary" variant="contained" onClick={handleClick} loading={loading}>
      Завершить
    </MutableButton>
  );
};
