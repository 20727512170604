import React from 'react';
import { EditWardForm } from './EditWardForm';
import { LayoutPageGrid } from 'ui/LayoutGrid/LayoutGrid';
import { PassedAwayButton } from '../components/PassedAwayButton';
import { WardPassedAwayPage } from '../WardPassedAwayPage';
import { RouteWithoutLayout, useMeContext } from 'components';
import { EditWardProps } from './types';
import { usePermissionPage } from 'hooks';
import { WardDeactivationPage } from '../WardDeactivationPage';
import { DeactivateWardButton } from '../components/DeactivateWardButton';

export const EditWardPage = ({ ward }: EditWardProps) => {
  const { user } = useMeContext();

  usePermissionPage('/wards', user?.permissions.wards.edit);

  return (
    <LayoutPageGrid
      title="Редактирование подопечного"
      tools={
        <>
          <DeactivateWardButton ward={ward} />
          <PassedAwayButton ward={ward} />
        </>
      }
    >
      <EditWardForm ward={ward} />
      <RouteWithoutLayout
        exact
        path="/wards/manage/:id/edit/rip"
        component={WardPassedAwayPage}
        componentProps={{ ward }}
      />
      <RouteWithoutLayout
        exact
        path="/wards/manage/:id/edit/deactivate"
        component={WardDeactivationPage}
        componentProps={{ ward }}
      />
    </LayoutPageGrid>
  );
};
