import React from 'react';
import { Rating, RatingProps } from '@material-ui/lab';
import { FieldRenderProps } from 'react-final-form-hooks';
import { FormControl, FormHelperText, FormLabel } from '@material-ui/core';

export type StarRatingFieldProps<V = any, T = string> = RatingProps &
  FieldRenderProps<V, T> & {
    label: string | undefined;
  };

export const StarRating = (props: StarRatingFieldProps) => {
  const { input, value, meta, label = 'Рейтинг', ...rest } = props;
  const error = !meta.valid && meta.touched;
  const helperText = error ? meta.error : null;

  return (
    <FormControl component="fieldset" error={error}>
      <FormLabel component="legend">{label}</FormLabel>
      <Rating {...input} value={Number(input.value)} {...rest} />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};
