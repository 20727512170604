import React from 'react';
import { Button } from '@material-ui/core';
import { Link, Route } from 'react-router-dom';
import { EditProjectRequirementsPopup } from './EditProjectRequirementsPopup';
import { ProjectRequirements } from '../../../../schema';

type Props = {
  requirements: ProjectRequirements;
};

export const EditProjectRequirements = ({ requirements }: Props) => {
  return (
    <>
      <Button
        color="primary"
        variant="contained"
        component={Link}
        to={`/project/requirements/edit`}
      >
        Редактировать потребности
      </Button>

      <Route
        exact
        path="/project/requirements/edit"
        render={() => <EditProjectRequirementsPopup requirements={requirements} />}
      />
    </>
  );
};
