import { CurrentUserInformation } from 'components';

const UserKey = 'user';

export const DisplayNameKey = 'https://schemas.gohelpme.ru/ws/2020/03/identity/claims/displayname';

const get = () => {
  try {
    const userString = localStorage.getItem('user');
    if (userString === null) {
      return undefined;
    }
    return JSON.parse(userString) as CurrentUserInformation;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

const save = (user: CurrentUserInformation) => {
  try {
    localStorage.setItem(UserKey, JSON.stringify(user));
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

const clear = () => {
  try {
    localStorage.removeItem(UserKey);
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export const userStorage = {
  get,
  save,
  clear,
};
