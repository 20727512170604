import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Typography, Theme, createStyles, Badge, withStyles } from '@material-ui/core';
import { DataGrid } from 'components';
import { WardStatusLabel } from './WardStatusLabel';
import { WardsSearch } from './WardsSearch';
import { useQueryVariables, ColumnConfig, DataConnection } from 'hooks';
import { formatDate, getInitials, toDateTimeString } from 'helpers';
import {
  OneTimeNeed,
  SortEnumType,
  useWardsQuery,
  WardFilterInput,
  WardsGridWardFragment,
  WardSortInput,
} from 'schema/serverTypes';
import { Link } from 'react-router-dom';
import { DataGridProps } from 'components/DataGrid/types';
import moment, { Moment } from 'moment';
import { toast } from 'react-toastify';
import { isOneTimeNeed } from '../../../../schema';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nameContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    avatar: {
      marginRight: theme.spacing(2),
    },
    statusAlarm: {
      color: theme.palette.error.main,
    },
    statusSuccess: {
      color: theme.palette.success.main,
    },
    statusWarning: {
      color: theme.palette.warning.main,
    },
    dateOfDeath: {
      textAlign: 'center',
    },
  })
);

const wrapTableCellWithLink = (ward: WardsGridWardFragment, cell: React.ReactNode) => (
  <Link to={`/wards/manage/${ward.id}`}>{cell}</Link>
);

const curStatus = (amount: number, requiredAmount: number, endDate: Moment) => {
  const timeLeft = endDate.diff(moment(), 'days');
  return amount / requiredAmount <= 0.8 && timeLeft <= 7
    ? 'statusAlarm'
    : amount / requiredAmount <= 0.5 && timeLeft <= 10
    ? 'statusWarning'
    : 'statusSuccess';
};

const getDataKey = (ward: WardsGridWardFragment) => ward.id;

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.main,
    border: '2px solid white',
    right: 22,
    width: 14,
    height: 14,
    borderRadius: '50%',
  },
  colorSecondary: {
    backgroundColor: theme.palette.text.secondary,
    color: theme.palette.text.secondary,
  },
}))(Badge);

export const WardsGrid = () => {
  const classes = useStyles();

  const config = useMemo(() => {
    const columns: ColumnConfig<WardsGridWardFragment, WardSortInput>[] = [
      {
        id: 'fullName',
        header: 'ФИО',
        style: { width: '20%' },
        orderBy: (kind) => ({
          publicInformation: {
            name: {
              fullName: kind,
            },
          },
        }),
        render: (user: WardsGridWardFragment) => {
          return (
            <div className={classes.nameContainer}>
              {wrapTableCellWithLink(
                user,
                <StyledBadge
                  overlap="circle"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  variant="dot"
                >
                  <Avatar className={classes.avatar} src={user.publicInformation.photo.avatar}>
                    {getInitials(user.publicInformation.name.fullName)}
                  </Avatar>
                </StyledBadge>
              )}
              {wrapTableCellWithLink(
                user,
                <Typography variant="body1">{user.publicInformation.name.fullName}</Typography>
              )}
            </div>
          );
        },
      },
      {
        id: 'address',
        header: 'Населенный пункт',
        style: { width: '15%' },
        orderBy: (kind) => ({
          publicInformation: {
            city: kind,
          },
        }),
        render: (user) => user.publicInformation.city,
      },
      {
        id: 'regularNeeds',
        header: 'Регулярные потребности',
        style: { width: '15%' },
        render: (user) => {
          const {
            activeRegularNeedsRequiredAmount,
            totalSubscriptionsAmount,
          } = user.countsAndAmounts;
          const { oneTimeDonationsAmount } = user.regularNeeds;

          return user.countsAndAmounts.totalRegularNeedsCount &&
            !user.publicInformation.dateOfDeath ? (
            <>
              <span>{user.countsAndAmounts.totalRegularNeedsCount}</span>
              <br />
              <Typography
                variant="body2"
                className={
                  classes[
                    curStatus(
                      totalSubscriptionsAmount + oneTimeDonationsAmount,
                      activeRegularNeedsRequiredAmount,
                      moment().endOf('month')
                    )
                  ]
                }
              >
                Самое необходимое
              </Typography>
            </>
          ) : (
            <Typography variant="body1" color="textSecondary">
              Отсутствует
            </Typography>
          );
        },
      },

      {
        id: 'oneTimeNeeds',
        header: 'Разовые потребности',
        style: { width: '15%' },
        render: (user) => {
          const needs = user.needs as OneTimeNeed[];
          const titles = needs
            .filter(isOneTimeNeed)
            .filter(({ archived }) => archived === null)
            .map((item, index, arr) => {
              return (
                <Typography
                  variant="body2"
                  component="span"
                  key={item.id + index}
                  className={
                    classes[
                      curStatus(
                        item?.account?.amount,
                        item?.requiredAmount,
                        moment(item?.targetDate)
                      )
                    ]
                  }
                >
                  {item.title}
                  {index + 1 < arr.length ? ', ' : null}
                </Typography>
              );
            });

          return user.countsAndAmounts.totalOneTimeNeedsCount &&
            titles.length &&
            !user.publicInformation.dateOfDeath ? (
            <>
              <span>{user.countsAndAmounts.totalOneTimeNeedsCount}</span>
              <br />
              {titles}
            </>
          ) : (
            <Typography variant="body1" color="textSecondary">
              Отсутствует
            </Typography>
          );
        },
      },
      {
        id: 'status',
        header: 'Статус',
        align: 'center',
        style: { width: '5%' },
        render: (user: WardsGridWardFragment) => {
          return wrapTableCellWithLink(
            user,
            <WardStatusLabel
              immediateHelpRequired={user.immediateHelpRequired}
              deactivated={Boolean(user.deactivationInformation)}
            />
          );
        },
      },
      {
        id: 'createdAt',
        header: 'Дата создания',
        style: { width: '10%' },
        render: (user) => {
          const date = new Date(user.created.timestamp as string);
          return <div>{toDateTimeString(date)}</div>;
        },
      },
      {
        id: 'dateOfDeath',
        header: 'Дата смерти',
        style: { width: '10%' },
        orderBy: (kind) => ({
          publicInformation: {
            dateOfDeath: kind,
          },
        }),
        render: (user) => {
          const date = user.publicInformation.dateOfDeath;
          return (
            <div className={classes.dateOfDeath}>
              {user.publicInformation.dateOfDeath ? formatDate(date) : '-'}
            </div>
          );
        },
      },
    ];
    return {
      columns,
      rowsPerPage: 50,
    };
  }, [classes]);

  const defaultOrderBy: WardSortInput = { id: SortEnumType.Desc };

  const {
    onSort,
    onSearch,
    onPage,
    variables,
    extractPagedData,
    columns,
    sortDirection,
    sortBy,
  } = useQueryVariables<WardsGridWardFragment, WardSortInput, WardFilterInput>(
    config,
    defaultOrderBy
  );

  const { data, loading, error } = useWardsQuery({ variables, fetchPolicy: 'network-only' });

  if (error) {
    toast.error('Ошибка загрузки подопечных');
    return null;
  }

  const entities = data?.wards as DataConnection<WardsGridWardFragment>;
  const { data: wards, count, startCursor, endCursor, page, rowsPerPage } = extractPagedData(
    entities
  );

  const gridProps: DataGridProps<WardsGridWardFragment, WardFilterInput> = {
    data: wards,
    onSort,
    onPage,
    onSearch,
    columns,
    getDataKey,
    count,
    startCursor: startCursor,
    endCursor: endCursor,
    page,
    searchComponent: WardsSearch,
    rowsPerPage,
    sortDirection,
    sortBy,
    loading,
  };

  return <DataGrid<WardsGridWardFragment, WardFilterInput> {...gridProps} />;
};
