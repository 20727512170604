import React from 'react';
import { useUrlSearchParams } from 'hooks';
import { Redirect } from 'react-router-dom';
import { ResetPasswordForm } from './ResetPasswordForm';
import { useMeContext } from 'components';

export const ResetPasswordPage = () => {
  const urlParams = useUrlSearchParams();
  const userId = urlParams.get('userId');
  const code = urlParams.get('code');

  const { user } = useMeContext();

  if (user !== undefined) {
    return <Redirect to="/" />;
  }

  if (userId === null || code === null) {
    return <Redirect to="/404" />;
  }

  return <ResetPasswordForm code={code} />;
};
