import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  createStyles,
  Divider,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { RedButton } from 'components/Buttons';

import { SpecialProjectsPropsFragment } from '../../../schema';
import { arrayMove, SortableContainer } from 'react-sortable-hoc';
import { DragbleProjectCard } from './components';
import { AutoFocusedForm } from '../../../components';
import { useUpdateOrderSpecialProjectsForm } from './useUpdateOrderSpecialProjectsForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 1000,
    },
    content: {
      maxHeight: 400,
      overflow: 'scroll',
    },
    header: {
      fontWeight: 'bolder',
    },
    sortableContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '15px',
    },
    actions: {
      justifyContent: 'flex-end',
    },
    cardProject: { width: '240px', position: 'relative' },
    helperClass: {
      zIndex: 2000,
    },
    accessButton: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.success.dark,
      '&:hover': {
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.success.light,
      },
    },
  })
);

interface OrderSpecialProjectModalFormProps {
  onClose: () => void;
  specialProjects: SpecialProjectsPropsFragment[];
}

export const OrderSpecialProjectModalForm = ({
  onClose,
  specialProjects,
}: OrderSpecialProjectModalFormProps) => {
  const styles = useStyles();
  const [specialProjectsState, setSpecialProjectsState] = useState<
    SpecialProjectsPropsFragment[] | undefined
  >([]);
  const [ids, setIds] = useState<string[]>([]);

  const { onSubmit, loading } = useUpdateOrderSpecialProjectsForm({
    onClose,
    ids,
  });

  useEffect(() => {
    const localSpecialProjects = specialProjects
      ?.slice()
      .map((specialProject) => specialProject) as SpecialProjectsPropsFragment[];

    const sortedSpecialProjects = localSpecialProjects?.sort((a, b) => {
      if (a.order === 0 && b.order !== 0) {
        return -1;
      }
      if (a.order !== 0 && b.order === 0) {
        return 1;
      }
      if (a.order === 0 && b.order === 0) {
        const aTimestamp = new Date(a.created.timestamp).getTime();
        const bTimestamp = new Date(b.created.timestamp).getTime();
        return bTimestamp - aTimestamp;
      }
      return 0;
    });
    setSpecialProjectsState(sortedSpecialProjects);
  }, [specialProjects]);

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    const projectsIds = specialProjectsState?.map(({ id }) => id);
    const newProjectIds = arrayMove(projectsIds!, oldIndex, newIndex);
    const newLocalSpecialProjectOrder = arrayMove(specialProjectsState!, oldIndex, newIndex);
    setSpecialProjectsState(newLocalSpecialProjectOrder);
    setIds(newProjectIds);
  };

  const SortableCardList = SortableContainer(
    ({ projects }: { projects: SpecialProjectsPropsFragment[] | undefined }) => {
      return (
        <div className={styles.sortableContainer}>
          {projects &&
            projects
              .filter(({ closed }) => !closed)
              .map((project, index) => (
                <DragbleProjectCard
                  key={project.id}
                  description={project.description}
                  id={project.id}
                  title={project.title}
                  index={index}
                />
              ))}
        </div>
      );
    }
  );

  return (
    <AutoFocusedForm
      onSubmit={onSubmit}
      render={({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card className={styles.root}>
              <CardHeader title={'Порядок спецпроектов'} />
              <Divider />
              <CardContent className={styles.content}>
                <SortableCardList
                  helperClass={styles.helperClass}
                  projects={specialProjectsState}
                  onSortEnd={onSortEnd}
                  axis="xy"
                  useDragHandle={true}
                />
              </CardContent>

              <CardActions className={styles.actions}>
                <Button
                  disabled={loading}
                  className={styles.accessButton}
                  color="primary"
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  Сохранить
                </Button>
                <RedButton
                  onClick={onClose}
                  disabled={loading}
                  size="medium"
                  type="button"
                  variant="contained"
                >
                  Закрыть
                </RedButton>
              </CardActions>
            </Card>
          </form>
        );
      }}
    />
  );
};
