import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FieldRenderProps } from 'react-final-form-hooks';
import { StyledComponentProps } from '@material-ui/core/styles';
import {
  colors,
  createStyles,
  makeStyles,
  Paper,
  PaperProps,
  Theme,
  Typography,
  FormHelperText,
} from '@material-ui/core';
import { usePrepareFilesUploadMutation } from 'schema/serverTypes';
import { uploadFile } from 'components/form/PhotoField/uploadFile';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '60%',
      height: '140px',
      maxWidth: 400,
      cursor: 'pointer',
      outline: 'none',
      position: 'relative',
      '&:after': {
        content: '""',
        display: 'block',
        paddingTop: '100%',
      },
      '&:hover': {
        backgroundColor: theme.palette.background.default,
      },
      '&:active': {
        backgroundColor: colors.grey[100],
      },
    },
    wrapper: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
    },
    text: {
      textAlign: 'center',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    empty: {
      margin: theme.spacing(0, 2),
    },
    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover' as 'cover',
    },
    helper: {
      marginTop: theme.spacing(2),
    },
  })
);

interface InnerTextProps {
  loading: Boolean;
  isDragActive: Boolean;
  imageUrl?: string;
  className?: string;
}

const InnerText = ({ loading, isDragActive, imageUrl, className }: InnerTextProps) => {
  const classes = useStyles();

  if (loading) {
    return <span>Загружается....</span>;
  }
  if (imageUrl !== undefined) {
    return <img className={className} src={imageUrl} alt="Фото" />;
  }
  if (loading) {
    return (
      <Typography variant="h5" align="center" color="textPrimary">
        Загружается...
      </Typography>
    );
  }
  return (
    <div className={classes.empty}>
      <CloudUploadIcon />
      <Typography variant="h5" align="center" color="textPrimary">
        {isDragActive
          ? 'Перетащите файл сюда для добавления'
          : 'Ператащите или нажмите для добавления файла'}
      </Typography>
      <Typography variant="subtitle2" align="center" color="textSecondary">
        Файл в формате CSV
      </Typography>
    </div>
  );
};

export type CsvFieldProps = PaperProps &
  FieldRenderProps &
  StyledComponentProps & {
    url?: string;
    className?: string;
  };

const PhotoFieldDropZoneParameters = {
  multiple: false,
  maxSize: 5120 * 1024,
  types: ['text/csv'],
};

export const CsvField = (props: CsvFieldProps) => {
  const classes = useStyles();
  const { input, meta, variant = 'outlined', className, url, ...rest } = props;
  const [imageUrl, setImageUrl] = useState<string | undefined>(url);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [prepareUpload, { loading }] = usePrepareFilesUploadMutation();

  const onDrop = useCallback(
    async (acceptedFiles: File[], notAcceptedFiles: File[]) => {
      const getErrorMessage = (file: File) => {
        let errorMessage;
        switch (true) {
          case file.size > PhotoFieldDropZoneParameters.maxSize:
            errorMessage = 'Недопустимый размер файла.';
            break;
          case !PhotoFieldDropZoneParameters.types.includes(file.type):
            errorMessage = 'Недопустимый формат файла.';
            break;
          default:
            errorMessage = 'Неизвестная ошибка.';
        }
        return errorMessage;
      };

      if (acceptedFiles.length === 0) {
        if (notAcceptedFiles.length) {
          const file = notAcceptedFiles[0];
          setErrorMessage(getErrorMessage(file));
        }
        return;
      }
      setErrorMessage(undefined);
      const file = acceptedFiles[0];
      const { name: fileName, type: contentType } = file;
      const prepareUploadResult = await prepareUpload({
        variables: {
          input: {
            files: [{ fileName, contentType }],
          },
        },
      });
      const fileMeta = prepareUploadResult.data?.prepareFilesUpload.files![0];
      if (fileMeta === undefined) {
        return;
      }

      const { uploadUrl, id, url } = fileMeta;
      try {
        await uploadFile(file, uploadUrl);
        setImageUrl(url);
        input.onChange(id);
      } catch (error) {
        console.log(error);
      }
    },
    [prepareUpload, setImageUrl, input]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: PhotoFieldDropZoneParameters.multiple,
    maxSize: PhotoFieldDropZoneParameters.maxSize,
    accept: PhotoFieldDropZoneParameters.types.join(', '),
  });

  const rootProps = getRootProps();
  const inputProps = getInputProps();
  const errorMessageElement = errorMessage ? (
    <FormHelperText className={classes.helper} error={true}>
      {errorMessage}
    </FormHelperText>
  ) : (
    ''
  );

  return (
    <>
      <Paper {...rest} {...rootProps} variant={variant} className={clsx(classes.root, className)}>
        <div className={classes?.wrapper}>
          <input {...inputProps} disabled={inputProps.disabled || loading} />
          <div className={classes?.text}>
            <InnerText
              loading={loading}
              isDragActive={isDragActive}
              imageUrl={imageUrl}
              className={classes?.img}
            />
          </div>
        </div>
      </Paper>
      {errorMessageElement}
    </>
  );
};
