import React from 'react';
import { Link } from 'react-router-dom';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { WardProps } from '../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      marginBottom: theme.spacing(3),
    },
    link: {},
  })
);

export const Breadcrumbs = (props: WardProps) => {
  const classes = useStyles();

  const { ward } = props;
  const {
    publicInformation: {
      name: { displayName },
    },
  } = ward;
  const wardEditUrl = `/wards/manage/${ward.id}`;
  return (
    <Box className={classes.content}>
      <Link className={classes.link} to="/wards">
        Подопечные /{' '}
      </Link>
      <Link className={classes.link} to={wardEditUrl}>
        {displayName} /{' '}
      </Link>
      Документы
    </Box>
  );
};
