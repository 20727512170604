import React, { useMemo } from 'react';

import { makeStyles } from '@material-ui/styles';
import { Typography, Theme, createStyles } from '@material-ui/core';

import { DataGrid } from 'components';
import { useQueryVariables, ColumnConfig, DataConnection } from 'hooks';

import { BenefactorStatusPropsFragment, useBenefactorStatusesQuery } from 'schema/serverTypes';
import { DataGridProps } from 'components/DataGrid/types';
import { toast } from 'react-toastify';
import { Link, Route } from 'react-router-dom';
import { AddBenefactorStatusModal } from '../../AddBenefactorStatusModal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    name: {
      fontWeight: 700,
    },
    link: {
      color: theme.palette.primary.dark,
    },
  })
);

const getDataKey = (status: BenefactorStatusPropsFragment) => status.id;

export const BenefactorStatusesGrid = () => {
  const classes = useStyles();

  const config = useMemo(() => {
    const columns: ColumnConfig<BenefactorStatusPropsFragment>[] = [
      {
        id: 'title',
        header: 'Название',
        render: (status: BenefactorStatusPropsFragment) => (
          <Link to={`/benefactor-statuses/manage/${status.id}`} className={classes.link}>
            {status.title}
          </Link>
        ),
      },
      {
        id: 'description',
        header: 'Описание',
        render: (status: BenefactorStatusPropsFragment) => (
          <Typography variant="body1">{status.description}</Typography>
        ),
      },
      {
        id: 'coins',
        header: 'Стоимость',
        render: (status: BenefactorStatusPropsFragment) => (
          <Typography variant="body1">{status.coins}</Typography>
        ),
      },
    ];
    return {
      columns,
      rowsPerPage: 50,
    };
  }, [classes]);

  const {
    onSort,
    onSearch,
    onPage,
    variables,
    extractPagedData,
    columns,
    sortDirection,
    sortBy,
  } = useQueryVariables<BenefactorStatusPropsFragment>(config);

  const { data, loading, error } = useBenefactorStatusesQuery({ variables });

  if (error) {
    toast.error('Ошибка загрузки статусов');
    return null;
  }

  const entities = data?.benefactorStatuses as DataConnection<BenefactorStatusPropsFragment>;
  const {
    data: benefactorStatuses,
    count,
    startCursor,
    endCursor,
    page,
    rowsPerPage,
  } = extractPagedData(entities);

  const gridProps: DataGridProps<BenefactorStatusPropsFragment> = {
    data: benefactorStatuses,
    onSort,
    onPage,
    onSearch,
    columns,
    getDataKey,
    count,
    startCursor: startCursor,
    endCursor: endCursor,
    page,
    rowsPerPage,
    sortDirection,
    sortBy,
    loading,
  };

  return (
    <>
      <DataGrid<BenefactorStatusPropsFragment> {...gridProps} />
      <Route path="/benefactor-statuses/create" exact>
        <AddBenefactorStatusModal />
      </Route>
    </>
  );
};
