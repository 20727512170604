import React from 'react';
import { useParams } from 'react-router-dom';

import { EditModeratorForm } from './EditModeratorForm';
import { Modal } from 'components';
import { useCancelRoute } from 'hooks';
import { useUserByIdQuery } from 'schema';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { EditFormOrEmpty } from 'components/hoc';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    form: {
      width: '100%',
      padding: '25px',
      backgroundColor: theme.palette.background.paper,
      border: '0px',
      borderRadius: '2px',
      boxShadow: theme.shadows[5],
    },
  })
);

const EditModerator = EditFormOrEmpty(EditModeratorForm);

export const EditModeratorPage = () => {
  const { onCancel } = useCancelRoute('/moderators');
  const styles = useStyles();

  const { id } = useParams<{ id: string }>();
  const { data, error, loading } = useUserByIdQuery({ variables: { id } });

  return (
    <Modal
      onClose={onCancel}
      aria-labelledby="Редактирование администратора"
      aria-describedby="Используйте эту форму для редактирования администратора"
      className={styles.modal}
    >
      <div className={styles.form}>
        <EditModerator
          onClose={onCancel}
          loading={loading}
          hasError={error !== undefined}
          data={data}
        />
      </div>
    </Modal>
  );
};
