import React from 'react';
import { RadioGroup, RadioGroupProps, FormControl, FormLabel, FormHelperText } from '@material-ui/core';
import { FieldRenderProps } from 'react-final-form-hooks';

export type RadioGroupFieldProps<V> = Omit<RadioGroupProps, 'name' | 'onChange' | 'value'> &
  FieldRenderProps<V> & {
    label?: string;
  };

export function RadioGroupField<V>(props: RadioGroupFieldProps<V>) {
  const { input, meta, children, className, label, ...rest } = props;
  const error = !meta.valid && meta.touched;
  const helperText = !meta.valid && meta.touched ? meta.error : null;

  return (
    <FormControl component="fieldset" error={error} className={className}>
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup
        {...rest}
        aria-label={label}
        name={input.name}
        value={input.value}
        onChange={input.onChange}
        onBlur={input.onBlur}
        onFocus={input.onFocus}
      >
        {children}
      </RadioGroup>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}
