import React from 'react';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import { LayoutPageGrid } from 'ui/LayoutGrid/LayoutGrid';
import { InvisibleCard } from 'ui/InvisibleCard';

import { BenefactorExtendedPropsFragment, useDonationsPaymentQuery } from '../../../schema';
import { useParams } from 'react-router-dom';

import { BenefactorDonationInfo, BenefactorTransactionData } from './components';
import { BreadcrumbsTransaction } from './components/BreadcrumbsTransaction';
import { formatDate } from '../../../helpers';

export interface BenefactorTransactionProps {
  benefactor: BenefactorExtendedPropsFragment;
}

export const BenefactorTransactionPage = ({ benefactor }: BenefactorTransactionProps) => {
  const { orderId } = useParams<{ orderId: string }>();

  const { data } = useDonationsPaymentQuery({
    variables: { id: orderId },
  });

  return (
    <>
      {data ? (
        <>
          <BreadcrumbsTransaction benefactor={benefactor} data={data} />
          <LayoutPageGrid title={`Заказ от ${formatDate(data?.orderById?.created.timestamp)}`}>
            <Card>
              <CardHeader title="Информация о заказе и платеже" />
              <CardContent>
                <InvisibleCard>
                  <CardContent>
                    <BenefactorTransactionData transaction={data} />
                  </CardContent>
                </InvisibleCard>
              </CardContent>
            </Card>
            <Card>
              <CardHeader title="Пожертвования" />
              <CardContent>
                <BenefactorDonationInfo />
              </CardContent>
            </Card>
          </LayoutPageGrid>
        </>
      ) : null}
    </>
  );
};
