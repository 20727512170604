import React from 'react';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { MutableButton } from 'ui/MutableButton';
import { RedButton } from 'components/Buttons';
import { Field } from 'react-final-form';
import { AutoFocusedForm, required, StarRating, TextField } from 'components';
import { useAddRatingForm } from './useAddRatingForm';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    header: {
      fontWeight: 'bolder',
    },
    content: {},
    divider: {},
    actions: {
      justifyContent: 'flex-end',
    },
    accessButton: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.success.dark,
      '&:hover': {
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.success.light,
      },
    },
  })
);

interface AddRatingFormProps {
  onClose: () => void;
}

export const AddRatingForm = (props: AddRatingFormProps) => {
  const { attendeeId } = useParams();
  const { onClose } = props;

  const styles = useStyles();

  const { onSubmit, initialValues, loading } = useAddRatingForm(onClose, attendeeId);

  return (
    <AutoFocusedForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      subscription={{
        submitError: true,
      }}
      render={({ handleSubmit, pristine }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card className={styles.root}>
              <CardHeader title="Рейтиг участника события" />
              <Divider className={styles.divider} />
              <CardContent className={styles.content}>
                <Grid container spacing={4}>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Field
                      name="rating"
                      component={StarRating}
                      label="Рейтинг участника"
                      type="number"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Field
                      name="comment"
                      label="Коментарий *"
                      component={TextField}
                      validate={required}
                      multiline
                      rows={5}
                      rowsMax={50}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions className={styles.actions}>
                <MutableButton
                  loading={loading}
                  color="primary"
                  disabled={pristine}
                  size="medium"
                  type="submit"
                  variant="contained"
                  className={styles.accessButton}
                >
                  Сохранить
                </MutableButton>
                <RedButton
                  onClick={onClose}
                  disabled={loading}
                  size="medium"
                  type="button"
                  variant="contained"
                >
                  Отменить
                </RedButton>
              </CardActions>
            </Card>
          </form>
        );
      }}
    />
  );
};
