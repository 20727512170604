import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Box, Button, Grid, MenuItem, Select } from '@material-ui/core';
import { useCitiesQuery } from '../../../../schema';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    search: {
      paddingRight: theme.spacing(2),
    },
    resetButton: {
      display: 'block',
      marginLeft: 'auto',
    },
    selectLabel: {
      margin: '10px',
    },
  })
);

export type SpecialProjectSearchProps = {
  onIncludeClosedOrCancelled?: (includeClosedOrCancelled: boolean | undefined) => void;
  onSearchCity?: (city: string | undefined) => void;
};

export const SpecialProjectSearch = (props: SpecialProjectSearchProps) => {
  const { onIncludeClosedOrCancelled, onSearchCity } = props;

  const classes = useStyles();

  const { data } = useCitiesQuery();
  const cities = data?.cities ? (data?.cities.nodes?.map(({ name }) => name) as string[]) : [];
  const [city, setCity] = useState<string>('');
  const [closed, setClosed] = useState<string | number>(0);

  const handleChangeCity = (e: React.ChangeEvent<{ value: unknown }>) => {
    setCity(e.target.value as string);
  };

  const handleChangeIncludeClosedOrCancelled = (e: React.ChangeEvent<{ value: unknown }>) => {
    setClosed(e.target.value as number | string);
  };

  useEffect(() => {
    if (onIncludeClosedOrCancelled !== undefined) {
      onIncludeClosedOrCancelled(Boolean(closed));
    }

    if (onSearchCity !== undefined) {
      onSearchCity(city);
    }
  }, [onIncludeClosedOrCancelled, closed, onSearchCity, city]);

  const handleOnReset = useCallback(() => {
    setClosed(0);
    setCity('');

    if (onSearchCity) {
      onSearchCity('');
    }

    if (onIncludeClosedOrCancelled) {
      onIncludeClosedOrCancelled(false);
    }
  }, [onIncludeClosedOrCancelled, onSearchCity]);

  return (
    <div className={classes.root}>
      <Grid container spacing={4} className={classes.root}>
        <Grid item lg={3} md={4} xl={2} xs={12}>
          <Box display="flex" alignItems="center">
            <h4 className={classes.selectLabel}>Город:</h4>
            <Select value={city} variant={'outlined'} displayEmpty onChange={handleChangeCity}>
              <MenuItem disabled value={''}>
                Выберите город
              </MenuItem>
              {cities.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
          </Box>
        </Grid>
        <Grid item lg={3} md={4} xl={2} xs={12}>
          <Box display="flex" alignItems="center">
            <h4 className={classes.selectLabel}>Завершенность:</h4>
            <Select
              value={closed}
              displayEmpty
              variant={'outlined'}
              onChange={handleChangeIncludeClosedOrCancelled}
            >
              <MenuItem value={1}>Все</MenuItem>
              <MenuItem value={0}>Незавершённые</MenuItem>
            </Select>
          </Box>
        </Grid>

        <Grid item lg={6} md={4} xl={8} xs={12}>
          <Button className={classes.resetButton} variant="contained" onClick={handleOnReset}>
            Сбросить фильтры
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
