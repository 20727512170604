import React from 'react';
import AddWardForm from './AddWardForm';
import { LayoutPageGrid } from 'ui/LayoutGrid/LayoutGrid';
import { usePermissionPage } from 'hooks';
import { useMeContext } from 'components';

export const AddWardPage = () => {
  const { user } = useMeContext();

  usePermissionPage('/ward', user?.permissions.wards.create);

  return (
    <LayoutPageGrid title="Добавление подопечного">
      <div>
        <AddWardForm />
      </div>
    </LayoutPageGrid>
  );
};
