import React from 'react';
import { Typography, createStyles, Theme, makeStyles } from '@material-ui/core';
import { orange, green } from '@material-ui/core/colors';
import { Moment } from 'moment';
import { WaitIcon, CompleteIcon, AmountLabel } from 'components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    waitIcon: {
      color: orange[500],
      marginRight: theme.spacing(1),
    },
    completeIcon: {
      color: green[500],
      marginRight: theme.spacing(1),
    },
    gray: {
      color: theme.palette.text.secondary,
    },
  })
);

export interface CollectedAmountLabelProps {
  collected: number;
  total: number;
  date?: Moment | null;
  format?: 'month' | 'fullDate' | null;
}

export const CollectedAmountLabel = (props: CollectedAmountLabelProps) => {
  const classes = useStyles();

  const { total, collected, date, format } = props;
  const dateLabel = format
    ? format === 'fullDate'
      ? date?.format('D MMMM YYYY')
      : date?.format('MMMM')
    : null;
  const preposition = date ? (format === 'fullDate' ? 'до' : 'за') : null;

  return (
    <>
      {collected < total ? (
        <WaitIcon className={classes.waitIcon} />
      ) : (
        <CompleteIcon className={classes.completeIcon} />
      )}
      <Typography component="span">
        <AmountLabel value={collected} showCurrency={false} />
        &nbsp;/&nbsp;
      </Typography>
      <Typography component="span" className={classes.gray}>
        <AmountLabel value={total} showCurrency={false} /> ₽ {preposition} {dateLabel}
      </Typography>
    </>
  );
};
