import React from 'react';
import { createContext } from 'react';
import { ApplicationsQueryVariables } from 'schema';

export type ApplicationsQueryContext = ApplicationsQueryVariables | undefined;

export const QueryVariablesContext = createContext<ApplicationsQueryContext>(undefined);

export interface QueryContextProviderProps {
  variables: ApplicationsQueryContext;
  children: React.ReactNode;
}

export const QueryContextProvider = (props: QueryContextProviderProps) => {
  const { children, variables } = props;
  return (
    <QueryVariablesContext.Provider value={variables}>{children}</QueryVariablesContext.Provider>
  );
};
