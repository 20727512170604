import React from 'react';
import { Card, CardActions, CardContent, CardHeader } from '@material-ui/core';
import { AccountActions, AccountDonations, AccountInformation } from './components';
import { useParams } from 'react-router-dom';
import { useAccount } from '.';
import { LayoutPageGrid } from 'ui/LayoutGrid/LayoutGrid';
import { InvisibleCard } from 'ui/InvisibleCard';
import { AccountQuery, BenefactorDepositAccountForSpecificWards, SystemAccountKind } from 'schema';

function getPageTitle(account: AccountQuery['account']): string {
  if (account?.__typename === 'BenefactorDepositAccountForAllWards') {
    return `Счёт-депозит жертвователя ${account.benefactor.displayName} для любых подопечных`;
  }

  if (account?.__typename === 'BenefactorDepositAccountForSpecificWards') {
    return `Счёт-депозит жертвователя  ${account.benefactor.displayName} для подопечных: ${
      (account as BenefactorDepositAccountForSpecificWards).title
    }`;
  }

  if (account?.__typename === 'BenefactorDepositAccountForProjectNeeds') {
    return `Счёт-депозит жертвователя  ${account.benefactor.displayName} для расходов проекта`;
  }

  if (account?.__typename === 'BenefactorDepositAccountWithoutConditions') {
    return `Счёт-депозит жертвователя  ${account.benefactor.displayName} на любые нужды`;
  }

  if (account?.__typename === 'SystemAccount') {
    if (account.kind === SystemAccountKind.Main) {
      return 'Основной счёт проекта';
    }

    return 'Счёт резерва подопечных';
  }

  /*if (account?.__typename === "RegularNeedsAccount") {
    const wardName = account.ward.publicInformation.name.fullName;
    const need = account.need;
    let needTitle;

    switch (need.__typename) {
      case 'OneTimeNeedWardPage':
        needTitle = need.title;
        break;

      case 'GrocerySetNeed':
        needTitle = 'Продуктовая корзина';
        break;

      default:
        needTitle = 'Неизвестная потребность';
        break;
    }

    return `Счёт потребности "${needTitle}" подопечного ${wardName}`;
  }*/

  /*  if (account?.__typename === 'RegularNeedsAccount') {
    return `Счёт-кошелёк подопечного ${account.ward.publicInformation.name.fullName}`;
  }

  if (account?.__typename === 'ProjectExpenseAccount') {
    return `Счёт расходов проекта: ${account.title}`;
  }*/

  if (account?.__typename === 'SpecialProjectAccount') {
    return `Счёт cпецпроекта: ${account.specialProject?.title}`;
  }

  return 'Счёт';
}

export const ManageAccountPage = () => {
  const { id } = useParams<{ id: string }>(),
    account = useAccount({ id });

  if (!account) {
    return null;
  }

  return (
    <LayoutPageGrid title={getPageTitle(account)}>
      <Card>
        <CardHeader title="Информация о счете" />
        <CardContent>
          <InvisibleCard>
            <CardContent>
              <AccountInformation account={account} />
            </CardContent>
            <CardActions>
              <AccountActions account={account} />
            </CardActions>
          </InvisibleCard>
        </CardContent>
      </Card>
      <Card>
        <CardHeader title="Пожертвования" />
        <CardContent>
          <AccountDonations id={id} />
        </CardContent>
      </Card>
    </LayoutPageGrid>
  );
};
