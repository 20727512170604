import React from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { NavLink as RouterLink } from 'react-router-dom';

type DropdownMenuProps = {
  title: string;
  classes: Record<'button' | 'item' | 'link', string>;
  screens?: { title: string; href: string }[];
};

export const DropdownMenu = ({ title, classes, screens }: DropdownMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        className={classes.button}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {title}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {screens?.map(({ title, href }) => (
          <MenuItem onClick={handleClose}>
            <RouterLink className={classes.link} to={href}>
              {title}
            </RouterLink>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
