import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { PropertiesTable, PropertiesTableRow } from '../../../../ui/PropertiesTable';
import { useStyles } from '../../BenefactorManagePage/components/BenefactorCard/useStyles';
import { OrderAmountSummary } from '../../../../schema';

type Props = {
  title: string;
  summary: OrderAmountSummary;
};

export const OrderAmountSummaryView = ({ title, summary }: Props) => {
  const classes = useStyles();

  return (
    <>
      <Typography component="div" variant="h5" align="center" className={classes.headerLabel}>
        {title}
      </Typography>
      <Box className={classes.accounts} textAlign="start">
        <PropertiesTable>
          {summary.oneTimeProjectDonation ? (
            <PropertiesTableRow
              caption="Разовое пожертвование на проект"
              value={summary.oneTimeProjectDonation}
            />
          ) : null}
          {summary.oneTimeWardDonation ? (
            <PropertiesTableRow
              caption="Разовое пожертвование на подопечных"
              value={summary.oneTimeWardDonation}
            />
          ) : null}
          {summary.projectSubscription ? (
            <PropertiesTableRow caption="Подписка на проект" value={summary.projectSubscription} />
          ) : null}
          {summary.projectSubscriptionCharge ? (
            <PropertiesTableRow
              caption="Списание подписки на проект"
              value={summary.projectSubscriptionCharge}
            />
          ) : null}
          {summary.specialProjectDonation ? (
            <PropertiesTableRow
              caption="Пожертвование на спецпроект"
              value={summary.specialProjectDonation}
            />
          ) : null}
          {summary.oneTimeWardsReserveDonation ? (
            <PropertiesTableRow
              caption="Разовое пожертвование в резерв подопечных"
              value={summary.oneTimeWardsReserveDonation}
            />
          ) : null}
          {summary.wardsReserveSubscription ? (
            <PropertiesTableRow
              caption="Подписка на резерв подопечных"
              value={summary.wardsReserveSubscription}
            />
          ) : null}
          {summary.wardsReserveSubscriptionCharge ? (
            <PropertiesTableRow
              caption="Списание подписки на резерв подопечных"
              value={summary.wardsReserveSubscription}
            />
          ) : null}
          {summary.wardSubscription ? (
            <PropertiesTableRow caption="Подписка на подопечных" value={summary.wardSubscription} />
          ) : null}
          {summary.wardSubscriptionCharge ? (
            <PropertiesTableRow
              caption="Списание подписки на подопечных"
              value={summary.wardSubscriptionCharge}
            />
          ) : null}
          {summary.commission ? (
            <PropertiesTableRow caption="Комиссия проекта" value={summary.commission} />
          ) : null}
        </PropertiesTable>
      </Box>
    </>
  );
};
