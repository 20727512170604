import React from 'react';
import { Typography } from '@material-ui/core';
import { DataGrid } from 'components';
import { LimitedLiabilityCompanyReport } from 'schema';
import { CompanyReportsProps } from './types';
import { Loading } from 'ui/Loading';

export const CompanyReports = ({ reports, gridProps, loading }: CompanyReportsProps) => {
  if (loading) {
    return <Loading/>;
  }

  if (!reports.length) {
    return <Typography>Нет отчетов</Typography>;
  }

  return <DataGrid<LimitedLiabilityCompanyReport> {...gridProps} />;
};
