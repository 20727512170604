import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Grid, MenuItem, Select } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FoundationEventFilterInput } from 'schema';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(1),
    },
    search: {
      paddingRight: theme.spacing(2),
    },
    selectLabel: {
      margin: '10px',
    },
    resetButton: {
      display: 'block',
      marginLeft: 'auto',
    },
  })
);

interface EventSearchProps {
  onSearch: (input: FoundationEventFilterInput | undefined) => void;
}

export const EventSearch = (props: EventSearchProps) => {
  const { onSearch } = props;
  const classes = useStyles();

  const [eventsStatus, setEventsStatus] = useState<string | number>('');

  const getWhere = useCallback(() => {
    const where: FoundationEventFilterInput = {};
    const date = new Date().toISOString();

    if (eventsStatus === '') {
      where.closed = { timestamp: { nlte: date } };
      where.cancelled = { exists: false };
    }

    if (eventsStatus === 0) {
      where.closed = { timestamp: { lte: date } };
    }

    if (!Object.keys(where).length) {
      return;
    }

    return where;
  }, [eventsStatus]);

  useEffect(() => {
    onSearch(getWhere());
  }, [onSearch, getWhere]);

  const handleOnReset = useCallback(() => {
    setEventsStatus('');
  }, []);

  return (
    <div className={classes.root}>
      <Grid container spacing={4} alignItems="center">
        <Grid item lg={3} md={3} xl={3} xs={12}>
          <Box display="flex" alignItems="center">
            <h4 className={classes.selectLabel}>Статус:</h4>
            <Select
              value={eventsStatus}
              displayEmpty
              variant={'outlined'}
              onChange={(e) => setEventsStatus(e.target.value as string | number)}
            >
              <MenuItem value="">Активные</MenuItem>
              <MenuItem value={0}>Завершённые</MenuItem>
              <MenuItem value={1}>Все</MenuItem>
            </Select>
          </Box>
        </Grid>
        <Grid item lg={9} md={12} xl={9} xs={12}>
          <Button className={classes.resetButton} variant="contained" onClick={handleOnReset}>
            Сбросить фильтры
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
