import React from 'react';
import { Backdrop, createStyles, Fade, makeStyles, Modal, Theme } from '@material-ui/core';
import { UpdateOrderCategoriesForm } from './UpdateOrderCategoriesForm';
import { useCancelRoute } from '../../../../hooks';
import { CategoryPropsFragment } from '../../../../schema';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    form: {
      backgroundColor: theme.palette.background.paper,
      border: '0px',
      borderRadius: '2px',
      boxShadow: theme.shadows[5],
    },
  })
);

type UpdateOrderCategoriesPageProps = {
  categories?: CategoryPropsFragment[] | null;
};

const UpdateOrderCategoriesPage = ({ categories }: UpdateOrderCategoriesPageProps) => {
  const classes = useStyles();

  const { onCancel } = useCancelRoute('/categories');

  return (
    <Modal
      open={true}
      onClose={onCancel}
      aria-labelledby="Добавление категории"
      aria-describedby="Используйте эту форму для создания новой категории"
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={true}>
        <div className={classes.form}>
          <UpdateOrderCategoriesForm onClose={onCancel} categories={categories} />
        </div>
      </Fade>
    </Modal>
  );
};

export default UpdateOrderCategoriesPage;
