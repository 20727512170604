import React, { useCallback } from 'react';
import { Button, Grid } from '@material-ui/core';
import { FieldArray } from 'react-final-form-arrays';
import { AutoFocusedForm, FilesUploadField } from 'components';
import { Document } from 'schema';

export interface DocumentsProps {
  documents: Document[];
  onSave: (documents: Document[]) => void;
}

export const ManageDocumentsForm = (props: DocumentsProps) => {
  const { onSave } = props;
  const onSubmit = useCallback(
    (values: DocumentsProps) => {
      onSave(values.documents);
    },
    [onSave]
  );
  return (
    <AutoFocusedForm
      onSubmit={onSubmit}
      initialValues={props}
      render={({ handleSubmit, pristine }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid item lg={12} md={12} xl={12} xs={12}>
                <FieldArray<Document>
                  name="documents"
                  multiple={true}
                  component={FilesUploadField}
                />
              </Grid>
            </Grid>
            <Button
              color="primary"
              disabled={pristine}
              size="medium"
              type="submit"
              variant="contained"
            >
              Сохранить
            </Button>
          </form>
        );
      }}
    />
  );
};
