import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    link: {
      color: theme.palette.primary.dark,
    },
    form: {
      padding: '15px',
      backgroundColor: theme.palette.background.paper,
      border: '0px',
      borderRadius: '2px',
      boxShadow: theme.shadows[5],
    },
    root: {
      minWidth: 500,
      maxWidth: 1000,
    },
    images: { display: 'flex', flexDirection: 'column', minWidth: 320, gap: '15px' },
    header: {
      fontWeight: 'bolder',
    },
    content: {
      display: 'flex',
      margin: '10px',
      maxHeight: 650,
    },
    actions: {
      justifyContent: 'flex-end',
    },
    accessButton: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.success.dark,
      '&:hover': {
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.success.light,
      },
    },
  })
);
