import React, { FC, ReactElement } from 'react';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useStyles } from './useStyles';
import { AmountLabel } from 'components';

export const PropertiesTable: FC = ({ children }) => {
  return (
    <Table>
      <TableBody>{children}</TableBody>
    </Table>
  );
};

interface PropertiesTableRowProps {
  caption: string;
  value?: string | number | null | ReactElement;
  isAmount?: boolean;
  link?: string;
}

export const PropertiesTableRow: FC<PropertiesTableRowProps> = ({
  caption,
  value,
  isAmount,
  link,
}) => {
  const classes = useStyles();

  const title =
    link !== undefined ? (
      <Link to={link}>
        <TableCell>{caption}</TableCell>
      </Link>
    ) : (
      <TableCell>{caption}</TableCell>
    );

  return (
    <TableRow className={classes.root}>
      {title}
      <TableCell>
        {isAmount && typeof value === 'number' ? (
          <AmountLabel value={value || 0} showCurrency={true} />
        ) : (
          value
        )}
      </TableCell>
    </TableRow>
  );
};
