import React from 'react';
import { createStyles, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { ScreenStoryPropsFragment } from '../../../../../schema';
import { useParams } from 'react-router-dom';
import { useScreensStorage } from '../../useScreensStorage';
import { useIsEditStoryStorage } from '../../useIsEditStoryStorage';
import { makeStyles } from '@material-ui/styles';

type DeleteScreenStoryButtonProps = {
  setScreenCards: (screen: ScreenStoryPropsFragment[]) => void;
  screenCards: ScreenStoryPropsFragment[];
  cardIndex: number;
  setIsStoryEdited: (isEdited: boolean) => void;
};

export const DeleteScreenStoryButton = ({
  screenCards,
  setScreenCards,
  cardIndex,
  setIsStoryEdited,
}: DeleteScreenStoryButtonProps) => {
  const { storyId } = useParams<{ storyId: string }>();
  const { updateScreensStorageHistory } = useScreensStorage();
  const { updateIsEditStoryStorageHistory } = useIsEditStoryStorage({ id: storyId });

  const useStyles = makeStyles(() =>
    createStyles({
      deleteButton: {
        color: screenCards[cardIndex].image ? 'white' : '',
        zIndex: 1,
      },
    })
  );
  const classes = useStyles();

  const removeCard = (index: number) => {
    const updatedCards = [...screenCards];
    updatedCards.splice(index, 1);
    updateScreensStorageHistory(updatedCards);
    setScreenCards(updatedCards);
    setIsStoryEdited(true);
    updateIsEditStoryStorageHistory(true);
  };

  return (
    <IconButton className={classes.deleteButton} onClick={() => removeCard(cardIndex)}>
      <DeleteIcon />
    </IconButton>
  );
};
