import React from 'react';
import { Redirect } from 'react-router-dom';
import { useMeContext } from 'components';
import { ForgotPasswordForm } from './ForgotPasswordForm';

export const ForgotPasswordPage = () => {
  const { user } = useMeContext();

  if (user !== undefined) {
    return <Redirect to="/" />;
  }

  return <ForgotPasswordForm />;
};
