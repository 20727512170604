import React from 'react';
import { Modal, makeStyles, Theme, createStyles, Backdrop, Fade } from '@material-ui/core';

import { useCancelRoute } from 'hooks';
import { DeleteCategoryForm } from './DeleteCategoryForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    form: {
      backgroundColor: theme.palette.background.paper,
      border: '0px',
      borderRadius: '2px',
      boxShadow: theme.shadows[5],
    },
  })
);

export const DeleteCategoryPage = () => {
  const classes = useStyles();

  const { onCancel } = useCancelRoute('/categories');

  return (
    <Modal
      open={true}
      onClose={onCancel}
      aria-labelledby="Удаление категории"
      aria-describedby="Используйте эту форму для удаления категории"
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={true}>
        <div className={classes.form}>
          <DeleteCategoryForm onClose={onCancel} />
        </div>
      </Fade>
    </Modal>
  );
};
