import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    buttons: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '10px',
    },
    avatar: {
      width: theme.spacing(14),
      height: theme.spacing(14),
      marginBottom: theme.spacing(2),
    },
    divider: {
      borderBottom: theme.spacing(1),
      width: '100%',
      marginBottom: theme.spacing(1),
    },
    headerLabel: {
      fontWeight: 'bolder',
      marginBottom: '10px',
    },
    active: {
      marginBottom: '12px',
      marginTop: '12px',
      color: 'green',
    },
    noActive: {
      marginBottom: '12px',
      marginTop: '12px',
      color: 'red',
    },
  })
);
