import {
  Document,
  RegularNeedPeriod,
  useUpdateRegularNeedPeriodDocumentsMutation,
  WardByIdDocument,
} from '../../../../../schema';
import { useParams } from 'react-router-dom';
import { ManageDocumentsForm, useMeContext } from '../../../../../components';
import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import { Loading } from '../../../../../ui/Loading';

type Props = {
  period: RegularNeedPeriod;
};

export const RegularNeedPeriodDocuments = ({ period }: Props) => {
  const { id, periodId } = useParams<{ id: string; periodId: string }>();
  const { documents } = period;
  const [
    updateRegularNeedPeriodDocuments,
    { loading, error },
  ] = useUpdateRegularNeedPeriodDocumentsMutation();
  const { user } = useMeContext();

  const onSave = useCallback(
    async (documents: Document[]) => {
      const files = documents.map((document) => {
        const {
          file: { id },
          title,
        } = document;
        return { id, title };
      });

      try {
        await updateRegularNeedPeriodDocuments({
          variables: {
            input: {
              periodId,
              documents: files,
            },
          },
          refetchQueries: [
            {
              query: WardByIdDocument,
              variables: { id, permission: user?.permissions?.wards.manageNeeds },
            },
          ],
        });
      } catch {
        error && toast.error('Ошибка загрузки документов');
      }
    },
    [updateRegularNeedPeriodDocuments, periodId, id, user, error]
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <ManageDocumentsForm documents={documents} onSave={onSave} />
    </>
  );
};
