import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { createStyles, makeStyles, Paper } from '@material-ui/core';
import { DragHandleCard } from '../../../stories/StoryPage/components/ScreenStoryCard/DragHandleCard';

const useStyles = makeStyles(() =>
  createStyles({
    categoryContainer: {
      position: 'relative',
      paddingLeft: '40px',
      padding: '10px',
      width: '100%',
    },
  })
);

type SortableCategoryProps = {
  name: string;
};

export const SortableCategory = SortableElement(({ name }: SortableCategoryProps) => {
  const styles = useStyles();

  return (
    <Paper className={styles.categoryContainer} elevation={3}>
      <DragHandleCard />
      {name}
    </Paper>
  );
});
