import React, { useCallback, useEffect, useState } from 'react';
import { Breadcrumbs, createStyles, Link, makeStyles, Typography } from '@material-ui/core';
import {
  Story,
  StoryBlockByIdDocument,
  useChangeStoriesOrderMutation,
  useStoryBlockByIdQuery,
} from '../../../../schema';
import { Loading } from '../../../../ui/Loading';
import { Route, useHistory, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CardPageGrid } from '../../../../ui/CardGrid';
import { AddCard } from '../../components/AddCard';
import { AddStoryModal } from '../AddStoryModal';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { StoryCard } from '../components/StoryCard';
import { arrayMove, SortableContainer } from 'react-sortable-hoc';
import { DeleteStoryModal } from '../DeleteStoryModal';

const useStyles = makeStyles(() =>
  createStyles({
    sortableContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '15px',
    },

    content: {
      cursor: 'pointer',
      height: '80%',
    },
  })
);

export const StoriesInBlockPage = () => {
  const { blockId } = useParams<{ blockId: string }>();
  const { data, loading, error } = useStoryBlockByIdQuery({ variables: { id: blockId } });
  const [storiesInBlock, setStoriesInBlock] = useState<Story[] | undefined>([]);
  const classes = useStyles();
  const { pathname } = useLocation();
  const history = useHistory();
  const [changeStoriesOrder, { error: changeStoriesOrderError }] = useChangeStoriesOrderMutation();

  useEffect(() => {
    if (error) {
      toast.error('Ошибка загрузки блоков историй');
    }
  }, [error]);

  useEffect(() => {
    const storiesByBlock = data?.storyBlockById?.stories?.nodes
      ?.slice()
      .map((story) => story) as Story[];

    const sortedStoriesInBlock = storiesByBlock?.sort((a, b) => {
      if (a.order === 0 && b.order !== 0) {
        return -1;
      }
      if (a.order !== 0 && b.order === 0) {
        return 1;
      }
      if (a.order === 0 && b.order === 0) {
        const aTimestamp = new Date(a.created.timestamp).getTime();
        const bTimestamp = new Date(b.created.timestamp).getTime();
        return bTimestamp - aTimestamp;
      }
      return 0;
    });
    setStoriesInBlock(sortedStoriesInBlock);
  }, [data]);

  const handleClickCard = useCallback(
    (storyId: string) => {
      history.push(`/block/${blockId}/story/${storyId}`);
    },
    [blockId, history]
  );

  const handleClickDeleteButton = useCallback(
    (storyId: string) => {
      history.push(`/block/${blockId}/stories/${storyId}/delete`);
    },
    [blockId, history]
  );

  const onSortEnd = async ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    const stories = data!.storyBlockById!.stories!.nodes!.map(({ id }) => id);
    const storiesIds = arrayMove(stories, oldIndex, newIndex);
    const newStoriesInBlock = arrayMove(storiesInBlock!, oldIndex, newIndex);
    setStoriesInBlock(newStoriesInBlock);
    try {
      await changeStoriesOrder({
        variables: {
          input: {
            blockId,
            storiesIds,
          },
        },
        refetchQueries: [{ query: StoryBlockByIdDocument, variables: { id: blockId } }],
      });
    } catch (error) {
      console.error(error);
      toast.error('Ошибка при изменении порядка историй');
    }
  };

  useEffect(() => {
    changeStoriesOrderError && toast.error('Ошибка при изменении порядка историй');
  }, [changeStoriesOrderError, data]);

  if (loading) {
    return <Loading />;
  }

  const SortableCardList = SortableContainer(({ stories }: { stories: Story[] | undefined }) => {
    return (
      <div className={classes.sortableContainer}>
        {stories &&
          stories!.map((story, index) => (
            <StoryCard
              key={story.id}
              index={index}
              id={story.id}
              image={story.screens.find(({ image }) => image)?.image}
              title={story.title}
              published={story.published}
              handleClickCard={handleClickCard}
              handleClickDeleteButton={handleClickDeleteButton}
            />
          ))}
        <AddCard path={`${pathname}/add`} text={'Добавить историю'} />
      </div>
    );
  });

  return (
    <>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
        <Link color="inherit" href={`/stories`}>
          {`Блоки историй`}
        </Link>
        <Typography color="textPrimary">
          {`${data!.storyBlockById?.title} (${data?.storyBlockById?.label})`}
        </Typography>
      </Breadcrumbs>
      <CardPageGrid title="Истории блока">
        <SortableCardList
          stories={storiesInBlock}
          onSortEnd={onSortEnd}
          axis="xy"
          useDragHandle={true}
        />
      </CardPageGrid>
      <Route exact path={`/block/:blockId/stories/add`} component={AddStoryModal} />
      <Route exact path={`/block/:blockId/stories/:storyId/delete`} component={DeleteStoryModal} />
    </>
  );
};
