import React from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.dark,
      padding: theme.spacing(0, 3),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);

export const Logo = () => {
  const classes = useStyles();

  return (
    <Link to={'/'} className={classes.root}>
      <img src="/images/logo.svg" width={71} height={31} alt="Проект Помощь" />
    </Link>
  );
};
