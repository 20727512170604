import React, { useContext } from 'react';
import { IconButton, Popover, List, ListItem, ListItemText } from '@material-ui/core';
import { MoreVertOutlined } from '@material-ui/icons';
import { useApplicationsActions } from './useApplicationsActions';
import { usePopover } from 'hooks';
import { ApplicationsQueryContext, QueryVariablesContext } from './QueryContextProvider';
import { FoundationEventAttendance } from '../../../../../schema';

export interface ApplicationsActionsButtonProps {
  applicationId: string;
  attendance?: FoundationEventAttendance | null;
}

export const ApplicationsActionsButton = (props: ApplicationsActionsButtonProps) => {
  const { applicationId, attendance } = props;
  const { id, onClick, onClose, open, anchorEl } = usePopover(applicationId);
  const variables = useContext<ApplicationsQueryContext>(QueryVariablesContext);
  const {
    secondRole,
    primeRole,
    declineApplication,
    convertFoundation,
    loading,
  } = useApplicationsActions(applicationId, onClose, variables);

  return (
    <>
      <IconButton aria-describedby={id} aria-label="Действия" onClick={onClick}>
        <MoreVertOutlined />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List component="nav" aria-label="Действия над заявками">
          <ListItem button onClick={primeRole} disabled={loading}>
            <ListItemText primary="Выбрать как основного" />
          </ListItem>
          <ListItem button onClick={secondRole} disabled={loading}>
            <ListItemText primary="Выбрать как запасного" />
          </ListItem>
          <ListItem button onClick={declineApplication} disabled={loading}>
            <ListItemText primary="Отклонить" />
          </ListItem>
          <ListItem
            button
            onClick={convertFoundation}
            disabled={loading || attendance?.checkedInAt}
          >
            <ListItemText primary="Отметить как участника" />
          </ListItem>
        </List>
      </Popover>
    </>
  );
};
