import React, { useCallback, useEffect } from 'react';
import {
  AchievementByIdDocument,
  AchievementByIdQuery,
  AchievementsDocument,
  PublishAchievementInput,
  UnpublishAchievementInput,
  usePublishAchievementMutation,
  useUnPublishAchievementMutation,
} from '../../../../schema';
import { Loading } from '../../../../ui/Loading';
import { AutoFocusedForm } from '../../../../components';
import { Card, CardActions, CardContent, CardHeader, Divider, Typography } from '@material-ui/core';
import { MutableButton } from '../../../../ui/MutableButton';
import { RedButton } from '../../../../components/Buttons';
import { useStyles } from '../useStyles';
import { toast } from 'react-toastify';
import { FORM_ERROR, SubmissionErrors } from 'final-form';
import { useParams } from 'react-router-dom';

interface AwardPublishModalFormProps {
  onClose: () => void;
  achievement: AchievementByIdQuery | undefined;
}

export const AwardPublishModalForm = ({ onClose, achievement }: AwardPublishModalFormProps) => {
  const styles = useStyles();
  const { awardId } = useParams<{ awardId: string }>();
  const isPublishedAward = achievement?.achievementById?.published;

  const [
    publishAchievement,
    {
      data: publishAchievementData,
      loading: publishAchievemntLoading,
      error: publishAchievemntError,
    },
  ] = usePublishAchievementMutation();

  const [
    unpublishAchievement,
    {
      data: unpublishAchievementData,
      loading: unpublishAchievementLoading,
      error: unpublishAchievemntError,
    },
  ] = useUnPublishAchievementMutation();

  const onSubmit = useCallback(async () => {
    if (isPublishedAward === null) {
      const input: PublishAchievementInput = {
        id: awardId,
      };
      try {
        return await publishAchievement({
          variables: { input },
          refetchQueries: [
            {
              query: AchievementByIdDocument,
              variables: {
                id: awardId,
              },
            },
            {
              query: AchievementsDocument,
              variables: { first: 50, includeUnpublished: false },
            },
          ],
        });
      } catch {
        publishAchievemntError && toast.error('Ошибка при публикации награды');
        return { [FORM_ERROR]: 'Ошибка публикации награды' } as SubmissionErrors;
      }
    } else {
      const input: UnpublishAchievementInput = {
        id: awardId,
      };
      try {
        return await unpublishAchievement({
          variables: { input },
          refetchQueries: [
            {
              query: AchievementByIdDocument,
              variables: {
                id: awardId,
              },
            },
            {
              query: AchievementsDocument,
              variables: { first: 50, includeUnpublished: false },
            },
          ],
        });
      } catch {
        unpublishAchievemntError && toast.error('Ошибка при снятии с публикации награды');
        return { [FORM_ERROR]: 'Ошибка снятия публикации награды' } as SubmissionErrors;
      }
    }
  }, [
    awardId,
    isPublishedAward,
    publishAchievement,
    publishAchievemntError,
    unpublishAchievement,
    unpublishAchievemntError,
  ]);

  useEffect(() => {
    if (publishAchievementData !== undefined || unpublishAchievementData !== undefined) {
      onClose();
    }
  }, [publishAchievementData, unpublishAchievementData, onClose]);

  if (publishAchievemntLoading || unpublishAchievementLoading) {
    return <Loading />;
  }

  return (
    <AutoFocusedForm
      onSubmit={onSubmit}
      render={({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card className={styles.root}>
              <CardHeader
                title={isPublishedAward ? `Снять с публикации награду` : `Опубликовать награду`}
              />
              <Divider />
              <CardContent className={styles.content}>
                <Typography>
                  {isPublishedAward
                    ? `Вы действительно хотите снять с публикации награду ${achievement?.achievementById?.title}?`
                    : `Вы действительно хотите опубликовать награду ${achievement?.achievementById?.title}?`}
                </Typography>
              </CardContent>

              <CardActions className={styles.actions}>
                <MutableButton
                  loading={false}
                  className={styles.accessButton}
                  color="primary"
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  {isPublishedAward ? 'Снять с публикации' : 'Опубликовать'}
                </MutableButton>
                <RedButton onClick={onClose} size="medium" type="button" variant="contained">
                  Отменить
                </RedButton>
              </CardActions>
            </Card>
          </form>
        );
      }}
    />
  );
};
