import React from 'react';
import {
  IconButton,
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import { MoreVertOutlined } from '@material-ui/icons';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { useNeedActions } from './useNeedActions';
import { NeedInfoItemProps } from '../types';
import { usePopover } from 'hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row-reverse',
      '& button': {
        marginLeft: theme.spacing(1),
      },
    },
  })
);

export const NeedMenuList = (props: NeedInfoItemProps) => {
  const classes = useStyles();

  const { need, ward } = props;
  const { id, onClick, onClose, open, anchorEl } = usePopover(need.id);
  const { onEdit, loading } = useNeedActions(ward, need, onClose);

  return (
    <div className={classes.root}>
      <IconButton aria-describedby={id} aria-label="Действия" onClick={onClick}>
        <MoreVertOutlined />
      </IconButton>
      <Popover id={id} open={open} anchorEl={anchorEl} onClose={onClose}>
        <List component="nav" aria-label="Основный действия с потребностью">
          <ListItem button onClick={onEdit} disabled={loading}>
            <ListItemIcon>
              <EditOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Изменить" />
          </ListItem>
        </List>
      </Popover>
    </div>
  );
};
