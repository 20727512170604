import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme, Grid, createStyles } from '@material-ui/core';
import { LayoutProps } from 'components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.default,
      height: '100%',
    },
    grid: {
      height: '100%',
    },
    contentContainer: {},
    content: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    contentBody: {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
);

export const Minimal: FunctionComponent<LayoutProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.content} item>
          <div className={classes.content}>
            <div className={classes.contentBody}>{children}</div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
