import { useCallback, useEffect, useMemo } from 'react';
import {
  StoryBlockByIdQuery,
  StoryBlockDocument,
  UpdateStoryBlockInput,
  useUpdateStoryBlockMutation,
} from 'schema/serverTypes';

import { FORM_ERROR, SubmissionErrors } from 'final-form';
import { toast } from 'react-toastify';

type Props = {
  onClose: () => void;
  blockStory?: StoryBlockByIdQuery;
};

export const useEditBlockStoryModalForm = ({ blockStory, onClose }: Props) => {
  const [updateStoryBlock, { data, loading, error }] = useUpdateStoryBlockMutation();

  const initialValues = useMemo<UpdateStoryBlockInput>(() => {
    return {
      id: blockStory?.storyBlockById?.id || '',
      title: blockStory?.storyBlockById?.title || '',
    };
  }, [blockStory]);

  const onSubmit = useCallback(
    async (values: UpdateStoryBlockInput) => {
      const { title } = values;

      const input: UpdateStoryBlockInput = {
        title,
        id: blockStory!.storyBlockById!.id,
      };

      try {
        return await updateStoryBlock({
          variables: { input },
          refetchQueries: [
            {
              query: StoryBlockDocument,
            },
          ],
        });
      } catch {
        error && toast.error('Ошибка изменения блока историй');
        return { [FORM_ERROR]: 'Ошибка изменения' } as SubmissionErrors;
      }
    },
    [blockStory, updateStoryBlock, error]
  );

  useEffect(() => {
    if (data !== undefined) {
      onClose();
    }
  }, [data, onClose]);

  return { initialValues, onSubmit, loading };
};
