import { createRequired } from '../validation';
import { FormApi } from 'final-form';
import { useFormField } from './useFormField';

export function useRequiredField<T, K extends keyof T>(
  name: K,
  form: FormApi<T>,
  errorMessage: string | undefined = undefined
) {
  return useFormField(name, form, createRequired(errorMessage));
}
