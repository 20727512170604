import React, { useEffect, useState } from 'react';
import { LayoutPageGrid } from '../../../ui/LayoutGrid';
import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CardHeader,
  createStyles,
  Typography,
  Link,
} from '@material-ui/core';
import { StoryInfoCard } from './StoryInfoCard';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import { useStoryBlockByIdQuery, useStoryByIdQuery } from '../../../schema';
import { Loading } from '../../../ui/Loading';
import { toast } from 'react-toastify';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { ScreensStory } from './ScreensStory';
import { makeStyles } from '@material-ui/styles';

import { ScreensStorageProvider } from './useScreensStorage';
import { SubmitStoryScreensChangesButton } from './components/SubmitStoryScreensChangesButton';
import { useIsEditStoryStorage } from './useIsEditStoryStorage';
import { CancelStoryChangesButton } from './components/CancelStoryChangesButton';

export const useStyles = makeStyles(() =>
  createStyles({
    content: {
      display: 'flex',
      gap: '15px',
      flexWrap: 'wrap',
    },
    infoCardContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    actions: {
      display: 'flex',
      gap: '10px',
    },
  })
);
const StoryPage = () => {
  const [isStoryEdited, setIsStoryEdited] = useState<boolean>(false);
  const [screenCardsLoaded, setScreenCardsLoaded] = useState(false);
  const { pathname } = useLocation();
  const { storyId } = useParams<{ storyId: string }>();
  const { data, error, loading } = useStoryByIdQuery({ variables: { id: storyId } });
  const { blockId } = useParams<{ blockId: string }>();
  const { data: blockStoryData, loading: loadingBlockStory } = useStoryBlockByIdQuery({
    variables: { id: blockId },
  });
  const { isStoryEditedStorageHistory } = useIsEditStoryStorage({
    id: storyId,
  });
  const classes = useStyles();

  useEffect(() => {
    setIsStoryEdited(isStoryEditedStorageHistory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  if (loading || loadingBlockStory) {
    return <Loading />;
  }

  return (
    <ScreensStorageProvider id={storyId}>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
        <Link color="inherit" href={`/stories`}>
          {`Блоки историй`}
        </Link>
        <Link color="inherit" href={`/block/${blockId}/stories`}>
          {`${blockStoryData?.storyBlockById?.title} (${blockStoryData?.storyBlockById?.label})`}
        </Link>
        <Typography color="textPrimary">{`${data?.storyById?.title}`}</Typography>
      </Breadcrumbs>
      <LayoutPageGrid title={'История'}>
        <Card>
          <CardHeader title="Информация об истории" />
          <CardContent className={classes.infoCardContent}>
            <StoryInfoCard data={data} />
            <Button
              color="primary"
              variant="contained"
              component={RouterLink}
              to={`${pathname}/manage`}
            >
              Редактировать
            </Button>
          </CardContent>
        </Card>
        <Card>
          <CardHeader
            title="Экраны истории"
            action={
              isStoryEdited && (
                <div className={classes.actions}>
                  <SubmitStoryScreensChangesButton setIsStoryEdited={setIsStoryEdited} />
                  <CancelStoryChangesButton
                    setScreenCardsLoaded={setScreenCardsLoaded}
                    id={storyId}
                    setIsStoryEdited={setIsStoryEdited}
                  />
                </div>
              )
            }
          />
          <CardContent>
            <ScreensStory
              screenCardsLoaded={screenCardsLoaded}
              setScreenCardsLoaded={setScreenCardsLoaded}
              setIsStoryEdited={setIsStoryEdited}
              data={data}
            />
          </CardContent>
        </Card>
      </LayoutPageGrid>
    </ScreensStorageProvider>
  );
};

export default StoryPage;
