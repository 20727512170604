import React from 'react';
import { Card, CardContent, Typography, CardHeader, CardActions } from '@material-ui/core';
import { AmountLabel, TitledToolbar } from 'components';
import { CompanyReports, CreateReport, UpdateCompanyBalance } from './components';
import { useReports } from './useReports';
import { LayoutContentGrid } from 'ui/LayoutGrid';
import { InvisibleCard } from 'ui/InvisibleCard';

export const CompanyPage = () => {
  const { balance, reports, gridProps, loading } = useReports();

  return (
    <>
      <TitledToolbar title="ООО" />
      <LayoutContentGrid>
        <Card>
          <CardHeader title="Баланс" />
          <CardContent>
            <InvisibleCard>
              <CardContent>
                <Typography>
                  Баланс: <AmountLabel value={balance || 0} />
                </Typography>
              </CardContent>
              <CardActions>
                <CreateReport />
                <UpdateCompanyBalance />
              </CardActions>
            </InvisibleCard>
          </CardContent>
        </Card>
        <Card>
          <CardHeader title="Отчеты" />
          <CardContent>
            <CompanyReports reports={reports} gridProps={gridProps} loading={loading} />
          </CardContent>
        </Card>
      </LayoutContentGrid>
    </>
  );
};
