import React from 'react';
import { Switch, useParams } from 'react-router-dom';
import { SpecialProjectsPropsFragment, useSpecialProjectByIdQuery } from 'schema';
import { Loading } from 'ui/Loading';
import { toast } from 'react-toastify';
import { RouteWithLayout } from 'components';
import { Layout } from 'layouts';
import { SpecialProjectManagePage } from '../SpecialProjectManagePage';
import { EditSpecialProjectPage } from '../EditSpecialProjectPage';

export const SpecialProjectContainer = () => {
  const { id } = useParams();

  const { data, loading, error } = useSpecialProjectByIdQuery({
    variables: {
      id,
    },
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    toast.error('Произошла ошибка загрузки');
    return null;
  }

  const specialProject = data?.specialProjectById as SpecialProjectsPropsFragment;

  if (specialProject === null) {
    toast.error('Спецпроект не найден');
    return null;
  }

  return (
    <Switch>
      <RouteWithLayout
        path={'/special-projects/manage/:id/edit'}
        layout={Layout}
        component={EditSpecialProjectPage}
        specialProject={specialProject}
      />
      <RouteWithLayout
        path="/special-projects/manage/:id"
        component={SpecialProjectManagePage}
        specialProject={specialProject}
        layout={Layout}
      />
    </Switch>
  );
};
