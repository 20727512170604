import React, { useState } from 'react';
import {
  Button,
  Chip,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Paper,
  PaperProps,
  Theme,
} from '@material-ui/core';
import { FieldArrayRenderProps } from 'react-final-form-arrays';
import { Skill } from 'schema';
import { EditSkillsForm } from './EditSkillsForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'start',
      flexWrap: 'wrap',
      listStyle: 'none',
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    chip: {
      marginLeft: theme.spacing(0.5),
    },
    dialog: {
      margin: '10px 20px',
      padding: '10px',
    },
  })
);

interface ComponentProps {}

export interface EditSkillsFieldProps
  extends PaperProps,
    FieldArrayRenderProps<Skill, HTMLElement>,
    ComponentProps {}

export const EditSkillsField = (props: EditSkillsFieldProps) => {
  const { fields, variant = 'outlined' } = props;
  const styles = useStyles();
  const [open, setOpen] = useState<boolean>(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Paper variant={variant} className={styles.root}>
        {fields.value.map((skill, index) => {
          return (
            <Chip
              key={skill.id}
              label={skill.title}
              onDelete={() => fields.remove(index)}
              className={styles.chip}
            />
          );
        })}
      </Paper>
      <div>
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          Добавить навык
        </Button>
        <Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Виберете навык</DialogTitle>
          <DialogContent className={styles.dialog}>
            <EditSkillsForm
              skills={fields.value}
              openForm={setOpen}
              handleClose={handleClose}
              skillsPush={fields.push}
            />
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};
