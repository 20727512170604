import React from 'react';
import { WardNeedStatus, Breadcrumbs } from './components';
import { WardProps } from '../types';
import { useParams, Redirect } from 'react-router-dom';
import { LayoutPageGrid } from 'ui/LayoutGrid/LayoutGrid';
import { usePermissionPage } from 'hooks';
import { useMeContext } from 'components';
import { isOneTimeNeed } from '../../../schema';
import OneTimeNeedWardPage from './components/OneTimeNeedWardPage/OneTimeNeedWardPage';

export const WardNeedPage = (props: WardProps) => {
  const { ward } = props;
  const { needs } = ward;
  const { needId } = useParams<{ needId: string }>();
  const need = needs.find((x) => x.id === needId);
  const { user } = useMeContext();

  usePermissionPage('/wards', user?.permissions.wards.edit);

  if (need === undefined) {
    return <Redirect to="/404" />;
  }

  return (
    <>
      <Breadcrumbs ward={ward} need={need} />
      <LayoutPageGrid title="Потребность подопечного">
        {isOneTimeNeed(need) ? (
          <OneTimeNeedWardPage need={need} />
        ) : (
          <WardNeedStatus ward={ward} need={need} />
        )}
      </LayoutPageGrid>
    </>
  );
};
