import React, { useCallback } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { useMarkWardAsNotRequiringImmediateHelpMutation } from 'schema/serverTypes';
import { MutableButton } from 'ui/MutableButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      '&:hover': {
        borderColor: theme.palette.primary.main,
      },
    },
  })
);

export const NotRequiringHelpButton = (props: { id: string }) => {
  const classes = useStyles();
  const { id } = props;
  const [markAsNotRequiringHelp, { loading }] = useMarkWardAsNotRequiringImmediateHelpMutation({
    variables: {
      input: {
        id,
      },
    },
  });
  const onClick = useCallback(() => {
    markAsNotRequiringHelp();
  }, [markAsNotRequiringHelp]);

  return (
    <MutableButton
      loading={loading}
      className={classes.button}
      variant="outlined"
      disabled={loading}
      onClick={onClick}
    >
      Отменить срочную помощь
    </MutableButton>
  );
};
