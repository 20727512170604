import React from 'react';
import {
  Grid,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Button,
  CardActions,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import { AutoFocusedForm, CheckboxField, moreThanZero, notEmpty, TextField } from 'components';
import { Field } from 'react-final-form';
import { useCityForm } from './useCityForm';
import { Alert } from '@material-ui/lab/';
import { RedButton } from 'components/Buttons';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minWidth: 500,
    },
    header: {
      fontWeight: 'bolder',
    },
    actions: {
      justifyContent: 'flex-end',
    },
  })
);

export interface CreateCityFormProps {
  onClose: () => void;
}

export const CreateCityForm = ({ onClose }: CreateCityFormProps) => {
  const styles = useStyles();

  const { onSubmit, initialValues } = useCityForm(onClose);
  return (
    <AutoFocusedForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      subscription={{
        submitError: true,
      }}
      render={({ handleSubmit, valid, submitError }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card className={styles.root}>
              <CardHeader title="Добавление города" />
              <Divider />
              <CardContent>
                <Grid container spacing={4}>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Field
                      name="name"
                      label="Наименование города"
                      component={TextField}
                      validate={notEmpty}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item lg={6} md={6} xl={6} xs={6}>
                    <CheckboxField label={'Показывать по умолчанию?'} name="default" />
                  </Grid>
                  <Grid item lg={5} md={5} xl={5} xs={5}>
                    <Field
                      name="order"
                      label="Порядок показа города в списке"
                      component={TextField}
                      validate={moreThanZero}
                    />
                  </Grid>
                </Grid>

                {submitError && <Alert severity="error">{submitError}</Alert>}
              </CardContent>
              <CardActions className={styles.actions}>
                <Button
                  color="primary"
                  disabled={!valid}
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  Добавить
                </Button>
                <RedButton onClick={onClose} size="medium" type="button" variant="contained">
                  Отменить
                </RedButton>
              </CardActions>
            </Card>
          </form>
        );
      }}
    />
  );
};
