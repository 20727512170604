import React from 'react';
import { EditFoundationProps } from '../types';
import { AddressField, AutoFocusedForm, PhotoField, required, TextField } from 'components';
import { Card, CardActions, CardContent, Grid, Theme, Typography } from '@material-ui/core';
import { Field } from 'react-final-form';
import { MutableButton } from 'ui/MutableButton';
import { RedButton } from 'components/Buttons';
import { makeStyles } from '@material-ui/styles';
import { useAddOrEditFoundationForm } from './useAddOrEditFoundationForm';
import { useBackRoute } from 'hooks';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  container: {
    padding: '10px',
    marginBottom: '10px',
  },
  header: {
    fontWeight: 'bolder',
    marginBottom: '10px',
    marginTop: '20px',
  },
  actions: {
    justifyContent: 'flex-start',
  },
  accessButton: {
    marginLeft: '15px',
    marginRight: '15px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.success.dark,
    '&:hover': {
      color: theme.palette.success.dark,
      backgroundColor: theme.palette.success.light,
    },
  },
}));

export const AddOrEditFoundationForm = ({ foundation }: EditFoundationProps) => {
  const styles = useStyles();
  const { onCancel } = useBackRoute();

  const { initialValues, onSubmit, loading } = useAddOrEditFoundationForm(foundation);

  return (
    <AutoFocusedForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      subscription={{
        submitError: true,
      }}
      render={({ handleSubmit, pristine }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card className={styles.container}>
              <CardContent>
                <Typography variant="h5" component="h5" className={styles.header}>
                  Логотип
                </Typography>
                <Grid container spacing={4}>
                  <Grid item lg={3} md={4} xl={3} xs={12}>
                    <Field name="logoId" component={PhotoField} url={foundation?.logo?.url} />
                  </Grid>
                  <Grid item lg={9} md={8} xl={9} xs={12}>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <Field
                          name="name"
                          label="Название фонда"
                          component={TextField}
                          validate={required}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="description"
                          label="Описание фонда"
                          component={TextField}
                          validate={required}
                          multiline
                          rows={5}
                          rowsMax={50}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="address"
                          component={AddressField}
                          label="Город, улица, дом *"
                          needDetermineCoordinates="hasCoordinates"
                          validate={required}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions className={styles.actions}>
                <MutableButton
                  className={styles.accessButton}
                  loading={loading}
                  disabled={pristine}
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  {foundation ? 'Сохранить' : 'Создать'}
                </MutableButton>
                <RedButton
                  color="primary"
                  size="medium"
                  type="button"
                  variant="contained"
                  onClick={onCancel}
                >
                  Отменить
                </RedButton>
              </CardActions>
            </Card>
          </form>
        );
      }}
    />
  );
};
