import React from 'react';
import { useStyles } from '../SpecialProjectManagePage/useStyles';
import { useBackRoute } from '../../../hooks';
import { Backdrop, Fade, Modal } from '@material-ui/core';
import { SpecialProjectsPropsFragment } from '../../../schema';
import { OrderSpecialProjectModalForm } from './OrderSpecialProjectModalForm';

type Props = {
  specialProjects: SpecialProjectsPropsFragment[];
};

export const OrderSpecialProjectsModal = ({ specialProjects }: Props) => {
  const styles = useStyles();

  const { onCancel } = useBackRoute();

  return (
    <Modal
      open={true}
      onClose={onCancel}
      aria-labelledby="Изменить порядок спецпроектов"
      aria-describedby="Используйте эту форму для изменения порядка спецпроектов"
      className={styles.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={true}>
        <OrderSpecialProjectModalForm specialProjects={specialProjects} onClose={onCancel} />
      </Fade>
    </Modal>
  );
};
