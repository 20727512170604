import React, { useCallback } from 'react';
import { colors, createStyles, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import { DeleteForeverOutlined } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { shortDescription } from 'helpers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '5px',
      padding: '10px',
      width: '190px',
      height: '200px',
      cursor: 'pointer',
      outline: 'none',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      '&:hover': {
        backgroundColor: theme.palette.background.default,
      },
      '&:active': {
        backgroundColor: colors.grey[100],
      },
    },
    wrapper: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    removeIcon: {
      position: 'absolute',
      top: theme.spacing(0.8),
      right: theme.spacing(0.6),
      '&:hover': {
        color: theme.palette.error.main,
      },
    },
  })
);

interface RequirementsCardProps {
  title: string;
  quantity: string | number;
  description?: string;
  skillId: string;
  index: number;
  removeCard: (e: React.MouseEvent<SVGSVGElement>, index: number) => void;
}

export const RequirementsCard = (props: RequirementsCardProps) => {
  const { description, quantity, title, skillId, index, removeCard } = props;
  const history = useHistory();
  const location = useLocation();

  const handleAddRequirement = useCallback(() => {
    history.push(`${location.pathname}/requirement/${skillId}/edit`);
  }, [history, location, skillId]);

  const styles = useStyles();
  return (
    <Paper className={styles.root} onClick={handleAddRequirement}>
      <DeleteForeverOutlined className={styles.removeIcon} onClick={(e) => removeCard(e, index)} />
      <div className={styles.wrapper}>
        <Typography variant="h5" align="center">
          {title}
        </Typography>
        <Typography color="error" align="center">
          Количество: {quantity !== 0 ? quantity : 'Без ограничений'}
        </Typography>
        <Typography align="center">{shortDescription(description, 50)}</Typography>
      </div>
    </Paper>
  );
};
