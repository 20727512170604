import React, { useCallback } from 'react';
import { KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers';
import { FieldRenderProps } from 'react-final-form-hooks';
import type { Moment } from 'moment';

export type DateFieldProps = Omit<KeyboardDatePickerProps, 'value' | 'onChange'> &
  FieldRenderProps & { returnTime: boolean };

export const convertDateToISOString = (
  date: Moment | null,
  returnTime: boolean,
  isUtc: boolean = true
) => {
  if (!date) {
    return null;
  }
  const dateString: string = date.toString();
  if (Date.parse(dateString)) {
    if (!returnTime) {
      return date.format('YYYY-MM-DD');
    }

    return date.utc(isUtc).toISOString();
  }
  return null;
};

export const DateField = (props: DateFieldProps) => {
  const {
    input,
    meta,
    invalidDateMessage = 'Неверный формат даты',
    format = 'DD.MM.YYYY',
    variant = 'inline',
    inputVariant = 'outlined',
    autoOk = true,
    returnTime = true,
    ...rest
  } = props;

  const handleOnChange = useCallback(
    (date: Moment | null) => {
      date?.utc(true);
      input.onChange(convertDateToISOString(date, returnTime));
    },
    [input, returnTime]
  );

  const getVisibleValue = (value: string) => {
    const valueDate = new Date(value);
    return new Date(valueDate.getTime() + valueDate.getTimezoneOffset() * 60000);
  };

  const value = input.value ? getVisibleValue(input.value) : null;

  return (
    <KeyboardDatePicker
      {...rest}
      {...input}
      variant={variant}
      inputVariant={inputVariant}
      onChange={handleOnChange}
      value={value}
      format={format}
      invalidDateMessage={invalidDateMessage}
      autoOk={autoOk}
    />
  );
};
