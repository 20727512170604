import { useCallback, useEffect } from 'react';
import {
  BenefactorStatusByIdDocument,
  BenefactorStatusByIdQuery,
  UpdateBenefactorStatusInput,
  useUpdateBenefactorStatusMutation,
} from 'schema/serverTypes';

import { FORM_ERROR, SubmissionErrors } from 'final-form';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

type UseAwardEditModalFormProps = {
  onClose: () => void;
  status: BenefactorStatusByIdQuery | undefined;
};

export const useBenefactorStatusEditModalForm = ({
  onClose,
  status,
}: UseAwardEditModalFormProps) => {
  const initialValues: UpdateBenefactorStatusInput = {
    id: status?.benefactorStatusById?.id,
    title: status?.benefactorStatusById?.title ?? '',
    imageId: status?.benefactorStatusById?.image.id ?? '',
    storyId: status?.benefactorStatusById?.story?.id,
    description: status?.benefactorStatusById?.description ?? '',
  };

  const { statusId } = useParams<{ statusId: string }>();

  const [updateBenefactorMutation, { data, loading, error }] = useUpdateBenefactorStatusMutation();

  const onSubmit = useCallback(
    async (values: UpdateBenefactorStatusInput) => {
      const input = values;

      try {
        return await updateBenefactorMutation({
          variables: { input },
          refetchQueries: [
            {
              query: BenefactorStatusByIdDocument,
              variables: { id: statusId },
            },
          ],
        });
      } catch {
        error && toast.error('Ошибка обновления статуса');
        return { [FORM_ERROR]: 'Ошибка обновления' } as SubmissionErrors;
      }
    },
    [updateBenefactorMutation, statusId, error]
  );

  useEffect(() => {
    if (data !== undefined) {
      onClose();
    }
  }, [data, onClose]);

  return {
    initialValues,
    onSubmit,
    loading,
  };
};
