import { useState, useCallback } from 'react';
import { useForm, FormConfig } from 'react-final-form-hooks';
import { toast } from 'react-toastify';
import { useRequiredField } from 'components';
import { useResetPasswordMutation, ResetPasswordInput } from 'schema/serverTypes';
import { useHistory } from 'react-router-dom';

type ResetPasswordFormConfig = FormConfig<ResetPasswordInput>;

export const useResetPasswordForm = (code: string) => {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const [resetPassword, { loading }] = useResetPasswordMutation({
    errorPolicy: 'ignore',
    onError: () => {
      setOpen(true);
    },
  });

  const onSubmit = useCallback(
    async (input: ResetPasswordInput) => {
      const response = await resetPassword({ variables: { input } });

      if (response === undefined) {
        return;
      }

      const { data, errors } = response;

      if (errors && errors.length > 0) {
        return;
      }

      if (data?.resetPassword.errors) {
        toast.error(`${data?.resetPassword.errors?.map((error) => error.message)}`, {
          position: 'top-center',
        });
        return;
      }

      if (data?.resetPassword.success) {
        history.push('/login');
      }
    },
    [resetPassword, history]
  );

  const { form, handleSubmit, submitting, pristine, hasValidationErrors } = useForm({
    onSubmit,
    initialValues: {
      code,
      email: '',
      password: '',
      confirmPassword: '',
    },
  } as ResetPasswordFormConfig);

  const email = useRequiredField('email', form);
  const password = useRequiredField('password', form);
  const confirmPassword = useRequiredField('confirmPassword', form);

  return {
    handleClose,
    fields: {
      email,
      password,
      confirmPassword,
    },
    handleSubmit,
    disabled: loading || submitting || pristine || hasValidationErrors,
    open,
  };
};
