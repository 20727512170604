import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const usePermissionPage = (path: string, isAllowed: boolean | undefined) => {
  const history = useHistory();

  useEffect(() => {
    if (!isAllowed) {
      history.replace(path);
    }
  }, [isAllowed, history, path]);
};
