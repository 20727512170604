import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { UniversalNeed, Ward } from 'schema';

export const useNeedActions = (owner: Ward, need: UniversalNeed, close: () => void) => {
  const history = useHistory();

  const onEdit = useCallback(() => {
    close();
    history.push(`/wards/manage/${owner.id}/needs/${need.id}/edit`);
  }, [close, history, owner, need.id]);
  const loading = false;

  return {
    onEdit,
    loading,
  };
};
