import { useCallback, useContext, useEffect } from 'react';
import {
  AddBroadcastNotificationInput,
  BroadcastNotificationsDocument,
  useAddBroadcastNotificationMutation,
} from 'schema/serverTypes';
import {
  BroadcastNotificationsQueryContext,
  QueryVariablesContext,
} from '../../QueryContextProvider';
import { FORM_ERROR, SubmissionErrors } from 'final-form';
import { TypeRecipient } from './BroadcastRecipientField';

const initialValues: AddBroadcastNotificationInput = {
  recipients: null,
  title: '',
  text: '',
  buttonText: '',
  buttonUrl: '',
  images: null,
  fileWithListOfRecipients: null,
};

type UseNotificationProps = {
  onClose: () => void;
  typeRecipientList: string;
};
export const useNotificationForm = ({ onClose, typeRecipientList }: UseNotificationProps) => {
  const [createNotificationMutation, { data, loading }] = useAddBroadcastNotificationMutation();

  const variables = useContext<BroadcastNotificationsQueryContext>(QueryVariablesContext);

  const onSubmit = useCallback(
    (values: AddBroadcastNotificationInput) => {
      const {
        recipients,
        title,
        text,
        buttonText,
        fileWithListOfRecipients,
        buttonUrl,
        images,
      } = values;
      const input: AddBroadcastNotificationInput = {
        recipients: typeRecipientList === TypeRecipient.Condition ? recipients : null,
        images,
        buttonUrl,
        buttonText,
        fileWithListOfRecipients:
          typeRecipientList === TypeRecipient.FromFile ? fileWithListOfRecipients : null,
        title,
        text,
      };
      return createNotificationMutation({
        variables: { input },
        refetchQueries: [
          {
            query: BroadcastNotificationsDocument,
            variables,
          },
        ],
      }).catch(() => {
        return { [FORM_ERROR]: 'Ошибка добавления' } as SubmissionErrors;
      });
    },
    [createNotificationMutation, variables, typeRecipientList]
  );

  useEffect(() => {
    if (data !== undefined) {
      onClose();
    }
  }, [data, onClose]);

  return { initialValues, onSubmit, loading };
};
