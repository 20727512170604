import React from 'react';
import { AutoFocusedForm, PhotoField, required, StorySelect, TextField } from 'components';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import { Loading } from 'ui/Loading';
import { Field } from 'react-final-form';
import { useBenefactorStatusEditModalForm } from './useBenefactorStatusEditModalForm';
import { MutableButton } from '../../../../ui/MutableButton';
import { RedButton } from '../../../../components/Buttons';
import { BenefactorStatusByIdQuery } from '../../../../schema';
import { useStyles } from '../useStyles';

interface BenefactorStatusEditModalFormProps {
  onClose: () => void;
  status: BenefactorStatusByIdQuery | undefined;
}

export const BenefactorStatusEditModalForm = ({
  onClose,
  status,
}: BenefactorStatusEditModalFormProps) => {
  const styles = useStyles();

  const { onSubmit, initialValues, loading } = useBenefactorStatusEditModalForm({
    onClose,
    status,
  });

  if (loading) {
    return <Loading />;
  }

  return (
    <AutoFocusedForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, pristine }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card className={styles.root}>
              <CardHeader title={`Редактировать статус`} />
              <Divider />
              <CardContent className={styles.content}>
                <Grid container>
                  <Grid item lg={10} md={10} xl={10} xs={10}>
                    <Field
                      name="imageId"
                      component={PhotoField}
                      url={status?.benefactorStatusById?.image.url}
                    />
                    <Typography variant="body2">Изображение статуса</Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Field
                      name="title"
                      component={TextField}
                      label="Название статуса"
                      validate={required}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Field
                      name="description"
                      component={TextField}
                      multiline
                      rows={3}
                      label="Описание статуса"
                      validate={required}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <StorySelect
                      storyLabel="statuses"
                      name="storyId"
                      label="История с описанием статуса"
                      validate={required}
                    />
                  </Grid>
                </Grid>
              </CardContent>

              <CardActions className={styles.actions}>
                <MutableButton
                  loading={false}
                  className={styles.accessButton}
                  color="primary"
                  disabled={pristine}
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  Сохранить
                </MutableButton>
                <RedButton onClick={onClose} size="medium" type="button" variant="contained">
                  Отменить
                </RedButton>
              </CardActions>
            </Card>
          </form>
        );
      }}
    />
  );
};
