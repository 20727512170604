import React, { FunctionComponent, useEffect } from 'react';
import { LayoutGrid } from 'ui/LayoutGrid';
import { Topbar } from './components';
import { LayoutProps, useMeContext } from 'components';
import { useHistory } from 'react-router-dom';

export const Layout: FunctionComponent<LayoutProps> = ({ children, path }) => {
  const { user } = useMeContext();
  const history = useHistory();

  useEffect(() => {
    if (user === undefined) {
      const redirectUrl =
        path !== undefined ? (Array.isArray(path) && path.length > 0 ? path[0] : path) : undefined;
      const to = redirectUrl !== undefined ? `/login?returnUrl=${redirectUrl}` : '/login';
      console.log('Redirecting to the login page');
      history.push(to);
    }
  }, [user, history, path]);

  return (
    <LayoutGrid>
      <Topbar />
      <main>{children}</main>
    </LayoutGrid>
  );
};
