import { makeStyles } from '@material-ui/styles';
import { createStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    layout: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',

      minHeight: '300px',
      gap: '20px',
      '& main': {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      },
    },
    container: {
      '& *:first-child:not(:last-child)': {
        flex: '0 1 33%',
      },
      '& *:last-child': {
        flexGrow: 1,
      },
    },
  })
);
