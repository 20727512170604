import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  StoryByIdDocument,
  StoryByIdQuery,
  UpdateStoryInput,
  useUpdateStoryMutation,
} from '../../../../schema';
import { toast } from 'react-toastify';
import { FORM_ERROR, SubmissionErrors } from 'final-form';

type Props = {
  story: StoryByIdQuery | undefined;
  onCancel: () => void;
};

export const useEditStoryForm = ({ story, onCancel }: Props) => {
  const { storyId } = useParams<{ storyId: string }>();

  const [updateStory, { data, loading, error }] = useUpdateStoryMutation();

  const initialValues: UpdateStoryInput = {
    id: storyId,
    title: story?.storyById?.title ?? '',
    imageId: story?.storyById?.image?.id ?? '',
  };

  const onSubmit = useCallback(
    async (values: UpdateStoryInput) => {
      const input: UpdateStoryInput = values;
      try {
        return await updateStory({
          variables: { input },
          refetchQueries: [
            {
              query: StoryByIdDocument,
              variables: { id: storyId },
            },
          ],
        });
      } catch {
        error && toast.error('Ошибка изменения истории');
        return { [FORM_ERROR]: 'Ошибка изменения' } as SubmissionErrors;
      }
    },
    [error, storyId, updateStory]
  );

  useEffect(() => {
    if (data !== undefined) {
      onCancel();
    }
  }, [data, onCancel]);

  return { initialValues, onSubmit, loading };
};
