import React, { useMemo } from 'react';
import {
  BenefactorExtendedPropsFragment,
  BenefactorStatus,
  useBenefactorByIdStatusesQuery,
} from 'schema/serverTypes';
import { useQueryVariables, ColumnConfig, DataConnection } from 'hooks';
import { DataGrid } from 'components';
import { DataGridProps } from 'components/DataGrid/types';
import { useStyles } from '../useStyles';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

const getDataKey = (status: BenefactorStatus) => status.id;

interface BenefactorAchievementsProps {
  benefactor: BenefactorExtendedPropsFragment;
}

export const BenefactorStatusesGrid = (props: BenefactorAchievementsProps) => {
  const classes = useStyles();

  const {
    benefactor: { id },
  } = props;

  const config = useMemo(() => {
    const columns: ColumnConfig<BenefactorStatus>[] = [
      {
        id: 'title',
        header: 'Название',
        render: (status: BenefactorStatus) => (
          <Link className={classes.link} to={`/benefactor-statuses/manage/${status.id}`}>
            {status.title}
          </Link>
        ),
      },
      {
        id: 'coins',
        header: 'Монеты',
        render: (status: BenefactorStatus) => <Typography>{status.coins}</Typography>,
      },
    ];
    return {
      columns,
    };
  }, [classes]);

  const { onPage, variables, extractPagedData, columns } = useQueryVariables<BenefactorStatus>(
    config
  );

  const { data } = useBenefactorByIdStatusesQuery({
    variables: { id, ...variables },
  });

  const entities = data?.benefactorById?.statuses as DataConnection<BenefactorStatus>;
  const { data: statuses, count, startCursor, endCursor, page, rowsPerPage } = extractPagedData(
    entities
  );

  const gridProps: DataGridProps<BenefactorStatus> = {
    data: statuses,
    onPage,
    columns,
    getDataKey,
    count,
    startCursor: startCursor,
    endCursor: endCursor,
    page,
    rowsPerPage,
    onSort: () => {},
    onSearch: () => {},
  };

  return <DataGrid<BenefactorStatus> {...gridProps} />;
};
