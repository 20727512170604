import React from 'react';
import { SpecialProjectsPropsFragment } from '../../../../schema';
import { CheckCircle, Cancel, RadioButtonUnchecked, RadioButtonChecked } from '@material-ui/icons';
import { createStyles, Icon, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nameContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    avatar: {
      marginRight: theme.spacing(2),
    },
    redIcon: {
      color: theme.palette.error.dark,
    },
    greenIcon: {
      color: theme.palette.success.dark,
    },
    greyIcon: {
      color: theme.palette.grey['500'],
    },
  })
);

type SpecialProjectStatusesProps = {
  project: SpecialProjectsPropsFragment;
};
export const SpecialProjectStatuses = ({ project }: SpecialProjectStatusesProps) => {
  const classes = useStyles();

  const getSpecialProjectStatusIcon = () => {
    if (project.cancelled) {
      return (
        <Icon className={classes.redIcon}>
          <Cancel />
        </Icon>
      );
    }

    if (!project.published) {
      return (
        <Icon className={classes.greyIcon}>
          <RadioButtonUnchecked />
        </Icon>
      );
    }

    if (project.published && project.closed) {
      return (
        <Icon className={classes.greenIcon}>
          <CheckCircle />
        </Icon>
      );
    }

    if (project.published) {
      return (
        <Icon className={classes.greenIcon}>
          <RadioButtonChecked />
        </Icon>
      );
    }

    if (project.closed) {
      return (
        <Icon className={classes.greenIcon}>
          <CheckCircle />
        </Icon>
      );
    }

    if (project.cancelled) {
      return (
        <Icon className={classes.redIcon}>
          <Cancel />
        </Icon>
      );
    }
  };

  return <div>{getSpecialProjectStatusIcon()}</div>;
};
