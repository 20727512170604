import React from 'react';
import { Button, Card, CardContent } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { LayoutPageGrid } from '../../../ui/LayoutGrid';
import { AwardsGrid } from './AwardsGrid';
import { useMeContext } from '../../../components';

export const AwardsPage = () => {
  const { user } = useMeContext();

  return (
    <LayoutPageGrid
      title="Награды"
      tools={
        user?.permissions.achievements.manage ? (
          <Button color="primary" variant="contained" component={Link} to="/awards/create">
            Добавить награду
          </Button>
        ) : (
          <></>
        )
      }
    >
      <Card>
        <CardContent>
          <AwardsGrid />
        </CardContent>
      </Card>
    </LayoutPageGrid>
  );
};
