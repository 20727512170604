import { useCallback, useEffect } from 'react';
import {
  CategoriesDocument,
  UpdateCategoriesOrderInput,
  useUpdateCategoriesOrderMutation,
} from 'schema/serverTypes';

import { FORM_ERROR, SubmissionErrors } from 'final-form';
import { toast } from 'react-toastify';
import { QueryVariables } from '../../../../hooks';

type UseCategoryProps = {
  onClose: () => void;
  variables?: QueryVariables<{}, {}>;
  ids: string[];
};
export const useUpdateOrderCategoriesForm = ({ onClose, variables, ids }: UseCategoryProps) => {
  const [updateOrder, { error, data, loading }] = useUpdateCategoriesOrderMutation();

  const onSubmit = useCallback(async () => {
    const input: UpdateCategoriesOrderInput = {
      ids,
    };
    try {
      return await updateOrder({
        variables: { input },
        refetchQueries: [
          {
            query: CategoriesDocument,
            variables,
          },
        ],
      });
    } catch {
      error && toast.error('Ошибка при удалении категории');
      return { [FORM_ERROR]: 'Ошибка удаления категории' } as SubmissionErrors;
    }
  }, [ids, variables, updateOrder, error]);

  useEffect(() => {
    if (data?.updateCategoriesOrder.success === true) {
      onClose();
    } else if (data?.updateCategoriesOrder.success === false) {
      toast.error('Ошибка обновления порядка');
    }
  }, [onClose, data]);

  return { onSubmit, loading };
};
