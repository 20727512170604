import React from 'react';
import { AddRegularNeedForm } from '../AddNeedForm/AddRegularNeedForm';
import { EditOneTimeNeedForm } from './EditOneTimeNeedForm';
import {
  Button,
  Card,
  CardHeader,
  Divider,
  CardContent,
  makeStyles,
  createStyles,
  useTheme,
  CardActions,
  Theme,
} from '@material-ui/core';
import { TabPanel, NeedProps } from 'components';
import { AutoFocusedForm } from 'components';
import { useEditNeedForm } from './useEditNeedForm';
import { isRegularNeed } from 'schema';
import { WardProps } from 'pages';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '500px',
    },
    header: {
      fontWeight: 'bolder',
    },
    divider: {},
    content: {
      marginTop: theme.spacing(2),
    },
    actions: {
      justifyContent: 'flex-end',
    },
  })
);

export interface EditNeedFormProps extends WardProps, NeedProps {}

export const EditNeedForm = ({ ward, need }: EditNeedFormProps) => {
  const classes = useStyles();
  const theme = useTheme();

  const { onCancel, initialValues, onSubmit, required } = useEditNeedForm(ward, need);

  const form = isRegularNeed(need) ? (
    <AddRegularNeedForm required={required} />
  ) : (
    <EditOneTimeNeedForm required={required} />
  );

  return (
    <AutoFocusedForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, pristine }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card className={classes.root}>
              <CardHeader title="Редактирование потребности" />
              <Divider className={classes.divider} />
              <CardContent className={classes.content}>
                <TabPanel value={0} index={0} dir={theme.direction}>
                  {form}
                </TabPanel>
              </CardContent>
              <CardActions className={classes.actions}>
                <Button
                  color="primary"
                  disabled={pristine}
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  Сохранить
                </Button>
                <Button onClick={onCancel} size="medium" type="button" variant="contained">
                  Отменить
                </Button>
              </CardActions>
            </Card>
          </form>
        );
      }}
    />
  );
};
