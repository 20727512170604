import React from 'react';
import { LoginForm } from './components';
import { Redirect } from 'react-router-dom';
import { useMeContext } from 'components';

export const LoginPage = () => {
  const { user } = useMeContext();

  if (user !== undefined) {
    const to = '/wards';
    return <Redirect to={to} />;
  }

  return <LoginForm />;
};
