import React from 'react';
import {
  Card,
  CardHeader,
  Divider,
  CardContent,
  makeStyles,
  createStyles,
  CardActions,
  Theme,
  Grid,
  MenuItem,
} from '@material-ui/core';
import { AutoFocusedForm, required, SelectField, TextField } from 'components';
import { Field } from 'react-final-form';
import { useBackRoute } from 'hooks';
import { MutableButton } from 'ui/MutableButton';
import { RedButton } from 'components/Buttons';
import { useCreateDepositAccountForm } from './useCreateDepositAccountForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '600px',
      minWidth: '600px',
    },
    header: {
      fontWeight: 'bolder',
    },
    actions: {
      justifyContent: 'flex-end',
    },
    item: {
      marginBottom: theme.spacing(2),
    },
    accessButton: {
      marginLeft: '15px',
      marginRight: '15px',
      color: theme.palette.common.white,
      backgroundColor: theme.palette.success.dark,
      '&:hover': {
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.success.light,
      },
    },
  })
);

export const CreateDepositAccountForm = (props: { benefactorId: string; permission: boolean }) => {
  const classes = useStyles();
  const { benefactorId } = props;

  const { onCancel } = useBackRoute();

  const { initialValues, onSubmit, loading } = useCreateDepositAccountForm(benefactorId, onCancel);

  return (
    <AutoFocusedForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, pristine, values }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card className={classes.root}>
              <CardHeader title="Создать счет" />
              <Divider />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} className={classes.item}>
                    <Field
                      name="account"
                      label="Тип счета *"
                      fullWidth={true}
                      component={SelectField}
                      validate={required}
                    >
                      <MenuItem value="BenefactorDepositAccountWithoutConditions">
                        Счет на что угодно
                      </MenuItem>
                      <MenuItem value="BenefactorDepositAccountForProjectNeeds">
                        Счет на развитие проекта
                      </MenuItem>
                      <MenuItem value="BenefactorDepositAccountForAllWards">
                        Счет на любых подопечных
                      </MenuItem>
                      <MenuItem value="BenefactorDepositAccountForSpecificWards">
                        Счет для особых подопечных
                      </MenuItem>
                    </Field>
                  </Grid>
                  {values.account === 'BenefactorDepositAccountForSpecificWards' && (
                    <Grid item xs={12} className={classes.item}>
                      <Field
                        name="title"
                        label="Название депозита *"
                        fullWidth={true}
                        component={TextField}
                        validate={required}
                      />
                    </Grid>
                  )}
                </Grid>
              </CardContent>
              <CardActions className={classes.actions}>
                <MutableButton
                  className={classes.accessButton}
                  loading={loading}
                  disabled={pristine}
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  Создать депозит
                </MutableButton>
                <RedButton
                  color="primary"
                  size="medium"
                  type="button"
                  variant="contained"
                  onClick={onCancel}
                >
                  Отменить
                </RedButton>
              </CardActions>
            </Card>
          </form>
        );
      }}
    />
  );
};
