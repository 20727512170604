import { useCallback, useEffect, useMemo } from 'react';
import {
  AccountDocument,
  AccountWithRequiredAmount,
  SystemAccountKind,
  TransferFundsToAccountInput,
  useTransferFundsToAccountMutation,
} from 'schema/serverTypes';
import { useBackRoute } from 'hooks';
import { IAccountProps } from 'pages/components';
import { toast } from 'react-toastify';

interface createInitialValuesProps {
  amount: number;
  requiredAmount: number;
}

const createInitialValues = ({
  amount,
  requiredAmount,
}: createInitialValuesProps): TransferFundsToAccountInput => ({
  maximumAmount: requiredAmount ? requiredAmount - amount : 0,
  sourceAccountId: '',
  accountId: '',
});

export const useFillAccountForm = ({ account }: IAccountProps, id: string) => {
  const { onCancel } = useBackRoute();

  const refetchQueries = useMemo(() => {
    return [
      {
        query: AccountDocument,
        variables: { id: account!.id },
      },
    ];
  }, [account]);

  const [transferFundsToAccount, { data, loading, error }] = useTransferFundsToAccountMutation();

  useEffect(() => {
    const errorMessage = data?.transferFundsToAccount.errors?.map((item) => item.message);

    if (errorMessage) {
      toast.error(`${errorMessage}`);
      return;
    }

    if (data) {
      toast.success('Перевод средств успешно произведен');
      onCancel();
    }
  }, [data, error, onCancel]);

  useEffect(() => {
    if (error) {
      toast.error('Ошибка перевода');
    }
  }, [error, data]);

  const onSubmit = useCallback(
    async (values: TransferFundsToAccountInput) => {
      const { maximumAmount, sourceAccountId } = values;
      const input: TransferFundsToAccountInput = {
        accountId: id,
        maximumAmount: parseFloat(maximumAmount.toString()),
        sourceAccountId,
      };
      await transferFundsToAccount({
        refetchQueries,
        variables: { input },
      });
    },
    [refetchQueries, transferFundsToAccount, id]
  );

  const accountWithRequiredAmount = account as AccountWithRequiredAmount;

  const benefactorAccounts = useMemo(() => {
    return account?.transferSources.map((item) => {
      if (item?.__typename === 'SystemAccount') {
        if (item.kind === SystemAccountKind.Main) {
          return { ...item, title: 'Основной счёт проекта' };
        }

        return { ...item, title: 'Счёт резерва подопечных' };
      }

      switch (item.__typename) {
        case 'BenefactorDepositAccountWithoutConditions':
          return { ...item, title: `${item.benefactor.displayName} без условий` };
        case 'BenefactorDepositAccountForProjectNeeds':
          return { ...item, title: `${item.benefactor.displayName} на проект` };
        case 'BenefactorDepositAccountForAllWards':
          return { ...item, title: `${item.benefactor.displayName} на подопечных` };
        default:
          return { ...item, title: 'Счет' };
      }
    });
  }, [account]);

  return {
    initialValues: createInitialValues({
      amount: account!.amount,
      requiredAmount: accountWithRequiredAmount?.requiredAmount,
    }),
    onSubmit,
    onCancel,
    data,
    loading,
    error,
    benefactorAccounts: benefactorAccounts,
  };
};
