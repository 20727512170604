import { useCallback, useEffect } from 'react';
import {
  Document,
  FoundationEvent,
  FoundationEventParticipationRequirement,
  FoundationEventParticipationRequirementInput,
  useAddFoundationEventMutation,
  useUpdateFoundationEventMutation,
} from 'schema';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

interface InputValuesProps {
  agreementFile?: Array<Document>;
  agreementUrl?: string;
  address: string;
  city: string;
  description: string;
  images: Array<Document>;
  meetingInstructions: string;
  passportRequired: boolean;
  phoneNumber: string;
  requirements: Array<FoundationEventParticipationRequirement>;
  start: string;
  title: string;
}

export const useAddOrEditEventForm = (foundationId: number, event?: FoundationEvent) => {
  const history = useHistory();

  const [
    addFoundationEventMutation,
    { data: addData, loading: addLoading, error: addError },
  ] = useAddFoundationEventMutation();
  const [
    updateFoundationEventMutation,
    { data: updateData, loading: updateLoading, error: updateError },
  ] = useUpdateFoundationEventMutation();

  const data = event ? updateData : addData;
  const loading = event ? updateLoading : addLoading;
  const error = event ? updateError : addError;

  const onSubmit = useCallback(
    async (values: InputValuesProps) => {
      const { images, agreementFile, agreementUrl, requirements, address, city, ...rest } = values;

      const inputRequirements: Array<FoundationEventParticipationRequirementInput> = requirements.map(
        (requirement) => {
          return {
            quantity: requirement.quantity,
            skillId: requirement.skill.id,
            description: requirement.description,
            autoSelect: requirement.autoSelect,
          };
        }
      );

      const addInput = {
        ...rest,
        address:
          event && event.details.address.displayText === address
            ? event.details.address.fullAddress
            : address,
        requirements: inputRequirements,
        city: city,
        images: images.map((item) => item.file.id),
        agreementFileId: agreementFile?.length ? agreementFile[0].file.id : undefined,
        agreementUrl: agreementUrl?.length ? agreementUrl : undefined,
        start: moment(values.start),
      };

      event
        ? await updateFoundationEventMutation({
            variables: {
              input: {
                ...addInput,
                id: event?.id,
              },
            },
          })
        : await addFoundationEventMutation({
            variables: {
              input: {
                ...addInput,
                foundationId: `${foundationId}`,
              },
            },
          });
    },
    [updateFoundationEventMutation, addFoundationEventMutation, foundationId, event]
  );

  const existingAgreementFile: Array<Document> = [
    {
      file: {
        id: event?.details.agreementFile?.id || '',
        url: event?.details.agreementFile?.url || '',
      },
      title: event?.details.agreementFile?.id || '',
    },
  ];

  const existingImages: Array<Document> =
    event?.details.images.map((item) => {
      return {
        file: {
          id: item.id || '',
          url: item.url || '',
        },
        title: 'Нет названия',
      };
    }) || [];

  const initialValues: InputValuesProps = {
    title: event?.details.title || '',
    description: event?.details.description || '',
    address: event?.details.address.displayText || '',
    city: event?.details.city || '',
    start: event?.details.start || '',
    phoneNumber: event?.details.phoneNumber || '',
    meetingInstructions: event?.details.meetingInstructions || '',
    passportRequired: event?.details.passportRequired || false,
    images: existingImages,
    agreementFile: event?.details.agreementFile ? existingAgreementFile : [],
    requirements: event?.details.requirements || [],
    agreementUrl: event?.details.agreementUrl || '',
  };

  useEffect(() => {
    if (error) {
      toast.error(`Ошибка ${event ? 'изменения' : 'создания'} события`);
    }

    if (data) {
      toast.success(`Событие ${event ? 'изменено' : 'создано'}`);

      history.push(
        `/foundations/manage/${foundationId}/event/${
          addData?.addFoundationEvent.foundationEvent?.id || event?.id
        }`
      );
    }
  }, [error, data, event, history, addData, foundationId]);

  return {
    initialValues,
    onSubmit,
    loading,
  };
};
