import {
  Benefactor,
  Document,
  UpdateBenefactorVolunteerInformationByAdministratorInput,
  useUpdateBenefactorVolunteerInformationByAdministratorMutation,
} from 'schema';
import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

interface InitialValuesType {
  experience: string;
  documents: Array<Document>;
}

export const useEditBenefactorVolunteerInformationForm = (
  benefactor: Benefactor,
  onCancel: () => void
) => {
  const [
    volunteerUpdate,
    { data, error, loading },
  ] = useUpdateBenefactorVolunteerInformationByAdministratorMutation();

  const onSubmit = useCallback(
    async (values: InitialValuesType) => {
      const { documents, experience } = values;

      const inputValues: UpdateBenefactorVolunteerInformationByAdministratorInput = {
        benefactorId: benefactor.id,
        experience: experience !== '' ? experience : undefined,
        documents: documents.map((document) => {
          return { id: document.file.id, title: document.title };
        }),
      };

      await volunteerUpdate({
        variables: {
          input: inputValues,
        },
      });
    },
    [volunteerUpdate, benefactor]
  );

  useEffect(() => {
    if (error) {
      toast.error('Ошибка изменения информации о волонтерстве');
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      toast.success('Волонтерская информация пользователя успешно изменена');
      onCancel();
    }
  }, [data, onCancel]);

  const initialValues: InitialValuesType = {
    experience: benefactor.volunteerInformation?.experience || '',
    documents: benefactor.volunteerInformation?.documents || [],
  };

  return {
    initialValues,
    onSubmit,
    loading,
  };
};
