import { makeStyles } from '@material-ui/styles';
import { createStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
    },
    nameContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    avatar: {
      marginRight: theme.spacing(2),
    },
    name: {
      fontWeight: 700,
    },
    link: {
      display: 'block',
      position: 'absolute',
      cursor: 'pointer',
      zIndex: 3,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
  })
);
