import React from 'react';
import { LayoutPageGrid } from '../../../ui/LayoutGrid';
import { Card, CardHeader } from '@material-ui/core';
import { Route, useParams } from 'react-router-dom';
import { Loading } from '../../../ui/Loading';
import { useBenefactorStatusByIdQuery } from '../../../schema';
import { BenefactorStatusCard } from './components';
import { BenefactorStatusEditModal } from './BenefactorStatusEditModal';
import { toast } from 'react-toastify';
import { BenefactorStatusDeleteModal } from './BenefactorStatusDeleteModal';

export const BenefactorStatusManagePage = () => {
  const { statusId } = useParams<{ statusId: string }>();
  const { data, loading, error } = useBenefactorStatusByIdQuery({ variables: { id: statusId } });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    toast.error('Ошибка загрузки статуса');
    return null;
  }

  return (
    <>
      <LayoutPageGrid title={'Статус'}>
        <Card>
          <CardHeader title="Информация о статусе" />
          <BenefactorStatusCard data={data} />
        </Card>
      </LayoutPageGrid>

      <Route
        path="/benefactor-statuses/manage/:statusId/edit"
        render={() => <BenefactorStatusEditModal status={data} />}
      />

      <Route
        path="/benefactor-statuses/manage/:statusId/delete"
        component={BenefactorStatusDeleteModal}
      />
    </>
  );
};
