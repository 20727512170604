import React, { useRef, useCallback, useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Button, Grid, Select, MenuItem, Box } from '@material-ui/core';
import useDebouncedCallback from 'use-debounce/lib/useDebouncedCallback';
import {
  WardFilterInput,
  WardPublicInformationFilterInput,
  useCitiesQuery,
} from 'schema/serverTypes';
import { SearchInput, SearchCheckboxes } from 'components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(1),
    },
    search: {
      paddingRight: theme.spacing(2),
    },
    selectLabel: {
      margin: '10px',
    },
    resetButton: {
      display: 'block',
      marginLeft: 'auto',
    },
  })
);

export type WardsSearchProps = {
  onSearch: (input: WardFilterInput | undefined) => void;
};

const getOptionLabel = (option: string) => option;

export const WardsSearch = (props: WardsSearchProps) => {
  const { onSearch } = props;
  const classes = useStyles();

  const [published, setPublished] = useState<string | number>('');
  const [rip, setRip] = useState<string | number>('');
  const firstNameRef = useRef<HTMLInputElement>();
  const lastNameRef = useRef<HTMLInputElement>();
  const middleNameRef = useRef<HTMLInputElement>();
  const selectedCities = useRef<string[]>([]);

  const getWhere = useCallback(() => {
    const firstName = firstNameRef.current?.value || '';
    const lastName = lastNameRef.current?.value || '';
    const middleName = middleNameRef.current?.value || '';
    if (
      firstName === '' &&
      lastName === '' &&
      middleName === '' &&
      selectedCities.current.length === 0 &&
      published === '' &&
      rip === ''
    ) {
      return undefined;
    }

    const filters: Array<WardPublicInformationFilterInput> = [];

    if (firstName !== '') {
      filters.push({
        name: {
          firstName: {
            contains: firstName,
          },
        },
      });
    }

    if (lastName !== '') {
      filters.push({
        name: {
          lastName: {
            contains: lastName,
          },
        },
      });
    }

    if (middleName !== '') {
      filters.push({
        name: {
          middleName: {
            contains: middleName,
          },
        },
      });
    }

    if (selectedCities.current.length) {
      filters.push({
        city: {
          in: selectedCities.current,
        },
      });
    }

    if (rip !== '') {
      filters.push({
        dateOfDeath: rip === 0 ? { neq: null } : { eq: null },
      });
    }

    const where: WardFilterInput = {};

    if (!!filters.length) {
      where.publicInformation = { and: filters };
    }

    if (typeof published === 'number') {
      where.published = { eq: Boolean(published) };
    }

    return where;
  }, [published, rip]);

  const [handleOnNameChange] = useDebouncedCallback(() => {
    onSearch(getWhere());
  }, 1000);

  const handleOnChangePublished = (e: React.ChangeEvent<{ value: unknown }>) => {
    setPublished(e.target.value as number | string);
  };

  const handleOnChangeRip = (e: React.ChangeEvent<{ value: unknown }>) => {
    setRip(e.target.value as number | string);
  };

  useEffect(() => onSearch(getWhere()), [published, onSearch, getWhere, rip]);

  const { data } = useCitiesQuery();
  const cities = data?.cities ? (data?.cities.nodes?.map(({ name }) => name) as string[]) : [];

  const handleOnChange = useCallback(
    (cities: string[]) => {
      selectedCities.current = cities;
      onSearch(getWhere());
    },
    [onSearch, getWhere]
  );

  const handleOnReset = useCallback(() => {
    if (firstNameRef && firstNameRef.current) {
      firstNameRef.current.value = '';
    }

    if (lastNameRef && lastNameRef.current) {
      lastNameRef.current.value = '';
    }

    if (middleNameRef && middleNameRef.current) {
      middleNameRef.current.value = '';
    }

    setPublished('');
    setRip('');
    selectedCities.current.length = 0;
    onSearch(undefined);
  }, [onSearch]);

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={4} md={4} xl={4} xs={12}>
          <SearchInput
            inputRef={lastNameRef}
            className={classes.search}
            placeholder="Поиск по фамилии..."
            onChange={handleOnNameChange}
          />
        </Grid>
        <Grid item lg={4} md={4} xl={4} xs={12}>
          <SearchInput
            inputRef={firstNameRef}
            className={classes.search}
            placeholder="Поиск по имени..."
            onChange={handleOnNameChange}
          />
        </Grid>
        <Grid item lg={4} md={4} xl={4} xs={12}>
          <SearchInput
            inputRef={middleNameRef}
            className={classes.search}
            placeholder="Поиск по отчеству..."
            onChange={handleOnNameChange}
          />
        </Grid>
      </Grid>
      <Grid container spacing={4} alignItems="center">
        <Grid item lg={4} md={4} xl={4} xs={12}>
          <SearchCheckboxes
            options={cities}
            getOptionLabel={getOptionLabel}
            label={<h4>Все населенные пункты</h4>}
            onChange={handleOnChange}
            variant="outlined"
          />
        </Grid>
        <Grid item lg={3} md={4} xl={2} xs={6}>
          <Box display="flex" alignItems="center">
            <h4 className={classes.selectLabel}>Опубликовано:</h4>
            <Select
              value={published}
              displayEmpty
              variant={'outlined'}
              onChange={handleOnChangePublished}
            >
              <MenuItem value="">Не важно</MenuItem>
              <MenuItem value={1}>Да</MenuItem>
              <MenuItem value={0}>Нет</MenuItem>
            </Select>
          </Box>
        </Grid>
        <Grid item lg={3} md={4} xl={2} xs={6}>
          <Box display="flex" alignItems="center">
            <h4 className={classes.selectLabel}>Состояние:</h4>
            <Select value={rip} displayEmpty variant={'outlined'} onChange={handleOnChangeRip}>
              <MenuItem value="">Все подопечные</MenuItem>
              <MenuItem value={1}>Живые подопечные</MenuItem>
              <MenuItem value={0}>Умершие подопечные</MenuItem>
            </Select>
          </Box>
        </Grid>
        <Grid item lg={2} md={1} xl={2} xs={1}>
          <Button
            className={classes.resetButton}
            variant="contained"
            size="medium"
            onClick={handleOnReset}
          >
            Сбросить фильтры
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
