import { UniversalNeed } from './RegularNeed';
import { isOneTimeNeed, isGrocerySetNeed } from './needTypeGuards';

export const getNeedDescription = (need: UniversalNeed) => {
  if (isOneTimeNeed(need)) {
    return need.description;
  }

  if (isGrocerySetNeed(need)) {
    return 'Продукты и самое необходимое';
  }
};
