import React, { useMemo } from 'react';
import {
  BenefactorAchievement,
  BenefactorExtendedPropsFragment,
  useBenefactorAchievementsQuery,
} from 'schema/serverTypes';
import { useQueryVariables, ColumnConfig, DataConnection } from 'hooks';
import { DataGrid } from 'components';
import { DataGridProps } from 'components/DataGrid/types';
import { useStyles } from '../useStyles';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { formatDateTime } from '../../../../../../helpers';

const getDataKey = (achievement: BenefactorAchievement) => achievement.id;

interface BenefactorAchievementsProps {
  benefactor: BenefactorExtendedPropsFragment;
}

export const BenefactorAchievements = (props: BenefactorAchievementsProps) => {
  const classes = useStyles();

  const {
    benefactor: { id },
  } = props;

  const config = useMemo(() => {
    const columns: ColumnConfig<BenefactorAchievement>[] = [
      {
        id: 'title',
        header: 'Название',
        render: (achievement: BenefactorAchievement) => (
          <Link className={classes.link} to={`/award/${achievement.achievement.id}`}>
            {achievement.achievement.title}
          </Link>
        ),
      },
      {
        id: 'coins',
        header: 'Монеты',
        render: (achievement: BenefactorAchievement) => (
          <Typography>{achievement.achievement.coins}</Typography>
        ),
      },
      {
        id: 'receivedAt',
        header: 'Дата получения',
        render: (achievement: BenefactorAchievement) => (
          <Typography>{formatDateTime(achievement.receivedAt)}</Typography>
        ),
      },
    ];
    return {
      columns,
    };
  }, [classes]);

  const { onPage, variables, extractPagedData, columns } = useQueryVariables<BenefactorAchievement>(
    config
  );

  const { data } = useBenefactorAchievementsQuery({
    variables: { id, ...variables },
  });

  const entities = data?.benefactorById?.achievements as DataConnection<BenefactorAchievement>;
  const { data: achievements, count, startCursor, endCursor, page, rowsPerPage } = extractPagedData(
    entities
  );

  const gridProps: DataGridProps<BenefactorAchievement> = {
    data: achievements,
    onPage,
    columns,
    getDataKey,
    count,
    startCursor: startCursor,
    endCursor: endCursor,
    page,
    rowsPerPage,
    onSort: () => {},
    onSearch: () => {},
  };

  return <DataGrid<BenefactorAchievement> {...gridProps} />;
};
