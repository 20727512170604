import React, { useCallback } from 'react';
import { Button, Card, CardContent } from '@material-ui/core';
import { ManageDocumentsForm } from 'components';
import {
  Document,
  useLimitedLiabilityCompanyReportQuery,
  usePublishLimitedLiabilityCompanyReportMutation,
  useUpdateCompanyReportDocumentsMutation,
} from 'schema/serverTypes';
import { useParams } from 'react-router-dom';
import { LayoutPageGrid } from 'ui/LayoutGrid/LayoutGrid';
import { MutableButton } from 'ui/MutableButton';

export const CompanyReportPage = () => {
  const { id } = useParams<{ id: string }>();

  const { data, loading: reportLoading } = useLimitedLiabilityCompanyReportQuery({
    variables: { id },
  });

  const [mutate] = useUpdateCompanyReportDocumentsMutation();
  const [publishMutate, { loading }] = usePublishLimitedLiabilityCompanyReportMutation({
    variables: { input: { id } },
  });

  const onSave = useCallback(
    (documents: Document[]) => {
      mutate({
        variables: {
          input: {
            id,
            documents: documents.map((document) => {
              const {
                file: { id },
                title,
              } = document;
              return { id, title };
            }),
          },
        },
      });
    },
    [mutate, id]
  );

  const onPublish = () => {
    publishMutate({
      variables: {
        input: {
          id,
        },
      },
    });
  };

  if (reportLoading) {
    return null;
  }

  if (!data?.limitedLiabilityCompanyReport) {
    return null;
  }

  const { title, published } = data.limitedLiabilityCompanyReport;

  return (
    <LayoutPageGrid
      title={`Отчет ООО "${title}"`}
      tools={
        !published ? (
          <MutableButton loading={loading} color="primary" variant="contained" onClick={onPublish}>
            Опубликовать
          </MutableButton>
        ) : (
          <Button>Опубликован</Button>
        )
      }
    >
      <Card>
        <CardContent>
          <ManageDocumentsForm
            documents={data?.limitedLiabilityCompanyReport.documents}
            onSave={onSave}
          />
        </CardContent>
      </Card>
    </LayoutPageGrid>
  );
};
