import { useState } from 'react';
import { isStoryEditedStorage } from '../../../helpers';

type Props = {
  id: string;
};
export const useIsEditStoryStorage = ({ id }: Props) => {
  const [isStoryEditedStorageHistory, setIsStoryEditedStorageHistory] = useState(() =>
    isStoryEditedStorage.get(id)
  );

  const updateIsEditStoryStorageHistory = (values: boolean) => {
    setIsStoryEditedStorageHistory(values);
    isStoryEditedStorage.save(id, values);
  };

  const clearIsEditStoryStorageHistory = () => {
    setIsStoryEditedStorageHistory(undefined);
    isStoryEditedStorage.clear(id);
  };

  return {
    isStoryEditedStorageHistory,
    updateIsEditStoryStorageHistory,
    clearIsEditStoryStorageHistory,
  };
};
