import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, IconButton, AppBarProps, Theme, createStyles } from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';
import { TopNav } from '../TopNav';
import { useMeContext } from 'components';
import { Logo } from 'components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'sticky',
      flexDirection: 'row',
    },
    flexGrow: {
      flexGrow: 1,
    },
    nav: {},
    signOutButton: {
      marginLeft: theme.spacing(1),
    },
    toolbar: {
      minHeight: 48,
      flexGrow: 1,
      paddingLeft: 0,
    },
  })
);

type TopbarProps = AppBarProps;

export const Topbar = (props: TopbarProps) => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const { logout: handleSignOut, user } = useMeContext();

  const permission = user?.permissions;

  const pages = [
    {
      title: 'Dashboard',
      href: '/dashboard',
      permissions: permission?.users.createAndEditModerator,
    },
    {
      title: 'Подопечные',
      href: '/wards',
      permissions: true,
    },
    {
      title: 'Спецпроекты',
      href: '/special-projects',
      permissions: permission?.specialProjects.manage,
    },
    {
      title: 'Пользователи',
      href: '/benefactors',
      permissions: permission?.benefactors.list,
    },
    {
      title: 'Фонды-партнеры',
      href: '/foundations',
      permissions: permission?.foundations.edit,
    },
    {
      title: 'Мой фонд',
      href: `/foundations/manage/${user?.foundationId}`,
      permissions: true,
      type: 'FoundationRepresentative',
    },
    {
      title: 'Администраторы',
      href: '/moderators',
      permissions:
        permission?.users.createAndEditSystemAdministrator ||
        permission?.users.createAndEditModerator ||
        permission?.users.createAndEditFoundationRepresentative,
    },
    {
      title: 'Проект',
      href: '/project',
      permissions: permission?.project.manageExpenses,
    },
    {
      title: 'ООО',
      href: '/company',
      permissions: permission?.limitedLiabilityCompany.manage,
    },
    {
      title: 'Версии',
      href: '/versions',
      permissions: permission?.clientVersions.manage,
    },
    {
      title: 'Уведомления',
      href: '/notifications',
      permissions: permission?.broadcastNotifications.view,
    },
    {
      title: 'Категории',
      href: '/categories',
      permissions: permission?.categories.manage,
    },
    {
      title: 'Города',
      href: '/cities',
      permissions: permission?.catalogs.manageCities,
    },
    {
      title: 'Stories',
      href: '/stories',
      permissions: permission?.stories.view,
    },
    {
      title: 'Геймификация',
      href: '/achievements',
      permissions: permission?.achievements.view,
      selectButton: true,
      screens: [
        { title: 'Награды', href: '/awards' },
        { title: 'Статусы', href: '/benefactor-statuses' },
      ],
    },
  ];

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Logo />
      <Toolbar className={classes.toolbar}>
        <TopNav className={classes.nav} pages={pages} />
        <div className={classes.flexGrow} />
        {user?.username}
        <IconButton className={classes.signOutButton} color="inherit" onClick={handleSignOut}>
          <InputIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};
