import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  colors,
  createStyles,
  makeStyles,
  Paper,
  PaperProps,
  Theme,
  FormHelperText,
} from '@material-ui/core';
import { Document, FileUploadInformation, usePrepareFilesUploadMutation } from 'schema/serverTypes';
import { uploadFile } from '../PhotoField/uploadFile';
import { DragNDropFilesList } from './DragNDropFilesList/DragNDropFilesList';
import { FieldArrayRenderProps } from 'react-final-form-arrays';
import { InnerText } from './InnerText';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '5px',
      width: '202px',
      height: '210px',
      cursor: 'pointer',
      outline: 'none',
      position: 'relative',
      '&:hover': {
        backgroundColor: theme.palette.background.default,
      },
      '&:active': {
        backgroundColor: colors.grey[100],
      },
    },
    wrapper: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    logo: {
      textAlign: 'center',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    helper: {
      marginTop: theme.spacing(3),
    },
    preview: {
      width: '100%',
      outline: 'none',
      height: '100%',
      padding: '10px',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
    },
  })
);

export interface handleImagesSortEndType {
  oldIndex: number;
  newIndex: number;
}

interface DropZoneParameters {
  multiple?: boolean;
  maxSize?: number;
  accept?: string[];
}

interface UploadFieldArrayProps
  extends PaperProps,
    FieldArrayRenderProps<Document, HTMLElement>,
    DropZoneParameters {
  maxCount?: number;
}

export const FilesUploadField = (props: UploadFieldArrayProps) => {
  const styles = useStyles();
  const {
    fields,
    meta,
    variant = 'outlined',
    multiple = true,
    maxSize = 5120 * 1024,
    accept = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'],
    maxCount = 50,
    ...rest
  } = props;
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [prepareUpload, { loading }] = usePrepareFilesUploadMutation();

  const existingCount = fields.length || 0;
  const allowedCount = maxCount - existingCount;
  const onDrop = useCallback(
    async (acceptedFiles: File[], notAcceptedFiles: File[]) => {
      const getErrorMessage = (file: File) => {
        let errorMessage;
        switch (true) {
          case file.size > maxSize:
            errorMessage = 'Недопустимый размер файла.';
            break;
          case !accept.includes(file.type):
            errorMessage = 'Недопустимый формат файла.';
            break;
          default:
            errorMessage = 'Неизвестная ошибка.';
        }
        return errorMessage;
      };

      if (acceptedFiles.length === 0) {
        if (notAcceptedFiles.length) {
          const file = notAcceptedFiles[0];
          setErrorMessage(getErrorMessage(file));
        }
        return;
      }
      setErrorMessage(undefined);
      for (let file of acceptedFiles.slice(0, allowedCount)) {
        const { name: fileName, type: contentType } = file;
        const prepareUploadResult = await prepareUpload({
          variables: {
            input: {
              files: [{ fileName, contentType }],
            },
          },
        });
        const fileMeta = prepareUploadResult.data?.prepareFilesUpload.files![0];
        if (fileMeta === undefined) {
          return;
        }

        const fileInfo = fileMeta as FileUploadInformation;
        const { uploadUrl } = fileInfo;
        try {
          await uploadFile(file, uploadUrl);
          fields.push({ file: fileInfo, title: fileInfo.fileName });
        } catch (error) {
          console.log(error);
        }
      }
    },
    [prepareUpload, fields, allowedCount, maxSize, accept]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: multiple,
    maxSize: maxSize,
    accept: accept.join(', '),
    disabled: fields.length !== undefined && fields.length >= maxCount,
  });

  const rootProps = getRootProps();
  const inputProps = getInputProps();

  const errorMessageElement = errorMessage ? (
    <FormHelperText className={styles.helper} error={true}>
      {errorMessage}
    </FormHelperText>
  ) : (
    ''
  );

  const handleImagesSortEnd = useCallback(
    ({ oldIndex, newIndex }: handleImagesSortEndType) => {
      fields.move(oldIndex, newIndex);
    },
    [fields]
  );

  return (
    <>
      <Paper variant={variant} className={styles?.preview}>
        <DragNDropFilesList
          fields={fields}
          meta={meta}
          axis="x"
          useDragHandle={true}
          onSortEnd={handleImagesSortEnd}
        />
        <Paper {...rest} {...rootProps} variant={variant} className={styles?.root}>
          <div className={styles?.wrapper}>
            <input {...inputProps} disabled={inputProps.disabled || loading} />
            <div className={styles?.logo}>
              <InnerText loading={loading} maxSize={maxSize} accept={accept} />
            </div>
          </div>
        </Paper>
        {errorMessageElement}
      </Paper>
    </>
  );
};
