import React from 'react';
import {
  Card,
  CardHeader,
  Divider,
  CardContent,
  Button,
  CardActions,
  makeStyles,
  createStyles,
  Typography,
} from '@material-ui/core';
import { AutoFocusedForm } from 'components';
import { Loading } from '../../../../../ui/Loading';
import { useDeleteCategoryForm } from './useDeleteCategoryForm';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minWidth: 700,
    },
    header: {
      fontWeight: 'bolder',
    },
    actions: {
      justifyContent: 'flex-end',
    },
  })
);

export interface CreateVersionFormProps {
  onClose: () => void;
}

export const DeleteCategoryForm = ({ onClose }: CreateVersionFormProps) => {
  const styles = useStyles();
  const { onSubmit, loading } = useDeleteCategoryForm({ onClose });

  if (loading) {
    return <Loading />;
  }

  return (
    <AutoFocusedForm
      onSubmit={onSubmit}
      render={({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card className={styles.root}>
              <CardHeader title="Удаление категории" />
              <Divider />
              <CardContent>
                <Typography>Вы действительно хотите удалить категорию?</Typography>
              </CardContent>
              <CardActions className={styles.actions}>
                <Button
                  disabled={loading}
                  color="primary"
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  Удалить
                </Button>
                <Button onClick={onClose} size="medium" type="button" variant="contained">
                  Отменить
                </Button>
              </CardActions>
            </Card>
          </form>
        );
      }}
    />
  );
};
