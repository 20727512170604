import React from 'react';
import { createContext } from 'react';
import { RequirementType } from './ParticipationRequirementField';
import { FoundationEventParticipationRequirement } from 'schema';

interface ParticipationRequirementType {
  requirements: Array<RequirementType | FoundationEventParticipationRequirement>;
  handleChangeRequirements: (requirement: RequirementType) => void;
}

export type EventParticipationRequirementQueryContext = ParticipationRequirementType | undefined;

export const QueryVariablesContext = createContext<EventParticipationRequirementQueryContext>(
  undefined
);

export interface QueryContextProviderProps {
  variables: ParticipationRequirementType;
  children: React.ReactNode;
}

export const QueryContextProvider = (props: QueryContextProviderProps) => {
  const { children, variables } = props;
  return (
    <QueryVariablesContext.Provider value={variables}>{children}</QueryVariablesContext.Provider>
  );
};
