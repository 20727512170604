import { makeStyles } from '@material-ui/styles';
import { createStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      boxShadow: 'none',
      backgroundColor: 'transparent',
      overflow: 'visible',
      '& > .MuiCardHeader-root': {
        padding: '0 0 10px',
        boxShadow: 'none',
        '& .MuiTypography-root': {
          fontSize: 18,
        },
      },
      '& > .MuiCardContent-root': {
        padding: 0,
      },
      '& > .MuiCardActions-root': {
        padding: 0,
      },
      '& > .MuiCardContent-root + .MuiCardActions-root': {
        marginTop: theme.spacing(2),
      },
    },
  })
);
