import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { MutableButton } from 'ui/MutableButton';
import { RedButton } from 'components/Buttons';
import { useStyles } from '../../useStyles';
import {
  AutoFocusedForm,
  composeValidators,
  phoneNumber as phone,
  PhoneField,
  required,
} from 'components';
import { Field } from 'react-final-form';
import { useEditPhoneNumber } from './useEditPhoneNumber';

interface EditPhoneNumberProps {
  benefactorId: string;
  phoneNumber?: string | null;
  openPhone: boolean;
  handleClosePhone: () => void;
}

export const EditPhoneNumber = (props: EditPhoneNumberProps) => {
  const { openPhone, handleClosePhone, phoneNumber, benefactorId } = props;
  const styles = useStyles();

  const { onSubmit, initialValues, loading } = useEditPhoneNumber(
    benefactorId,
    handleClosePhone,
    phoneNumber
  );

  return (
    <>
      <Dialog open={openPhone} onClose={handleClosePhone} maxWidth="xs">
        <DialogTitle disableTypography className={styles.header}>
          Редактировать номер телефона
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={styles.alarm} color="error">
            Выполнение данной операции может привести к невозможности пользователя войти в систему!
          </DialogContentText>
          <AutoFocusedForm
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({ handleSubmit, pristine }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Field
                    name="phoneNumber"
                    label="Номер телефона *"
                    fullWidth
                    component={PhoneField}
                    validate={composeValidators(required, phone)}
                  />
                  <DialogActions>
                    <MutableButton
                      className={styles.accessButton}
                      loading={loading}
                      disabled={pristine}
                      size="medium"
                      type="submit"
                      variant="contained"
                    >
                      Сохранить
                    </MutableButton>
                    <RedButton
                      color="primary"
                      size="medium"
                      type="button"
                      variant="contained"
                      onClick={handleClosePhone}
                    >
                      Отменить
                    </RedButton>
                  </DialogActions>
                </form>
              );
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
