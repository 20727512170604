import React from 'react';
import { WardProps } from 'pages/wards';
import {
  Button,
  Grid,
  Typography,
  makeStyles,
  Theme,
  Tabs,
  Tab,
  createStyles,
  Box,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import theme from 'theme';
import { TabPanel, useMeContext } from 'components';
import { useWardInfo } from './useWardInfo';
import { NeedsTab } from './NeedsTab';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttons: {
      display: 'flex',
      flexDirection: 'row-reverse',
      '& button': {
        marginLeft: theme.spacing(1),
      },
      marginBottom: theme.spacing(1),
    },
  })
);

export interface WardInfoProps extends WardProps {
  label?: string;
  addNeedUrl?: string;
}

export const WardInfo = (props: WardInfoProps) => {
  const classes = useStyles();
  const { ward, label = 'Потребности подопечного', addNeedUrl } = props;

  const { user } = useMeContext();

  const isAllowed = user?.permissions.wards.edit;

  const { tabIndex, onChangeTab, onChangeTabIndex, tabs, a11yProps } = useWardInfo(ward);

  return (
    <>
      <Grid container>
        <Grid item lg={6} md={6} xl={6} xs={6}>
          <Typography variant="h5">{label}</Typography>
        </Grid>
        {addNeedUrl !== undefined && !ward.deactivationInformation && isAllowed && (
          <Grid item lg={6} md={6} xl={6} xs={6} className={classes.buttons}>
            <Button color="primary" variant="contained" component={Link} to={addNeedUrl}>
              Добавить потребность
            </Button>
          </Grid>
        )}
      </Grid>
      <Box mb={1}>
        <Tabs
          value={tabIndex}
          onChange={onChangeTab}
          indicatorColor="primary"
          textColor="primary"
          aria-label="tabs"
        >
          <Tab label={`Активные (${tabs.active.length})`} {...a11yProps(0)} />
          <Tab label={`Завершенные (${tabs.finished.length})`} {...a11yProps(1)} />
        </Tabs>
      </Box>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={tabIndex}
        onChangeIndex={onChangeTabIndex}
      >
        <TabPanel value={tabIndex} index={0} dir={theme.direction}>
          <NeedsTab needs={tabs.active} ward={ward} active={true} />
        </TabPanel>
        <TabPanel value={tabIndex} index={1} dir={theme.direction}>
          <NeedsTab needs={tabs.finished} ward={ward} active={false} />
        </TabPanel>
      </SwipeableViews>
    </>
  );
};
