import React from 'react';
import {
  AddressField,
  AutoFocusedForm,
  composeValidators,
  DateTimeField,
  FilesUploadField,
  PhoneField,
  phoneNumber,
  required,
  TextField,
  ParticipationRequirementField,
  SelectField,
} from 'components';
import { MutableButton } from 'ui/MutableButton';
import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Theme,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useAddOrEditEventForm } from './useAddOrEditEventForm';
import { RedButton } from 'components/Buttons';
import { Field } from 'react-final-form';
import {
  Document,
  FoundationEvent,
  FoundationEventParticipationRequirement,
  useCitiesQuery,
} from 'schema';
import { FieldArray } from 'react-final-form-arrays';
import { useBackRoute } from 'hooks';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  primary: {
    marginBottom: '20px',
  },
  header: {
    fontWeight: 'bolder',
    marginBottom: '10px',
    marginTop: '20px',
  },
  accessButton: {
    marginLeft: '15px',
    marginRight: '15px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.success.dark,
    '&:hover': {
      color: theme.palette.success.dark,
      backgroundColor: theme.palette.success.light,
    },
  },
}));

export interface AddOrEditEventFormProps {
  event?: FoundationEvent;
  foundationId: number;
}

export const AddOrEditEventForm = ({ event, foundationId }: AddOrEditEventFormProps) => {
  const styles = useStyles();
  const { data: citiesData } = useCitiesQuery();
  const cities = citiesData?.cities?.nodes;

  const { onCancel } = useBackRoute();

  const { initialValues, onSubmit, loading } = useAddOrEditEventForm(foundationId, event);
  return (
    <AutoFocusedForm
      onSubmit={onSubmit}
      keepDirtyOnReinitialize
      initialValues={initialValues}
      render={({ handleSubmit, pristine }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card className={styles.primary}>
              <CardHeader title="Общая информация события" />
              <Divider />
              <CardContent>
                <Grid container spacing={4}>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Field
                      name="title"
                      label="Название *"
                      component={TextField}
                      validate={required}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Field
                      name="description"
                      label="Описание *"
                      component={TextField}
                      validate={required}
                      multiline
                      rows={5}
                      rowsMax={50}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item lg={6} md={6} xl={6} xs={12}>
                    <Field
                      name="address"
                      component={AddressField}
                      label="Город, улица, дом *"
                      needDetermineCoordinates="hasCoordinates"
                      validate={required}
                    />
                  </Grid>
                  <Grid item lg={3} md={3} xl={3} xs={12}>
                    <Field name="city" label="Город *" component={SelectField} validate={required}>
                      {cities?.map(({ name, id }) => (
                        <MenuItem key={id} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid item lg={3} md={3} xl={3} xs={12}>
                    <Field
                      name="start"
                      label="Дата и время начала (время местное)*"
                      fullWidth={true}
                      component={DateTimeField}
                      validate={required}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item lg={4} md={6} xl={4} xs={12}>
                    <Field
                      name="phoneNumber"
                      label="Номер телефона для связи *"
                      component={PhoneField}
                      validate={composeValidators(required, phoneNumber)}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Field
                      name="meetingInstructions"
                      label="Инструкции для участников *"
                      validate={composeValidators(required)}
                      component={TextField}
                      multiline
                      rows={5}
                      rowsMax={50}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      label="Оповестить участников о необходимости иметь с собой паспорт"
                      labelPlacement="end"
                      control={
                        <Field
                          name="passportRequired"
                          type="checkbox"
                          render={({ input }) => {
                            const { type, ...restInput } = input;
                            return <Checkbox {...restInput} />;
                          }}
                        />
                      }
                    />
                  </Grid>
                </Grid>
                <Typography variant="h5" component="h5" className={styles.header}>
                  Фото события *
                </Typography>
                <Grid container spacing={4}>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <FieldArray<Document>
                      name="images"
                      component={FilesUploadField}
                      accept={['image/jpeg', 'image/jpg', 'image/png']}
                    />
                  </Grid>
                </Grid>
                <Typography variant="h5" component="h5" className={styles.header}>
                  Договор между фондом и участником
                </Typography>
                <Grid container spacing={4}>
                  <Grid item lg={4} md={4} xl={4} xs={12}>
                    <FieldArray<Document>
                      label="Договор между фондом и участником"
                      name="agreementFile"
                      component={FilesUploadField}
                      maxCount={1}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item lg={6} md={6} xl={6} xs={12}>
                    <Field name="agreementUrl" label="Ссылка на договор" component={TextField} />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card className={styles.primary}>
              <CardHeader title="Требования к участникам *" />
              <Divider />
              <CardContent>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <FieldArray<FoundationEventParticipationRequirement>
                      name="requirements"
                      component={ParticipationRequirementField}
                      foundationId={foundationId}
                      validate={required}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <MutableButton
              className={styles.accessButton}
              loading={loading}
              disabled={pristine}
              size="medium"
              type="submit"
              variant="contained"
            >
              Сохранить
            </MutableButton>
            <RedButton
              color="primary"
              size="medium"
              type="button"
              variant="contained"
              onClick={onCancel}
            >
              Отменить
            </RedButton>
          </form>
        );
      }}
    />
  );
};
