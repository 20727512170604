import { makeStyles } from '@material-ui/styles';
import { createStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      color: theme.palette.primary.dark,
    },
    iconColor: {
      color: theme.palette.text.secondary,
    },
    iconErrorColor: {
      color: theme.palette.error.dark,
    },
    skillItem: {
      marginRight: theme.spacing(0.5),
    },
    photoContainer: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
    },
    photoCard: {
      margin: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      maxWidth: '180px',
      height: '200px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    fileIcon: {
      width: '145px',
      height: '145px',
    },
    tooltip: {
      fontSize: '15px',
    },
  })
);
