import React, { useEffect } from 'react';
import { Button, DialogActions, DialogContent, Typography } from '@material-ui/core';
import {
  CloudPaymentsSavedCardPaymentIntentInput,
  DonationIntentInput,
  PaymentMethod,
  PlaceOrderPayload,
  ProjectSubscriptionIntentInput,
  usePlaceOrderMutation,
  WardsReserveSubscriptionIntentInput,
  WardsSubscriptionIntentInput,
} from '../../../../../../../../schema';
import { RedButton } from '../../../../../../../../components/Buttons';
import { toast } from 'react-toastify';
import { PropertiesTable, PropertiesTableRow } from '../../../../../../../../ui/PropertiesTable';
import { MutableButton } from '../../../../../../../../ui/MutableButton';

type Props = {
  paymentMethod: PaymentMethod;
  // subscriptionIntent: SubscriptionIntentUnion;
  handleClose: () => void;
  setActiveStep: (updateFunction: (prevStep: number) => number) => void;
  wardsSubscriptionIntentInput: WardsSubscriptionIntentInput;
  projectSubscriptionIntentInput: ProjectSubscriptionIntentInput;
  wardsReserveSubscriptionIntentInput: WardsReserveSubscriptionIntentInput;
  paymentIntentInput: CloudPaymentsSavedCardPaymentIntentInput;
  benefactorId: string;
  setPlaceOrderData: (placeOrderData?: PlaceOrderPayload) => void;
};

export const PlaceOrderStep = ({
  paymentMethod,
  paymentIntentInput,
  wardsSubscriptionIntentInput,
  projectSubscriptionIntentInput,
  wardsReserveSubscriptionIntentInput,
  setActiveStep,
  handleClose,
  benefactorId,
  setPlaceOrderData,
}: Props) => {
  const [placeOrder, { data: placeOrderData, error, loading }] = usePlaceOrderMutation();

  const currentDateTime = new Date();

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  const handleBack = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
  };

  const handlePlaceOrder = async () => {
    const donationIntents: DonationIntentInput[] = [];

    if (wardsSubscriptionIntentInput.wardsSubscriptionsAmounts.length) {
      donationIntents.push({ wardsSubscription: wardsSubscriptionIntentInput });
    }

    if (projectSubscriptionIntentInput.amount > 0) {
      donationIntents.push({ projectSubscription: projectSubscriptionIntentInput });
    }

    if (wardsReserveSubscriptionIntentInput.amount > 0) {
      donationIntents.push({ wardsReserveSubscription: wardsReserveSubscriptionIntentInput });
    }

    try {
      await placeOrder({
        variables: {
          input: {
            benefactorId,
            currentDateTime,
            donationIntents,
            paymentIntent: { cloudPaymentsSavedCard: paymentIntentInput },
          },
        },
      });
    } catch (err) {
      console.error(err);
    }
    setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
  };

  useEffect(() => {
    setPlaceOrderData(placeOrderData?.placeOrder);
  }, [setPlaceOrderData, placeOrderData]);

  return (
    <>
      <DialogContent>
        <Typography variant="body1">Вы собираетесь разместить новый заказ</Typography>
        {wardsSubscriptionIntentInput?.wardsSubscriptionsAmounts.length ? (
          <PropertiesTable>
            <PropertiesTableRow caption={'Назначение заказа'} value={'Подписка на подопечных'} />
            <PropertiesTableRow
              caption={'Общая сумма'}
              value={`${wardsSubscriptionIntentInput.wardsSubscriptionsAmounts.reduce(
                (total, subscription) => {
                  return total + subscription.amount;
                },
                0
              )} ₽`}
            />
          </PropertiesTable>
        ) : null}
        {projectSubscriptionIntentInput.amount > 0 ? (
          <PropertiesTable>
            <PropertiesTableRow
              caption={'Назначение заказа'}
              value={'Подписка на развитие проекта'}
            />
            <PropertiesTableRow
              caption={'Общая сумма'}
              value={`${projectSubscriptionIntentInput.amount} ₽`}
            />
            <PropertiesTableRow caption={'Способ оплаты'} value={paymentMethod.title} />
          </PropertiesTable>
        ) : null}
        {wardsReserveSubscriptionIntentInput.amount > 0 ? (
          <PropertiesTable>
            <PropertiesTableRow
              caption={'Назначение заказа'}
              value={'Подписка на резервный фонд подопечных'}
            />
            <PropertiesTableRow
              caption={'Общая сумма'}
              value={`${wardsReserveSubscriptionIntentInput.amount} ₽`}
            />
            <PropertiesTableRow caption={'Способ оплаты'} value={paymentMethod.title} />
          </PropertiesTable>
        ) : null}
        <PropertiesTableRow caption={'Способ оплаты'} value={paymentMethod.title} />
        <PropertiesTableRow
          caption={'Размер комиссии'}
          value={`${wardsSubscriptionIntentInput?.commissionPercentage}%`}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleBack}>Назад</Button>
        <MutableButton
          variant="contained"
          color="primary"
          onClick={handlePlaceOrder}
          loading={loading}
        >
          Разместить заказ
        </MutableButton>
        <RedButton onClick={handleClose}>Отменить</RedButton>
      </DialogActions>
    </>
  );
};
