export const uploadFile = async (file: File, signedRequest: string) => {
  const buffer = await file.arrayBuffer();
  return fetch(signedRequest, {
    method: 'PUT',
    headers: {
      'Content-Type': file.type,
    },
    body: buffer,
  });
};
