import React from 'react';
import { useBackRoute } from '../../../../../../hooks';
import { Backdrop, Fade, Modal } from '@material-ui/core';
import { useStyles } from '../../../useStyles';
import { CancelSpecialProjectModalForm } from './CancelSpecialProjectModalForm';

export const CancelSpecialProjectModal = () => {
  const styles = useStyles();

  const { onCancel } = useBackRoute();

  return (
    <Modal
      open={true}
      onClose={onCancel}
      aria-labelledby="Отмена спецпроекта"
      aria-describedby="Используйте эту форму для отмены спецпроекта"
      className={styles.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={true}>
        <CancelSpecialProjectModalForm onClose={onCancel} />
      </Fade>
    </Modal>
  );
};
