import { useCallback, useEffect } from 'react';
import { AddCategoryInput, CategoriesDocument, useAddCategoryMutation } from 'schema/serverTypes';

import { FORM_ERROR, SubmissionErrors } from 'final-form';
import { toast } from 'react-toastify';
import { QueryVariables } from '../../../../../hooks';

const initialValues: AddCategoryInput = {
  name: '',
  imageId: '',
};

type UseCategoryProps = {
  onClose: () => void;
  variables?: QueryVariables<{}, {}>;
};
export const useCategoryForm = ({ onClose, variables }: UseCategoryProps) => {
  const [addCategory, { data, loading, error }] = useAddCategoryMutation();

  const onSubmit = useCallback(
    async (values: AddCategoryInput) => {
      const input: AddCategoryInput = values;
      try {
        return await addCategory({
          variables: { input },
          refetchQueries: [
            {
              query: CategoriesDocument,
              variables,
            },
          ],
        });
      } catch {
        error && toast.error('Ошибка при добавлении категории');
        return { [FORM_ERROR]: 'Ошибка добавления' } as SubmissionErrors;
      }
    },
    [addCategory, error, variables]
  );

  useEffect(() => {
    if (data !== undefined) {
      onClose();
    }
  }, [data, onClose]);

  return { initialValues, onSubmit, loading };
};
