import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';

export const useBackRoute = () => {
  const history = useHistory();
  const onCancel = useCallback(() => {
    history.goBack();
  }, [history]);
  return { onCancel };
};
