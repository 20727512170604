import React from 'react';
import { Modal, useMeContext } from 'components';
import { AddBenefactorForm } from './AddBenefactorForm';
import { QueryVariables, useBackRoute, usePermissionPage } from 'hooks';
import { BenefactorSort, BenefactorFilter } from 'schema';

export interface AddBenefactorPageProps {
  variables?: QueryVariables<BenefactorSort, BenefactorFilter>;
}

export const AddBenefactorPage = (props: AddBenefactorPageProps) => {
  const { onCancel } = useBackRoute();
  const { user } = useMeContext();

  usePermissionPage('/benefactors', user?.permissions.benefactors.create);

  return (
    <Modal
      onClose={onCancel}
      aria-labelledby="Создание жертвователя"
      aria-describedby="Используйте эту форму для Создания жертвователя"
    >
      <AddBenefactorForm {...props} />
    </Modal>
  );
};
