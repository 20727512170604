import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { useStyles } from './useStyles';

export const Loading = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <CircularProgress />
    </div>
  );
};