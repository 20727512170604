import { useCallback, useEffect } from 'react';
import {
  AddBenefactorStatusInput,
  BenefactorStatusesDocument,
  useAddBenefactorStatusMutation,
} from 'schema/serverTypes';

import { FORM_ERROR, SubmissionErrors } from 'final-form';
import { toast } from 'react-toastify';

const initialValues: AddBenefactorStatusInput = {
  title: '',
  storyId: '',
  coins: 0,
  imageId: '',
  description: '',
};

export const useAddBenefactorStatusesForm = (onClose: () => void) => {
  const [addBenefactorStatus, { data, loading, error }] = useAddBenefactorStatusMutation();

  const onSubmit = useCallback(
    async (values: AddBenefactorStatusInput) => {
      const input: AddBenefactorStatusInput = {
        ...values,
        coins: Number(values.coins),
      };
      try {
        return await addBenefactorStatus({
          variables: { input },
          refetchQueries: [
            {
              query: BenefactorStatusesDocument,
            },
          ],
        });
      } catch {
        error && toast.error('Ошибка добавления статуса');
        return { [FORM_ERROR]: 'Ошибка добавления' } as SubmissionErrors;
      }
    },
    [addBenefactorStatus, error]
  );

  useEffect(() => {
    if (error) {
      toast.error('Ошибка добавления статуса');
    }
  }, [error]);

  useEffect(() => {
    if (data !== undefined) {
      onClose();
    }
  }, [data, onClose]);

  return { initialValues, onSubmit, loading };
};
