import React from 'react';
import { createStyles, Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Counter } from './Counter';

export type CaptionExtractor<TData> = (data: TData) => string;
export type CaptionOrExtractor<TData> = CaptionExtractor<TData> | string;
export type ValueExtractor<TData> = (data: TData) => number;
export type ColumnsWidth = 1 | 2 | 3 | 4 | 6 | 12 | 'auto';

export interface CounterDefinition<TData> {
  title: string;
  value: ValueExtractor<TData>;
  previousValue?: ValueExtractor<TData>;
  showCurrency?: boolean;
}

export interface CountersGroupDefinition<TData> {
  counters: CounterDefinition<TData>[][];
  title: CaptionOrExtractor<TData>;
  subtitle?: CaptionOrExtractor<TData>;
}

export interface CountersGroupProps<TData> extends CountersGroupDefinition<TData> {
  data: TData;
  width?: ColumnsWidth;
  innerWidth?: ColumnsWidth;
}

export const moneyCounter = <TQuery,>(
  title: string,
  value: ValueExtractor<TQuery>,
  previousValue?: ValueExtractor<TQuery>
): CounterDefinition<TQuery> => {
  return {
    title,
    value,
    previousValue,
    showCurrency: true,
  };
};

export const counter = <TQuery,>(
  title: string,
  value: ValueExtractor<TQuery>,
  previousValue?: ValueExtractor<TQuery>
): CounterDefinition<TQuery> => {
  return {
    title,
    value,
    previousValue,
  };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      paddingLeft: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
    },
    subtitle: {
      marginLeft: theme.spacing(1),
    },
  })
);

function extractCaption<TData>(data: TData, captionOrExtractor: CaptionOrExtractor<TData>): string {
  if (typeof captionOrExtractor === 'string') {
    return captionOrExtractor;
  }

  return captionOrExtractor(data);
}

export const CountersGroup = <TData,>(
  props: React.PropsWithChildren<CountersGroupProps<TData>>
): JSX.Element => {
  const classes = useStyles();

  const { data, title, subtitle, counters, width, innerWidth } = props;

  const subtitleElement =
    subtitle !== undefined ? (
      <Typography component="span" className={classes.subtitle} color="textSecondary" variant="h6">
        ({extractCaption(data, subtitle)})
      </Typography>
    ) : null;

  return (
    <Grid item xs={12} sm={width || 'auto'}>
      <Grid container direction="column" spacing={1}>
        <Grid item className={classes.title}>
          <Typography component="span" color="textPrimary" variant="h6">
            {extractCaption(data, title)}
          </Typography>
          {subtitleElement}
        </Grid>
        {counters.map((columnCounters, row) => {
          let rowIndex = row;
          return (
            <Grid container item xs={12} spacing={1} key={`counter-row-${rowIndex}`}>
              {columnCounters.map((counter, columnIndex) => {
                const currentValue = counter.value(data);
                const previousValue = counter.previousValue
                  ? counter.previousValue(data)
                  : undefined;

                return (
                  <Grid
                    item
                    xs={innerWidth || 'auto'}
                    key={`counter-row-${rowIndex}-column-${columnIndex}`}
                  >
                    <Counter
                      title={counter.title}
                      value={currentValue}
                      previousValue={previousValue}
                      showCurrency={counter.showCurrency}
                    />
                  </Grid>
                );
              })}
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};
