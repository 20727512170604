import { useCallback, useEffect } from 'react';
import { AddStoryInput, StoryBlockByIdDocument, useAddStoryMutation } from 'schema/serverTypes';

import { FORM_ERROR, SubmissionErrors } from 'final-form';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';

const initialValues: AddStoryInput = {
  title: '',
  storyBlockId: '',
};

export const useAddStoryModalForm = () => {
  const [addStory, { data, loading, error }] = useAddStoryMutation();
  const history = useHistory();
  const { blockId } = useParams<{ blockId: string }>();
  const onSubmit = useCallback(
    async (values: AddStoryInput) => {
      const { title } = values;
      const input: AddStoryInput = {
        storyBlockId: blockId,
        title,
      };
      try {
        return await addStory({
          variables: { input },
          refetchQueries: [
            {
              query: StoryBlockByIdDocument,
              variables: { id: blockId },
            },
          ],
        });
      } catch {
        error && toast.error('Ошибка добавления блока историй');
        return { [FORM_ERROR]: 'Ошибка добавления' } as SubmissionErrors;
      }
    },
    [addStory, blockId, error]
  );

  useEffect(() => {
    if (data !== undefined) {
      const newStoryId = data!.addStory.story?.id;
      history.push(`/block/${blockId}/story/${newStoryId}`);
    }
  }, [blockId, data, history]);

  return { initialValues, onSubmit, loading, data };
};
