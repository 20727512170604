import React, { useRef, useCallback, useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Box, Button, Grid, MenuItem, Select } from '@material-ui/core';
import useDebouncedCallback from 'use-debounce/lib/useDebouncedCallback';
import { BenefactorFilter } from 'schema/serverTypes';
import { SearchInput } from 'components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(1),
    },
    search: {
      paddingRight: theme.spacing(2),
    },
    resetButton: {
      display: 'block',
      marginLeft: 'auto',
    },
    selectLabel: {
      margin: '10px',
    },
  })
);

export type BenefactorsSearchProps = {
  onSearch: (input: BenefactorFilter | undefined) => void;
};

interface FilterOptionsType {
  personalDataRegisteredAt: string | number;
  anonymizedAt: string | number;
}

export const BenefactorsSearch = (props: BenefactorsSearchProps) => {
  const { onSearch } = props;
  const classes = useStyles();

  const initialFilterOptions: FilterOptionsType = {
    personalDataRegisteredAt: '',
    anonymizedAt: '',
  };

  const [filterOptions, setFilterOptions] = useState<FilterOptionsType>(initialFilterOptions);

  const nameRef = useRef<HTMLInputElement>();

  const getWhere = useCallback(() => {
    const displayName = nameRef.current?.value || '';

    const where: BenefactorFilter = {};

    if (displayName !== '') {
      where.or = [
        {
          phoneNumber: {
            startsWith: displayName,
          },
        },
        {
          displayName: {
            startsWith: displayName,
          },
        },
      ];
    }

    if (filterOptions.personalDataRegisteredAt !== '') {
      where.personalDataRegisteredAt = filterOptions.personalDataRegisteredAt
        ? { neq: null }
        : { eq: null };
    }

    if (filterOptions.anonymizedAt !== '') {
      where.anonymizedAt = filterOptions.anonymizedAt ? { eq: null } : { neq: null };
    }

    if (!Object.keys(where).length) {
      return undefined;
    }

    return where;
  }, [filterOptions]);

  const [handleOnNameChange] = useDebouncedCallback(() => {
    onSearch(getWhere());
  }, 1000);

  useEffect(() => {
    onSearch(getWhere());
  }, [filterOptions, onSearch, getWhere]);

  const handleOnFilterChange = useCallback(
    (event) => {
      const value = event.target.value;
      const name = event.target.name;
      setFilterOptions({
        ...filterOptions,
        [name]: value,
      });
    },
    [filterOptions]
  );

  const handleOnReset = useCallback(() => {
    if (nameRef && nameRef.current) {
      nameRef.current.value = '';
    }
    filterOptions.personalDataRegisteredAt = '';
    filterOptions.anonymizedAt = '';
    onSearch(undefined);
  }, [onSearch, filterOptions]);

  return (
    <div className={classes.root}>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12}>
          <SearchInput
            inputRef={nameRef}
            className={classes.search}
            placeholder="Поиск пользователя..."
            onChange={handleOnNameChange}
          />
        </Grid>
        <Grid item lg={3} md={4} xl={2} xs={12}>
          <Box display="flex" alignItems="center">
            <h4 className={classes.selectLabel}>Наличие анкеты:</h4>
            <Select
              value={filterOptions.personalDataRegisteredAt}
              name="personalDataRegisteredAt"
              displayEmpty
              variant={'outlined'}
              onChange={handleOnFilterChange}
            >
              <MenuItem value="">Не важно</MenuItem>
              <MenuItem value={1}>С анкетой</MenuItem>
              <MenuItem value={0}>Без анкеты</MenuItem>
            </Select>
          </Box>
        </Grid>
        <Grid item lg={3} md={4} xl={2} xs={12}>
          <Box display="flex" alignItems="center">
            <h4 className={classes.selectLabel}>Анонимизация:</h4>
            <Select
              value={filterOptions.anonymizedAt}
              name="anonymizedAt"
              displayEmpty
              variant={'outlined'}
              onChange={handleOnFilterChange}
            >
              <MenuItem value="">Не важно</MenuItem>
              <MenuItem value={1}>Активный профиль</MenuItem>
              <MenuItem value={0}>Удалённый профиль</MenuItem>
            </Select>
          </Box>
        </Grid>
        <Grid item lg={6} md={4} xl={8} xs={12}>
          <Button className={classes.resetButton} variant="contained" onClick={handleOnReset}>
            Сбросить фильтры
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
