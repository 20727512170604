import React, { useCallback } from 'react';
import {
  makeStyles,
  Theme,
  TablePagination,
  LabelDisplayedRowsArgs,
  createStyles,
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { SortableTable } from '../SortableTable';
import { DataGridProps } from './types';
import { Loading } from 'ui/Loading';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    content: {
      padding: theme.spacing(1, 0),
    },
    searchContainer: {
      padding: theme.spacing(1),
    },
    table: {},
    pager: {},
    actions: {
      justifyContent: 'flex-end',
    },
  })
);

export const DataGrid = <T, F = {}>(props: DataGridProps<T, F>) => {
  const styles = useStyles();
  const {
    page = 1,
    count = -1,
    rowsPerPage = 10,
    onPage,
    backIconButtonProps,
    labelDisplayedRows,
    labelRowsPerPage,
    nextIconButtonProps,
    searchComponent: SearchComponent,
    onSearch,
    onSearchName,
    onIncludeUnpublished,
    onIncludeClosedOrCancelled,
    onSearchCity,
    filter,
    startCursor,
    endCursor,
    tableComponent: TableComponent,
    loading,
    ...rest
  } = props;

  const handleOnChangePage = useCallback(
    (_event: any, page: number) => {
      onPage(page, startCursor, endCursor);
    },
    [onPage, startCursor, endCursor]
  );

  const pagerRowsLabel = useCallback(
    (paginationInfo: LabelDisplayedRowsArgs) => {
      if (labelDisplayedRows !== undefined) {
        return labelDisplayedRows(paginationInfo);
      }
      if (paginationInfo.count < 0) {
        return null;
      }
      return (
        <span>
          {paginationInfo.from}-{paginationInfo.to} из {paginationInfo.count}
        </span>
      );
    },
    [labelDisplayedRows]
  );
  const table =
    TableComponent !== undefined ? (
      <TableComponent {...rest} className={styles.table} />
    ) : (
      <SortableTable {...rest} className={styles.table} />
    );

  return (
    <>
      <PerfectScrollbar>
        {SearchComponent && (
          <div className={styles.searchContainer}>
            <SearchComponent
              onSearch={onSearch}
              onSearchName={onSearchName}
              onIncludeUnpublished={onIncludeUnpublished}
              onIncludeClosedOrCancelled={onIncludeClosedOrCancelled}
              onSearchCity={onSearchCity}
              filter={filter}
            />
          </div>
        )}
        {loading ? <Loading /> : table}
      </PerfectScrollbar>
      {!loading && (
        <TablePagination
          component="div"
          className={styles.pager}
          count={count}
          onChangePage={handleOnChangePage}
          page={page}
          rowsPerPage={rowsPerPage}
          backIconButtonProps={backIconButtonProps}
          labelDisplayedRows={pagerRowsLabel}
          labelRowsPerPage={labelRowsPerPage}
          nextIconButtonProps={nextIconButtonProps}
          rowsPerPageOptions={[]}
          backIconButtonText="Назад"
          nextIconButtonText="Вперед"
        />
      )}
    </>
  );
};
