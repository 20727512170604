import React from 'react';
import { Button } from '@material-ui/core';
import { Link, Route } from 'react-router-dom';
import { AddProjectExpensePopup } from './AddProjectExpensePopup';

export const AddProjectExpense = () => {
  return (
    <>
      <Button color="primary" variant="contained" component={Link} to={`/project/account/create`}>
        Создать расход
      </Button>

      <Route exact path="/project/account/create" component={AddProjectExpensePopup} />
    </>
  );
};
