import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Paper, PaperProps, Theme } from '@material-ui/core';
import { FieldArrayRenderProps } from 'react-final-form-arrays';
import { FoundationEventParticipationRequirement, Skill } from 'schema';
import { AddRequirementButton } from './AddRequirementButton';
import { Route, useParams } from 'react-router-dom';
import { AddOrEditRequirementModal } from './AddOrEditRequirementModal';
import { QueryContextProvider } from './QueryContextProvider';
import { RequirementsCard } from './RequirementsCard';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    preview: {
      height: '100%',
      width: '100%',
      outline: 'none',
      padding: '10px',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
    },
  })
);

export interface RequirementType {
  skill: Skill;
  description?: string;
  quantity: number;
  autoSelect: boolean;
  edit?: FoundationEventParticipationRequirement | RequirementType;
}

interface ComponentProps {}

export interface ParticipationRequirementFieldProps
  extends PaperProps,
    FieldArrayRenderProps<FoundationEventParticipationRequirement, HTMLElement>,
    ComponentProps {
  foundationId?: number;
}

export const ParticipationRequirementField = (props: ParticipationRequirementFieldProps) => {
  const { fields, variant = 'outlined' } = props;
  const [requirements, setRequirements] = useState<
    Array<FoundationEventParticipationRequirement | RequirementType>
  >([...fields.value]);
  const styles = useStyles();

  const { eventId } = useParams<{ eventId?: string }>();

  const handleChangeRequirements = (requirement: RequirementType) => {
    const input = {
      autoSelect: requirement.autoSelect,
      description: requirement.description,
      quantity: Number(requirement.quantity),
      skill: requirement.skill,
    };

    if (requirement.description === undefined) {
      delete input.description;
    }

    if (requirement.edit !== undefined) {
      if (
        fields.value.find(
          (item) =>
            item.skill.id === requirement.skill.id &&
            requirement.edit?.skill.id !== requirement.skill.id
        )
      ) {
        toast.error('Такой навык уже существует', { position: 'top-center' });
        return;
      }

      const editedRequirements = fields.value.map((item) => {
        if (item.skill.id === requirement.edit?.skill.id) {
          return requirement;
        }

        return item;
      });
      setRequirements(editedRequirements);

      fields.update(editedRequirements.indexOf(requirement), input);
    } else {
      setRequirements([...requirements, requirement]);

      fields.push(input);
    }
  };

  const removeCard = (e: React.MouseEvent<SVGSVGElement>, index: number) => {
    e.stopPropagation();
    fields.remove(index);
  };

  useEffect(() => {
    setRequirements([...fields.value]);
  }, [fields.value]);

  return (
    <QueryContextProvider variables={{ requirements, handleChangeRequirements }}>
      <Paper variant={variant} className={styles.preview}>
        {fields.value.map((item, index) => {
          return (
            <RequirementsCard
              key={item.skill.id}
              index={index}
              removeCard={removeCard}
              quantity={item.quantity}
              title={item.skill.title}
              description={item.description || ''}
              skillId={item.skill.id}
            />
          );
        })}
        <AddRequirementButton />
      </Paper>
      <Route
        exact
        path={`/foundations/manage/:id/event/${
          eventId ? ':eventId/edit' : 'create'
        }/requirement/add`}
        component={AddOrEditRequirementModal}
      />
      <Route
        exact
        path={`/foundations/manage/:id/event/${
          eventId ? ':eventId/edit' : 'create'
        }/requirement/:id/edit`}
        component={AddOrEditRequirementModal}
      />
    </QueryContextProvider>
  );
};
