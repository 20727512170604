import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { useStyles } from '../../useStyles';

export const CancelSpecialProjectButton = () => {
  const { id } = useParams();
  const styles = useStyles();

  return (
    <Button
      className={styles.cancelButton}
      variant="contained"
      component={Link}
      to={`/special-projects/manage/${id}/cancel`}
    >
      Отменить
    </Button>
  );
};
