import { useBackRoute } from '../../../../hooks';
import { Backdrop, Fade, Modal } from '@material-ui/core';
import React from 'react';
import { useStyles } from '../useStyles';
import { AwardDeleteModalForm } from './AwardDeleteModalForm';

export const AwardDeleteModal = () => {
  const styles = useStyles();
  const { onCancel } = useBackRoute();

  return (
    <Modal
      open={true}
      onClose={onCancel}
      aria-labelledby="Удаление награды"
      aria-describedby="Используйте эту форму для удаление награды"
      className={styles.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={true}>
        <AwardDeleteModalForm onClose={onCancel} />
      </Fade>
    </Modal>
  );
};
