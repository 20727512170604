import React, { useMemo } from 'react';
import { OneTimeNeed } from '../../../../../schema';
import { PropertiesTable, PropertiesTableRow } from '../../../../../ui/PropertiesTable';
import { formatDate } from '../../../../../helpers';

type Props = {
  need: OneTimeNeed;
};

export const OneTimeNeedInformation = ({ need }: Props) => {
  const status = useMemo<string>(() => {
    if (need.archived !== null) {
      return 'Заархивирована';
    } else if (need?.reportSent !== null) {
      return 'Отчёт отправлен';
    } else if (need?.account.closedAt !== null) {
      return 'Средства собраны';
    } else {
      return 'Сбор средств';
    }
  }, [need]);

  return (
    <PropertiesTable>
      <PropertiesTableRow caption="Баланс" value={need.account.amount} isAmount />
      <PropertiesTableRow caption="Необходимая сумма" value={need.requiredAmount} isAmount />
      <PropertiesTableRow
        caption="Дата завершения сбора средств"
        value={formatDate(need.targetDate)}
      />
      {need?.reportSent && (
        <PropertiesTableRow
          caption="Дата отправки отчёта"
          value={formatDate(need?.reportSent?.timestamp)}
        />
      )}
      <PropertiesTableRow caption="Статус" value={status} />
    </PropertiesTable>
  );
};
