import React from 'react';
import { Button, Card, CardContent } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { LayoutPageGrid } from '../../../ui/LayoutGrid';
import { BenefactorStatusesGrid } from './BenefactorStatusesGrid';
import { useMeContext } from '../../../components';

export const BenefactorStatusesPage = () => {
  const { user } = useMeContext();

  return (
    <LayoutPageGrid
      title="Статусы"
      tools={
        user?.permissions.achievements.manage ? (
          <Button
            color="primary"
            variant="contained"
            component={Link}
            to="/benefactor-statuses/create"
          >
            Добавить статус
          </Button>
        ) : (
          <></>
        )
      }
    >
      <Card>
        <CardContent>
          <BenefactorStatusesGrid />
        </CardContent>
      </Card>
    </LayoutPageGrid>
  );
};
