import React from 'react';
import { Card, CardActions, CardContent, CardHeader, Grid } from '@material-ui/core';
import {
  ProjectAccounts,
  ProjectAmounts,
  AddProjectExpense,
  ProjectRequirements,
  EditProjectRequirements,
} from './components';
import { LayoutPageGrid } from 'ui/LayoutGrid/LayoutGrid';
import { InvisibleCard } from 'ui/InvisibleCard';
import { usePermissionPage } from 'hooks';
import { useMeContext } from 'components';
import { useProjectQuery, ProjectRequirements as ProjectRequirementsType } from '../../schema';

export const ProjectPage = () => {
  const { user } = useMeContext();
  const { data, loading } = useProjectQuery();

  usePermissionPage('/wards', user?.permissions.project.manageExpenses);

  return (
    <>
      <LayoutPageGrid title="Проект">
        <Grid container spacing={2}>
          <Grid item>
            <Card>
              <CardHeader title="Показатели проекта" />
              <CardContent>
                <InvisibleCard>
                  <CardContent>
                    <ProjectAmounts data={data} loading={loading} />
                  </CardContent>
                  <CardActions>
                    <AddProjectExpense />
                  </CardActions>
                </InvisibleCard>
              </CardContent>
            </Card>
          </Grid>
          <Grid item>
            <Card>
              <CardHeader title="Потребности проекта" />
              <CardContent>
                <InvisibleCard>
                  <CardContent>
                    <ProjectRequirements data={data} loading={loading} />
                  </CardContent>
                  <CardActions>
                    <EditProjectRequirements
                      requirements={data?.project?.requirements as ProjectRequirementsType}
                    />
                  </CardActions>
                </InvisibleCard>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Card>
          <CardHeader title="Расходы проекта" />
          <CardContent>
            <ProjectAccounts />
          </CardContent>
        </Card>
      </LayoutPageGrid>
    </>
  );
};
