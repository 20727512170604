import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import { DragNDropFileItem } from './DragNDropFileItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Document } from 'schema/serverTypes';
import { FieldArrayRenderProps } from 'react-final-form-arrays';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
    },
  })
);

interface DragNDropPhotoListProps extends FieldArrayRenderProps<Document, HTMLElement> {}

export const DragNDropFilesList = SortableContainer(({ fields }: DragNDropPhotoListProps) => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      {fields.value.map((item, index) => (
        <DragNDropFileItem
          key={item.file.id}
          index={index}
          fileIndex={index}
          file={item}
          removeFile={fields.remove}
          updateFile={fields.update}
        />
      ))}
    </div>
  );
});
