import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardMedia,
  createStyles,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { FileInformation } from '../../../../../schema';
import { useHistory } from 'react-router-dom';

type CategoryCardProps = {
  name: string;
  image?: FileInformation | null;
  id: string;
};

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      width: '220px',
      height: '320px',
    },
    title: {
      fontSize: '20px',
      paddingLeft: '10px',
    },
    image: {
      width: '100%',
      height: '250px',
      cursor: 'pointer',
      transition: 'all ease .3s',

      '&:hover': {
        opacity: '.8',
      },
    },
    wrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    content: {
      cursor: 'pointer',
      padding: 0,
      width: '100%',
    },
  })
);

export const CategoryCard = ({ id, name, image }: CategoryCardProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const history = useHistory();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickMenuItem = () => {
    setAnchorEl(null);
    history.push(`/categories/delete/${id}`);
  };

  const handleClickOnCard = () => {
    history.push(`/categories/edit/${id}`);
  };

  return (
    <Card className={classes.card}>
      <CardHeader
        title={name}
        action={
          <IconButton
            aria-label="more"
            aria-controls="more"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
        }
      />
      <CardMedia
        onClick={handleClickOnCard}
        image={image?.url}
        title={name}
        className={classes.image}
      />
      <Menu id="more" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        <MenuItem onClick={handleClickMenuItem}>Удалить категорию</MenuItem>
      </Menu>
    </Card>
  );
};
