import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { editStoryStorage } from '../../../helpers';
import { ScreenStoryPropsFragment } from '../../../schema';

type ScreensStorageContextState = {
  screensStorageHistory: ScreenStoryPropsFragment[] | undefined;
  updateScreensStorageHistory: (values: ScreenStoryPropsFragment[]) => void;
  clearScreensStorageHistory: () => void;
};

const ScreensStorageContext = createContext<ScreensStorageContextState>({
  screensStorageHistory: [],
  clearScreensStorageHistory: () => {},
  updateScreensStorageHistory: () => {},
});

export const useScreensStorage = () => {
  const screensStorageContext = useContext(ScreensStorageContext);

  const {
    screensStorageHistory,
    updateScreensStorageHistory,
    clearScreensStorageHistory,
  } = screensStorageContext;

  return {
    screensStorageHistory,
    updateScreensStorageHistory,
    clearScreensStorageHistory,
  };
};

export type ScreensStorageProviderProps = PropsWithChildren<{
  id: string;
}>;

export const ScreensStorageProvider = ({ id, children }: ScreensStorageProviderProps) => {
  const [screensStorageHistory, setScreensStorageHistory] = useState(() =>
    editStoryStorage.get(id)
  );

  useEffect(() => {
    setScreensStorageHistory(editStoryStorage.get(id));
  }, [id]);

  const updateScreensStorageHistory = (values: ScreenStoryPropsFragment[]) => {
    setScreensStorageHistory(values);
    editStoryStorage.save(id, values);
  };

  const clearScreensStorageHistory = () => {
    setScreensStorageHistory(undefined);
    editStoryStorage.clear(id);
  };

  const screensStorageContextValue: ScreensStorageContextState = {
    screensStorageHistory,
    updateScreensStorageHistory,
    clearScreensStorageHistory,
  };

  return (
    <ScreensStorageContext.Provider value={screensStorageContextValue}>
      {children}
    </ScreensStorageContext.Provider>
  );
};
