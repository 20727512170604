import { Action } from './actions';
import { State, ColumnConfig } from './types';
import { SortEnumType } from 'schema/serverTypes';
import { SortDirection } from '@material-ui/core';

const getOrderBy = <T, O>(
  sortBy: string | undefined,
  sortDirection: SortDirection,
  columns: ColumnConfig<T, O>[]
) => {
  if (sortBy === undefined || sortDirection === false) {
    return undefined;
  }
  const column = columns.find((t) => t.id === sortBy);
  if (column === undefined || column.orderBy === undefined) {
    return undefined;
  }
  const kind = sortDirection === 'asc' ? SortEnumType.Asc : SortEnumType.Desc;
  return column.orderBy(kind);
};

const clearPaging = <T, O, W>(state: State<T, O, W>) => {
  return {
    ...state,
    page: 0,
    after: undefined,
    first: state.rowsPerPage,
    before: undefined,
    last: undefined,
  };
};

export const reducer = <T, O, W>(state: State<T, O, W>, action: Action<W>) => {
  switch (action.type) {
    case 'orderBy':
      const { sortBy, sortDirection } = action;
      const orderBy = getOrderBy(sortBy, sortDirection, state.columns);
      return {
        ...clearPaging(state),
        orderBy,
        sortDirection,
        sortBy,
      };
    case 'filter':
      const { where } = action;
      return {
        ...clearPaging(state),
        where,
      };
    case 'includeUnpublished':
      const { includeUnpublished } = action;
      return {
        ...clearPaging(state),
        includeUnpublished,
      };

    case 'includeClosedOrCancelled':
      const { includeClosedOrCancelled } = action;
      return {
        ...clearPaging(state),
        includeClosedOrCancelled,
      };

    case 'onSearchCity':
      const { city } = action;
      return {
        ...clearPaging(state),
        city,
      };

    case 'search':
      const { benefactorSearch } = action;
      return {
        ...clearPaging(state),
        benefactorSearch,
      };
    case 'paging': {
      const { page, startCursor, endCursor, rowsPerPage = state.rowsPerPage } = action;
      const prevPage = state.page;
      if (page > prevPage) {
        return {
          ...state,
          rowsPerPage,
          page,
          after: endCursor,
          first: rowsPerPage,
          before: undefined,
          last: undefined,
        };
      } else {
        return {
          ...state,
          page,
          after: undefined,
          first: undefined,
          before: startCursor,
          last: state.rowsPerPage,
        };
      }
    }
  }
};
