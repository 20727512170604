import React, { useMemo } from 'react';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { Star } from '@material-ui/icons';
import {
  AttendanceQueryVariables,
  FoundationEventAttendance,
  FoundationEventAttendanceFilterInput,
  FoundationEventAttendanceSortInput,
  useAttendanceQuery,
} from 'schema';
import { ColumnConfig, DataConnection, useQueryVariables } from 'hooks';
import { shortDescription, toDateTime } from 'helpers';
import { toast } from 'react-toastify';
import { DataGrid, DataGridProps } from 'components';
import { AttendanceSearch } from './AttendanceSearch';
import { Link, Route, useLocation } from 'react-router-dom';
import { AddRating } from './AddRating';
import { QueryContextProvider } from './QueryContextProvider';
import { AttendeeCommentModal } from './AttendeeComment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nameContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    starRate: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginBottom: '5px',
    },
    star: {
      color: 'gold',
    },
  })
);

interface AttendanceGridProps {
  rateAttendees: boolean;
  eventId: string;
}

const getDataKey = (attendance: FoundationEventAttendance) => attendance.id;

export const AttendanceGrid = (props: AttendanceGridProps) => {
  const { eventId, rateAttendees } = props;
  const styles = useStyles();
  const location = useLocation();

  const config = useMemo(() => {
    const columns: ColumnConfig<FoundationEventAttendance, FoundationEventAttendanceSortInput>[] = [
      {
        id: 'displayName',
        header: 'Имя',
        render: (attendance) => {
          return (
            <div className={styles.nameContainer}>
              <Link to={`/benefactors/manage/${attendance.benefactor.id}`}>
                <Typography variant="body1">{attendance.benefactor.displayName}</Typography>
              </Link>
            </div>
          );
        },
      },
      {
        id: 'phoneNumber',
        header: 'Телефон пользователя',
        render: (attendance) => {
          return (
            <div className={styles.nameContainer}>
              <Link to={`/benefactors/manage/${attendance.benefactor.id}`}>
                <Typography variant="body1">{attendance.benefactor.phoneNumber}</Typography>
              </Link>
            </div>
          );
        },
      },
      {
        id: 'checkedInAt',
        header: 'Дата чекина',
        render: (attendance) => {
          return toDateTime(attendance.checkedInAt);
        },
      },
      {
        id: 'attendeeRating',
        header: 'Оценка и Комментарий участника',
        render: (attendance) => {
          return (
            <>
              {attendance.attendeeComment?.length! > 40 ? (
                <Link to={`${location.pathname}/${attendance.id}/comment`}>
                  <Typography variant="body1" className={styles.starRate}>
                    <Star className={styles.star} />
                    {attendance.attendeeRating || 0}
                  </Typography>
                  {attendance.attendeeComment && (
                    <Typography variant="body1">
                      {shortDescription(attendance.attendeeComment, 40)}
                    </Typography>
                  )}
                </Link>
              ) : (
                <>
                  <Typography variant="body1" className={styles.starRate}>
                    <Star className={styles.star} />
                    {attendance.attendeeRating || 0}
                  </Typography>
                  {attendance.attendeeComment && (
                    <Typography variant="body1">
                      {shortDescription(attendance.attendeeComment, 40)}
                    </Typography>
                  )}
                </>
              )}
            </>
          );
        },
      },
      {
        id: 'foundationRating',
        header: 'Оценка и Комментарий организатора',
        render: (attendance) => {
          if (rateAttendees) {
            return (
              <Link to={`${location.pathname}/${attendance.id}/rating`}>
                <Typography variant="body1" className={styles.starRate}>
                  <Star className={styles.star} />
                  {attendance.foundationRating || 0}
                </Typography>

                {attendance.foundationComment && (
                  <Typography variant="body1">
                    {shortDescription(attendance.foundationComment, 40)}
                  </Typography>
                )}
              </Link>
            );
          }
          return (
            <>
              <Typography variant="body1" className={styles.starRate}>
                <Star className={styles.star} />
                {attendance.foundationRating}
              </Typography>
              ;
              {attendance.foundationComment && (
                <Typography variant="body1">
                  {shortDescription(attendance.foundationComment, 40)}
                </Typography>
              )}
            </>
          );
        },
      },
    ];
    return {
      columns,
    };
  }, [styles, location, rateAttendees]);

  const {
    onSort,
    onSearch,
    onPage,
    variables,
    extractPagedData,
    columns,
    sortDirection,
    sortBy,
    onSearchName,
  } = useQueryVariables<FoundationEventAttendance>(config);

  const createVariables: AttendanceQueryVariables = {
    ...variables,
    id: eventId,
  };

  const { data, loading, error } = useAttendanceQuery({
    variables: createVariables,
  });

  const attendances = data?.foundationEventById?.attendances?.nodes;

  if (error) {
    toast.error('Ошибка загрузки участников');
    return null;
  }

  const entities = data?.foundationEventById?.attendances as DataConnection<
    FoundationEventAttendance
  >;
  const { data: attendance, count, startCursor, endCursor, page, rowsPerPage } = extractPagedData(
    entities
  );

  const gridProps: DataGridProps<
    FoundationEventAttendance,
    FoundationEventAttendanceFilterInput
  > = {
    data: attendance,
    onSort,
    onPage,
    onSearch,
    onSearchName,
    columns,
    getDataKey,
    count,
    startCursor: startCursor,
    endCursor: endCursor,
    page,
    searchComponent: AttendanceSearch,
    rowsPerPage,
    sortDirection,
    sortBy,
    loading,
  };

  return (
    <QueryContextProvider values={{ createVariables, attendances }}>
      <DataGrid<FoundationEventAttendance, FoundationEventAttendanceFilterInput> {...gridProps} />
      <Route
        exact
        path="/foundations/manage/:id/event/:eventId/:attendeeId/rating"
        component={AddRating}
      />
      {attendance.map(({ attendeeComment, id }) => (
        <Route exact path={`/foundations/manage/:id/event/:eventId/${id}/comment`}>
          <AttendeeCommentModal attendeeComment={attendeeComment} />
        </Route>
      ))}
    </QueryContextProvider>
  );
};
