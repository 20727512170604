import { isOneTimeNeed, Need, Ward } from 'schema';
import moment, { Moment } from 'moment';
import { useTabs } from 'hooks';

const isActiveNeed = (now: Moment) => (need: Need) => {
  if (isOneTimeNeed(need)) {
    const targetDate = moment(need.targetDate as string).utc();
    return targetDate > now;
  }

  return true;
};

const isFinishedNeed = (now: Moment) => (need: Need) => {
  if (isOneTimeNeed(need)) {
    const targetDate = moment(need.targetDate as string).utc();
    return targetDate <= now;
  }

  return need.archived !== null;
};

const getNeedsTab = (needs: Need[]) => {
  const now = moment().utc();

  const active = needs.filter(isActiveNeed(now));
  const finished = needs.filter(isFinishedNeed(now));

  return {
    active,
    finished,
  };
};

export const useWardInfo = (ward: Ward) => {
  const { needs } = ward;
  const tabs = getNeedsTab(needs);
  const { tabIndex, onChangeTab, onChangeTabIndex, a11yProps } = useTabs();

  return {
    tabs,
    tabIndex,
    onChangeTab,
    onChangeTabIndex,
    a11yProps,
  };
};
