import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';

export const useCancelRoute = (route: string) => {
  const history = useHistory();
  const onCancel = useCallback(() => {
    history.push(route);
  }, [history, route]);
  return { onCancel };
};
