import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  createStyles,
  Divider,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { RedButton } from 'components/Buttons';
import React from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: 500,
    },

    actions: {
      justifyContent: 'flex-end',
    },
  })
);

export interface DeleteErrorCityModalProps {
  message: string;
  onClose: () => void;
}

export const DeleteErrorCityModal = ({ message, onClose }: DeleteErrorCityModalProps) => {
  const styles = useStyles();

  return (
    <Card className={styles.root}>
      <CardHeader title="Ошибка удаления города" />
      <Divider />
      <CardContent>
        <Typography variant="h5" component={'p'}>
          {message}
        </Typography>
      </CardContent>
      <CardActions className={styles.actions}>
        <RedButton onClick={onClose} size="medium" type="button" variant="contained">
          Закрыть
        </RedButton>
      </CardActions>
    </Card>
  );
};
