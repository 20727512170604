import React, { useCallback, useEffect } from 'react';
import {
  BenefactorStatusesDocument,
  DeleteBenefactorStatusInput,
  useDeleteBenefactorStatusMutation,
} from '../../../../schema';
import { Loading } from '../../../../ui/Loading';
import { AutoFocusedForm } from '../../../../components';
import { Card, CardActions, CardContent, CardHeader, Divider, Typography } from '@material-ui/core';
import { MutableButton } from '../../../../ui/MutableButton';
import { RedButton } from '../../../../components/Buttons';
import { useStyles } from '../useStyles';
import { toast } from 'react-toastify';
import { FORM_ERROR, SubmissionErrors } from 'final-form';
import { useHistory, useParams } from 'react-router-dom';

interface BenefactorStatusDeleteModalFormProps {
  onClose: () => void;
}

export const BenefactorStatusDeleteModalForm = ({
  onClose,
}: BenefactorStatusDeleteModalFormProps) => {
  const history = useHistory();
  const styles = useStyles();
  const { statusId } = useParams<{ statusId: string }>();

  const [deleteBenefactorStatus, { data, loading, error }] = useDeleteBenefactorStatusMutation();

  const onSubmit = useCallback(async () => {
    const input: DeleteBenefactorStatusInput = {
      id: statusId,
    };
    try {
      return await deleteBenefactorStatus({
        variables: { input },
        refetchQueries: [
          {
            query: BenefactorStatusesDocument,
          },
        ],
      });
    } catch {
      error && toast.error('Ошибка при публикации награды');
      return { [FORM_ERROR]: 'Ошибка публикации награды' } as SubmissionErrors;
    }
  }, [deleteBenefactorStatus, error, statusId]);

  useEffect(() => {
    if (data?.deleteBenefactorStatus.success === true) {
      history.push('/benefactor-statuses');
    }
  }, [onClose, data, history]);

  useEffect(() => {
    if (data?.deleteBenefactorStatus.success === false) {
      toast.error('Ошибка удаления статуса');
    }
  }, [data?.deleteBenefactorStatus.success]);

  if (loading) {
    return <Loading />;
  }

  return (
    <AutoFocusedForm
      onSubmit={onSubmit}
      render={({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card className={styles.root}>
              <CardHeader title={'Удаление статуса'} />
              <Divider />
              <CardContent className={styles.content}>
                <Typography>Вы действительно хотите удалить статус?</Typography>
              </CardContent>

              <CardActions className={styles.actions}>
                <MutableButton
                  loading={false}
                  className={styles.accessButton}
                  color="primary"
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  Удалить
                </MutableButton>
                <RedButton onClick={onClose} size="medium" type="button" variant="contained">
                  Отменить
                </RedButton>
              </CardActions>
            </Card>
          </form>
        );
      }}
    />
  );
};
