import React, { useCallback } from 'react';
import { ManageDocumentsForm } from '../../../../../components';
import { SpecialProjectButtonProps } from '../types';
import {
  Document,
  SpecialProjectByIdDocument,
  useUpdateSpecialProjectDocumentsMutation,
} from '../../../../../schema';
import { toast } from 'react-toastify';
import { Loading } from '../../../../../ui/Loading';

export const SpecialProjectDocuments = ({ specialProject }: SpecialProjectButtonProps) => {
  const { documents, id: specialProjectId } = specialProject;
  const [updateDocuments, { loading, error }] = useUpdateSpecialProjectDocumentsMutation();

  const onSave = useCallback(
    async (documents: Document[]) => {
      const files = documents.map((document) => {
        const {
          file: { id },
          title,
        } = document;
        return { id, title };
      });

      try {
        await updateDocuments({
          variables: {
            input: {
              id: specialProjectId,
              documents: files,
            },
          },
          refetchQueries: [
            { query: SpecialProjectByIdDocument, variables: { id: specialProjectId } },
          ],
        });
      } catch {
        error && toast.error('Ошибка загрузки документов');
      }
    },
    [updateDocuments, specialProjectId, error]
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <ManageDocumentsForm documents={documents} onSave={onSave} />
    </>
  );
};
