import React from 'react';
import {
  Grid,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Button,
  CardActions,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import { AutoFocusedForm, PhotoField, required, TextField } from 'components';
import { Field } from 'react-final-form';
import { useEditCategoryForm } from './useEditCategoryForm';
import { Alert } from '@material-ui/lab/';
import { useCategoryByIdQuery } from '../../../../../schema';
import { useParams } from 'react-router-dom';
import { Loading } from '../../../../../ui/Loading';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minWidth: 700,
    },
    header: {
      fontWeight: 'bolder',
    },
    divider: {},
    content: {},
    actions: {
      justifyContent: 'flex-end',
    },
  })
);

export interface CreateVersionFormProps {
  onClose: () => void;
}

export const EditCategoryForm = ({ onClose }: CreateVersionFormProps) => {
  const styles = useStyles();
  const { id } = useParams<{ id: string }>();
  const { data: categoryData, loading: loadingData } = useCategoryByIdQuery({ variables: { id } });
  const category = categoryData?.categoryById;

  const { onSubmit, initialValues } = useEditCategoryForm({
    onClose,
    category,
  });

  return (
    <AutoFocusedForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      subscription={{
        submitError: true,
      }}
      render={({ handleSubmit, pristine, submitError }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card className={styles.root}>
              <CardHeader title="Редактирование категории" />
              <Divider className={styles.divider} />
              {!loadingData ? (
                <CardContent className={styles.content}>
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} xl={12} xs={12}>
                      <Field
                        name="imageId"
                        component={PhotoField}
                        validate={required}
                        url={category?.image?.url}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={12} xs={12}>
                      <Field
                        name="name"
                        label="Название *"
                        component={TextField}
                        validate={required}
                      />
                    </Grid>
                  </Grid>
                  {submitError && <Alert severity="error">{submitError}</Alert>}
                </CardContent>
              ) : (
                <Loading />
              )}

              <CardActions className={styles.actions}>
                <Button
                  color="primary"
                  disabled={pristine}
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  Добавить
                </Button>
                <Button onClick={onClose} size="medium" type="button" variant="contained">
                  Отменить
                </Button>
              </CardActions>
            </Card>
          </form>
        );
      }}
    />
  );
};
