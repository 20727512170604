import React, { useCallback } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import {
  SpecialProjectByIdDocument,
  SpecialProjectsDocument,
  useUnpublishSpecialProjectMutation,
} from 'schema/serverTypes';
import { MutableButton } from 'ui/MutableButton';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
      '&:hover': {
        borderColor: theme.palette.error.main,
      },
    },
  })
);

export const UnpublishSpecialProjectButton = () => {
  const { id } = useParams();
  const classes = useStyles();
  const [unpublish, { loading, error }] = useUnpublishSpecialProjectMutation();

  const onClick = useCallback(async () => {
    try {
      return await unpublish({
        variables: { input: { id } },
        refetchQueries: [
          { query: SpecialProjectByIdDocument, variables: { id } },
          {
            query: SpecialProjectsDocument,
            variables: { first: 50, includeClosedOrCancelled: false },
          },
        ],
      });
    } catch {
      return error && toast.error('Ошибка снятия с  публикации спецпроекта');
    }
  }, [unpublish, id, error]);

  return (
    <MutableButton
      className={classes.button}
      variant="outlined"
      loading={loading}
      onClick={onClick}
    >
      Отменить публикацию
    </MutableButton>
  );
};
