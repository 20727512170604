import React, { useCallback } from 'react';
import { TableCell, TableSortLabel, TableCellProps } from '@material-ui/core';
import { SortableTableHeaderProps } from './types';

export function SortableTableHeader<T>(props: SortableTableHeaderProps<T>) {
  const {
    id,
    header,
    render,
    align = 'left',
    padding = 'default',
    sortDirection = false,
    onSort,
    sortable = true,
    active = false,
    ...rest
  } = props;

  const tableCellProps: TableCellProps = {
    ...rest,
    align,
    padding,
  };

  const handleOnClick = useCallback(() => {
    const direction = !sortDirection ? 'asc' : sortDirection === 'asc' ? 'desc' : 'asc';
    onSort(id, direction);
  }, [onSort, id, sortDirection]);

  if (!sortable) {
    return (
      <TableCell key={id} {...tableCellProps}>
        {header}
      </TableCell>
    );
  }

  return (
    <TableCell {...tableCellProps} sortDirection={sortDirection}>
      <TableSortLabel
        active={active}
        direction={sortDirection ? sortDirection : undefined}
        onClick={handleOnClick}
      >
        {header}
      </TableSortLabel>
    </TableCell>
  );
}
