import React, { useMemo } from 'react';
import { ColumnConfig, DataConnection, useQueryVariables } from 'hooks';
import { Donation, QueryAccountArgs, useAccountDonationsQuery } from 'schema';
import { Link } from 'react-router-dom';
import { Avatar, Typography } from '@material-ui/core';
import { getInitials, toDateTimeString } from 'helpers';
import { AmountLabel, DataGridProps } from 'components';
import { useStyles } from './useStyles';

const getDataKey = (donation: Donation) => donation.id;

export const useDonations = ({ id }: QueryAccountArgs) => {
  const classes = useStyles();

  const config = useMemo(() => {
    /**
     * Конфиг колонок для таблицы
     */
    const columns: ColumnConfig<Donation>[] = [
      {
        id: 'fullName',
        header: 'ФИО',
        orderBy: (kind) => ({
          displayName: kind,
        }),
        render: (donation: Donation) => {
          const user = donation.benefactor;
          return (
            <>
              <Link to={`/benefactors/manage/${user.id}`} className={classes.link} />
              <div className={classes.nameContainer}>
                <Avatar className={classes.avatar} src={user.photo?.url}>
                  {getInitials(user.displayName)}
                </Avatar>
                <Typography variant="body1" className={classes.name}>
                  {user.displayName}
                </Typography>
              </div>
            </>
          );
        },
      },
      {
        id: 'amount',
        header: 'Сумма',
        render: (transaction) => <AmountLabel value={transaction.amount} />,
      },
      {
        id: 'createdAt',
        header: 'Дата',
        render: (transaction) => {
          const date = new Date(transaction.createdAt as string);
          return toDateTimeString(date);
        },
      },
    ];

    return {
      columns,
    };
  }, [classes.nameContainer, classes.avatar, classes.name, classes.link]);

  /**
   * Подготовка переменных запроса
   */
  const { variables, extractPagedData, ...restVariables } = useQueryVariables<Donation>(config);

  /**
   * Запрос
   */
  const { data, loading } = useAccountDonationsQuery({
    variables: {
      id,
      ...variables,
    },
  });

  /**
   * Парсинг данных
   */
  const entities = data?.account?.donations as DataConnection<Donation>;
  const { data: donations, count, startCursor, endCursor, page, rowsPerPage } = extractPagedData(
    entities
  );

  /**
   * Свойства для компонента Grid
   */
  const gridProps: DataGridProps<Donation> = {
    ...restVariables,
    data: donations,
    getDataKey,
    count,
    startCursor: startCursor,
    endCursor: endCursor,
    page,
    rowsPerPage,
  };

  return { donations, gridProps, loading };
};
