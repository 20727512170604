import React, { useEffect, useState } from 'react';
import { Route, useHistory, useLocation, useParams } from 'react-router-dom';
import { ScreenStoryCard } from '../components/ScreenStoryCard';
import { Card, CardContent, createStyles, IconButton, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/styles';
import { EditScreenModal } from '../EditScreenModal';
import { ScreenStoryPropsFragment, StoryByIdQuery } from '../../../../schema';
import { arrayMove, SortableContainer } from 'react-sortable-hoc';
import { useScreensStorage } from '../useScreensStorage';
import { useIsEditStoryStorage } from '../useIsEditStoryStorage';

export const useStyles = makeStyles(() =>
  createStyles({
    addCard: {
      border: '2px solid #546e7a',
      backgroundColor: 'transparent',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '200px',
      minHeight: '300px',
      maxHeight: '300px',
      cursor: 'pointer',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    text: {
      color: '#546e7a',
      fontWeight: 'bold',
    },
    sortableContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '15px',
    },
  })
);

type ScreensStoryProps = {
  data?: StoryByIdQuery;
  setIsStoryEdited: (isEdited: boolean) => void;
  setScreenCardsLoaded: (isEdited: boolean) => void;
  screenCardsLoaded: boolean;
};

export const ScreensStory = ({
  data,
  setIsStoryEdited,
  setScreenCardsLoaded,
  screenCardsLoaded,
}: ScreensStoryProps) => {
  const { pathname } = useLocation();
  const { storyId } = useParams<{ storyId: string }>();
  const history = useHistory();
  const classes = useStyles();
  const [screenCards, setScreenCards] = useState<ScreenStoryPropsFragment[]>([]);
  const [selectedCardIndex, setSelectedCardIndex] = useState<number | null>(null);
  const { screensStorageHistory, updateScreensStorageHistory } = useScreensStorage();
  const { updateIsEditStoryStorageHistory } = useIsEditStoryStorage({ id: storyId });

  useEffect(() => {
    if (screensStorageHistory) {
      setScreenCards(screensStorageHistory);
      setScreenCardsLoaded(true);
      return;
    } else if (data && !screenCardsLoaded) {
      const initialData = data?.storyById?.screens.map(
        ({ title, text, buttonTitle, buttonUrl, theme, image }) => {
          return {
            title,
            text,
            buttonTitle,
            buttonUrl,
            theme,
            image,
            imageId: image?.id || null,
          };
        }
      ) as ScreenStoryPropsFragment[];
      setScreenCards(initialData);
      setScreenCardsLoaded(true);
    }
  }, [data, screenCardsLoaded, screensStorageHistory, setScreenCardsLoaded]);

  const updateCardData = (updatedData: ScreenStoryPropsFragment) => {
    selectedCardIndex === null
      ? setScreenCards([...screenCards, updatedData])
      : setScreenCards(screensStorageHistory!);
  };

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    const updatedCards = arrayMove(screenCards, oldIndex, newIndex);
    updateScreensStorageHistory(updatedCards);
    setScreenCards(updatedCards);
    setIsStoryEdited(true);
    updateIsEditStoryStorageHistory(true);
  };
  const openEditScreenModal = () => {
    history.push(`${pathname}/edit-screen`);
  };

  const SortableList = SortableContainer(({ items }: { items: ScreenStoryPropsFragment[] }) => (
    <div className={classes.sortableContainer}>
      {items.map(({ title, text, theme, buttonTitle, buttonUrl, image }, index) => (
        <ScreenStoryCard
          editScreen={() => {
            setSelectedCardIndex(index);
            openEditScreenModal();
          }}
          key={index}
          index={index}
          image={image}
          title={title}
          text={text}
          buttonUrl={buttonUrl}
          buttonTitle={buttonTitle}
          theme={theme}
          screenCards={screenCards}
          setScreenCards={setScreenCards}
          cardIndex={index}
          setIsStoryEdited={setIsStoryEdited}
        />
      ))}
      <Card className={classes.addCard} onClick={openEditScreenModal}>
        <CardContent className={classes.content}>
          <Typography className={classes.text}>Добавить экран</Typography>
          <IconButton>
            <AddIcon />
          </IconButton>
        </CardContent>
      </Card>
    </div>
  ));

  return (
    <>
      <SortableList items={screenCards} onSortEnd={onSortEnd} useDragHandle axis="xy" />

      <Route
        exact
        path={'/block/:blockId/story/:storyId/edit-screen'}
        render={(props) => (
          <EditScreenModal
            {...props}
            screenCards={screenCards}
            setSelectedCardIndex={setSelectedCardIndex}
            setIsStoryEdited={setIsStoryEdited}
            onUpdate={updateCardData}
            cardIndex={selectedCardIndex}
          />
        )}
      />
    </>
  );
};
