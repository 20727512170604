import React from 'react';
import { DisableSubscriptionTransferButton } from './DisableSubscriptionTransferButton';
import { EnableSubscriptionTransferButton } from './EnableSubscriptionTransferButton';
import { WardProps } from '../../types';

export const SubscriptionTransferButton = (props: WardProps) => {
  const {
    ward: { id, subscriptionTransferEnabled },
  } = props;

  if (subscriptionTransferEnabled) {
    return <DisableSubscriptionTransferButton id={id} />;
  }

  return <EnableSubscriptionTransferButton id={id} />;
};
