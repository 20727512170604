import React from 'react';
import { ProjectExpenseAccount } from 'schema';
import { PropertiesTable, PropertiesTableRow } from 'ui/PropertiesTable';
import moment from 'moment';

// const getDateLabelText = (date?: any) => {
//   return date ? moment(date as string).format('DD.MM.YYYY') : undefined;
// };

export const ProjectExpenseAccountInformation = ({
  account,
}: {
  account: ProjectExpenseAccount;
}) => {
  return (
    <PropertiesTable>
      <PropertiesTableRow caption="Баланс" value={account.amount} isAmount />
      <PropertiesTableRow caption="Необходимая сумма" value={account.requiredAmount} isAmount />
      <PropertiesTableRow
        caption="Дата завершения"
        value={moment(account.closedAt).format('DD.MM.YYYY')}
      />
      {/*<PropertiesTableRow caption="Статус" value={account} />*/}
    </PropertiesTable>
  );
};
