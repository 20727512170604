import React, { useEffect } from 'react';
import { MutableButton } from '../../../../../ui/MutableButton';
import { StoryByIdDocument, useUpdateStoryScreensMutation } from '../../../../../schema';
import { toast } from 'react-toastify';
import { useScreensStorage } from '../../useScreensStorage';
import { useIsEditStoryStorage } from '../../useIsEditStoryStorage';
import { useParams } from 'react-router-dom';

type SubmitStoryScreensChangesButtonProps = {
  setIsStoryEdited: (isEdited: boolean) => void;
};
export const SubmitStoryScreensChangesButton = ({
  setIsStoryEdited,
}: SubmitStoryScreensChangesButtonProps) => {
  const { storyId } = useParams<{ storyId: string }>();

  const [updateStoryScreens, { error, loading }] = useUpdateStoryScreensMutation();
  const { screensStorageHistory, clearScreensStorageHistory } = useScreensStorage();
  const { clearIsEditStoryStorageHistory } = useIsEditStoryStorage({
    id: storyId,
  });

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  const handleUpdateStoryScreens = async () => {
    try {
      await updateStoryScreens({
        variables: {
          input: {
            screens: screensStorageHistory!,
            id: storyId,
          },
        },
        refetchQueries: [
          {
            query: StoryByIdDocument,
            variables: { id: storyId },
          },
        ],
      });
    } catch (err) {
      if (error) {
        toast.error(error?.message);
      }
    } finally {
      setIsStoryEdited(false);
      clearScreensStorageHistory();
      clearIsEditStoryStorageHistory();
    }
  };

  return (
    <MutableButton
      color={'primary'}
      onClick={handleUpdateStoryScreens}
      loading={loading}
      disabled={false}
    >
      Сохранить изменения
    </MutableButton>
  );
};
