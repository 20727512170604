import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import { httpLink } from './httpLink';
import { authLink } from './authLink';
import { errorLink } from './errorLink';
import { refreshTokenLink } from './tokensLink';
import possibleTypes from './possibleTypes.json';

const client = new ApolloClient({
  link: ApolloLink.from([refreshTokenLink, authLink, errorLink, httpLink]),
  cache: new InMemoryCache({
    possibleTypes: possibleTypes.possibleTypes,
  }),
  defaultOptions: {
    query: {
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
});

export default client;
