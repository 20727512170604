import { useCallback, useEffect } from 'react';
import { ChangeUserPasswordInput, useChangeUserPasswordMutation, User } from 'schema';
import { toast } from 'react-toastify';

interface ChangePasswordInputValues {
  password: string;
  repeatPassword: string;
}

const initialValues: ChangePasswordInputValues = {
  password: '',
  repeatPassword: '',
};

export const useUserPasswordForm = (onClose: () => void, user: User) => {
  const [changeUserPasswordMutation, { data, loading, error }] = useChangeUserPasswordMutation();

  const onSubmit = useCallback(
    async (values: ChangePasswordInputValues) => {
      const { password, repeatPassword } = values;
      if (password === repeatPassword) {
        const input: ChangeUserPasswordInput = {
          id: user.id,
          newPassword: password,
        };
        await changeUserPasswordMutation({
          variables: { input },
        });
      } else {
        toast.error('Пароли должны совпадать', {
          position: 'top-center',
        });
      }
    },
    [user, changeUserPasswordMutation]
  );

  useEffect(() => {
    error && toast.error('Ошибка изменения пароля');
    data?.changeUserPassword.errors &&
      toast.error(`${data?.changeUserPassword.errors.map((error) => error.message)}`, {
        position: 'top-center',
      });
  }, [error, data]);

  useEffect(() => {
    if (data !== undefined) {
      onClose();
    }
  }, [data, onClose]);

  return { initialValues, onSubmit, loading };
};
