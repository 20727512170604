import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, createStyles, makeStyles, Theme } from '@material-ui/core';
import { WardProps } from '../../types';
import { NeedProps, PeriodProps } from 'components';
import { getNeedName } from 'schema';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      marginBottom: theme.spacing(3),
    },
    link: {
      color: theme.palette.primary.dark,
    },
  })
);

export const Breadcrumbs = (props: NeedProps & WardProps & PeriodProps) => {
  const classes = useStyles();

  const { ward, need, period } = props;
  const {
    publicInformation: {
      name: { displayName },
    },
  } = ward;
  const wardEditUrl = `/wards/manage/${ward.id}`;

  const needUrl = `/wards/manage/${ward.id}/needs/${need.id}/information`;

  const formattedDate = moment(period?.start).format('MMMM YYYY');

  return (
    <Box className={classes.content}>
      <Typography>
        <Link className={classes.link} to="/wards">
          Подопечные
        </Link>{' '}
        /{' '}
        <Link className={classes.link} to={wardEditUrl}>
          {displayName}
        </Link>{' '}
        /{' '}
        {!period ? (
          getNeedName(need)
        ) : (
          <Link className={classes.link} to={needUrl}>
            {getNeedName(need)}{' '}
          </Link>
        )}
        {period && `/ ${formattedDate}`}
      </Typography>
    </Box>
  );
};
