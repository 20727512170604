import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { MutableButton } from 'ui/MutableButton';
import { RedButton } from 'components/Buttons';
import { useStyles } from '../../useStyles';
import { AutoFocusedForm, composeValidators, email, required, TextField } from 'components';
import { Field } from 'react-final-form';
import { useEditMail } from './useEditMail';

interface EditEmailProps {
  userMail?: string | null;
  benefactorId: string;
  openEmail: boolean;
  handleCloseEmail: () => void;
}

export const EditEmail = (props: EditEmailProps) => {
  const { openEmail, handleCloseEmail, userMail, benefactorId } = props;
  const styles = useStyles();

  const { initialValues, loading, onSubmit } = useEditMail(
    benefactorId,
    handleCloseEmail,
    userMail
  );

  return (
    <>
      <Dialog open={openEmail} onClose={handleCloseEmail} aria-labelledby="form-dialog-title">
        <DialogTitle className={styles.header} disableTypography>
          Редактировать email
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={styles.alarm} color="error">
            Выполнение данной операции может привести к невозможности пользователя войти в систему!
          </DialogContentText>
          <AutoFocusedForm
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({ handleSubmit, pristine }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Field
                    name="email"
                    label="Адрес электронной почты *"
                    component={TextField}
                    validate={composeValidators(required, email)}
                  />
                  <DialogActions>
                    <MutableButton
                      className={styles.accessButton}
                      loading={loading}
                      disabled={pristine}
                      size="medium"
                      type="submit"
                      variant="contained"
                    >
                      Сохранить
                    </MutableButton>
                    <RedButton
                      color="primary"
                      size="medium"
                      type="button"
                      variant="contained"
                      onClick={handleCloseEmail}
                    >
                      Отменить
                    </RedButton>
                  </DialogActions>
                </form>
              );
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
