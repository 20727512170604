import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const UtkonosIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 40 40">
      <path xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" d="M36.9995 4.33253C36.9995 1.94003 35.0362 -1.68482e-06 32.6147 -1.68482e-06H4.38498C1.96341 -1.68482e-06 0 1.94003 0 4.33253V21.7217C0 31.8164 8.28241 40 18.4996 40C28.7172 40 36.9995 31.8164 36.9995 21.7217C36.9988 14.8004 37.0009 11.2541 36.9995 4.33253Z" fill="#FFC709"/>
      <path xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" d="M23.2726 26.5552C23.2726 25.613 22.495 24.8516 21.5523 24.8516C20.609 24.8516 19.8314 25.613 19.8314 26.5552C19.8314 27.4984 20.609 28.2756 21.5523 28.2756C22.495 28.2756 23.2726 27.4984 23.2726 26.5552ZM17.1681 26.5552C17.1681 25.613 16.391 24.8516 15.4312 24.8516C14.4883 24.8516 13.7274 25.613 13.7274 26.5552C13.7274 27.4984 14.4883 28.2756 15.4312 28.2756C16.391 28.2756 17.1681 27.4984 17.1681 26.5552Z" fill="black"/>
    </SvgIcon>
  );
};
