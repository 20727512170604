import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  SpecialProjectByIdDocument,
  useSendReportSpecialProjectReportMutation,
} from '../../../../../schema';
import { MutableButton } from '../../../../../ui/MutableButton';
import { toast } from 'react-toastify';

export const SendReportSpecialProjectButton = () => {
  const { id } = useParams();
  const [sendReport, { loading, error }] = useSendReportSpecialProjectReportMutation();

  const handleClick = useCallback(async () => {
    try {
      return await sendReport({
        variables: { input: { id } },
        refetchQueries: [{ query: SpecialProjectByIdDocument, variables: { id } }],
      });
    } catch {
      return error && toast.error('Ошибка отправки отчета');
    }
  }, [error, id, sendReport]);
  return (
    <MutableButton onClick={handleClick} loading={loading}>
      Отправить отчёт
    </MutableButton>
  );
};
