import React from 'react';
import {
  BenefactorApplications,
  BenefactorAttendances,
  BenefactorInformation,
  BenefactorSubscriptions,
  BenefactorTransactions,
  BenefactorAchievements,
  BenefactorStatusesGrid,
} from './components';
import { Box, createStyles, makeStyles, Tab, Tabs, Theme } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { useTabs } from 'hooks';
import { TabPanel } from 'components';
import theme from 'theme';
import { BenefactorExtendedPropsFragment, BenefactorPermissions } from '../../../../../schema';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      width: '100%',
    },
  })
);

interface BenefactorHelpProps {
  permissions: BenefactorPermissions;
  benefactor: BenefactorExtendedPropsFragment;
}

export const BenefactorHelp = (props: BenefactorHelpProps) => {
  const { benefactor, permissions } = props;
  const styles = useStyles();

  const initialTab = permissions.viewPaymentInformation ? 0 : 1;
  const { tabIndex, onChangeTab, onChangeTabIndex, a11yProps } = useTabs(initialTab);

  return (
    <>
      <Box mb={1} className={styles.content}>
        <Tabs
          value={tabIndex}
          onChange={onChangeTab}
          indicatorColor="primary"
          textColor="primary"
          aria-label="tabs"
          variant="scrollable"
          scrollButtons="auto"
        >
          {permissions.viewPaymentInformation ? (
            <Tab label="Подписки" value={0} {...a11yProps(0)} />
          ) : (
            ''
          )}
          {benefactor.personalData ||
          (benefactor.skillsInformation && benefactor.skillsInformation.skills?.length > 0) ||
          benefactor.citiesOfInterest.length > 0 ? (
            <Tab label="Информация" value={1} {...a11yProps(1)} />
          ) : (
            ''
          )}
          {permissions.viewPaymentInformation ? (
            <Tab label="Заказы" value={2} {...a11yProps(2)} />
          ) : (
            ''
          )}
          {permissions.viewVolunteerInformation ? (
            <Tab label="Заявки на события" value={3} {...a11yProps(3)} />
          ) : (
            ''
          )}
          {permissions.viewVolunteerInformation ? (
            <Tab label="Участие в событиях" value={4} {...a11yProps(4)} />
          ) : (
            ''
          )}
          {permissions.viewPersonalData ? <Tab label="Награды" value={5} {...a11yProps(5)} /> : ''}
          {permissions.viewPersonalData ? <Tab label="Статусы" value={6} {...a11yProps(6)} /> : ''}
        </Tabs>
      </Box>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={tabIndex}
        onChangeIndex={onChangeTabIndex}
        className={styles.content}
      >
        {permissions.viewVolunteerInformation ? (
          <TabPanel value={tabIndex} index={0} dir={theme.direction}>
            <BenefactorSubscriptions benefactor={benefactor} />
          </TabPanel>
        ) : (
          <></>
        )}
        <TabPanel value={tabIndex} index={1} dir={theme.direction}>
          <BenefactorInformation benefactor={benefactor} permissions={permissions} />
        </TabPanel>
        {permissions.viewPaymentInformation ? (
          <TabPanel value={tabIndex} index={2} dir={theme.direction}>
            <BenefactorTransactions benefactor={benefactor} />
          </TabPanel>
        ) : (
          <></>
        )}
        {permissions.viewVolunteerInformation ? (
          <TabPanel value={tabIndex} index={3} dir={theme.direction}>
            <BenefactorApplications benefactorId={benefactor.id} />
          </TabPanel>
        ) : (
          <></>
        )}
        {permissions.viewVolunteerInformation ? (
          <TabPanel value={tabIndex} index={4} dir={theme.direction}>
            <BenefactorAttendances benefactorId={benefactor.id} />
          </TabPanel>
        ) : (
          <></>
        )}
        {permissions.viewPersonalData ? (
          <TabPanel value={tabIndex} index={5} dir={theme.direction}>
            <BenefactorAchievements benefactor={benefactor} />
          </TabPanel>
        ) : (
          <></>
        )}
        {permissions.viewPersonalData ? (
          <TabPanel value={tabIndex} index={6} dir={theme.direction}>
            <BenefactorStatusesGrid benefactor={benefactor} />
          </TabPanel>
        ) : (
          <></>
        )}
      </SwipeableViews>
    </>
  );
};
