import React from 'react';
import AddSpecialProjectForm from './AddSpecialProjectForm';
import { LayoutPageGrid } from 'ui/LayoutGrid/LayoutGrid';
import { usePermissionPage } from 'hooks';
import { useMeContext } from 'components';

export const AddSpecialProjectPage = () => {
  const { user } = useMeContext();

  usePermissionPage('/special-projects', user?.permissions.specialProjects.manage);

  return (
    <LayoutPageGrid title="Создание спецпроекта">
      <div>
        <AddSpecialProjectForm />
      </div>
    </LayoutPageGrid>
  );
};
