import {
  FoundationEventReport,
  useUpdateFoundationEventReportMutation,
  Document,
  UpdateFoundationEventReportInput,
} from 'schema';
import { useBackRoute } from 'hooks';
import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

interface InitialValuesType {
  text: string;
  images: Array<Document>;
}

export const useEventReportForm = (eventId: string, report?: FoundationEventReport) => {
  const { onCancel } = useBackRoute();

  const [editReport, { data, loading, error }] = useUpdateFoundationEventReportMutation();

  const onSubmit = useCallback(
    async (values: InitialValuesType) => {
      const { text, images } = values;

      const input: UpdateFoundationEventReportInput = {
        id: eventId,
        images: images.map((image) => {
          return {
            id: image.file.id,
            title: image.title,
          };
        }),
        text,
      };

      await editReport({
        variables: input,
      });
    },
    [eventId, editReport]
  );

  const initialValues: InitialValuesType = {
    text: report?.text || '',
    images: report?.images || [],
  };

  useEffect(() => {
    if (error) {
      toast.error('Ошибка редактирования отчета');
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      toast.success('Отчет успешно создан');
      onCancel();
    }
  }, [data, onCancel]);

  return {
    initialValues,
    onCancel,
    onSubmit,
    loading,
  };
};
