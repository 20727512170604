interface AuthRequest {
  grant_type: string;
  refresh_token?: string;
  username?: string;
  password?: string;
}

export const getTokens = async (form: AuthRequest | undefined) => {
  const formData = new URLSearchParams({
    client_id: 'admin-panel',
    scope: 'api offline_access',
  });
  form?.grant_type && formData.set('grant_type', form.grant_type);
  form?.username && formData.set('username', form.username);
  form?.password && formData.set('password', form.password);
  form?.refresh_token && formData.set('refresh_token', form.refresh_token);

  const response = await fetch('/connect/token', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
    },
    body: formData,
  });

  return await response.json();
};
