export const getInitials = (name: string | null | undefined = '') => {
  if (name === null) {
    return null;
  }

  return name
    .replace(/\s+/, ' ')
    .split(' ')
    .slice(0, 2)
    .map((v) => v && v[0].toUpperCase())
    .join('');
};
