import React from 'react';
import { formatDate } from 'helpers';
import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { useStyles } from '../../useStyles';
import { PhotoCard } from './PhotoCard';
import {
  BenefactorExtendedPropsFragment,
  BenefactorPermissions,
} from '../../../../../../../schema';

interface BenefactorInformationProps {
  permissions: BenefactorPermissions;
  benefactor: BenefactorExtendedPropsFragment;
}

export const BenefactorInformation = (props: BenefactorInformationProps) => {
  const { benefactor, permissions } = props;
  const styles = useStyles();
  return (
    <Card>
      {permissions.viewPersonalData && benefactor.personalData && (
        <>
          <CardHeader title="Персональные данные" />
          <CardContent>
            <Grid container spacing={4}>
              <Grid item lg={4} md={4} xl={4} xs={4}>
                <Typography variant="h5" color="textSecondary" gutterBottom>
                  Ф.И.О.
                </Typography>
                <Typography>{benefactor.personalData.name.fullName}</Typography>
              </Grid>
              <Grid item lg={4} md={4} xl={4} xs={4}>
                <Typography variant="h5" color="textSecondary" gutterBottom>
                  Дата рождения
                </Typography>
                <Typography>{formatDate(benefactor.personalData.birthday)}</Typography>
              </Grid>
              <Grid item lg={4} md={4} xl={4} xs={4}>
                <Typography variant="h5" color="textSecondary" gutterBottom>
                  Имя в VK
                </Typography>
                <Typography>{benefactor.personalData.vkUsername}</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item lg={12} md={12} xl={12} xs={12}>
                <Typography variant="h5" color="textSecondary" gutterBottom>
                  Информация о пользователе
                </Typography>
                <Typography>{benefactor.personalData.about}</Typography>
              </Grid>
            </Grid>
          </CardContent>
        </>
      )}
      {permissions.viewVolunteerInformation && benefactor.volunteerInformation && (
        <>
          <CardHeader title="Опыт волонтера" />
          <CardContent>
            <Grid container spacing={4}>
              <Grid item lg={12} md={12} xl={12} xs={12}>
                <Typography>{benefactor.volunteerInformation.experience}</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item lg={12} md={12} xl={12} xs={12} className={styles.photoContainer}>
                {benefactor.volunteerInformation.documents.map((document) => {
                  return (
                    <Link key={document.file.id} target="_blank" href={document.file.url}>
                      <PhotoCard document={document} />
                    </Link>
                  );
                })}
              </Grid>
            </Grid>
          </CardContent>
        </>
      )}
      {permissions.viewVolunteerInformation && benefactor.skillsInformation && (
        <>
          <CardHeader title="Навыки" />
          <CardContent>
            {benefactor.skillsInformation.skills?.map((skill) => {
              return <Chip className={styles.skillItem} key={skill?.id} label={skill?.title} />;
            })}
          </CardContent>
          <CardContent>
            <Grid container spacing={4}>
              <Grid item lg={4} md={4} xl={4} xs={4}>
                <Typography variant="h5" color="textSecondary" gutterBottom>
                  Портфолио
                </Typography>
                <Link
                  href={benefactor.skillsInformation.portfolio || '#'}
                  rel="noreferrer"
                  target="_blank"
                >
                  <Typography>{benefactor.skillsInformation.portfolio}</Typography>
                </Link>
              </Grid>
              <Grid item lg={12} md={12} xl={8} xs={12}>
                <Typography variant="h5" color="textSecondary" gutterBottom>
                  Опыт
                </Typography>
                <Typography>{benefactor.skillsInformation.experience}</Typography>
              </Grid>
            </Grid>
          </CardContent>
        </>
      )}
      {permissions.viewVolunteerInformation && benefactor.citiesOfInterest.length > 0 && (
        <>
          <CardHeader title="Отслеживаемые города" />
          <CardContent>
            <List>
              {benefactor.citiesOfInterest.map((city) => {
                return (
                  <ListItem key={city}>
                    <ListItemText primary={city} />
                  </ListItem>
                );
              })}
            </List>
          </CardContent>
        </>
      )}
    </Card>
  );
};
