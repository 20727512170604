import React from 'react';
import { PropertiesTable, PropertiesTableRow } from 'ui/PropertiesTable';
import { DonationsPaymentQuery, getTypeDonation } from '../../../../schema';

import { formatDate, toDateTimeString } from '../../../../helpers';
import { Divider } from '@material-ui/core';
import { useStyles } from '../../BenefactorManagePage/components/BenefactorCard/useStyles';
import { OrderAmountSummaryView } from './OrderAmountSummaryView';

export interface BenefactorTransactionDataProps {
  transaction?: DonationsPaymentQuery;
}

const getPropertiesForTableRow = (payment: DonationsPaymentQuery) => {
  if (!payment.orderById?.failureMessage) {
    if (payment.orderById?.paymentDetails) {
      const paymentDetails = payment.orderById.paymentDetails;
      switch (paymentDetails.__typename) {
        case 'CloudPaymentsCardSuccessfulPaymentDetails':
          return (
            <>
              <PropertiesTableRow caption="Тип платежа" value={'Оплата картой'} />
              <PropertiesTableRow caption="Номер транзакции" value={paymentDetails.transactionId} />
              <PropertiesTableRow
                caption="Код авторизации"
                value={paymentDetails.authorizationCode}
              />
              <PropertiesTableRow
                caption="Дата авторизации"
                value={toDateTimeString(new Date(paymentDetails.authorizationDate))}
              />
            </>
          );
        case 'BankTransferPaymentDetails':
          return (
            <>
              <PropertiesTableRow caption="Тип платежа" value={'Банковский перевод'} />
              <PropertiesTableRow
                caption="Название контрагента"
                value={paymentDetails.counterpartyName}
              />
              <PropertiesTableRow
                caption="Номер платежного документа"
                value={paymentDetails.documentNumber}
              />
              <PropertiesTableRow
                caption="Дата авторизации"
                value={toDateTimeString(new Date(paymentDetails.transferDate))}
              />
            </>
          );
        case 'CloudPaymentsSbpSuccessfulPaymentDetails':
          return (
            <>
              <PropertiesTableRow caption="Тип платежа" value={'Оплата через СБП'} />
              <PropertiesTableRow caption="Номер транзакции" value={paymentDetails.transactionId} />
            </>
          );
        case 'CloudPaymentsCardFailedPaymentDetails':
          return (
            <>
              <PropertiesTableRow caption="Тип платежа" value={'Оплата картой'} />
              <PropertiesTableRow caption={'Сообщение'} value={paymentDetails.cardholderMessage} />
            </>
          );
        case 'CloudPaymentsSbpFailedPaymentDetails':
          return (
            <>
              <PropertiesTableRow caption="Тип платежа" value={'Оплата через СБП'} />
              <PropertiesTableRow caption={'Сообщение'} value={paymentDetails.message} />
            </>
          );
        case 'VoucherSuccessfulPaymentDetails':
          return (
            <>
              <PropertiesTableRow caption="Тип платежа" value={'Оплата ваучером'} />
              <PropertiesTableRow caption="Ваучер" value={paymentDetails.voucher.title} />
            </>
          );
        case 'VoucherFailedPaymentDetails':
          return (
            <>
              <PropertiesTableRow caption="Тип платежа" value={'Оплата ваучером'} />
              <PropertiesTableRow
                caption="Ваучер"
                value={paymentDetails.maybeVoucher?.title ?? 'Не найден'}
              />
              <PropertiesTableRow caption="Сообщение" value={paymentDetails.reason} />
            </>
          );
        case 'GenericFailedPaymentDetails':
          return <PropertiesTableRow caption={'Сообщение'} value={paymentDetails.failureMessage} />;
      }
    } else if (payment.orderById?.paymentIntent) {
      const paymentIntent = payment.orderById.paymentIntent;

      switch (paymentIntent.__typename) {
        case 'BankTransferPaymentIntent':
          return (
            <>
              <PropertiesTableRow caption="Тип платежа" value={'Банковский перевод'} />
              <PropertiesTableRow
                caption="Номер платежного документа"
                value={paymentIntent.documentNumber}
              />
            </>
          );
        case 'CloudPaymentsSavedCardPaymentIntent':
          return (
            <>
              <PropertiesTableRow caption="Тип платежа" value={'Оплата картой'} />

              <PropertiesTableRow
                caption="Номер карты"
                value={
                  paymentIntent.savedCard
                    ? paymentIntent.savedCard?.maskedCardNumber
                    : 'Карта не найдена"'
                }
              />
            </>
          );
        case 'CloudPaymentsNewCardPaymentIntent':
          return (
            <>
              <PropertiesTableRow caption="Тип платежа" value={'Оплата картой'} />
              <PropertiesTableRow caption="Номер карты" value={'Новая карта'} />;
            </>
          );
        case 'CloudPaymentsSbpPaymentIntent':
          return (
            <>
              <PropertiesTableRow caption="Тип платежа" value={'Оплата через СБП'} />
            </>
          );
        case 'VoucherPaymentIntent':
          return (
            <>
              <PropertiesTableRow caption="Тип платежа" value="Ваучер" />
              <PropertiesTableRow
                caption="Ваучер"
                value={paymentIntent.voucher?.title ?? 'Не найден'}
              />
            </>
          );
      }
    }
  } else {
    return <PropertiesTableRow caption="Сообщение" value={payment.orderById.failureMessage} />;
  }
};

export const BenefactorTransactionData = ({ transaction }: BenefactorTransactionDataProps) => {
  const payment = transaction?.orderById;
  const classes = useStyles();

  return (
    <>
      <PropertiesTable>
        <PropertiesTableRow caption="Дата платежа" value={formatDate(payment?.created.timestamp)} />
        <PropertiesTableRow caption={'Тип заказа'} value={getTypeDonation(transaction)} />
        {getPropertiesForTableRow(transaction!)}
        <PropertiesTableRow caption="Общая сумма" value={payment?.amount} isAmount />
      </PropertiesTable>
      <Divider className={classes.divider} />
      {payment?.intentAmountSummary ? (
        <OrderAmountSummaryView
          title="Планируемое распределение средств"
          summary={payment.intentAmountSummary}
        />
      ) : null}
      {payment?.actualAmountSummary ? (
        <OrderAmountSummaryView
          title="Фактическое распределение средств"
          summary={payment.actualAmountSummary}
        />
      ) : null}
    </>
  );
};
