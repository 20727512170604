import React from 'react';
import { LayoutPageGrid } from 'ui/LayoutGrid';
import {
  Breadcrumbs,
  Card,
  CardContent,
  createStyles,
  Link,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { InformationBlock, MemberManagementBlock } from '../components';
import { EventPageProps } from './types';
import { usePermissionPage } from 'hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  })
);

export const EventPage = (props: EventPageProps) => {
  const { event, permissions } = props;
  const styles = useStyles();

  usePermissionPage('/foundations', permissions?.edit);

  return (
    <>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
        <Link color="inherit" href={`/foundations/manage/${event.foundation?.id}`}>
          {`${event.foundation?.name}`}
        </Link>
        <Typography color="textPrimary" />
      </Breadcrumbs>
      <LayoutPageGrid title={event?.details.title || ''}>
        <Card>
          <CardContent className={styles.content}>
            <InformationBlock permissions={permissions} event={event} />
          </CardContent>
        </Card>
        <Card>
          <CardContent className={styles.content}>
            <MemberManagementBlock
              eventId={event.id}
              selectApplications={permissions.selectApplications}
              rateAttendees={permissions.rateAttendees}
            />
          </CardContent>
        </Card>
      </LayoutPageGrid>
    </>
  );
};
