import React from 'react';
import { OneTimeNeed } from 'schema';
import { IAccountProps } from 'pages/components';
import { CloseAccount, FillAccount } from '../';

export const AccountActions = ({ account, need, period, project }: IAccountProps) => {
  if (!account) {
    return null;
  }

  const { __typename } = account,
    showFillControl = account.closedAt === null,
    showCloseControlForNeed =
      (((need as unknown) as OneTimeNeed)?.documents?.length || period?.documents?.length) &&
      need?.archived === null &&
      account.closedAt !== null,
    showCloseControl = __typename === 'ProjectExpenseAccount' && account.closedAt;

  return (
    <>
      {showFillControl && <FillAccount account={account} />}
      {showCloseControlForNeed ? (
        <CloseAccount account={account} need={need} period={period} />
      ) : null}

      {showCloseControl ? <CloseAccount account={account} need={need} project={project} /> : null}
    </>
  );
};
