import React from 'react';
import { Route, RouteProps, RouteComponentProps } from 'react-router-dom';

export interface RouteWithLayoutProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  componentProps: Record<string, unknown>;
}

export const RouteWithoutLayout = (props: RouteWithLayoutProps) => {
  const { component: Component, componentProps, ...rest } = props;

  return (
    <Route
      {...rest}
      render={(matchProps) => <Component {...componentProps} {...rest} {...matchProps} />}
    />
  );
};
