import {
  Benefactor,
  UpdateBenefactorPersonalDataByAdministratorInput,
  useUpdateBenefactorPersonalDataByAdministratorMutation,
} from 'schema';
import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

interface InitialValuesType {
  firstName: string;
  lastName: string;
  middleName?: string | null;
  birthday: string;
  vkUsername?: string | null;
  about?: string | null;
}

export const useEditBenefactorPersonalDataForm = (benefactor: Benefactor, onCancel: () => void) => {
  const [
    personalUpdate,
    { data, error, loading },
  ] = useUpdateBenefactorPersonalDataByAdministratorMutation();

  const onSubmit = useCallback(
    async (values: InitialValuesType) => {
      const { middleName, about, vkUsername, ...rest } = values;
      const inputValues: UpdateBenefactorPersonalDataByAdministratorInput = {
        ...rest,
        middleName: middleName !== '' ? middleName : undefined,
        about: about !== '' ? about : undefined,
        vkUsername: vkUsername !== '' ? vkUsername : undefined,
        benefactorId: benefactor.id,
      };

      await personalUpdate({
        variables: {
          input: inputValues,
        },
      });
    },
    [personalUpdate, benefactor]
  );

  useEffect(() => {
    if (error) {
      toast.error('Ошибка изменения персональных данных');
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      toast.success('Персональные данные пользователя успешно изменены');
      onCancel();
    }
  }, [data, onCancel]);

  const initialValues: InitialValuesType = {
    firstName: benefactor.personalData?.name.firstName || '',
    lastName: benefactor.personalData?.name.lastName || '',
    middleName: benefactor.personalData?.name.middleName,
    birthday: benefactor.personalData?.birthday || '',
    vkUsername: benefactor.personalData?.vkUsername,
    about: benefactor.personalData?.about,
  };

  return {
    initialValues,
    onSubmit,
    loading,
  };
};
