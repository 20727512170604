import React from 'react';
import { Backdrop, createStyles, Fade, makeStyles, Modal, Theme } from '@material-ui/core';
import { useBackRoute } from 'hooks';
import { DeleteStoryModalForm } from './DeleteStoryModalForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    form: {
      padding: '15px',
      backgroundColor: theme.palette.background.paper,
      border: '0px',
      borderRadius: '2px',
      boxShadow: theme.shadows[5],
    },
  })
);

export const DeleteStoryModal = () => {
  const styles = useStyles();

  const { onCancel } = useBackRoute();

  return (
    <Modal
      open={true}
      onClose={onCancel}
      aria-labelledby="Удаление истории в блоке"
      aria-describedby="Подтверждение удаление истории"
      className={styles.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={true}>
        <DeleteStoryModalForm onClose={onCancel} />
      </Fade>
    </Modal>
  );
};
