import React from 'react';
import { Table as MuiTable, TableHead, TableRow, TableBody, TableCell } from '@material-ui/core';
import { SortableTableHeader } from './SortableTableHeader';
import { SortableTableProps } from './types';

export const SortableTable = <T,>(props: SortableTableProps<T>) => {
  const { data = [], columns, getDataKey, onSort, sortBy, style, sortDirection, ...rest } = props;
  return (
    <MuiTable {...rest}>
      <TableHead>
        <TableRow>
          {columns.map((column) => {
            const active = sortBy === column.id;
            return (
              <SortableTableHeader
                key={column.id}
                {...column}
                sortDirection={sortDirection}
                onSort={onSort}
                active={active}
              />
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {data?.map((entity: T) => (
          <TableRow hover key={getDataKey(entity)} selected={false}>
            {columns.map(({ render, id, header, sortable, ...rest }) => {
              return (
                <TableCell key={id} {...rest}>
                  {render(entity)}
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </TableBody>
    </MuiTable>
  );
};
