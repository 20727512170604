import { makeStyles } from '@material-ui/styles';
import { createStyles, Theme } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      color: theme.palette.primary.dark,
    },
    completeIcon: {
      color: green[500],
    },
    iconColor: {
      color: theme.palette.text.secondary,
    },
    button: {
      float: 'right',
    },
  })
);
