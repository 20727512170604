export const IsRequiredMessage = 'Необходимо заполнить';

export const createRequired = (errorMessage: string | undefined = IsRequiredMessage) => (value: any) => {
  if (value === undefined || value === null) {
    return errorMessage;
  }

  if (typeof value === 'string' && value === '') {
    return errorMessage;
  }
};

export const required = createRequired();
