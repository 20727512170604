import {
  Benefactor,
  UpdateBenefactorByAdministratorInput,
  useUpdateBenefactorByAdministratorMutation,
} from 'schema';
import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

interface InitialValuesType {
  name: string;
  photoId?: string;
}

export const useEditBenefactorForm = (benefactor: Benefactor, onCancel: () => void) => {
  const [systemUpdate, { data, error, loading }] = useUpdateBenefactorByAdministratorMutation();

  const onSubmit = useCallback(
    async (values: InitialValuesType) => {
      const { name, photoId } = values;

      const inputValues: UpdateBenefactorByAdministratorInput = {
        photoId: photoId ? photoId : undefined,
        displayName: name,
        benefactorId: benefactor.id,
      };

      await systemUpdate({
        variables: {
          input: inputValues,
        },
      });
    },
    [systemUpdate, benefactor]
  );

  useEffect(() => {
    if (error) {
      toast.error('Ошибка изменения системных данных');
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      toast.success('Системные данные пользователя успешно изменены');
      onCancel();
    }
  }, [data, onCancel]);

  const initialValues: InitialValuesType = {
    name: benefactor.displayName || '',
    photoId: benefactor.photo?.id,
  };

  return {
    initialValues,
    onSubmit,
    loading,
  };
};
