import React from 'react';
import { Button, Card, CardContent } from '@material-ui/core';
import { WardsGrid } from './WardsGrid';
import { Link } from 'react-router-dom';
import { LayoutPageGrid } from 'ui/LayoutGrid/LayoutGrid';
import { useMeContext } from 'components';

export const WardsPage = () => {
  const { user } = useMeContext();

  const isAllowed = user?.permissions.wards.create;
  return (
    <LayoutPageGrid
      title="Подопечные"
      tools={
        isAllowed ? (
          <Button color="primary" variant="contained" component={Link} to="/wards/create">
            Добавить подопечного
          </Button>
        ) : (
          <></>
        )
      }
    >
      <Card>
        <CardContent>
          <WardsGrid />
        </CardContent>
      </Card>
    </LayoutPageGrid>
  );
};
