import React from 'react';
import { Backdrop, createStyles, Fade, makeStyles, Modal, Theme } from '@material-ui/core';
import { QueryVariables, useBackRoute } from 'hooks';
import { AddAwardModalForm } from './AddAwardModalForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    form: {
      padding: '15px',
      backgroundColor: theme.palette.background.paper,
      border: '0px',
      borderRadius: '2px',
      boxShadow: theme.shadows[5],
    },
  })
);

interface AddAwardModalProps {
  variables?: QueryVariables<{}, {}>;
}
export const AddAwardModal = ({ variables }: AddAwardModalProps) => {
  const styles = useStyles();

  const { onCancel } = useBackRoute();

  return (
    <Modal
      open={true}
      onClose={onCancel}
      aria-labelledby="Добавление новой награды"
      aria-describedby="Используйте эту форму для добавления новой награды"
      className={styles.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={true}>
        <AddAwardModalForm variables={variables} onClose={onCancel} />
      </Fade>
    </Modal>
  );
};
