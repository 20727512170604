import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { CompanyPage } from './CompanyPage';
import { CompanyReportPage } from './CompanyReportPage';
import { usePermissionPage } from 'hooks';
import { useMeContext } from 'components';

export const CompanyContainer = () => {
  const { user } = useMeContext();

  usePermissionPage('/wards', user?.permissions.limitedLiabilityCompany.manage);

  return (
    <Switch>
      <Route exact path="/company/report/:id" component={CompanyReportPage} />
      <Route path="/company" component={CompanyPage} />
    </Switch>
  );
};
