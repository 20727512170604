import React from 'react';
import Icon from '@material-ui/core/Icon';
import { green } from '@material-ui/core/colors';

export type OneTimeNeedIconNumber = 0 | 1 | 2;

export interface OneTimeNeedIconProps {
  number: OneTimeNeedIconNumber;
  className?: string;
}

export const OneTimeNeedIcon = (props: OneTimeNeedIconProps) => {
  const { number, className } = props;

  // TODO: switch on number to choose real icon
  switch (number) {
    case 0:
      return (
        <Icon className={className} color="primary">
          {number}
        </Icon>
      );
    case 1:
      return (
        <Icon className={className} color="secondary">
          {number}
        </Icon>
      );
    case 2:
      return (
        <Icon className={className} style={{ color: green[500] }}>
          {number}
        </Icon>
      );
  }
};
