import { useCallback, useContext, useEffect } from 'react';
import {
  AttendanceDocument,
  UpdateFoundationEventAttendanceRatingInput,
  useUpdateFoundationEventAttendanceRatingMutation,
} from 'schema';
import { toast } from 'react-toastify';
import { AttendanceQueryContext, QueryVariablesContext } from '../../QueryContextProvider';

interface InitialValuesType {
  rating: number;
  comment: string;
}

export const useAddRatingForm = (onClose: () => void, attendeeId: string) => {
  const variables = useContext<AttendanceQueryContext>(QueryVariablesContext);

  const attendance = variables?.attendances?.filter((item) => item.id === attendeeId);

  const [
    updateRating,
    { data, error, loading },
  ] = useUpdateFoundationEventAttendanceRatingMutation();

  const onSubmit = useCallback(
    async (values: InitialValuesType) => {
      const inputData: UpdateFoundationEventAttendanceRatingInput = {
        attendanceId: attendeeId,
        comment: values.comment,
        rating: Number(values.rating),
      };

      await updateRating({
        variables: {
          input: inputData,
        },
        refetchQueries: [
          {
            query: AttendanceDocument,
            variables: variables?.createVariables,
          },
        ],
      });
    },
    [attendeeId, updateRating, variables]
  );

  useEffect(() => {
    if (error) {
      toast.error('Ошибка добавления рейтинга');
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      onClose();
    }
  }, [data, onClose]);

  const initialValues: InitialValuesType = {
    rating: attendance ? Number(attendance[0].foundationRating) : 0,
    comment: attendance && attendance[0].foundationComment ? attendance[0].foundationComment : '',
  };

  return { initialValues, onSubmit, loading };
};
