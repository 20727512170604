import React from 'react';
import { makeStyles, Theme, Button, createStyles } from '@material-ui/core';
import { Gender, WardPropsFragment } from 'schema';
import { Link } from 'react-router-dom';
import { PowerSettingsNewOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: theme.palette.common.black,
      borderColor: theme.palette.common.black,
      '&:hover': {
        borderColor: theme.palette.common.black,
      },
      marginLeft: theme.spacing(1),
    },
  })
);

interface DeactivateWardButtonProps {
  ward: WardPropsFragment;
}

export const DeactivateWardButton = (props: DeactivateWardButtonProps) => {
  const classes = useStyles();
  const { ward } = props;

  if (ward.deactivationInformation != null) {
    return null;
  }

  const title =
    ward.publicInformation.gender === Gender.Female
      ? 'Деактивировать подопечную'
      : 'Деактивировать подопечного';
  const link = `/wards/manage/${ward.id}/edit/deactivate`;

  return (
    <Button
      className={classes.button}
      variant="outlined"
      startIcon={<PowerSettingsNewOutlined />}
      component={Link}
      to={link}
    >
      {title}
    </Button>
  );
};
