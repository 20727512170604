import { useCallback, useEffect, useMemo } from 'react';
import {
  ProjectDocument,
  ProjectRequirements,
  UpdateProjectRequirementsInput,
  useUpdateProjectRequirementsMutation,
} from 'schema/serverTypes';
import { useBackRoute } from 'hooks';
import { toast } from 'react-toastify';

type Props = {
  requirements?: ProjectRequirements;
};

export const useUpdateProjectRequirementsForm = ({ requirements }: Props) => {
  const { onCancel } = useBackRoute();

  const initialValues: UpdateProjectRequirementsInput = {
    remainingDuration: requirements?.remainingDuration || '',
    estimatedMonthlyExpensesAmount: requirements?.estimatedMonthlyExpensesAmount || 1,
  };

  const refetchQueries = useMemo(() => {
    return [
      {
        query: ProjectDocument,
      },
    ];
  }, []);

  const [
    updateProjectRequirements,
    { data, loading, error },
  ] = useUpdateProjectRequirementsMutation();

  useEffect(() => {
    if (error) {
      toast.error('Произошла ошибка изменения потребностей');
    }
  }, [error]);

  useEffect(() => {
    if (data?.updateProjectRequirements.success) {
      onCancel();
    }
  }, [data, onCancel]);

  const onSubmit = useCallback(
    (values: UpdateProjectRequirementsInput) => {
      const input: UpdateProjectRequirementsInput = {
        remainingDuration: values.remainingDuration,
        estimatedMonthlyExpensesAmount: Math.round(values.estimatedMonthlyExpensesAmount),
      };
      updateProjectRequirements({
        refetchQueries,
        variables: { input },
        awaitRefetchQueries: true,
      });
    },
    [updateProjectRequirements, refetchQueries]
  );

  return {
    initialValues,
    onSubmit,
    onCancel,
    data,
    loading,
    error,
  };
};
