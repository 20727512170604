export default {
  root: {
    whiteSpace: 'nowrap',
  },
  contained: {
    boxShadow: 'none',
    backgroundColor: '#888',
    color: '#eee',
    transition: '.2s ease',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#777',
      color: '#ddd',
    },
  },
};
