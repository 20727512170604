import React from 'react';
import {
  Grid,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Button,
  CardActions,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import { AutoFocusedForm, PhotoField, required, TextField } from 'components';
import { Field } from 'react-final-form';
import { useCategoryForm } from './useCategoryForm';
import { Alert } from '@material-ui/lab/';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minWidth: 700,
    },
    header: {
      fontWeight: 'bolder',
    },
    divider: {},
    content: {},
    actions: {
      justifyContent: 'flex-end',
    },
  })
);

export interface CreateVersionFormProps {
  onClose: () => void;
}

export const CreateCategoryForm = ({ onClose }: CreateVersionFormProps) => {
  const styles = useStyles();
  const { onSubmit, initialValues } = useCategoryForm({
    onClose,
  });

  return (
    <AutoFocusedForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      subscription={{
        submitError: true,
      }}
      render={({ handleSubmit, pristine, submitError }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card className={styles.root}>
              <CardHeader title="Добавление категории" />
              <Divider className={styles.divider} />
              <CardContent className={styles.content}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} xl={12} xs={12}>
                    <Field name="imageId" component={PhotoField} validate={required} />
                  </Grid>
                  <Grid item lg={6} md={6} xl={12} xs={12}>
                    <Field
                      name="name"
                      label="Название *"
                      component={TextField}
                      validate={required}
                    />
                  </Grid>
                </Grid>
                {submitError && <Alert severity="error">{submitError}</Alert>}
              </CardContent>
              <CardActions className={styles.actions}>
                <Button
                  color="primary"
                  disabled={pristine}
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  Добавить
                </Button>
                <Button onClick={onClose} size="medium" type="button" variant="contained">
                  Отменить
                </Button>
              </CardActions>
            </Card>
          </form>
        );
      }}
    />
  );
};
