import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { YMaps, Map, Placemark } from 'react-yandex-maps';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    map: {
      width: '100%',
      minHeight: 250,
      marginTop: theme.spacing(4),
      backgroundColor: theme.palette.background.default,
    },
  })
);

export type MapPanelProps = {
  latitude: number;
  longitude: number;
  height?: number;
  width?: number;
  distance?: number;
};

export const MapPanel = (props: MapPanelProps) => {
  const classes = useStyles();

  const { latitude, longitude, height = 250, distance = 16 } = props;

  return (
    <YMaps
      query={{
        ns: 'use-load-option',
        load: 'Map,Placemark,control.ZoomControl,control.FullscreenControl,geoObject.addon.balloon',
      }}
    >
      {latitude && longitude ? (
        <Map
          defaultState={{
            center: [latitude, longitude],
            zoom: distance,
            controls: ['zoomControl'],
          }}
          height={height}
          className={classes.map}
        >
          <Placemark defaultGeometry={[latitude, longitude]} />
        </Map>
      ) : null}
    </YMaps>
  );
};
