import React, { useMemo } from 'react';

import { RegularNeedPeriodPropsFragment } from '../../../../../schema';
import { PropertiesTable, PropertiesTableRow } from '../../../../../ui/PropertiesTable';
import { formatDate } from '../../../../../helpers';

type Props = {
  period?: RegularNeedPeriodPropsFragment | null;
};

export const RegularNeedPeriodInformation = ({ period }: Props) => {
  const status = useMemo<string>(() => {
    if (period?.need.archived !== null) {
      return 'Заархивирована';
    } else if (period?.reportSentAt !== null) {
      return 'Отчёт отправлен';
    } else if (period?.account?.closedAt !== null) {
      return 'Средства собраны';
    } else {
      return 'Сбор средств';
    }
  }, [period]);

  return (
    <PropertiesTable>
      <PropertiesTableRow caption="Баланс" value={period?.account?.amount} isAmount />
      <PropertiesTableRow
        caption="Необходимая сумма"
        value={period?.need?.requiredAmount}
        isAmount
      />
      <PropertiesTableRow caption="Дата завершения" value={formatDate(period?.end)} />
      {period?.reportSentAt && (
        <PropertiesTableRow
          caption="Дата отправки отчёта"
          value={formatDate(period?.reportSentAt)}
        />
      )}
      <PropertiesTableRow caption="Статус" value={status} />
    </PropertiesTable>
  );
};
