import { ScreenStoryPropsFragment } from '../schema';

const updateStoryKey = 'updateStoryData';

const get = (id: string) => {
  try {
    const updatedStoryArray = localStorage.getItem(`${updateStoryKey}${id}`);

    return JSON.parse(updatedStoryArray!) as ScreenStoryPropsFragment[];
  } catch (error) {
    return undefined;
  }
};

const save = (id: string, updatedData: ScreenStoryPropsFragment[]) => {
  try {
    localStorage.setItem(`${updateStoryKey}${id}`, JSON.stringify(updatedData));
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

const clear = (id: string) => {
  try {
    localStorage.removeItem(`${updateStoryKey}${id}`);
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export const editStoryStorage = {
  get,
  save,
  clear,
};
