import React, { useMemo } from 'react';

import { DataGrid } from 'components';
import { useQueryVariables, ColumnConfig, DataConnection } from 'hooks';

import {
  BroadcastNotification,
  BroadcastNotificationStatus,
  useBroadcastNotificationsQuery,
} from 'schema/serverTypes';
import { DataGridProps } from 'components/DataGrid/types';
import { QueryContextProvider } from '../../QueryContextProvider';
import { CreateNotificationPage } from '../../CreateNotificationPage';
import { Route } from 'react-router-dom';
import moment from 'moment';
import {
  BroadcastNotificationRecipientsLabels,
  BroadcastNotificationStatusLabels,
} from '../../enumsObjects';
import { Typography } from '@material-ui/core';

const getDataKey = (notification: BroadcastNotification) => notification.id;

export const NotificationsGrid = () => {
  const config = useMemo(() => {
    const columns: ColumnConfig<BroadcastNotification>[] = [
      {
        id: 'createdAt',
        header: 'Дата создания',
        render: (notification: BroadcastNotification) =>
          moment(notification.created.timestamp).format('LLL'),
      },
      {
        id: 'title',
        header: 'Заголовок',
        render: (notification: BroadcastNotification) => (
          <span title={notification.text}>{notification.title}</span>
        ),
      },
      {
        id: 'recipients',
        header: 'Получатели',
        render: (notification: BroadcastNotification) =>
          notification.recipients !== null
            ? BroadcastNotificationRecipientsLabels[notification.recipients!]
            : 'Список из файла',
      },
      {
        id: 'buttonText',
        header: 'Текст кнопки',
        render: (notification: BroadcastNotification) => notification.buttonText,
      },
      {
        id: 'populatedNotificationsCount',
        header: 'Кол-во уведомлений',
        render: (notification: BroadcastNotification) => notification.populatedNotificationsCount,
      },
      {
        id: 'status',
        header: 'Статус',
        render: (notification: BroadcastNotification) =>
          notification.status === null ? (
            <Typography color={'error'}>{BroadcastNotificationStatus.Error}</Typography>
          ) : (
            BroadcastNotificationStatusLabels[notification.status]
          ),
      },
    ];
    return {
      columns,
    };
  }, []);

  const {
    onSort,
    onSearch,
    onPage,
    variables,
    extractPagedData,
    columns,
    sortDirection,
    sortBy,
  } = useQueryVariables<BroadcastNotification>(config);

  const { data } = useBroadcastNotificationsQuery({ variables, pollInterval: 5000 });

  const entities = data?.broadcastNotifications as DataConnection<BroadcastNotification>;
  const { data: wards, count, startCursor, endCursor, page, rowsPerPage } = extractPagedData(
    entities
  );

  const gridProps: DataGridProps<BroadcastNotification> = {
    data: wards,
    onSort,
    onPage,
    onSearch,
    columns,
    getDataKey,
    count,
    startCursor: startCursor,
    endCursor: endCursor,
    page,
    rowsPerPage,
    sortDirection,
    sortBy,
  };

  return (
    <QueryContextProvider variables={variables}>
      <DataGrid<BroadcastNotification> {...gridProps} />
      <Route exact path="/notifications/add" component={CreateNotificationPage} />
    </QueryContextProvider>
  );
};
