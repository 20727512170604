import { useCallback, useEffect } from 'react';
import {
  CancelSpecialProjectInput,
  SpecialProjectByIdDocument,
  SpecialProjectsDocument,
  useCancelSpecialProjectMutation,
} from 'schema/serverTypes';

import { FORM_ERROR, SubmissionErrors } from 'final-form';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

const initialValues: CancelSpecialProjectInput = {
  id: '',
  reason: '',
};

type Props = {
  onClose: () => void;
};

export const useCancelSpecialProjectModalForm = ({ onClose }: Props) => {
  const [cancel, { data, loading, error }] = useCancelSpecialProjectMutation();

  const { id } = useParams<{ id: string }>();

  const onSubmit = useCallback(
    async (values: CancelSpecialProjectInput) => {
      const { reason } = values;
      const input: CancelSpecialProjectInput = {
        id,
        reason,
      };
      try {
        return await cancel({
          variables: { input },
          refetchQueries: [
            {
              query: SpecialProjectByIdDocument,
              variables: { id },
            },
            {
              query: SpecialProjectsDocument,
              variables: { first: 50, includeClosedOrCancelled: false },
            },
          ],
        });
      } catch {
        error && toast.error('Ошибка отмены спецпроекта');
        return { [FORM_ERROR]: 'Ошибка отмены' } as SubmissionErrors;
      }
    },
    [cancel, error, id]
  );

  useEffect(() => {
    if (data !== undefined) {
      onClose();
    }
  }, [data, onClose]);

  return { initialValues, onSubmit, loading, data };
};
