import React, { useEffect, useState } from 'react';

import { Box, Button, createStyles, makeStyles } from '@material-ui/core';
import { Link, Route } from 'react-router-dom';
import { CardPageGrid } from '../../../../ui/CardGrid';
import { CategoryPropsFragment, useCategoriesQuery } from '../../../../schema';
import { Loading } from '../../../../ui/Loading';
import { toast } from 'react-toastify';
import { CategoryCard } from './components';
import UpdateOrderCategoriesPage from '../UpdateOrderCategories/UpdateOrderCategoriesPage';
import { EditCategoryPage } from '../EditCategory';

const useStyles = makeStyles(() =>
  createStyles({
    toolsContainer: {
      display: 'flex',
      gap: '10px',
    },
  })
);

export const CategoriesGrid = () => {
  const classes = useStyles();
  const { data, loading, error } = useCategoriesQuery();
  const [categories, setCategories] = useState<CategoryPropsFragment[] | null>(null);

  useEffect(() => {
    const slicedCategories = data?.categories
      ?.slice()
      .map((category) => category) as CategoryPropsFragment[];
    const sortedCategories = slicedCategories?.sort((a, b) => {
      return a.order - b.order;
    });
    setCategories(sortedCategories);
  }, [data]);

  useEffect(() => {
    if (error) {
      toast.error('Ошибка загрузки категорий');
    }
  }, [error]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <CardPageGrid
        title="Категории"
        tools={
          <Box className={classes.toolsContainer}>
            <Button color="primary" variant="contained" component={Link} to="/categories/add">
              Добавить
            </Button>
            <Button color="primary" variant="contained" component={Link} to="/categories/order">
              Упорядочить категории
            </Button>
          </Box>
        }
      >
        {categories?.map(({ id, name, image }) => (
          <CategoryCard key={id} id={id} name={name} image={image} />
        ))}
      </CardPageGrid>
      <Route exact path={'/categories/edit/:id'} render={() => <EditCategoryPage />} />
      <Route
        exact
        path={'/categories/order'}
        render={() => <UpdateOrderCategoriesPage categories={categories} />}
      />
    </>
  );
};
