import React, { useMemo } from 'react';
import { createStyles, makeStyles, Paper, Theme } from '@material-ui/core';
import { SortableElement } from 'react-sortable-hoc';
import { DragHandle } from './DragHandle';
import { Document } from 'schema';
import { DocumentItem } from './FileItem';
import DescriptionTwoToneIcon from '@material-ui/icons/DescriptionTwoTone';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    photoItem: {
      margin: '8px',
      width: '202px',
      height: '210px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      cursor: 'grabbing',
    },
    fileIcon: {
      width: '100px',
      height: '100px',
    },
  })
);

interface DragNDropPhotoItemProps {
  file: Document;
  removeFile: (index: number) => Document;
  updateFile: (index: number, file: Document) => void;
  fileIndex: number;
}

export const DragNDropFileItem = SortableElement(
  ({ file, removeFile, fileIndex, updateFile }: DragNDropPhotoItemProps) => {
    const styles = useStyles();

    const fileType = useMemo(() => {
      return file.file.id.split('.')[1];
    }, [file]);

    const createBackgroundImage = (url: string) => {
      return {
        backgroundImage: `url(${url})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      };
    };

    return (
      <Paper
        elevation={3}
        className={styles.photoItem}
        style={createBackgroundImage(file.file.url)}
      >
        {fileType === 'pdf' && <DescriptionTwoToneIcon className={styles.fileIcon} />}
        <DragHandle />
        <DocumentItem file={file} onRemove={removeFile} onUpdate={updateFile} index={fileIndex} />
      </Paper>
    );
  }
);
