import React, { useMemo } from 'react';

import { makeStyles } from '@material-ui/styles';
import { Typography, Theme, createStyles } from '@material-ui/core';

import { CompleteIcon, DataGrid } from 'components';
import { AwardsSearch } from './AwardsSearch';
import { useQueryVariables, ColumnConfig, DataConnection } from 'hooks';

import { AchievementsPropsFragment, useAchievementsQuery } from 'schema/serverTypes';
import { DataGridProps } from 'components/DataGrid/types';
import { toast } from 'react-toastify';
import { Link, Route } from 'react-router-dom';
import { AddAwardModal } from '../../AddAwardModal';
import palette from '../../../../theme/palette';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    name: {
      fontWeight: 700,
    },
    link: {
      color: theme.palette.primary.dark,
    },
  })
);

const getDataKey = (award: AchievementsPropsFragment) => award.id;

export const AwardsGrid = () => {
  const classes = useStyles();

  const getTypeAward = (award: AchievementsPropsFragment) => {
    switch (award.__typename) {
      case 'AchievementWithMaterialPrizes':
        return 'С материальными призами';
      case 'AchievementWithPromoCodes':
        return 'С промо-кодами';
      case 'AchievementWithUnlimitedNumberOfPrizes':
        return 'Достижение с неограниченным количеством наград';
    }
  };

  const config = useMemo(() => {
    const columns: ColumnConfig<AchievementsPropsFragment>[] = [
      {
        id: 'topPosition',
        header: '№ в TOP',
        style: { width: '7%' },
        render: (award: AchievementsPropsFragment) => (
          <Typography variant="body1" className={classes.name}>
            {award.topPosition}
          </Typography>
        ),
      },
      {
        id: 'coins',
        header: 'Стоимость',
        style: { width: '5%' },
        render: (award: AchievementsPropsFragment) => (
          <Typography variant="body1">{award.coins}</Typography>
        ),
      },
      {
        id: 'title',
        header: 'Название',
        render: (award: AchievementsPropsFragment) => (
          <Link to={`/award/${award.id}`} className={classes.link}>
            {award.title}
          </Link>
        ),
      },
      {
        id: 'description',
        header: 'Описание',
        render: (award: AchievementsPropsFragment) => (
          <Typography variant="body1">{award.description}</Typography>
        ),
      },
      {
        id: 'type',
        header: 'Тип награды',
        render: (award: AchievementsPropsFragment) => (
          <Typography variant="body1">{getTypeAward(award)}</Typography>
        ),
      },
      {
        id: 'published',
        header: 'Опубликовано',
        align: 'center',
        style: { width: '5%' },
        render: (award: AchievementsPropsFragment) =>
          award.published ? (
            <CompleteIcon htmlColor={palette.success.main} />
          ) : (
            <CompleteIcon color="error" />
          ),
      },
    ];
    return {
      columns,
      rowsPerPage: 50,
    };
  }, [classes]);

  const {
    onSort,
    onSearch,
    onPage,
    variables,
    extractPagedData,
    columns,
    sortDirection,
    sortBy,
    onIncludeUnpublished,
  } = useQueryVariables<AchievementsPropsFragment>(config);

  const { data, loading, error } = useAchievementsQuery({
    variables: {
      ...variables,
      includeUnpublished: variables.includeUnpublished as boolean,
    },
  });

  if (error) {
    toast.error('Ошибка загрузки наград');
    return null;
  }

  const entities = data?.achievements as DataConnection<AchievementsPropsFragment>;
  const { data: achievements, count, startCursor, endCursor, page, rowsPerPage } = extractPagedData(
    entities
  );

  const gridProps: DataGridProps<AchievementsPropsFragment> = {
    data: achievements,
    onSort,
    onPage,
    onIncludeUnpublished,
    onSearch,
    columns,
    getDataKey,
    count,
    startCursor: startCursor,
    endCursor: endCursor,
    page,
    rowsPerPage,
    sortDirection,
    sortBy,
    searchComponent: AwardsSearch,
    loading,
  };

  return (
    <>
      <DataGrid<AchievementsPropsFragment> {...gridProps} />
      <Route path="/awards/create" exact>
        <AddAwardModal variables={variables} />
      </Route>
    </>
  );
};
