import React, { useCallback, useEffect } from 'react';
import {
  AuditMarkerPropsFragment,
  Maybe,
  PublishStoryInput,
  StoryBlockByIdDocument,
  StoryBlockDocument,
  UnpublishStoryInput,
  usePublishStoryMutation,
  useUnpublishStoryMutation,
} from '../../../../../schema';
import { createStyles, IconButton, makeStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { toast } from 'react-toastify';
import { FORM_ERROR, SubmissionErrors } from 'final-form';

type PublishedStatusButtonProps = {
  id: string;
  published?: Maybe<{ __typename?: 'AuditMarker' } & AuditMarkerPropsFragment>;
};

export const PublishedStatusButton = ({ id, published }: PublishedStatusButtonProps) => {
  const useStyles = makeStyles(() =>
    createStyles({
      checkIcon: {
        fill: 'lightgreen',
      },
      unPublishIcon: {
        fill: 'red',
      },
      publishIcon: {
        position: 'absolute',
        bottom: '10px',
        right: '5px',
      },
    })
  );

  const classes = useStyles();
  const [publishStory, { data: publishData, error: publishError }] = usePublishStoryMutation();
  const [unPublishStory, { error: unpublishError }] = useUnpublishStoryMutation();

  const handleClickPublishStory = useCallback(async () => {
    if (published === null) {
      const input: PublishStoryInput = {
        id: id,
      };
      try {
        return await publishStory({
          variables: { input },
          refetchQueries: [
            {
              query: StoryBlockByIdDocument,
              variables: { id: id },
            },
          ],
        });
      } catch {
        publishError && toast.error('Ошибка при публикации истории');
        return { [FORM_ERROR]: 'Ошибка изменения' } as SubmissionErrors;
      }
    } else {
      const input: UnpublishStoryInput = {
        id: id,
      };
      try {
        return await unPublishStory({
          variables: { input },
          refetchQueries: [
            {
              query: StoryBlockDocument,
            },
          ],
        });
      } catch {
        unpublishError && toast.error('Ошибка при cнятии с публикации истории');
        return { [FORM_ERROR]: 'Ошибка публикации' } as SubmissionErrors;
      }
    }
  }, [published, id, publishStory, publishError, unPublishStory, unpublishError]);

  useEffect(() => {
    if (publishData?.publishStory.errors) {
      toast.error(publishData?.publishStory.errors[0].message);
    }
  }, [publishData?.publishStory.errors]);

  return (
    <IconButton className={classes.publishIcon} onClick={handleClickPublishStory}>
      {published !== null ? (
        <CheckIcon className={classes.checkIcon} />
      ) : (
        <CheckIcon className={classes.unPublishIcon} />
      )}
    </IconButton>
  );
};
