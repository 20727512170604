import React from 'react';
import { AmountLabel, AutoFocusedForm, Modal, required, SelectField, TextField } from 'components';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  MenuItem,
  Theme,
} from '@material-ui/core';
import { useFillAccountForm } from './useFillAccountForm';
import { Field } from 'react-final-form';
import { MutableButton } from 'ui/MutableButton';
import { IAccountProps } from 'pages/components';
import { useParams } from 'react-router-dom';
import { RedButton } from 'components/Buttons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '500px',
    },
    header: {
      fontWeight: 'bolder',
    },
    divider: {},
    content: {},
    actions: {
      justifyContent: 'flex-end',
    },
    benefactorOption: {
      justifyContent: 'space-between',
    },
  })
);

export const FillAccountPopup = ({ account }: IAccountProps) => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();

  const { onCancel, initialValues, onSubmit, benefactorAccounts, loading } = useFillAccountForm(
    {
      account,
    },
    id
  );

  return (
    <Modal
      onClose={onCancel}
      aria-labelledby="Заполнение счета"
      aria-describedby="Используйте эту форму для заполнения счета"
    >
      <AutoFocusedForm
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, pristine }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Card className={classes.root}>
                <CardHeader title="Заполнение счета" />
                <Divider className={classes.divider} />
                <CardContent className={classes.content}>
                  <Grid container spacing={4}>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <Field
                        name="maximumAmount"
                        component={TextField}
                        label="Максимальное количество средств, которое будет переведено"
                        validate={required}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={4}>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <Field
                        name="sourceAccountId"
                        component={SelectField}
                        label="Источник перевода средств"
                        validate={required}
                      >
                        <MenuItem value="">
                          <em>Не указан</em>
                        </MenuItem>
                        {benefactorAccounts?.map((account) => {
                          if (!account) {
                            return null;
                          }

                          return (
                            <MenuItem
                              className={classes.benefactorOption}
                              value={account.id}
                              key={account.id}
                            >
                              {account.title}
                              <AmountLabel value={account.amount} showCurrency />
                            </MenuItem>
                          );
                        })}
                      </Field>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions className={classes.actions}>
                  <MutableButton
                    loading={loading}
                    color="primary"
                    disabled={pristine || !benefactorAccounts?.length}
                    size="medium"
                    type="submit"
                    variant="contained"
                  >
                    Сохранить
                  </MutableButton>
                  <RedButton
                    color="primary"
                    size="medium"
                    type="button"
                    variant="contained"
                    onClick={onCancel}
                  >
                    Отменить
                  </RedButton>
                </CardActions>
              </Card>
            </form>
          );
        }}
      />
    </Modal>
  );
};
