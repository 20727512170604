import React, { useState, useCallback } from 'react';

export const usePopover = (elementId: string) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const onClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? `popover-actions-${elementId}` : undefined;

  return {
    onClick,
    onClose,
    id,
    anchorEl,
    open,
  };
};
