import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AdministratorsQueryContext, QueryVariablesContext } from '../QueryContextProvider';
import {
  useDeleteUserMutation,
  useEnableOrDisableUserMutation,
  AdministratorsDocument,
} from 'schema/serverTypes';
import { toast } from 'react-toastify';

export const useModeratorActions = (id: string, disabled: boolean, close: () => void) => {
  const variables = useContext<AdministratorsQueryContext>(QueryVariablesContext);

  const history = useHistory();

  const onEdit = useCallback(() => {
    close();
    history.push(`/moderators/edit/${id}`);
  }, [id, history, close]);

  const [
    deleteUser,
    { data: deleteData, loading: deleteLoading, error: deleteError },
  ] = useDeleteUserMutation({
    variables: { input: { id } },
    refetchQueries: [
      {
        query: AdministratorsDocument,
        variables,
      },
    ],
  });

  const onChangePassword = useCallback(() => {
    close();
    history.push(`/moderators/password/${id}`);
  }, [id, history, close]);

  const onDelete = useCallback(async () => {
    await deleteUser({ variables: { input: { id } } });
    close();
  }, [deleteUser, id, close]);

  const [
    enableOrDisableUser,
    { data: enableOrDisableData, loading: enableOrDisableLoading, error: enableOrDisableError },
  ] = useEnableOrDisableUserMutation();

  const onEnableOrDisable = useCallback(async () => {
    await enableOrDisableUser({
      variables: {
        input: {
          id,
          disabled: !disabled,
        },
      },
    });
    close();
  }, [enableOrDisableUser, id, disabled, close]);

  if (enableOrDisableError || enableOrDisableData?.enableOrDisableUser.errors) {
    deleteError && toast.error('Произошла ошибка изменения статуса');
    enableOrDisableData?.enableOrDisableUser.errors &&
      toast.error(
        `${enableOrDisableData?.enableOrDisableUser.errors.map((error) => error.message)}`
      );
  }

  if (deleteError || deleteData?.deleteUser.errors) {
    deleteError && toast.error('Произошла ошибка удаления');
    deleteData?.deleteUser.errors &&
      toast.error(`${deleteData?.deleteUser.errors.map((error) => error.message)}`);
  }

  const loading = deleteLoading || enableOrDisableLoading;
  const error = deleteError || enableOrDisableError;
  const success =
    deleteData?.deleteUser.success ||
    enableOrDisableData?.enableOrDisableUser.user !== undefined ||
    false;

  return {
    onEdit,
    onChangePassword,
    onDelete,
    onEnableOrDisable,
    loading,
    error,
    success,
  };
};
