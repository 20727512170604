import React from 'react';
import { Benefactor } from 'schema';
import { AutoFocusedForm, PhotoField, required, TextField } from 'components';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import { useEditBenefactorForm } from './useEditBenefactorForm';
import { Field } from 'react-final-form';
import { MutableButton } from 'ui/MutableButton';
import { RedButton } from 'components/Buttons';
import { useBackRoute } from 'hooks';
import { useStyles } from '../useStyles';

interface EditBenefactorFormProps {
  benefactor: Benefactor;
}

export const EditBenefactorForm = (props: EditBenefactorFormProps) => {
  const { benefactor } = props;
  const styles = useStyles();

  const { onCancel } = useBackRoute();

  const { onSubmit, initialValues, loading } = useEditBenefactorForm(benefactor, onCancel);

  return (
    <AutoFocusedForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, pristine }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card className={styles.primary}>
              <CardHeader title="Системные данные" />
              <Divider />
              <CardContent>
                <Grid container spacing={4}>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Typography variant="body1">Фото</Typography>
                    <Field
                      name="photoId"
                      className={styles.photo}
                      component={PhotoField}
                      url={benefactor.photo?.url}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Field
                      name="name"
                      label="Отображаемое имя *"
                      component={TextField}
                      validate={required}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <MutableButton
                  className={styles.accessButton}
                  loading={loading}
                  disabled={pristine}
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  Сохранить
                </MutableButton>
                <RedButton
                  color="primary"
                  size="medium"
                  type="button"
                  variant="contained"
                  onClick={onCancel}
                >
                  Отменить
                </RedButton>
              </CardActions>
            </Card>
          </form>
        );
      }}
    />
  );
};
