import React, { useCallback, useMemo } from 'react';
import {
  PaymentWithDonationGridFragment,
  SystemAccountKind,
  useDonationsPaymentQuery,
} from 'schema/serverTypes';
import { ColumnConfig, DataConnection, useQueryVariables } from 'hooks';
import { DataGrid } from 'components';
import { DataGridProps } from 'components/DataGrid/types';
import { Link, useParams } from 'react-router-dom';
import { toDateTimeString } from '../../../../helpers';
import { toast } from 'react-toastify';
import moment from 'moment';
import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const getDataKey = (donation: PaymentWithDonationGridFragment) => donation.id;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      color: theme.palette.primary.dark,
    },
  })
);

export const BenefactorDonationInfo = () => {
  const { orderId } = useParams<{ orderId: string }>();
  const classes = useStyles();

  const getPurposeDonation = useCallback(
    (donation: PaymentWithDonationGridFragment) => {
      switch (donation.account.__typename) {
        case 'SystemAccount':
          if (donation.account.kind === SystemAccountKind.Main) {
            return 'Резерв администрации';
          } else if (donation.account.kind === SystemAccountKind.WardsReserve) {
            return 'Резерв подопечных';
          }
          break;
        case 'RegularNeedsAccount':
          return `Счёт регулярных потребностей ${donation.account.ward.id}`;
        case 'RegularNeedPeriodAccount':
          return (
            <Link
              className={classes.link}
              to={`/wards/manage/${donation.account.ward.id}/needs/${donation.account.need.id}/period/${donation.account.regularNeedPeriod.id}`}
            >
              {donation.account.ward.publicInformation.name.fullName},{' '}
              {moment(donation.account.regularNeedPeriod.start).format('MMMM YYYY')}
            </Link>
          );
        case 'SpecialProjectAccount':
          return (
            <Link
              className={classes.link}
              to={`/special-projects/manage/${donation.account.specialProject.id}`}
            >
              {donation.account.specialProject.title}
            </Link>
          );
      }
    },
    [classes.link]
  );

  const config = useMemo(() => {
    const columns: ColumnConfig<PaymentWithDonationGridFragment>[] = [
      {
        id: 'createdAt',
        header: 'Дата пожертвования',
        style: { width: '20%' },
        render: (payment: PaymentWithDonationGridFragment) => {
          return <>{toDateTimeString(new Date(payment.createdAt))}</>;
        },
      },
      {
        id: 'amount',
        header: 'Сумма пожертвования',
        style: { width: '20%' },
        render: (payment: PaymentWithDonationGridFragment) => {
          return <>{payment.amount}</>;
        },
      },
      {
        id: 'account',
        header: 'Назначение платежа',
        style: { width: '20%' },
        render: (payment: PaymentWithDonationGridFragment) => {
          return <> {getPurposeDonation(payment)}</>;
        },
      },
    ];
    return {
      columns,
    };
  }, [getPurposeDonation]);

  const { onPage, variables, extractPagedData, columns } = useQueryVariables<
    PaymentWithDonationGridFragment
  >(config);

  const { data, error, loading } = useDonationsPaymentQuery({
    variables: { id: orderId, ...variables },
  });

  if (error) {
    toast.error('Ошибка загрузки платежа');
    return null;
  }

  const entities = data?.orderById?.donations as DataConnection<PaymentWithDonationGridFragment>;
  /*    data?.orderById?.__typename ===  ||
    data?.orderById?.__typename === 'BankTransferPayment'
      ? ()
      : null;*/
  const { data: donations, count, startCursor, endCursor, page, rowsPerPage } = extractPagedData(
    entities
  );

  const gridProps: DataGridProps<PaymentWithDonationGridFragment> = {
    data: donations,
    onPage,
    columns,
    getDataKey,
    count,
    startCursor: startCursor,
    endCursor: endCursor,
    page,
    rowsPerPage,
    loading,
    onSort: () => {},
    onSearch: () => {},
  };

  return <DataGrid<PaymentWithDonationGridFragment> {...gridProps} />;
};
