import React from 'react';
import { EditBenefactorForm } from './EditBenefactorForm';
import { Benefactor, BenefactorPermissions } from 'schema';
import { usePermissionPage } from 'hooks';
import { Grid } from '@material-ui/core';
import { TitledToolbar } from 'components';
import { EditBenefactorPersonalDataForm } from './EditBenefactorPersonalDataForm';
import { EditBenefactorSkillsForm } from './EditBenefactorSkillsForm';
import { EditBenefactorVolunteerInformationForm } from './EditBenefactorVolonteerInformationForm';

interface EditBenefactorPageProps {
  benefactor: Benefactor;
  permissions: BenefactorPermissions;
}

export const EditBenefactorPage = (props: EditBenefactorPageProps) => {
  const { permissions, benefactor } = props;

  usePermissionPage('/benefactors', permissions.edit);

  return (
    <>
      <TitledToolbar title="Редактирование данных пользователя" />
      <Grid container spacing={2}>
        <Grid item lg={3} md={4} xl={3} xs={12}>
          <EditBenefactorForm benefactor={benefactor} />
        </Grid>
        <Grid item lg={9} md={8} xl={9} xs={12}>
          <EditBenefactorPersonalDataForm benefactor={benefactor} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <EditBenefactorVolunteerInformationForm benefactor={benefactor} />
        </Grid>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <EditBenefactorSkillsForm benefactor={benefactor} />
        </Grid>
      </Grid>
    </>
  );
};
