import React, { useCallback } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import Autocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {
  UseAutocompleteMultipleProps,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
} from '@material-ui/lab/useAutocomplete';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type InputProps = Pick<TextFieldProps, 'variant' | 'label' | 'placeholder'>;

type SearchCheckboxesBaseProps<T> = {
  getOptionLabel: (option: T) => string;
  onChange: (values: T[]) => void;
};

export type SearchCheckboxesProps<T> = Omit<
  AutocompleteProps<T> & UseAutocompleteMultipleProps<T>,
  'getOptionLabel' | 'renderOption' | 'renderInput' | 'multiple' | 'onChange'
> &
  SearchCheckboxesBaseProps<T> &
  InputProps;

export function SearchCheckboxes<T>(props: SearchCheckboxesProps<T>) {
  const {
    options,
    disableCloseOnSelect = true,
    getOptionLabel,
    style = { width: '100%' },
    label = '',
    variant = 'outlined',
    placeholder = '',
    onChange,
    ...rest
  } = props;

  const handleOnChange = useCallback(
    (
      _event: React.ChangeEvent<{}>,
      value: T[],
      _reason: AutocompleteChangeReason,
      _details?: AutocompleteChangeDetails<T>
    ) => {
      onChange(value);
    },
    [onChange]
  );

  return (
    <Autocomplete
      {...rest}
      onChange={handleOnChange}
      multiple
      options={options}
      noOptionsText="результатов не найдено"
      openText={'Открыть'}
      closeText={'Закрыть'}
      disableCloseOnSelect={disableCloseOnSelect}
      getOptionLabel={getOptionLabel}
      renderOption={(option, { selected }) => (
        <>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {getOptionLabel(option)}
        </>
      )}
      style={style}
      renderInput={(params) => (
        <TextField {...params} label={label} variant={variant} placeholder={placeholder} />
      )}
    />
  );
}
