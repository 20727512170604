import React from 'react';
import { ColumnsWidth, CountersGroup, CountersGroupDefinition } from './CountersGroup';
import { Grid } from '@material-ui/core';
import { QueryResult } from '@apollo/client/react';
import { QueryHookOptions } from '@apollo/client/react';
import { Skeleton } from '@material-ui/lab';

export interface CountersRowProps<TQuery, TVariables> {
  groups: CountersGroupDefinition<TQuery>[];
  variables?: TVariables;
  useQuery: (baseOptions?: QueryHookOptions<TQuery, TVariables>) => QueryResult<TQuery, TVariables>;
  className?: string;
  width?: ColumnsWidth;
  innerWidth?: ColumnsWidth;
}

export const CountersRow = <TQuery, TVariables>(
  props: React.PropsWithChildren<CountersRowProps<TQuery, TVariables>>
) => {
  const { useQuery, variables, groups, className, width, innerWidth } = props;
  const { data, error, loading } = useQuery(variables);

  if (loading) {
    return <Skeleton />;
  }

  if (error || !data) {
    return null;
  }

  return (
    <Grid container spacing={2} className={className}>
      {groups.map((group, groupIndex) => {
        return (
          <CountersGroup
            key={groupIndex}
            width={width}
            innerWidth={innerWidth}
            data={data}
            title={group.title}
            subtitle={group.subtitle}
            counters={group.counters}
          />
        );
      })}
    </Grid>
  );
};
