import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Modal,
  makeStyles,
  Theme,
  createStyles,
  Backdrop,
  Fade,
  Grid,
  Typography,
  IconButton,
  Button,
} from '@material-ui/core';
import { useBackRoute } from 'hooks';
import {
  useRemoveWardFromTheBenefactorDepositAccountForSpecificWardsMutation,
  Ward,
  WardByIdDocument,
} from 'schema';
import DeleteIcon from '@material-ui/icons/Delete';
import { toast } from 'react-toastify';
import { Loading } from 'ui/Loading';
import { AddBenefactorInList } from './components/AddBenefactorInList';
import { RedButton } from 'components/Buttons';
import { useMeContext } from '../../../components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    content: {
      minWidth: '35%',
      padding: '15px',
      backgroundColor: theme.palette.background.paper,
      border: '0px',
      borderRadius: '2px',
      boxShadow: theme.shadows[5],
    },
    header: {
      margin: theme.spacing(2),
    },
    list: {
      margin: '10px 0',
    },
    button: {
      marginRight: theme.spacing(2),
    },
  })
);

interface EditedRouteParams {
  ward: Ward;
}

export const BenefactorsListPage = ({ ward }: EditedRouteParams) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { user } = useMeContext();
  const { onCancel } = useBackRoute();

  const refetchQueries = useMemo(() => {
    return [
      {
        query: WardByIdDocument,
        variables: { id: ward.id, permission: user?.permissions.accounts.viewDepositAccounts },
      },
    ];
  }, [ward.id, user]);

  const [
    removeBenefactor,
    { data, loading, error },
  ] = useRemoveWardFromTheBenefactorDepositAccountForSpecificWardsMutation({
    refetchQueries,
  });

  useEffect(() => {
    if (error) {
      toast.error('Произошла ошибка удаления жертвователя из списка');
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      toast.success('Счет успешно удален из списка');
    }
  }, [data]);

  const handleRemoveBenefactor = useCallback(
    async (id: string) => {
      await removeBenefactor({
        variables: {
          input: {
            wardId: ward.id,
            id: id,
          },
        },
      });
    },
    [removeBenefactor, ward]
  );

  const handleCloseWindow = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <Modal
      open={true}
      onClose={onCancel}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={true}>
        <div className={classes.content}>
          <Typography variant="h4" className={classes.header} align="center">
            Управление жертвователями
          </Typography>
          {loading ? (
            <Loading />
          ) : (
            ward?.benefactorDepositAccounts?.map((benefactor) => (
              <Grid item container spacing={2} alignItems="center" key={benefactor.id}>
                <Grid item lg={5} md={5} xl={5} xs={7}>
                  <Typography>{benefactor?.benefactor?.displayName ?? ''}</Typography>
                </Grid>
                <Grid item lg={5} md={4} xl={4} xs={7}>
                  <Typography>{benefactor?.title ?? ''}</Typography>
                </Grid>
                <Grid item lg={2} md={2} xl={2} xs={1}>
                  <IconButton onClick={() => handleRemoveBenefactor(benefactor.id)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))
          )}
          <Grid container className={classes.list}>
            <Grid xs={12} item className={classes.modal}>
              <Button
                color="primary"
                variant="contained"
                className={classes.button}
                onClick={() => setIsOpen(true)}
              >
                Добавить
              </Button>
              <RedButton
                color="primary"
                size="medium"
                type="button"
                variant="contained"
                onClick={onCancel}
              >
                Отмена
              </RedButton>
            </Grid>
          </Grid>
          <AddBenefactorInList
            wardId={ward.id}
            isOpen={isOpen}
            handleCloseWindow={handleCloseWindow}
          />
        </div>
      </Fade>
    </Modal>
  );
};
