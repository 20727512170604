import React from 'react';
import { ModeratorsGrid } from './ModeratorsGrid';
import { Button, Card, CardContent } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { LayoutPageGrid } from 'ui/LayoutGrid/LayoutGrid';
import { usePermissionPage } from 'hooks';
import { useMeContext } from 'components';

export const ModeratorsPage = () => {
  const { user } = useMeContext();

  usePermissionPage(
    '/ward',
    user?.permissions.users.createAndEditModerator ||
      user?.permissions.users.createAndEditSystemAdministrator ||
      user?.permissions.users.createAndEditFoundationRepresentative
  );

  return (
    <LayoutPageGrid
      title="Администраторы"
      tools={
        <Button color="primary" variant="contained" component={Link} to="/moderators/create">
          Добавить
        </Button>
      }
    >
      <Card>
        <CardContent>
          <ModeratorsGrid />
        </CardContent>
      </Card>
    </LayoutPageGrid>
  );
};
