import React from 'react';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  createStyles,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { DragHandleProject } from '../DragHandleCard';
import { SortableElement } from 'react-sortable-hoc';

type DragbleProjectCardProps = {
  index: number;
  title: string;
  id: number;
  description: string;
};

const useStyles = makeStyles(() =>
  createStyles({
    actions: {
      justifyContent: 'flex-end',
    },
    cardProject: { width: '240px', maxHeight: '260px', position: 'relative' },
    title: {
      maxWidth: '208px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    description: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 3,
      WebkitBoxOrient: 'vertical',
    },
  })
);

export const DragbleProjectCard = SortableElement(
  ({ title, description }: DragbleProjectCardProps) => {
    const styles = useStyles();
    return (
      <Card className={styles.cardProject}>
        <CardHeader title={title} titleTypographyProps={{ className: styles.title }} />
        <CardContent>
          <Typography className={styles.description}>{description}</Typography>
        </CardContent>
        <CardActions className={styles.actions}>
          <DragHandleProject />
        </CardActions>
      </Card>
    );
  }
);
