import React from 'react';
import { Backdrop, createStyles, Fade, makeStyles, Modal, Theme } from '@material-ui/core';
import { useBackRoute } from 'hooks';
import { EditScreenForm } from './EditScreenForm';
import { ScreenStoryPropsFragment } from '../../../../schema';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    form: {
      padding: '15px',
      backgroundColor: theme.palette.background.paper,
      border: '0px',
      borderRadius: '2px',
      boxShadow: theme.shadows[5],
    },
  })
);

type EditScreenModalProps = {
  onUpdate: (values: ScreenStoryPropsFragment) => void;
  setIsStoryEdited: (isEdited: boolean) => void;
  screenCards: ScreenStoryPropsFragment[];
  cardIndex: number | null;
  setSelectedCardIndex: (index: number | null) => void;
};

export const EditScreenModal = ({
  onUpdate,
  setIsStoryEdited,
  cardIndex,
  setSelectedCardIndex,
  screenCards,
}: EditScreenModalProps) => {
  const styles = useStyles();

  const { onCancel } = useBackRoute();

  return (
    <Modal
      open={true}
      onClose={() => {
        onCancel();
        setSelectedCardIndex(null);
      }}
      aria-labelledby="Редактор экрана"
      aria-describedby="Используйте эту форму для редактирования экрана истории"
      className={styles.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={true}>
        <EditScreenForm
          screenCards={screenCards}
          setIsStoryEdited={setIsStoryEdited}
          onUpdate={onUpdate}
          onClose={() => {
            onCancel();
            setSelectedCardIndex(null);
          }}
          cardIndex={cardIndex}
        />
      </Fade>
    </Modal>
  );
};
