import React from 'react';

import { EditCityForm } from './EditCityForm';
import { Modal } from 'components';
import { useCancelRoute } from 'hooks';
import { useCityByIdQuery } from 'schema';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { EditFormOrEmptyCity } from 'components/hoc/EditFormOrEmptyCity';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    form: {
      width: '100%',
      padding: '25px',
      backgroundColor: theme.palette.background.paper,
      border: '0px',
      borderRadius: '2px',
      boxShadow: theme.shadows[5],
    },
  })
);

const EditCity = EditFormOrEmptyCity(EditCityForm);

export const EditCityPage = () => {
  const { onCancel } = useCancelRoute('/cities');
  const styles = useStyles();

  const { id } = useParams<{ id: string }>();
  const { data, error, loading } = useCityByIdQuery({ variables: { id } });

  return (
    <Modal
      onClose={onCancel}
      aria-labelledby="Редактирование города"
      aria-describedby="Используйте эту форму для редактирования города"
      className={styles.modal}
    >
      <div className={styles.form}>
        <EditCity onClose={onCancel} loading={loading} hasError={error !== undefined} data={data} />
      </div>
    </Modal>
  );
};
