import { useCallback, useEffect, useContext } from 'react';
import {
  useCreateModeratorMutation,
  useCreateSystemAdministratorMutation,
  CreateModeratorInput,
  CreateSystemAdministratorInput,
  AdministratorsDocument,
  AdministratorsKind,
} from 'schema/serverTypes';
import { AdministratorsQueryContext, QueryVariablesContext } from '../../QueryContextProvider';
import { toast } from 'react-toastify';

interface KindType {
  kind: string;
}

export type CreateAdministratorInputValues = CreateSystemAdministratorInput & KindType;

const initialValues: CreateAdministratorInputValues = {
  displayName: '',
  email: '',
  kind: '',
};

export const useModeratorForm = (onClose: () => void) => {
  const [
    createSystemAdministratorMutation,
    { data: administratorData, loading: administratorLoading, error: administratorError },
  ] = useCreateSystemAdministratorMutation();
  const [
    createModeratorMutation,
    { data: moderatorData, loading: moderatorLoading, error: moderatorError },
  ] = useCreateModeratorMutation();

  const loading = administratorLoading || moderatorLoading || false;
  const error = administratorError || moderatorError || false;

  const variables = useContext<AdministratorsQueryContext>(QueryVariablesContext);

  const onSubmit = useCallback(
    async (values: CreateAdministratorInputValues) => {
      const { displayName, email, kind } = values;
      const input: CreateSystemAdministratorInput | CreateModeratorInput = {
        displayName,
        email,
      };

      const createVariables = {
        variables: { input },
        refetchQueries: [
          {
            query: AdministratorsDocument,
            variables,
          },
        ],
      };
      kind === AdministratorsKind.SystemAdministrators
        ? await createSystemAdministratorMutation(createVariables)
        : await createModeratorMutation(createVariables);
    },
    [createModeratorMutation, createSystemAdministratorMutation, variables]
  );

  useEffect(() => {
    error && toast.error('Ошибка добавления администратора');
    administratorData?.createSystemAdministrator.errors &&
      toast.error(
        `${administratorData?.createSystemAdministrator.errors.map((error) => error.message)}`
      );
    moderatorData?.createModerator.errors &&
      toast.error(`${moderatorData?.createModerator.errors.map((error) => error.message)}`);
  }, [error, administratorData, moderatorData]);

  useEffect(() => {
    if (administratorData !== undefined || moderatorData !== undefined) {
      onClose();
    }
  }, [administratorData, moderatorData, onClose]);

  return { initialValues, onSubmit, loading };
};
