import React from 'react';
import { Button, createStyles, ListItem, Theme } from '@material-ui/core';
import { NavLink as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { DropdownMenu, useMeContext } from 'components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      float: 'left',
      width: 'auto',
      padding: 0,
    },
    button: {
      ...theme.typography.subtitle1,
      color: theme.palette.common.white,
      textTransform: 'none',
      padding: theme.spacing(1, 2),
      '&:hover': {
        backgroundColor: 'rgba(255,255,255,0.3)',
      },
      margin: '0 6px',
    },
    link: {
      color: 'black',
    },
  })
);

export type TopNavButtonProps = {
  title: string;
  href: string;
  permissions: boolean | undefined;
  type?: string;
  selectButton?: boolean;
  screens?: { title: string; href: string }[];
};

export const TopNavButton = (props: TopNavButtonProps) => {
  const { title, href, permissions, type, selectButton, screens } = props;
  const classes = useStyles();

  const { user } = useMeContext();

  if (type && type !== user?.type) {
    return null;
  }

  if (!permissions) {
    return null;
  }

  return (
    <ListItem disableGutters className={classes.item}>
      {!selectButton ? (
        <Button className={classes.button} component={RouterLink} to={href}>
          {title}
        </Button>
      ) : (
        <DropdownMenu screens={screens} title={title} classes={classes} />
      )}
    </ListItem>
  );
};
