import { useCallback, useEffect, useMemo } from 'react';
import { City, UpdateCityInput, useUpdateCityMutation } from 'schema/serverTypes';
import { toast } from 'react-toastify';

export interface UpdateCityInputValues {
  displayName: string;
}

const createInitialValues = (city: City) => {
  const initialValues: UpdateCityInput = {
    id: city.id || '',
    name: city.name || '',
    default: city.default || false,
    order: city.order || 0,
  };
  return initialValues;
};

export const useEditCityForm = (city: City, onClose: () => void) => {
  const [updateCityMutation, { data, error, loading }] = useUpdateCityMutation();

  const initialValues = useMemo(() => {
    return createInitialValues(city);
  }, [city]);

  const onSubmit = useCallback(
    async (values: UpdateCityInput) => {
      const { name, order } = values;
      const input: UpdateCityInput = {
        id: city.id,
        name: name,
        default: values.default,
        order: parseInt(order.toString()),
      };

      const createVariables = {
        variables: { input },
      };

      await updateCityMutation(createVariables);
    },
    [city.id, updateCityMutation]
  );

  useEffect(() => {
    if (error || data?.updateCity.errors) {
      error && toast.error('Ошибка изменения города');
      data?.updateCity.errors &&
        toast.error(`${data?.updateCity.errors.map(({ message }) => message)}`);
    }
  }, [error, data]);

  useEffect(() => {
    if (data !== undefined) {
      onClose();
    }
  }, [data, onClose]);

  return {
    initialValues,
    onSubmit,
    loading,
  };
};
