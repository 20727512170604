import React from 'react';
import { AmountsQuery, useAmountsQuery } from '../../../schema';
import { CountersGroupDefinition, moneyCounter } from './CountersGroup';
import { CountersRow } from './CountersRow';

const groups: CountersGroupDefinition<AmountsQuery>[] = [
  {
    title: 'АДМИНИСТРАЦИЯ',
    counters: [
      [
        moneyCounter('Счёт администрации', (e) => e.project.mainAccount!.amount),
        moneyCounter('Депозиты жертвователей', (e) => e.amounts.amountOfBenefactorDeposits),
      ],
      [
        moneyCounter(
          'Собрано в текущем месяце',
          (e) =>
            e.amounts.amountOfProjectSubscriptionDonationsCollectedInCurrentMonth +
            e.amounts.amountOfProjectNonSubscriptionDonationsCollectedInCurrentMonth
        ),
        moneyCounter(
          'Подписками',
          (e) => e.amounts.amountOfProjectSubscriptionDonationsCollectedInCurrentMonth
        ),
        moneyCounter(
          'Разовыми переводами',
          (e) => e.amounts.amountOfProjectNonSubscriptionDonationsCollectedInCurrentMonth
        ),
      ],
      [
        moneyCounter('Общая сумма подписок', (e) => e.amounts.amountOfProjectActiveSubscriptions),
        moneyCounter(
          'Существующие подписки',
          (e) => e.amounts.amountOfProjectActiveSubscriptionsCreatedBeforeCurrentMonth
        ),
        moneyCounter(
          'Новые подписки',
          (e) => e.amounts.amountOfProjectActiveSubscriptionsCreatedInCurrentMonth
        ),
      ],
      [
        moneyCounter(
          'Сумма отписок',
          (e) => e.amounts.amountOfProjectSubscriptionsCancelledInCurrentMonth
        ),
      ],
    ],
  },
  {
    title: 'ПОДОПЕЧНЫЕ',
    counters: [
      [
        moneyCounter('Резерв подопечных', (e) => e.project.wardsReserveAccount!.amount),
        moneyCounter('Счета-кошельки', (e) => e.amounts.amountOfWardsRegularNeedsAccounts),
      ],
      [
        moneyCounter(
          'Собрано в текущем месяце',
          (e) =>
            e.amounts.amountOfWardsSubscriptionDonationsCollectedInCurrentMonth +
            e.amounts.amountOfWardsNonSubscriptionDonationsCollectedInCurrentMonth
        ),
        moneyCounter(
          'Подписками',
          (e) => e.amounts.amountOfWardsSubscriptionDonationsCollectedInCurrentMonth
        ),
        moneyCounter(
          'Разовыми переводами',
          (e) => e.amounts.amountOfWardsNonSubscriptionDonationsCollectedInCurrentMonth
        ),
      ],
      [
        moneyCounter('Общая сумма подписок', (e) => e.amounts.amountOfWardsActiveSubscriptions),
        moneyCounter(
          'Существующие подписки',
          (e) => e.amounts.amountOfWardsActiveSubscriptionsCreatedBeforeCurrentMonth
        ),
        moneyCounter(
          'Новые подписки',
          (e) => e.amounts.amountOfWardsActiveSubscriptionsCreatedInCurrentMonth
        ),
      ],
      [
        moneyCounter(
          'Сумма отписок',
          (e) => e.amounts.amountOfWardsSubscriptionsCancelledInCurrentMonth
        ),
      ],
    ],
  },
];

export interface AmountsProps {
  className?: string;
}

export const Amounts = (props: AmountsProps) => {
  return (
    <CountersRow
      groups={groups}
      useQuery={useAmountsQuery}
      width={6}
      innerWidth={4}
      className={props.className}
    />
  );
};
