import { useCallback, useEffect, useMemo } from 'react';
import { QueryVariables, useBackRoute } from 'hooks';
import {
  BenefactorFilter,
  BenefactorSort,
  CreateBenefactorInput,
  useCreateBenefactorMutation,
} from 'schema/serverTypes';
import { FORM_ERROR, SubmissionErrors } from 'final-form';
import { BenefactorsDocument } from 'schema/serverTypes';
import { toast } from 'react-toastify';
import { useMeContext } from '../../../components';

type CreateBenefactorValues = CreateBenefactorInput & {
  phoneNumber: string | null;
  email: string | null;
};

export const useAddBenefactorForm = (
  variables: QueryVariables<BenefactorSort, BenefactorFilter> | undefined
) => {
  const { onCancel } = useBackRoute();
  const { user } = useMeContext();

  const refetchQueries = useMemo(() => {
    return [
      {
        query: BenefactorsDocument,

        variables: { ...variables, permission: user?.permissions.benefactors.create },
      },
    ];
  }, [variables, user]);

  const [createBenefactor, { data, loading, error }] = useCreateBenefactorMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const onSubmit = useCallback(
    async (input: CreateBenefactorValues) => {
      const variables = {
        input: {
          ...input,
          phoneNumber: input.phoneNumber || null,
          email: input.email || null,
        },
      };

      return createBenefactor({ variables }).catch(() => {
        return { [FORM_ERROR]: 'Не удалось создать пользователя' } as SubmissionErrors;
      });
    },
    [createBenefactor]
  );

  useEffect(() => {
    if (error) {
      toast.error('Ошибка добавления пользователя');
    }
  }, [error]);

  const success = data?.createBenefactor.benefactor !== undefined;

  useEffect(() => {
    if (success) {
      onCancel();
    }
  }, [success, onCancel]);

  const initialValues: CreateBenefactorValues = {
    displayName: '',
    email: '',
    hideProfile: false,
    phoneNumber: '',
    photoId: null,
  };

  return {
    onCancel,
    onSubmit,
    initialValues,
    loading,
  };
};
