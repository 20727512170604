import React, { useCallback, useEffect } from 'react';
import {
  AchievementsDocument,
  DeleteAchievementInput,
  useDeleteAchievementMutation,
} from '../../../../schema';
import { Loading } from '../../../../ui/Loading';
import { AutoFocusedForm } from '../../../../components';
import { Card, CardActions, CardContent, CardHeader, Divider, Typography } from '@material-ui/core';
import { MutableButton } from '../../../../ui/MutableButton';
import { RedButton } from '../../../../components/Buttons';
import { useStyles } from '../useStyles';
import { toast } from 'react-toastify';
import { FORM_ERROR, SubmissionErrors } from 'final-form';
import { useHistory, useParams } from 'react-router-dom';

interface AwardDeleteModalFormProps {
  onClose: () => void;
}

export const AwardDeleteModalForm = ({ onClose }: AwardDeleteModalFormProps) => {
  const history = useHistory();
  const styles = useStyles();
  const { awardId } = useParams<{ awardId: string }>();

  const [deleteAchievement, { data, loading, error }] = useDeleteAchievementMutation();

  const onSubmit = useCallback(async () => {
    const input: DeleteAchievementInput = {
      id: awardId,
    };
    try {
      return await deleteAchievement({
        variables: { input },
        refetchQueries: [
          {
            query: AchievementsDocument,
          },
        ],
      });
    } catch {
      error && toast.error('Ошибка при удалении награды');
      return { [FORM_ERROR]: 'Ошибка удаления награды' } as SubmissionErrors;
    }
  }, [awardId, deleteAchievement, error]);

  useEffect(() => {
    if (data?.deleteAchievement.success) {
      history.push('/awards');
    }
  }, [onClose, data, history]);

  useEffect(() => {
    if (data?.deleteAchievement.success === false) {
      toast.error('Ошибка удаления награды');
    }
  }, [data?.deleteAchievement.success]);

  if (loading) {
    return <Loading />;
  }

  return (
    <AutoFocusedForm
      onSubmit={onSubmit}
      render={({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card className={styles.root}>
              <CardHeader title={'Удаление награды'} />
              <Divider />
              <CardContent className={styles.content}>
                <Typography>Вы действительно хотите удалить награду?</Typography>
              </CardContent>

              <CardActions className={styles.actions}>
                <MutableButton
                  loading={false}
                  className={styles.accessButton}
                  color="primary"
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  Удалить
                </MutableButton>
                <RedButton onClick={onClose} size="medium" type="button" variant="contained">
                  Отменить
                </RedButton>
              </CardActions>
            </Card>
          </form>
        );
      }}
    />
  );
};
