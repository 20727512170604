import React from 'react';
import { createContext } from 'react';
import { QueryBroadcastNotificationsArgs } from 'schema/serverTypes';

export type BroadcastNotificationsQueryContext = QueryBroadcastNotificationsArgs | undefined;

export const QueryVariablesContext = createContext<BroadcastNotificationsQueryContext>({
  first: 10,
});

export interface QueryContextProviderProps {
  variables: BroadcastNotificationsQueryContext;
  children: React.ReactNode;
}

export const QueryContextProvider = (props: QueryContextProviderProps) => {
  const { children, variables } = props;
  return (
    <QueryVariablesContext.Provider value={variables}>{children}</QueryVariablesContext.Provider>
  );
};
