import { useCallback, useEffect, useMemo } from 'react';
import {
  useCreateBenefactorDepositAccountForAllWardsMutation,
  useCreateBenefactorDepositAccountForProjectNeedsMutation,
  useCreateBenefactorDepositAccountForSpecificWardsMutation,
  useCreateBenefactorDepositAccountWithoutConditionsMutation,
} from 'schema';
import { toast } from 'react-toastify';

interface InitialValuesType {
  account:
    | 'BenefactorDepositAccountWithoutConditions'
    | 'BenefactorDepositAccountForProjectNeeds'
    | 'BenefactorDepositAccountForAllWards'
    | 'BenefactorDepositAccountForSpecificWards'
    | '';
  title: string;
}

export const useCreateDepositAccountForm = (benefactorId: string, onClose: () => void) => {
  const [
    accountWithoutConditions,
    {
      data: withoutConditionsData,
      error: withoutConditionsError,
      loading: withoutConditionsLoading,
    },
  ] = useCreateBenefactorDepositAccountWithoutConditionsMutation();
  const [
    accountForAllWards,
    { data: forAllWardsData, error: forAllWardsError, loading: forAllWardsLoading },
  ] = useCreateBenefactorDepositAccountForAllWardsMutation();
  const [
    accountForProjectNeeds,
    { data: forProjectNeedData, error: forProjectNeedError, loading: forProjectNeedLoading },
  ] = useCreateBenefactorDepositAccountForProjectNeedsMutation();
  const [
    accountForSpecificWards,
    { data: forSpecificWardsData, error: forSpecificWardsError, loading: forSpecificWardsLoading },
  ] = useCreateBenefactorDepositAccountForSpecificWardsMutation();

  const loading = useMemo(() => {
    return (
      withoutConditionsLoading ||
      forAllWardsLoading ||
      forProjectNeedLoading ||
      forSpecificWardsLoading
    );
  }, [
    forSpecificWardsLoading,
    withoutConditionsLoading,
    forAllWardsLoading,
    forProjectNeedLoading,
  ]);

  const error = useMemo(() => {
    return (
      withoutConditionsError || forAllWardsError || forProjectNeedError || forSpecificWardsError
    );
  }, [withoutConditionsError, forAllWardsError, forProjectNeedError, forSpecificWardsError]);

  const data = useMemo(() => {
    return withoutConditionsData || forAllWardsData || forProjectNeedData || forSpecificWardsData;
  }, [withoutConditionsData, forAllWardsData, forProjectNeedData, forSpecificWardsData]);

  const onSubmit = useCallback(
    async ({ account, title }: InitialValuesType) => {
      const inputValue = {
        variables: {
          input: {
            benefactorId,
          },
        },
      };

      if (account === 'BenefactorDepositAccountWithoutConditions') {
        await accountWithoutConditions(inputValue);
        return;
      }

      if (account === 'BenefactorDepositAccountForProjectNeeds') {
        await accountForProjectNeeds(inputValue);
        return;
      }

      if (account === 'BenefactorDepositAccountForAllWards') {
        await accountForAllWards(inputValue);
        return;
      }

      if (account === 'BenefactorDepositAccountForSpecificWards') {
        await accountForSpecificWards({
          variables: {
            input: {
              benefactorId,
              title,
            },
          },
        });
        return;
      }
    },
    [
      benefactorId,
      accountForSpecificWards,
      accountForAllWards,
      accountForProjectNeeds,
      accountWithoutConditions,
    ]
  );

  const initialValues: InitialValuesType = {
    account: '',
    title: '',
  };

  useEffect(() => {
    if (error) {
      toast.error('Ошибка добавления счета');
    }
  }, [error]);

  useEffect(() => {
    const errorMessage =
      withoutConditionsData?.createBenefactorDepositAccountWithoutConditions.errors?.map(
        (item) => item.message
      ) ||
      forAllWardsData?.createBenefactorDepositAccountForAllWards.errors?.map(
        (item) => item.message
      ) ||
      forProjectNeedData?.createBenefactorDepositAccountForProjectNeeds.errors?.map(
        (item) => item.message
      );

    if (errorMessage) {
      toast.error(`${errorMessage}`);
      return;
    }

    if (data) {
      toast.success('Счет успешно создан');
      onClose();
    }
  }, [data, onClose, withoutConditionsData, forAllWardsData, forProjectNeedData]);

  return {
    initialValues,
    onSubmit,
    loading,
  };
};
