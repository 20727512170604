import React, { useMemo } from 'react';
import { ColumnConfig, DataConnection, useQueryVariables } from 'hooks';
import { GrocerySetNeed, RegularNeedPeriodPropsFragment, useRegularNeedPeriodsQuery } from 'schema';
import { CompleteIcon, DataGrid, DataGridProps, WaitIcon } from 'components';
import { useStyles } from './useStyles';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';
import DocumentIcon from '@material-ui/icons/InsertDriveFile';
import { Button } from '@material-ui/core';
import { capitalizeFirst } from 'helpers';
import { toast } from 'react-toastify';

const getDataKey = (account: RegularNeedPeriodPropsFragment) => account.id;

const getName = (account: RegularNeedPeriodPropsFragment) => {
  const month = capitalizeFirst(moment(account.start as string).format('MMMM')),
    year = moment(account.start as string).year();
  return `${month} ${year}`;
};

const getEndDate = (account: RegularNeedPeriodPropsFragment) => {
  return moment(account.end as string).format('D MMMM YYYY');
};

export const RegularNeedPeriods = () => {
  const classes = useStyles();
  const { id, needId } = useParams<{ id: string; needId: string }>();

  const config = useMemo(() => {
    /**
     * Конфиг колонок для таблицы
     */
    const columns: ColumnConfig<RegularNeedPeriodPropsFragment>[] = [
      {
        id: 'name',
        header: 'Период за',
        render: (period: RegularNeedPeriodPropsFragment) => {
          return (
            <Link
              className={classes.link}
              to={`/wards/manage/${id}/needs/${needId}/period/${period.id}`}
            >
              {getName(period)}
            </Link>
          );
        },
      },
      {
        id: 'endDate',
        header: 'Дата закрытия',
        render: (period: RegularNeedPeriodPropsFragment) => {
          return getEndDate(period);
        },
      },
      {
        id: 'status',
        header: 'Статус',
        render: (account: RegularNeedPeriodPropsFragment) => {
          return (
            <div className={classes.center}>
              {account.reportSentAt ? (
                <CompleteIcon className={classes.completeIcon} />
              ) : (
                <WaitIcon className={classes.iconColor} />
              )}
            </div>
          );
        },
      },
      {
        id: 'hasDocuments',
        header: 'Документы',
        render: (period: RegularNeedPeriodPropsFragment) => {
          return (
            !!period.documents.length && (
              <div className={classes.center}>
                <Link to={`/account/${period.id}`}>
                  <DocumentIcon className={classes.iconColor} />
                </Link>
              </div>
            )
          );
        },
      },
      {
        id: 'actions',
        header: '',
        render: (period: RegularNeedPeriodPropsFragment) => {
          return (
            <Button
              className={classes.button}
              color="primary"
              variant="contained"
              size="small"
              component={Link}
              to={`/wards/manage/${id}/needs/${needId}/period/${period.id}`}
            >
              Детали
            </Button>
          );
        },
      },
    ];

    return {
      columns,
    };
  }, [classes, id, needId]);

  /**
   * Подготовка переменных запроса
   */
  const { variables, extractPagedData, ...restVariables } = useQueryVariables<
    RegularNeedPeriodPropsFragment
  >(config);

  const { data, error } = useRegularNeedPeriodsQuery({ variables: { id, ...variables } });

  if (error) {
    toast.error('Ошибка загрузки периодов');
    return null;
  }

  /**
   * Парсинг данных
   */
  const entities = ((data?.wardById?.needs.filter(
    ({ id }) => id === needId
  )[0] as unknown) as GrocerySetNeed)?.periods as DataConnection<RegularNeedPeriodPropsFragment>;

  const { data: periods, count, startCursor, endCursor, page, rowsPerPage } = extractPagedData(
    entities
  );

  /**
   * Свойства для компонента Grid
   */
  const gridProps: DataGridProps<RegularNeedPeriodPropsFragment> = {
    ...restVariables,
    data: periods,
    getDataKey,
    count,
    startCursor: startCursor,
    endCursor: endCursor,
    page,
    rowsPerPage,
  };

  return <DataGrid {...gridProps} />;
};
