import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const AlarmIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M10.58156,3 C9.27339223,3 10.382741,4.61441807 9.38714595,6.0144736 C7.56708642,8.57393232 4.83699712,11.9296671 5.0076277,14.7166333 C5.33470514,20.0588981 10.3540526,21.3143491 11.0365749,21.1437185 C11.3274143,21.0710086 11.4998111,20.3820468 10.9228212,19.9493044 C10.2402989,19.4374127 9.21651537,17.7844515 9.21651537,16.7073234 C9.21651537,15.1906071 9.8990377,14.1478646 11.2640823,13.579096 C10.9607391,14.9062228 11.1313697,15.9110473 11.7759741,16.5935696 C12.7428807,17.6173531 13.425403,18.0154912 14.1648022,19.0961515 C14.9042014,20.1768119 14.6198171,21.2574722 15.47297,21.2574722 C15.871108,21.2574722 18.9424585,19.3805358 18.9993354,15.2854019 C19.028577,13.1800028 18.0893056,8.63080918 16.8948915,8.63080918 C16.1554923,8.63080918 16.1703838,9.55380979 15.871108,10.1096076 C15.6056827,10.6025403 15.2644215,11.0575552 14.8473246,11.4746522 C14.517521,9.60807557 14.1573009,8.24303091 13.7666642,7.37951825 C12.6860039,4.99069012 11.0913609,3 10.58156,3 Z"
        id="Path-3"
      />
    </SvgIcon>
  );
};
