import React from 'react';
import EditSpecialProjectForm from './EditSpecialProjectForm';
import { LayoutPageGrid } from 'ui/LayoutGrid/LayoutGrid';
import { usePermissionPage } from 'hooks';
import { useMeContext } from 'components';
import { SpecialProjectsPropsFragment } from '../../../schema';

type Props = {
  specialProject: SpecialProjectsPropsFragment;
};

export const EditSpecialProjectPage = ({ specialProject }: Props) => {
  const { user } = useMeContext();

  usePermissionPage('/special-projects', user?.permissions.specialProjects.manage);

  return (
    <LayoutPageGrid title="Изменение спецпроекта">
      <div>
        <EditSpecialProjectForm specialProject={specialProject} />
      </div>
    </LayoutPageGrid>
  );
};
