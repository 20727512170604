import React, { useCallback, useMemo, useState } from 'react';
import { LayoutPageGrid } from 'ui/LayoutGrid';
import { Button, Card, CardContent, IconButton } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { BenefactorDepositAccountForSpecificWards } from 'schema';
import { AddWardsModal, EditAccountTitle } from './components';
import { WardsGrid } from './WardsGrid';
import EditIcon from '@material-ui/icons/Edit';

interface Props {
  accounts: BenefactorDepositAccountForSpecificWards[];
  permissions: boolean;
}

export const EditSpecialAccountPage = ({ accounts, permissions }: Props) => {
  const { accountId } = useParams<{ accountId: string }>();
  const [openEditTitle, setOpenEditTitle] = useState<boolean>(false);
  const [openAddWard, setOpenAddWard] = useState<boolean>(false);

  const account = useMemo(() => {
    return accounts?.find((item) => item.id === accountId);
  }, [accountId, accounts]);

  const handleClickOpenEditTitle = useCallback(() => {
    setOpenEditTitle(true);
  }, []);

  const handleCloseEditTitle = useCallback(() => {
    setOpenEditTitle(false);
  }, []);

  const handleClickOpenAddWard = () => {
    setOpenAddWard(true);
  };

  const handleCloseAddWard = () => {
    setOpenAddWard(false);
  };

  return (
    <LayoutPageGrid
      title={account?.title ?? ''}
      iconButton={
        <IconButton onClick={handleClickOpenEditTitle}>
          <EditIcon />
        </IconButton>
      }
      tools={
        <Button color="primary" variant="contained" onClick={handleClickOpenAddWard}>
          Добавить подопечного
        </Button>
      }
    >
      <Card>
        <CardContent>
          <WardsGrid
            permission={permissions}
            wards={account?.wards ?? []}
            accountId={account?.id ?? ''}
          />
        </CardContent>
      </Card>
      <EditAccountTitle
        title={account?.title ?? ''}
        handleCloseTitle={handleCloseEditTitle}
        openEditTitle={openEditTitle}
      />
      {openAddWard && (
        <AddWardsModal
          permission={permissions}
          openAddWards={openAddWard}
          handleCloseAddWards={handleCloseAddWard}
        />
      )}
    </LayoutPageGrid>
  );
};
