import { useReducer, Reducer, useCallback, useMemo } from 'react';
import { State, StateConfig, QueryVariables, Cursor, DataConnection } from './types';
import {
  Action,
  OrderByAction,
  FilterAction,
  PagingAction,
  SearchAction,
  IncludeUnpublishedAction,
  IncludeClosedAndCancelledAction,
  CityAction,
} from './actions';
import { reducer } from './reducer';
import { SortDirection } from '@material-ui/core/TableCell';
import { Maybe, WardSortInput } from 'schema/serverTypes';
import { SortableTableColumn } from 'components/SortableTable';

interface ExtractedPagedData<T> {
  data: T[];
  count: number;
  startCursor: Cursor;
  endCursor: Cursor;
  page: number;
  rowsPerPage: number;
}

export const useQueryVariables = <T, O = {}, W = {}>(
  config: StateConfig<T, O>,
  defaultOrderBy?: WardSortInput
) => {
  const { columns, rowsPerPage = 10 } = config;
  const orderBy = defaultOrderBy as O;

  const initialState: State<T, O, W> = {
    columns,
    page: 0,
    rowsPerPage,
    orderBy,
    first: rowsPerPage,
    sortDirection: false,
    includeUnpublished: true,
    includeClosedOrCancelled: false,
    city: '',
  };

  const [state, dispatch] = useReducer<Reducer<State<T, O, W>, Action<W>>>(reducer, initialState);

  const onSort = useCallback(
    (sortBy: string, sortDirection: SortDirection) => {
      const action: OrderByAction = {
        type: 'orderBy',
        sortBy,
        sortDirection,
      };
      dispatch(action);
    },
    [dispatch]
  );

  const onSearch = useCallback((where: W | undefined) => {
    const action: FilterAction<W> = {
      type: 'filter',
      where,
    };
    dispatch(action);
  }, []);

  const onSearchName = useCallback((benefactorSearch: string | undefined) => {
    const action: SearchAction = {
      type: 'search',
      benefactorSearch,
    };
    dispatch(action);
  }, []);

  const onIncludeUnpublished = useCallback((includeUnpublished: boolean | undefined) => {
    const action: IncludeUnpublishedAction = {
      type: 'includeUnpublished',
      includeUnpublished,
    };
    dispatch(action);
  }, []);

  const onIncludeClosedOrCancelled = useCallback(
    (includeClosedOrCancelled: boolean | undefined) => {
      const action: IncludeClosedAndCancelledAction = {
        type: 'includeClosedOrCancelled',
        includeClosedOrCancelled,
      };
      dispatch(action);
    },
    []
  );

  const onSearchCity = useCallback((city: string | undefined) => {
    const action: CityAction = {
      type: 'onSearchCity',
      city,
    };
    dispatch(action);
  }, []);

  const onPage = useCallback(
    (page: number, startCursor: Cursor, endCursor: Cursor) => {
      const action: PagingAction = {
        type: 'paging',
        page,
        startCursor,
        endCursor,
        rowsPerPage,
      };
      dispatch(action);
    },
    [rowsPerPage]
  );

  const extractPagedData = useCallback(
    (dataConnection: Maybe<DataConnection<T>> | undefined): ExtractedPagedData<T> => {
      if (dataConnection === undefined || dataConnection === null) {
        return {
          data: [],
          count: -1,
          startCursor: undefined,
          endCursor: undefined,
          page: state.page,
          rowsPerPage: state.rowsPerPage,
        };
      }
      const totalCount = dataConnection?.totalCount || -1;
      const data =
        dataConnection?.nodes?.filter((t) => t !== undefined && t !== null).map((t) => t as T) ||
        [];
      const pageInfo = dataConnection?.pageInfo;

      return {
        data,
        count: totalCount > 0 ? totalCount : 0,
        startCursor: pageInfo?.startCursor,
        endCursor: pageInfo?.endCursor,
        page: state.page,
        rowsPerPage: state.rowsPerPage,
      };
    },
    [state.page, state.rowsPerPage]
  );

  const variables: QueryVariables<O, W> = useMemo(() => {
    return {
      where: state.where,
      first: state.first,
      last: state.last,
      after: state.after,
      before: state.before,
      orderBy: state.orderBy,
      benefactorSearch: state.benefactorSearch,
      includeUnpublished: state.includeUnpublished,
      includeClosedOrCancelled: state.includeClosedOrCancelled,
      city: state.city,
    };
  }, [
    state.where,
    state.first,
    state.last,
    state.after,
    state.before,
    state.orderBy,
    state.benefactorSearch,
    state.includeUnpublished,
    state.includeClosedOrCancelled,
    state.city,
  ]);

  const sortableColumns: SortableTableColumn<T>[] = useMemo(() => {
    return columns.map(({ orderBy, ...column }) => {
      const sortableColumn: SortableTableColumn<T> = {
        ...column,
        sortable: orderBy !== undefined,
      };
      return sortableColumn;
    });
  }, [columns]);

  const { sortDirection, sortBy } = state;

  return {
    variables,
    onSort,
    onSearch,
    onPage,
    extractPagedData,
    columns: sortableColumns,
    sortDirection,
    sortBy,
    onSearchName,
    onIncludeUnpublished,
    onIncludeClosedOrCancelled,
    onSearchCity,
  };
};
