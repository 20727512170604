import React, { useMemo } from 'react';
import { Card, CardContent, Tooltip, Typography } from '@material-ui/core';
import { shortDescription } from 'helpers';
import { useStyles } from '../../useStyles';
import { Document } from 'schema';
import DescriptionTwoToneIcon from '@material-ui/icons/DescriptionTwoTone';

interface PhotoCardProps {
  document: Document;
}

export const PhotoCard = ({ document }: PhotoCardProps) => {
  const styles = useStyles();

  const fileType = useMemo(() => {
    return document.file.id.split('.')[1];
  }, [document]);

  return (
    <Card className={styles.photoCard}>
      <CardContent>
        {fileType === 'pdf' ? (
          <DescriptionTwoToneIcon className={styles.fileIcon} />
        ) : (
          <img
            src={document.file.url}
            alt={document.title || 'photo'}
            height="145px"
            width="145px"
          />
        )}
      </CardContent>
      <Tooltip
        classes={{ tooltip: styles.tooltip }}
        title={document.title || ''}
        arrow
        placement="top-start"
      >
        <Typography>{shortDescription(document.title, 18)}</Typography>
      </Tooltip>
    </Card>
  );
};
