import React from 'react';
import { LayoutPageGrid } from '../../../ui/LayoutGrid';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import { Route, useParams } from 'react-router-dom';
import { Loading } from '../../../ui/Loading';
import { useAchievementByIdQuery } from '../../../schema';
import { AwardCard, BenefactorAchievementCard } from './components';
import { AwardEditModal } from './AwardEditModal';
import { AwardPublishModal } from './AwardPublishModal';
import { toast } from 'react-toastify';
import { AwardDeleteModal } from './AwardDeleteModal';
import { AwardCsvModal } from './AwardCsvModal';

export const AwardManagePage = () => {
  const { awardId } = useParams<{ awardId: string }>();
  const { data, loading, error } = useAchievementByIdQuery({ variables: { id: awardId } });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    toast.error('Ошибка загрузки награды');
    return null;
  }

  return (
    <>
      <LayoutPageGrid title={'Награда'}>
        <Card>
          <CardHeader title="Информация о награде" />
          <AwardCard data={data} />
        </Card>
        <Card>
          <CardHeader title="Награжденные" />
          <CardContent>
            <BenefactorAchievementCard />
          </CardContent>
        </Card>
      </LayoutPageGrid>

      <Route path="/award/:awardId/edit" render={() => <AwardEditModal achievement={data} />} />

      <Route
        path="/award/:awardId/publish"
        render={() => <AwardPublishModal achievement={data} />}
      />

      <Route exact path="/award/:awardId/csv" component={AwardCsvModal} />

      <Route exact path="/award/:awardId/delete" component={AwardDeleteModal} />
    </>
  );
};
