import React from 'react';
import { Grid, MenuItem } from '@material-ui/core';
import { Field } from 'react-final-form';
import { SelectField } from 'components';
import { NeedFormProps } from '../types';

export const AddRegularNeedForm = (props: NeedFormProps) => {
  const { required } = props;
  return (
    <Grid container spacing={4}>
      <Grid item lg={12} md={12} xl={12} xs={12}>
        <Field name="regularNeed" component={SelectField} validate={required}>
          <MenuItem value={0}>Продуктовая корзина</MenuItem>
        </Field>
      </Grid>
    </Grid>
  );
};
