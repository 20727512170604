import { useDebounce } from 'use-debounce';
import { AddressSuggestionMode, useAddressSuggestionsLazyQuery } from 'schema/serverTypes';
import { useEffect } from 'react';

const isEnoughLength = (address: string) => (address ? address.length > 2 : false);

export interface OptionType {
  label: string;
  value: string;
}

export const useAddressSuggestions = (
  address: string,
  count: number = 10,
  debounce: number = 500,
  mode?: AddressSuggestionMode,
  needDetermineCoordinates?: 'hasRegion' | 'hasCity' | 'hasHouse' | 'hasCoordinates'
) => {
  const [debouncedAddress] = useDebounce(address, debounce);
  const [fetchSuggestions, { data }] = useAddressSuggestionsLazyQuery();

  useEffect(() => {
    if (isEnoughLength(debouncedAddress)) {
      fetchSuggestions({
        variables: {
          query: debouncedAddress,
          count,
          mode: mode ? mode : AddressSuggestionMode.Regular,
        },
      });
    }
  }, [count, fetchSuggestions, debouncedAddress, mode]);

  const suggestions = data?.addressSuggestions
    .filter((address) => {
      if (!address?.displayText && !address?.fullAddress) {
        return false;
      }

      if (!needDetermineCoordinates) {
        return true;
      }

      return address[needDetermineCoordinates];
    })
    //.map((t) => t!.unrestrictedValue as string)
    .map(
      (address) =>
        ({
          label: address!.displayText as string,
          value: address!.fullAddress as string,
        } as OptionType)
    );

  return suggestions || [];
};
