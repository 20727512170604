import React from 'react';
import { BenefactorStatusByIdQuery } from '../../../../schema';
import { PropertiesTable, PropertiesTableRow } from '../../../../ui/PropertiesTable';
import {
  Avatar,
  Box,
  Button,
  CardContent,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { useMeContext } from '../../../../components';
import { Link } from 'react-router-dom';

type Props = {
  data: BenefactorStatusByIdQuery | undefined;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '20px',
    },
    avatar: {
      width: theme.spacing(14),
      height: theme.spacing(14),
    },
    deleteButton: {
      background: theme.palette.warning.dark,
    },
    buttonContainer: {
      display: 'flex',
      gap: '10px',
      marginTop: 'auto',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
  })
);

export const BenefactorStatusCard = ({ data }: Props) => {
  const classes = useStyles();
  const { user } = useMeContext();

  const permissionsManage = user?.permissions.achievements.manage;

  return (
    <CardContent className={classes.content}>
      <Avatar src={data?.benefactorStatusById?.image.url} className={classes.avatar} />
      <PropertiesTable>
        <PropertiesTableRow caption="Название награды" value={data?.benefactorStatusById?.title} />
        <PropertiesTableRow
          caption="История"
          link={`/block/${data?.benefactorStatusById?.story?.storyBlock.id}/story/${data?.benefactorStatusById?.story?.id}`}
        />
      </PropertiesTable>

      {permissionsManage && (
        <Box className={classes.buttonContainer}>
          <Button
            color="primary"
            variant="contained"
            component={Link}
            to={`/benefactor-statuses/manage/${data?.benefactorStatusById?.id}/edit`}
          >
            Редактировать
          </Button>
          <Button
            variant="contained"
            className={classes.deleteButton}
            component={Link}
            to={`/benefactor-statuses/manage/${data?.benefactorStatusById?.id}/delete`}
          >
            Удалить
          </Button>
        </Box>
      )}
    </CardContent>
  );
};
