import React from 'react';
import { Modal, useMeContext } from 'components';
import { AddNeedForm } from './AddNeedForm';
import { useBackRoute, usePermissionPage } from 'hooks';
import { AddNeedFormProps } from './types';

export const AddNeedPage = (props: AddNeedFormProps) => {
  const { user } = useMeContext();

  usePermissionPage('/ward', user?.permissions.wards.manageNeeds);

  const { onCancel } = useBackRoute();
  return (
    <Modal
      onClose={onCancel}
      aria-labelledby="Добавление потребности"
      aria-describedby="Используйте эту форму для добавление потребности"
    >
      <AddNeedForm {...props} />
    </Modal>
  );
};
