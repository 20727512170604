import {
  UpdateBenefactorEmailByAdministratorInput,
  useUpdateBenefactorEmailByAdministratorMutation,
} from 'schema';
import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

interface InitialValuesType {
  email?: string | null;
}

export const useEditMail = (
  benefactorId: string,
  handleCloseEmail: () => void,
  mail?: string | null
) => {
  const [updateMail, { data, error, loading }] = useUpdateBenefactorEmailByAdministratorMutation();

  const onSubmit = useCallback(
    async (values: InitialValuesType) => {
      const inputValues: UpdateBenefactorEmailByAdministratorInput = {
        benefactorId,
        email: values.email,
      };
      await updateMail({
        variables: {
          input: inputValues,
        },
      });
    },
    [benefactorId, updateMail]
  );

  const initialValues: InitialValuesType = {
    email: mail,
  };

  useEffect(() => {
    if (error) {
      toast.error('Ошибка изменения электронной почты пользователя');
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      toast.success('Электронная почта пользователя успешно изменена');
      handleCloseEmail();
    }
  }, [data, handleCloseEmail]);

  return {
    initialValues,
    loading,
    onSubmit,
  };
};
