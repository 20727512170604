import { useState, useCallback } from 'react';
import { useForm, FormConfig } from 'react-final-form-hooks';
import { useRequiredField } from 'components';
import { useForgotPasswordMutation, ForgotPasswordInput } from 'schema/serverTypes';

type ForgotPasswordFormConfig = FormConfig<ForgotPasswordInput>;

export const useForgotPasswordForm = () => {
  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const [forgotPassword, { loading, data }] = useForgotPasswordMutation({
    errorPolicy: 'ignore',
    onError: () => {
      setOpen(true);
    },
  });

  const onSubmit = useCallback(
    (input: ForgotPasswordInput) => {
      forgotPassword({ variables: { input } });
    },
    [forgotPassword]
  );

  const { form, handleSubmit, submitting, pristine, hasValidationErrors } = useForm({
    onSubmit,
    initialValues: {
      email: '',
    },
  } as ForgotPasswordFormConfig);

  const email = useRequiredField('email', form);

  return {
    handleClose,
    fields: {
      email,
    },
    handleSubmit,
    disabled: loading || submitting || pristine || hasValidationErrors,
    open,
    success: data?.forgotPassword.success ?? false,
  };
};
