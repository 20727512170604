import React, { FC } from 'react';
import { Button, CircularProgress, ButtonProps } from '@material-ui/core';
import { useStyles } from './useStyles';
import clsx from 'clsx';

type MutableButtonProps = ButtonProps & {
  loading: boolean;
};

export const MutableButton: FC<MutableButtonProps> = ({
  children,
  className,
  loading,
  variant = 'contained',
  disabled,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Button
      className={clsx(classes.root, className)}
      variant={variant}
      disabled={disabled || loading}
      {...rest}
    >
      {loading && <CircularProgress className={classes.icon} size={16} color="inherit" />}
      {children}
    </Button>
  );
};
