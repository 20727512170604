import React from 'react';
import { Button } from '@material-ui/core';
import { Link, Route } from 'react-router-dom';
import { UpdateCompanyBalancePopup } from './UpdateCompanyBalancePopup';

export const UpdateCompanyBalance = () => {
  return (
    <>
      <Button color="primary" variant="contained" component={Link} to={`/company/balance/update`}>
        Изменить баланс
      </Button>

      <Route exact path="/company/balance/update" component={UpdateCompanyBalancePopup} />
    </>
  );
};
