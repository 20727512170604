import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Avatar,
  Badge,
  createStyles,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
  withStyles,
} from '@material-ui/core';
import { formatDate, getInitials } from 'helpers';
// import moment, { Moment } from 'moment';
import { WardStatusLabel } from '../../../wards/WardsPage/WardsGrid/WardStatusLabel';
import { makeStyles } from '@material-ui/styles';
import { Link, useParams } from 'react-router-dom';
import {
  BenefactorByIdDocument,
  Maybe,
  useRemoveWardFromTheBenefactorDepositAccountForSpecificWardsMutation,
  Ward,
} from 'schema';
import DeleteIcon from '@material-ui/icons/Delete';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nameContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    avatar: {
      marginRight: theme.spacing(2),
    },
    statusAlarm: {
      color: theme.palette.error.main,
    },
    statusSuccess: {
      color: theme.palette.success.main,
    },
    statusWarning: {
      color: theme.palette.warning.main,
    },
    dateOfDeath: {
      textAlign: 'center',
    },
  })
);

const wrapTableCellWithLink = (ward: Maybe<Ward>, cell: React.ReactNode) => (
  <Link to={`/wards/manage/${ward?.id ?? ''}`}>{cell}</Link>
);

// const curStatus = (amount: number, requiredAmount: number, endDate: Moment) => {
//   const timeLeft = endDate.diff(moment(), 'days');
//   return amount / requiredAmount <= 0.8 && timeLeft <= 7
//     ? 'statusAlarm'
//     : amount / requiredAmount <= 0.5 && timeLeft <= 10
//     ? 'statusWarning'
//     : 'statusSuccess';
// };

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.main,
    border: '2px solid white',
    right: 22,
    width: 14,
    height: 14,
    borderRadius: '50%',
  },
  colorSecondary: {
    backgroundColor: theme.palette.text.secondary,
    color: theme.palette.text.secondary,
  },
}))(Badge);

/*const needs = (
  user: Maybe<Ward>,
  classes: Record<
    'nameContainer' | 'avatar' | 'statusAlarm' | 'statusSuccess' | 'statusWarning' | 'dateOfDeath',
    string
  >
) => {
  const needs = user?.needs as OneTimeNeedWardPage[];
  const titles = needs
    ?.filter(isOneTimeNeed)
    .filter(isActiveNeed)
    .map((item, index, arr) => {
      const { amount, requiredAmount, endDate } =
        item ? (item.accounts.nodes[0] as any) : null;

      return (
        <Typography
          variant="body2"
          component="span"
          key={item.id + index}
          className={classes[curStatus(amount, requiredAmount, moment(endDate))]}
        >
          {item.title}
          {index + 1 < arr.length ? ', ' : null}
        </Typography>
      );
    });

  return user?.countsAndAmounts.totalOneTimeNeedsCount &&
    titles.length &&
    !user?.publicInformation.dateOfDeath ? (
    <>
      <span>{user?.countsAndAmounts.totalOneTimeNeedsCount}</span>
      <br />
      {titles}
    </>
  ) : (
    <Typography variant="body1" color="textSecondary">
      Отсутствует
    </Typography>
  );
}*/

export const WardsGrid = ({
  wards,
  accountId,
  permission,
}: {
  wards: Maybe<Ward>[];
  accountId: string;
  permission: boolean;
}) => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();

  const refetchQueries = useMemo(() => {
    return [
      {
        query: BenefactorByIdDocument,
        variables: { id, permission: permission },
      },
    ];
  }, [id, permission]);

  const [
    removeWard,
    { data, error },
  ] = useRemoveWardFromTheBenefactorDepositAccountForSpecificWardsMutation({
    refetchQueries,
  });

  const handleRemoveWard = useCallback(
    async (wardId: string) => {
      await removeWard({
        variables: {
          input: {
            wardId,
            id: accountId,
          },
        },
      });
    },
    [removeWard, accountId]
  );

  useEffect(() => {
    if (error) {
      toast.error('Ошибка удаления подопечного');
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      toast.success('Подопечный успешно удален из списка');
    }
  }, [data]);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align="left">ФИО</TableCell>
          <TableCell align="left">Населенный пункт</TableCell>
          <TableCell align="center">Регулярные потребности</TableCell>
          <TableCell align="center">Разовые потребности</TableCell>
          <TableCell align="center">Статус</TableCell>
          <TableCell align="center">Дата смерти</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {wards.map((ward) => (
          <TableRow key={ward?.id}>
            <TableCell>
              <div className={classes.nameContainer}>
                {wrapTableCellWithLink(
                  ward,
                  <StyledBadge
                    overlap="circle"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    variant="dot"
                  >
                    <Avatar className={classes.avatar} src={ward?.publicInformation.photo.url}>
                      {getInitials(ward?.publicInformation.name.fullName)}
                    </Avatar>
                  </StyledBadge>
                )}
                {wrapTableCellWithLink(
                  ward,
                  <Typography variant="body1">{ward?.publicInformation.name.fullName}</Typography>
                )}
              </div>
            </TableCell>
            <TableCell align="left">{ward?.publicInformation.city}</TableCell>
            <TableCell align="center">
              {ward?.countsAndAmounts.totalRegularNeedsCount &&
              !ward?.publicInformation.dateOfDeath ? (
                <>
                  <span>{ward?.countsAndAmounts.totalRegularNeedsCount}</span>
                  <br />
                  {/*<Typography*/}
                  {/*  variant="body2"*/}
                  {/*  className={*/}
                  {/*    classes[*/}
                  {/*      curStatus(*/}
                  {/*        ward.amounts.regularNeeds.subscriptionsAmount +*/}
                  {/*          ward?.regularNeeds.oneTimeDonationsAmount,*/}
                  {/*        ward?.amounts.regularNeeds.totalRequiredAmount,*/}
                  {/*        moment().endOf('month')*/}
                  {/*      )*/}
                  {/*    ]*/}
                  {/*  }*/}
                  {/*>*/}
                  {/*  Самое необходимое*/}
                  {/*</Typography>*/}
                </>
              ) : (
                <Typography variant="body1" color="textSecondary">
                  Отсутствует
                </Typography>
              )}
            </TableCell>
            {/*<TableCell align="center">{needs(ward, classes)}</TableCell>*/}
            <TableCell align="center">
              <WardStatusLabel
                immediateHelpRequired={ward?.immediateHelpRequired ?? false}
                deactivated={Boolean(ward?.deactivationInformation)}
              />
            </TableCell>
            <TableCell align="center">
              <div className={classes.dateOfDeath}>
                {ward?.publicInformation.dateOfDeath
                  ? formatDate(ward?.publicInformation.dateOfDeath)
                  : '-'}
              </div>
            </TableCell>
            <TableCell align="center">
              <IconButton onClick={() => handleRemoveWard(ward?.id ?? '')}>
                <DeleteIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
