import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Button, FormControl, Grid, MenuItem, Select } from '@material-ui/core';
import useDebouncedCallback from 'use-debounce/lib/useDebouncedCallback';
import { AdministratorsFilterInput, AdministratorsKind } from 'schema/serverTypes';
import { kindNames } from 'schema/kinds';
import { SearchInput } from 'components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(1),
    },
    search: {
      paddingRight: theme.spacing(2),
    },
    selectLabel: {
      minWidth: 'max-content',
      margin: '10px',
    },
    resetButton: {
      display: 'block',
      marginLeft: 'auto',
    },
  })
);

export type ModeratorsSearchProps = {
  onSearch: (input: AdministratorsFilterInput | undefined) => void;
};

interface FilterOptionsType {
  status: string | number;
  selectKind: AdministratorsKind | number;
  selectConfirm: string | number;
}

export const ModeratorsSearch = (props: ModeratorsSearchProps) => {
  const { onSearch } = props;
  const classes = useStyles();

  const initialFilterOptions: FilterOptionsType = {
    status: '',
    selectKind: 0,
    selectConfirm: '',
  };

  const [filterOptions, setFilterOptions] = useState<FilterOptionsType>(initialFilterOptions);

  const nameRef = useRef<HTMLInputElement>();

  const getWhere = useCallback(() => {
    const displayName = nameRef.current?.value || '';
    const where: AdministratorsFilterInput = {
      displayName: displayName !== '' ? displayName : undefined,

      disabled:
        typeof filterOptions.status === 'number' ? Boolean(filterOptions.status) : undefined,

      kind: typeof filterOptions.selectKind !== 'number' ? filterOptions.selectKind : undefined,

      emailConfirmed:
        typeof filterOptions.selectConfirm === 'number'
          ? Boolean(filterOptions.selectConfirm)
          : undefined,
    };
    return where;
  }, [filterOptions]);

  const [handleOnNameChange] = useDebouncedCallback(() => {
    onSearch(getWhere());
  }, 1000);

  const handleOnFilterChange = useCallback(
    (event) => {
      const value = event.target.value;
      const name = event.target.name;
      setFilterOptions({
        ...filterOptions,
        [name]: value,
      });
    },
    [filterOptions]
  );

  useEffect(() => {
    onSearch(getWhere());
  }, [filterOptions, onSearch, getWhere]);

  const handleOnReset = useCallback(() => {
    if (nameRef && nameRef.current) {
      nameRef.current.value = '';
    }
    filterOptions.status = '';
    filterOptions.selectKind = 0;
    filterOptions.selectConfirm = '';
    onSearch(undefined);
  }, [onSearch, filterOptions]);

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <SearchInput
            inputRef={nameRef}
            className={classes.search}
            placeholder="Поиск пользователя..."
            onChange={handleOnNameChange}
          />
        </Grid>
      </Grid>
      <Grid container spacing={4} alignItems="center">
        <Grid item lg={3} md={4} xl={2} xs={12}>
          <Box display="flex" alignItems="center">
            <h4 className={classes.selectLabel}>Все статусы:</h4>
            <FormControl fullWidth>
              <Select
                name="status"
                value={filterOptions.status}
                displayEmpty
                variant={'outlined'}
                onChange={handleOnFilterChange}
              >
                <MenuItem value="">Не важно</MenuItem>
                <MenuItem value={0}>Активирован</MenuItem>
                <MenuItem value={1}>Заблокирован</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item lg={3} md={4} xl={3} xs={12}>
          <Box display="flex" alignItems="center">
            <h4 className={classes.selectLabel}>Уровень доступа:</h4>
            <FormControl fullWidth>
              <Select
                name="selectKind"
                value={filterOptions.selectKind}
                displayEmpty
                variant={'outlined'}
                onChange={handleOnFilterChange}
              >
                <MenuItem value={0}>Все администраторы</MenuItem>
                <MenuItem value={AdministratorsKind.SystemAdministrators}>
                  {kindNames[AdministratorsKind.SystemAdministrators]}
                </MenuItem>
                <MenuItem value={AdministratorsKind.FoundationRepresentatives}>
                  {kindNames[AdministratorsKind.FoundationRepresentatives]}
                </MenuItem>
                <MenuItem value={AdministratorsKind.Moderators}>
                  {kindNames[AdministratorsKind.Moderators]}
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item lg={3} md={4} xl={2} xs={12}>
          <Box display="flex" alignItems="center">
            <h4 className={classes.selectLabel}>Статус email:</h4>
            <FormControl fullWidth>
              <Select
                name="selectConfirm"
                value={filterOptions.selectConfirm}
                displayEmpty
                variant={'outlined'}
                onChange={handleOnFilterChange}
              >
                <MenuItem value="">Не важно</MenuItem>
                <MenuItem value={0}>Не подтверждён</MenuItem>
                <MenuItem value={1}>Подтверждён</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item lg={3} md={12} xl={5} xs={12}>
          <Button className={classes.resetButton} variant="contained" onClick={handleOnReset}>
            Сбросить фильтры
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
