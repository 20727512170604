import React, { useState } from 'react';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import {
  CloudPaymentsSavedCardPaymentIntentInput,
  CloudPaymentsSavedCardsPropsFragment,
} from '../../../../../../../../schema';
import { RedButton } from '../../../../../../../../components/Buttons';

type Props = {
  handleClose: () => void;
  setActiveStep: (updateFunction: (prevStep: number) => number) => void;
  cloudPaymentsSavedCards: CloudPaymentsSavedCardsPropsFragment[];
  setPaymentIntentInput: (paymentIntentInput: CloudPaymentsSavedCardPaymentIntentInput) => void;
};

export const PaymentDetailsStep = ({
  cloudPaymentsSavedCards,
  handleClose,
  setActiveStep,
  setPaymentIntentInput,
}: Props) => {
  const [selectedCard, setSelectedCard] = useState(cloudPaymentsSavedCards[0].id);

  const handleNext = () => {
    const selectedCardDetails = cloudPaymentsSavedCards.find((card) => card.id === selectedCard);
    if (selectedCardDetails) {
      setPaymentIntentInput({ savedCardId: selectedCardDetails.id, setAsDefaultCard: true });

      setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
  };

  return (
    <>
      <DialogContent>
        <FormControl component="fieldset">
          <RadioGroup
            value={selectedCard}
            onChange={(event) => setSelectedCard(event.target.value)}
          >
            {cloudPaymentsSavedCards.map((card) => (
              <FormControlLabel
                key={card.id}
                value={card.id}
                control={<Radio />}
                label={`${card.cardType} ${card.maskedCardNumber}, ${card.cardExpirationDate}`}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleBack}>Назад</Button>
        <Button variant="contained" color="primary" onClick={handleNext}>
          Далее
        </Button>
        <RedButton onClick={handleClose}>Отменить</RedButton>
      </DialogActions>
    </>
  );
};
