import React from 'react';
import { AlarmIcon, CompleteIcon } from 'components';
import palette from 'theme/palette';

export interface WardStatusLabelProps {
  immediateHelpRequired: boolean;
  deactivated: boolean;
}

export const WardStatusLabel = ({ immediateHelpRequired, deactivated }: WardStatusLabelProps) => {
  if (deactivated) return <CompleteIcon htmlColor={palette.error.main} />;

  return immediateHelpRequired ? (
    <AlarmIcon color="error" />
  ) : (
    <CompleteIcon htmlColor={palette.success.main} />
  );
};
