import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Box, Button, Grid, MenuItem, Select } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    search: {
      paddingRight: theme.spacing(2),
    },
    resetButton: {
      display: 'block',
      marginLeft: 'auto',
    },
    selectLabel: {
      margin: '10px',
    },
  })
);

export type AwardsSearchProps = {
  onIncludeUnpublished?: (includeUnpublished: boolean | undefined) => void;
};

export const AwardsSearch = (props: AwardsSearchProps) => {
  const { onIncludeUnpublished } = props;
  const classes = useStyles();

  const [published, setPublished] = useState<string | number>(0);

  const handleOnChangePublished = (e: React.ChangeEvent<{ value: unknown }>) => {
    setPublished(e.target.value as number | string);
  };

  useEffect(() => {
    if (onIncludeUnpublished !== undefined) {
      onIncludeUnpublished(Boolean(published));
    }
  }, [published, onIncludeUnpublished]);

  const handleOnReset = useCallback(() => {
    setPublished(0);

    if (onIncludeUnpublished) {
      onIncludeUnpublished(false);
    }
  }, [onIncludeUnpublished]);

  return (
    <div className={classes.root}>
      <Grid container spacing={4} className={classes.root}>
        <Grid item lg={3} md={4} xl={2} xs={12}>
          <Box display="flex" alignItems="center">
            <h4 className={classes.selectLabel}>Неопубликованные:</h4>
            <Select
              value={published}
              displayEmpty
              variant={'outlined'}
              onChange={handleOnChangePublished}
            >
              <MenuItem value={1}>Да</MenuItem>
              <MenuItem value={0}>Нет</MenuItem>
            </Select>
          </Box>
        </Grid>
        <Grid item lg={6} md={4} xl={8} xs={12}>
          <Button className={classes.resetButton} variant="contained" onClick={handleOnReset}>
            Сбросить фильтры
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
