import React from 'react';
import { AutoFocusedForm, notEmpty, PhotoField, TextField } from 'components';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { MutableButton } from 'ui/MutableButton';
import { RedButton } from 'components/Buttons';
import { Field } from 'react-final-form';
import { useEditStoryForm } from './useEditStoryForm';
import { StoryByIdQuery } from '../../../../schema';
import { InvisibleCard } from '../../../../ui/InvisibleCard';
import { useBackRoute } from '../../../../hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 500,
    },
    header: {
      fontWeight: 'bolder',
      marginBottom: '10px',
      marginTop: '20px',
    },
    content: {
      display: 'flex',
      gap: '20px',
      margin: '10px',
    },
    privateHeader: {
      fontWeight: 'bolder',
      marginBottom: '20px',
    },
    accessButton: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.success.dark,
      '&:hover': {
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.success.light,
      },
    },
  })
);

interface UpdateNameStoryFormProps {
  story: StoryByIdQuery | undefined;
}

export const EditStoryForm = ({ story }: UpdateNameStoryFormProps) => {
  const styles = useStyles();
  const { onCancel } = useBackRoute();
  const { initialValues, onSubmit, loading } = useEditStoryForm({ story, onCancel });

  return (
    <AutoFocusedForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, pristine }) => {
        return (
          <form onSubmit={handleSubmit}>
            <InvisibleCard>
              <CardContent>
                <Card className={styles.root}>
                  <CardHeader title={`Изменить историю`} />
                  <Divider />
                  <CardContent>
                    <Typography variant="h5" component="h5" className={styles.header}>
                      Изображение для ленты историй
                    </Typography>
                    <Grid container>
                      <Grid item lg={4} md={4} xl={4} xs={12}>
                        <Field
                          name="imageId"
                          label="Изображение истории"
                          component={PhotoField}
                          url={story?.storyById?.image?.url}
                        />
                      </Grid>

                      <Grid item lg={8} md={8} xl={8} xs={12}>
                        <Typography variant="h5" component="h5" className={styles.privateHeader}>
                          Данные истории
                        </Typography>
                        <Grid container spacing={4}>
                          <Grid item lg={12} md={12} xl={12} xs={12}>
                            <Field
                              name="title"
                              label="Название истории"
                              component={TextField}
                              type="string"
                              validate={notEmpty}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
              <CardActions>
                <MutableButton
                  className={styles.accessButton}
                  loading={loading}
                  color="primary"
                  disabled={pristine}
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  Сохранить
                </MutableButton>
                <RedButton
                  color="primary"
                  size="medium"
                  type="button"
                  variant="contained"
                  onClick={onCancel}
                >
                  Отменить
                </RedButton>
              </CardActions>
            </InvisibleCard>
          </form>
        );
      }}
    />
  );
};
