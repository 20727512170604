import React from 'react';
import {
  Card,
  CardHeader,
  Divider,
  CardContent,
  makeStyles,
  createStyles,
  CardActions,
  Theme,
  Grid,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { AutoFocusedForm, DateField, required, TextField } from 'components';
import { Field } from 'react-final-form';
import { BenefactorExtendedPropsFragment } from 'schema/serverTypes';
import { useBackRoute, usePermissionPage } from 'hooks';
import { useDonateMoneyForm } from './useDonateMoneyForm';
import { MutableButton } from 'ui/MutableButton';
import { RedButton } from 'components/Buttons';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '600px',
      minWidth: '600px',
    },
    header: {
      fontWeight: 'bolder',
    },
    actions: {
      justifyContent: 'flex-end',
    },
    item: {
      marginBottom: theme.spacing(2),
    },
    accessButton: {
      marginLeft: '15px',
      marginRight: '15px',
      color: theme.palette.common.white,
      backgroundColor: theme.palette.success.dark,
      '&:hover': {
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.success.light,
      },
    },
  })
);

export const DonateMoneyForm = (props: {
  benefactor: BenefactorExtendedPropsFragment;
  permission: boolean;
}) => {
  const classes = useStyles();
  const { permission, benefactor } = props;
  const { accountId } = useParams<{ accountId: string }>();
  const { onCancel } = useBackRoute();

  usePermissionPage('/benefactors', permission);

  const { initialValues, onSubmit, loading } = useDonateMoneyForm(accountId, benefactor, onCancel);

  return (
    <AutoFocusedForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      subscription={{
        submitError: true,
      }}
      render={({ handleSubmit, pristine, submitError }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card className={classes.root}>
              <CardHeader title="Добавление платежа" />
              <Divider />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} xl={6} xs={6} className={classes.item}>
                    <Field
                      name="amount"
                      label="Сумма платежа *"
                      fullWidth={true}
                      component={TextField}
                      type="number"
                      validate={required}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} xl={6} xs={6} className={classes.item}>
                    <Field
                      name="transferDate"
                      label="Дата платежа *"
                      fullWidth={true}
                      component={DateField}
                      returnTime={false}
                      validate={required}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item lg={12} md={12} xl={12} xs={12} className={classes.item}>
                    <Field
                      name="counterpartyName"
                      label="Наименование контрагента *"
                      fullWidth={true}
                      component={TextField}
                      validate={required}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item lg={12} md={12} xl={12} xs={12} className={classes.item}>
                    <Field
                      name="documentNumber"
                      label="Номер документа *"
                      fullWidth={true}
                      component={TextField}
                      validate={required}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item lg={12} md={12} xl={12} xs={12} className={classes.item}>
                    <Field
                      name="purpose"
                      label="Назначение платежа *"
                      fullWidth={true}
                      component={TextField}
                      validate={required}
                      multiline
                      rows={3}
                    />
                  </Grid>
                </Grid>
                {submitError && <Alert severity="error">{submitError}</Alert>}
              </CardContent>
              <CardActions className={classes.actions}>
                <MutableButton
                  className={classes.accessButton}
                  loading={loading}
                  disabled={pristine}
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  Сохранить
                </MutableButton>
                <RedButton
                  color="primary"
                  size="medium"
                  type="button"
                  variant="contained"
                  onClick={onCancel}
                >
                  Отменить
                </RedButton>
              </CardActions>
            </Card>
          </form>
        );
      }}
    />
  );
};
