import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Theme, createStyles, Icon } from '@material-ui/core';
import { Route } from 'react-router-dom';
import { DataGrid } from 'components';
import { ModeratorsSearch } from './ModeratorsSearch';
import { useQueryVariables, ColumnConfig, DataConnection } from 'hooks';
import { CreateModeratorPage } from '../CreateModeratorPage';
import { EditModeratorPage } from '../EditModeratorPage';
import { ModeratorActionsButton } from './ModeratorActionsButton';
import { ChangeUserPasswordPage } from '../ChangeUserPassword';
import {
  User,
  BenefactorSort,
  useAdministratorsQuery,
  AdministratorsFilterInput,
  AdministratorsQueryVariables,
  SystemAdministrator,
  Moderator,
  FoundationRepresentative,
  SortEnumType,
  AdministratorsSortInput,
} from 'schema/serverTypes';
import { DataGridProps } from 'components/DataGrid/types';
import { QueryContextProvider } from '../QueryContextProvider';
import { toast } from 'react-toastify';
import { AccountCircle, CheckCircle } from '@material-ui/icons';
import { formatDate, getAdministratorKind } from 'helpers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nameContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    avatar: {
      marginRight: theme.spacing(2),
    },
    redIcon: {
      color: theme.palette.error.dark,
    },
    greenIcon: {
      color: theme.palette.success.dark,
    },
    greyIcon: {
      color: theme.palette.grey['500'],
    },
  })
);

const getDataKey = (user: User) => user.id;

export const ModeratorsGrid = () => {
  const classes = useStyles();

  const config = useMemo(() => {
    const columns: ColumnConfig<
      SystemAdministrator | Moderator | FoundationRepresentative,
      AdministratorsSortInput
    >[] = [
      {
        id: 'fullName',
        header: 'Имя',
        orderBy: (kind: SortEnumType) => ({
          displayName: kind,
        }),
        render: (user: User) => {
          return (
            <div className={classes.nameContainer}>
              <Icon className={user.disabled ? classes.redIcon : classes.greenIcon}>
                <AccountCircle />
              </Icon>
              <Typography variant="body1">{user.displayName}</Typography>
            </div>
          );
        },
      },
      {
        id: 'email',
        header: 'Email',
        render: (user: User) => {
          return (
            <div className={classes.nameContainer}>
              <Icon className={user.emailConfirmed ? classes.greenIcon : classes.greyIcon}>
                <CheckCircle />
              </Icon>
              <Typography variant="body1">{user.email}</Typography>
            </div>
          );
        },
      },
      {
        id: 'kind',
        header: 'Тип пользователя',
        render: (user) => {
          const kind = user.__typename;
          if (kind) {
            return getAdministratorKind(kind);
          }
        },
      },
      {
        id: 'foundation',
        header: 'Фонд партнер',
        render: (user: SystemAdministrator | Moderator | FoundationRepresentative) => {
          if (!('foundation' in user)) {
            return null;
          }
          return user.foundation.name;
        },
      },
      {
        id: 'createOn',
        header: 'Дата регистрации',
        render: (user: User) => {
          return formatDate(user.createdOn);
        },
      },
      {
        id: 'action',
        render: (user: User) => {
          return <ModeratorActionsButton userId={user.id} disabled={user.disabled} />;
        },
      },
    ];
    return {
      columns,
    };
  }, [classes.nameContainer, classes.redIcon, classes.greenIcon, classes.greyIcon]);

  const {
    onSort,
    onSearch,
    onPage,
    variables,
    extractPagedData,
    columns,
    sortDirection,
    sortBy,
  } = useQueryVariables<User, BenefactorSort, AdministratorsFilterInput>(config);

  const { where: filter = {}, ...other } = variables;

  let mergedFilter: AdministratorsFilterInput = {
    ...(filter !== null ? filter : {}),
  };

  const queryVariables: AdministratorsQueryVariables = {
    ...other,
    filter: mergedFilter,
  };
  const { data, loading, error } = useAdministratorsQuery({
    variables: queryVariables,
  });

  if (error) {
    toast.error('Ошибка загрузки администраторов');
    return null;
  }

  const entities = data?.administrators as DataConnection<User>;
  const { data: wards, count, startCursor, endCursor, page, rowsPerPage } = extractPagedData(
    entities
  );

  const gridProps: DataGridProps<User, AdministratorsFilterInput> = {
    data: wards,
    onSort,
    onPage,
    onSearch,
    columns,
    getDataKey,
    count,
    startCursor: startCursor,
    endCursor: endCursor,
    page,
    rowsPerPage,
    sortDirection,
    sortBy,
    searchComponent: ModeratorsSearch,
    loading,
  };

  return (
    <QueryContextProvider variables={queryVariables}>
      <DataGrid<User, AdministratorsFilterInput> {...gridProps} />
      <Route exact path="/moderators/create" component={CreateModeratorPage} />
      <Route exact path="/moderators/edit/:id" component={EditModeratorPage} />
      <Route exact path="/moderators/password/:id" component={ChangeUserPasswordPage} />
    </QueryContextProvider>
  );
};
