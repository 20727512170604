import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import { createStyles, makeStyles } from '@material-ui/core';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

const useStyles = makeStyles(() =>
  createStyles({
    dragHandle: {
      bottom: '10px',
      left: '5px',
      cursor: 'move',
      position: 'absolute',
    },
  })
);

export const DragHandleCard = SortableHandle(() => {
  const styles = useStyles();
  return <DragIndicatorIcon className={styles.dragHandle} />;
});
