import { useCallback, useEffect } from 'react';
import {
  SpecialProjectsDocument,
  UpdateSpecialProjectsVisibilityOrderInput,
  useUpdateSpecialProjectsVisibilityOrderMutation,
} from 'schema/serverTypes';

import { FORM_ERROR, SubmissionErrors } from 'final-form';
import { toast } from 'react-toastify';

type UseCategoryProps = {
  onClose: () => void;
  ids: string[];
};
export const useUpdateOrderSpecialProjectsForm = ({ onClose, ids }: UseCategoryProps) => {
  const [updateOrder, { loading, error, data }] = useUpdateSpecialProjectsVisibilityOrderMutation();

  const onSubmit = useCallback(async () => {
    const input: UpdateSpecialProjectsVisibilityOrderInput = {
      ids,
    };
    try {
      await updateOrder({
        variables: {
          input,
        },
        refetchQueries: [
          {
            query: SpecialProjectsDocument,
            variables: { first: 50, includeClosedOrCancelled: false },
          },
        ],
      });
    } catch {
      error && toast.error('Ошибка изменения порядка спецпроектов');
      return { [FORM_ERROR]: 'Ошибка изменения порядка спецпроектов' } as SubmissionErrors;
    }
  }, [ids, updateOrder, error]);

  useEffect(() => {
    if (data?.updateSpecialProjectsVisibilityOrder.success === true) {
      onClose();
    } else if (data?.updateSpecialProjectsVisibilityOrder.success === false) {
      toast.error('Ошибка обновления порядка');
    }
  }, [onClose, data]);

  return { onSubmit, loading };
};
