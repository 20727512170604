import { useCallback, useEffect } from 'react';
import { AddCityInput, CitiesDocument, useAddCityMutation } from 'schema/serverTypes';

import { FORM_ERROR, SubmissionErrors } from 'final-form';
import { toast } from 'react-toastify';

const initialValues: AddCityInput = {
  name: '',
  default: false,
  order: 0,
};

export const useCityForm = (onClose: () => void) => {
  const [
    createCityMutation,
    { data, loading: cityLoading, error: cityError },
  ] = useAddCityMutation();

  const loading = cityLoading || false;
  const error = cityError || false;

  const onSubmit = useCallback(
    async (values: AddCityInput) => {
      const { name, order } = values;
      const input: AddCityInput = {
        name,
        order: parseInt(order.toString()),
        default: values.default,
      };
      try {
        return await createCityMutation({
          variables: { input },
          refetchQueries: [
            {
              query: CitiesDocument,
            },
          ],
        });
      } catch {
        error && toast.error('Ошибка добавления города');
        return { [FORM_ERROR]: 'Ошибка добавления' } as SubmissionErrors;
      }
    },
    [createCityMutation, error]
  );

  useEffect(() => {
    if (data !== undefined) {
      onClose();
    }
  }, [data, onClose]);

  return { initialValues, onSubmit, loading };
};
