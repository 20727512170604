import React from 'react';
import { Modal } from 'components';
import { useBackRoute } from 'hooks';
import { CreateDepositAccountForm } from './CreateDepositAccountForm';

interface Props {
  benefactorId: string;
  permission: boolean;
}

export const CreateDepositAccount = ({ benefactorId, permission }: Props) => {
  const { onCancel } = useBackRoute();

  return (
    <Modal onClose={onCancel} aria-labelledby="Создать счет">
      <CreateDepositAccountForm benefactorId={benefactorId} permission={permission} />
    </Modal>
  );
};
