import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FoundationFilterInput } from 'schema';
import { Box, Button, Grid, MenuItem, Select } from '@material-ui/core';
import { SearchInput } from 'components';
import useDebouncedCallback from 'use-debounce/lib/useDebouncedCallback';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(1),
    },
    search: {
      paddingRight: theme.spacing(2),
    },
    selectLabel: {
      margin: '10px',
    },
    resetButton: {
      display: 'block',
      marginLeft: 'auto',
    },
  })
);

export type FoundationsSearchProps = {
  onSearch: (input: FoundationFilterInput | undefined) => void;
};

interface FilterOptionsType {
  selectEvents: string | number;
}

export const FoundationsSearch = (props: FoundationsSearchProps) => {
  const { onSearch } = props;
  const classes = useStyles();

  const initialFilterOptions: FilterOptionsType = {
    selectEvents: '',
  };

  const [filterOptions, setFilterOptions] = useState<FilterOptionsType>(initialFilterOptions);

  const nameRef = useRef<HTMLInputElement>();

  const getWhere = useCallback(() => {
    const fullName = nameRef.current?.value || '';

    const where: FoundationFilterInput = {};

    if (fullName !== '') {
      where.name = { contains: fullName };
    }
    if (filterOptions.selectEvents === 0) {
      where.eventsCounters = { total: { gt: 0 } };
    }
    if (filterOptions.selectEvents === 1) {
      where.eventsCounters = { active: { gt: 0 } };
    }

    if (!Object.keys(where).length) {
      return undefined;
    }

    return where;
  }, [filterOptions]);

  const [handleOnNameChange] = useDebouncedCallback(() => {
    onSearch(getWhere());
  }, 1000);

  const handleOnFilterChange = useCallback(
    (event) => {
      const value = event.target.value;
      const name = event.target.name;
      setFilterOptions({
        ...filterOptions,
        [name]: value,
      });
    },
    [filterOptions]
  );

  useEffect(() => {
    onSearch(getWhere());
  }, [filterOptions, onSearch, getWhere]);

  const handleOnReset = useCallback(() => {
    if (nameRef && nameRef.current) {
      nameRef.current.value = '';
    }
    filterOptions.selectEvents = '';
    onSearch(undefined);
  }, [onSearch, filterOptions]);

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <SearchInput
            inputRef={nameRef}
            className={classes.search}
            placeholder="Название фонда..."
            onChange={handleOnNameChange}
          />
        </Grid>
      </Grid>
      <Grid container spacing={4} alignItems="center">
        <Grid item lg={3} md={3} xl={3} xs={12}>
          <Box display="flex" alignItems="center">
            <h4 className={classes.selectLabel}>События:</h4>
            <Select
              name="selectEvents"
              value={filterOptions.selectEvents}
              displayEmpty
              variant={'outlined'}
              onChange={handleOnFilterChange}
            >
              <MenuItem value="">Не важно</MenuItem>
              <MenuItem value={0}>Есть события</MenuItem>
              <MenuItem value={1}>Есть активные события</MenuItem>
            </Select>
          </Box>
        </Grid>
        <Grid item lg={9} md={12} xl={9} xs={12}>
          <Button className={classes.resetButton} variant="contained" onClick={handleOnReset}>
            Сбросить фильтры
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
