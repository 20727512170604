import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Grid, MenuItem, Select } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FoundationEventApplicationFilterInput, FoundationEventApplicationStatus } from 'schema';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(1),
    },
    search: {
      paddingRight: theme.spacing(2),
    },
    selectLabel: {
      margin: '10px',
    },
    resetButton: {
      display: 'block',
      marginLeft: 'auto',
    },
  })
);

export type ApplicationsSearchProps = {
  onSearch: (input: FoundationEventApplicationFilterInput | undefined) => void;
};

interface FilterOptionsType {
  status: FoundationEventApplicationStatus | string;
  rating: string | number;
}

export const ApplicationsSearch = (props: ApplicationsSearchProps) => {
  const { onSearch } = props;
  const styles = useStyles();

  const initialFilterOptions: FilterOptionsType = {
    status: '',
    rating: '',
  };

  const [filterOptions, setFilterOptions] = useState<FilterOptionsType>(initialFilterOptions);

  const getWhere = useCallback(() => {
    const where: FoundationEventApplicationFilterInput = {};

    if (filterOptions.rating !== '') {
      where.benefactorRating = {
        gte: filterOptions.rating as number,
      };
    }

    if (filterOptions.status !== '') {
      where.status =
        filterOptions.status === FoundationEventApplicationStatus.SelectedAsPrimary
          ? {
              in: [
                FoundationEventApplicationStatus.SelectedAsPrimary,
                FoundationEventApplicationStatus.SelectedAsBackup,
              ],
            }
          : { eq: filterOptions.status as FoundationEventApplicationStatus };
    }

    if (!Object.keys(where).length) {
      return;
    }

    return where;
  }, [filterOptions]);

  useEffect(() => {
    onSearch(getWhere());
  }, [onSearch, getWhere]);

  const handleOnFilterChange = useCallback(
    (event) => {
      const value = event.target.value;
      const name = event.target.name;
      setFilterOptions({
        ...filterOptions,
        [name]: value,
      });
    },
    [filterOptions]
  );

  const handleOnReset = useCallback(() => {
    filterOptions.status = '';
    filterOptions.rating = '';
    onSearch(undefined);
  }, [onSearch, filterOptions]);

  return (
    <div className={styles.root}>
      <Grid container spacing={4} alignItems="center">
        <Grid item lg={4} md={4} xl={4} xs={12}>
          <Box display="flex" alignItems="center">
            <h4 className={styles.selectLabel}>Статус:</h4>
            <Select
              name="status"
              value={filterOptions.status}
              displayEmpty
              variant={'outlined'}
              onChange={handleOnFilterChange}
            >
              <MenuItem value="">Все заявки</MenuItem>
              <MenuItem value={FoundationEventApplicationStatus.Applied}>Не обработанные</MenuItem>
              <MenuItem value={FoundationEventApplicationStatus.SelectedAsPrimary}>
                Обработанные
              </MenuItem>
              <MenuItem value={FoundationEventApplicationStatus.Declined}>Отклоненные</MenuItem>
              <MenuItem value={FoundationEventApplicationStatus.Revoked}>Отказавшиеся</MenuItem>
            </Select>
          </Box>
        </Grid>
        <Grid item lg={4} md={4} xl={4} xs={12}>
          <Box display="flex" alignItems="center">
            <h4 className={styles.selectLabel}>Рейтинг:</h4>
            <Select
              name="rating"
              value={filterOptions.rating}
              displayEmpty
              variant={'outlined'}
              onChange={handleOnFilterChange}
            >
              <MenuItem value="">Любой</MenuItem>
              <MenuItem value={3}>Не менее 3.0</MenuItem>
              <MenuItem value={4}>Не менее 4.0</MenuItem>
            </Select>
          </Box>
        </Grid>
        <Grid item lg={4} md={4} xl={4} xs={12}>
          <Button className={styles.resetButton} variant="contained" onClick={handleOnReset}>
            Сбросить фильтры
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
