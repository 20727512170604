import React from 'react';
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { FieldRenderProps } from 'react-final-form-hooks';
import { FormControl, FormHelperText } from '@material-ui/core';

export type PhoneFieldProps<V = any, T = string> = PhoneInputProps &
  FieldRenderProps<V, T> & {
    label: string | undefined;
    fullWidth?: boolean;
  };

export const PhoneField = (props: PhoneFieldProps) => {
  const { input, meta, fullWidth, country = 'ru', label = 'Телефон', ...rest } = props;
  const error = !meta.valid && meta.touched;
  const helperText = error ? meta.error : null;

  return (
    <FormControl fullWidth={fullWidth} component="fieldset" error={error}>
      <PhoneInput
        inputStyle={{ width: '100%' }}
        specialLabel={label}
        country={country}
        {...input}
        {...rest}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};
