import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { AddRegularNeedForm } from './AddRegularNeedForm';
import { AddOneTimeNeedForm } from './AddOneTimeNeedForm';
import {
  Button,
  Card,
  CardHeader,
  Divider,
  CardContent,
  makeStyles,
  createStyles,
  Tabs,
  Tab,
  useTheme,
  CardActions,
  Theme,
  Box,
} from '@material-ui/core';
import { TabPanel } from 'components';
import { AutoFocusedForm } from 'components';
import { useAddNeedForm } from './useAddNeedForm';
import { AddNeedFormProps } from '../types';
import { MutableButton } from 'ui/MutableButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '500px',
    },
    header: {
      fontWeight: 'bolder',
    },
    divider: {},
    content: {},
    actions: {
      justifyContent: 'flex-end',
    },
  })
);

export const AddNeedForm = ({ ward }: AddNeedFormProps) => {
  const classes = useStyles();
  const theme = useTheme();

  const {
    onCancel,
    initialValues,
    onSubmit,
    tabIndex,
    onChangeTab,
    onChangeTabIndex,
    requiredOnTab1,
    requiredOnTab2,
    loading,
    a11yProps,
  } = useAddNeedForm(ward);

  return (
    <AutoFocusedForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, pristine }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card className={classes.root}>
              <CardHeader title="Добавление потребности" />
              <Divider className={classes.divider} />
              <CardContent className={classes.content}>
                <Tabs
                  value={tabIndex}
                  onChange={onChangeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  aria-label="tabs"
                >
                  <Tab label="Регулярная" {...a11yProps(0)} />
                  <Tab label="Разовая" {...a11yProps(1)} />
                </Tabs>

                <SwipeableViews
                  axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                  index={tabIndex}
                  onChangeIndex={onChangeTabIndex}
                >
                  <TabPanel value={tabIndex} index={0} dir={theme.direction}>
                    <Box mt={2}>
                      <AddRegularNeedForm required={requiredOnTab1} />
                    </Box>
                  </TabPanel>
                  <TabPanel value={tabIndex} index={1} dir={theme.direction}>
                    <Box mt={2}>
                      <AddOneTimeNeedForm required={requiredOnTab2} />
                    </Box>
                  </TabPanel>
                </SwipeableViews>
              </CardContent>
              <CardActions className={classes.actions}>
                <MutableButton
                  loading={loading}
                  color="primary"
                  disabled={pristine}
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  Сохранить
                </MutableButton>
                <Button onClick={onCancel} size="medium" type="button" variant="contained">
                  Отменить
                </Button>
              </CardActions>
            </Card>
          </form>
        );
      }}
    />
  );
};
