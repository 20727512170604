import React from 'react';
import { PublishWardButton } from './PublishWardButton';
import { UnpublishWardButton } from './UnpublishWardButton';
import { ViewWardProps } from '../../types';

export const ViewWardPublicityButton = (props: ViewWardProps) => {
  const { ward } = props;
  const { published } = ward;

  if (published) {
    return <UnpublishWardButton id={ward.id} />;
  }

  return <PublishWardButton id={ward.id} />;
};
