import React from 'react';
import {
  Button,
  Card,
  CardHeader,
  Divider,
  CardContent,
  makeStyles,
  createStyles,
  CardActions,
  Theme,
  Grid,
} from '@material-ui/core';
import {
  AutoFocusedForm,
  PhotoField,
  required,
  phoneNumber,
  TextField,
  PhoneField,
  email,
  CheckboxField,
} from 'components';
import { useAddBenefactorForm } from './useAddBenefactorForm';
import { Field } from 'react-final-form';
import { AddBenefactorPageProps } from './AddBenefactorPage';
import { MutableButton } from 'ui/MutableButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '500px',
      minWidth: '320px',
    },
    header: {
      fontWeight: 'bolder',
    },
    actions: {
      justifyContent: 'flex-end',
    },
    item: {
      marginBottom: theme.spacing(2),
    },
  })
);

export const AddBenefactorForm = (props: AddBenefactorPageProps) => {
  const classes = useStyles();

  const { onCancel, onSubmit, initialValues, loading } = useAddBenefactorForm(props.variables);

  return (
    <AutoFocusedForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      subscription={{
        submitError: true,
      }}
      render={({ handleSubmit, pristine, submitError }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card className={classes.root}>
              <CardHeader title="Добавление пользователя" />
              <Divider />
              <CardContent>
                <Grid container spacing={4}>
                  <Grid item lg={12} md={12} xl={12} xs={12} className={classes.item}>
                    <Field name="photoId" component={PhotoField} />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item lg={12} md={12} xl={12} xs={12} className={classes.item}>
                    <Field
                      name="displayName"
                      label="Имя *"
                      component={TextField}
                      validate={required}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item lg={12} md={12} xl={12} xs={12} className={classes.item}>
                    <Field
                      name="phoneNumber"
                      label="Телефон"
                      component={PhoneField}
                      validate={phoneNumber}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item lg={12} md={12} xl={12} xs={12} className={classes.item}>
                    <Field name="email" label="E-mail" component={TextField} validate={email} />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item lg={12} md={12} xl={12} xs={12} className={classes.item}>
                    <CheckboxField label="Скрыть профиль" labelPlacement="end" name="hideProfile" />
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions className={classes.actions}>
                <MutableButton
                  loading={loading}
                  color="primary"
                  disabled={pristine || loading}
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  Сохранить
                </MutableButton>
                <Button onClick={onCancel} size="medium" type="button" variant="contained">
                  Отменить
                </Button>
              </CardActions>
            </Card>
          </form>
        );
      }}
    />
  );
};
