import React from 'react';
import { colors, createStyles, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useLocation, Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '5px',
      width: '190px',
      height: '200px',
      cursor: 'pointer',
      outline: 'none',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      '&:hover': {
        backgroundColor: theme.palette.background.default,
      },
      '&:active': {
        backgroundColor: colors.grey[100],
      },
    },
    addIcon: {
      fontSize: '80px',
    },
  })
);

export const AddRequirementButton = () => {
  const styles = useStyles();
  const { pathname } = useLocation();

  return (
    <Link to={`${pathname}/requirement/add`}>
      <Paper className={styles.root}>
        <AddIcon className={styles.addIcon} />
        <Typography>Добавить требование</Typography>
      </Paper>
    </Link>
  );
};
