import { SpecialProjectAccount } from '../../../../../schema';
import { PropertiesTable, PropertiesTableRow } from '../../../../../ui/PropertiesTable';
import moment from 'moment/moment';
import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Link } from 'react-router-dom';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      color: theme.palette.primary.main,
    },
  })
);

export const SpecialProjectAccountInformation = ({
  account,
}: {
  account: SpecialProjectAccount;
}) => {
  const classes = useStyles();

  // TODO: Необходимая сумма special project requiredAmount
  return (
    <PropertiesTable>
      <PropertiesTableRow
        caption="Наименование проекта"
        value={
          <Link
            to={`/special-projects/manage/${account.specialProject?.id}`}
            className={classes.link}
          >
            {account.specialProject?.title}
          </Link>
        }
      />

      <PropertiesTableRow caption="Баланс" value={account.amount} isAmount />
      {/*      <PropertiesTableRow
        caption="Необходимая сумма"
        value={account.specialProject?.account.requiredAmount || 'Без ограничений'}
        isAmount
      />*/}
      <PropertiesTableRow
        caption="Дата начала сбора средств"
        value={moment(account.specialProject?.start).format('DD.MM.YYYY')}
      />
      <PropertiesTableRow
        caption="Дата завершения сбора средств"
        value={
          account.specialProject?.end
            ? moment(account.specialProject?.end).format('DD.MM.YYYY')
            : 'Не задана'
        }
      />
    </PropertiesTable>
  );
};
