import React from 'react';
import { Redirect } from 'react-router-dom';
import { City, CityByIdQuery } from 'schema';
import { Loading } from 'ui/Loading';

interface EditFormOrEmptyProps {
  onClose: () => void;
  loading: boolean;
  hasError: boolean;
  data?: CityByIdQuery;
}

type ComponentProps = {
  onClose: () => void;
  city: City;
};

export const EditFormOrEmptyCity = <P extends EditFormOrEmptyProps>(
  Component: React.ComponentType<P & ComponentProps>
) => {
  return (props: P) => {
    const { onClose, loading, hasError, data } = props;

    if (loading) {
      return <Loading />;
    }
    if (hasError) {
      return <Redirect to="/404" />;
    }
    if (data === undefined || data.cityById === undefined) {
      return <Redirect to="/404" />;
    }

    const city = data?.cityById as City;

    return <Component {...props} onClose={onClose} city={city} />;
  };
};
