import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const WaitIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 Z M12.5555556,6.55555556 L12.4389344,6.56228329 C11.9415957,6.62004839 11.5555556,7.04271972 11.5555556,7.55555556 L11.5555556,7.55555556 L11.555,12.63 L9.15308273,14.5524645 L9.05999947,14.636909 C8.71351887,14.9931165 8.67843862,15.5599404 8.99690897,15.9580284 C9.34191852,16.3892903 9.97121089,16.4592117 10.4024728,16.1142021 L10.4024728,16.1142021 L13.1802506,13.8919799 L13.2757318,13.8049135 C13.4541019,13.6197822 13.5555556,13.3714972 13.5555556,13.1111111 L13.5555556,13.1111111 L13.5555556,7.55555556 L13.5488278,7.43893443 C13.4910627,6.94159575 13.0683914,6.55555556 12.5555556,6.55555556 L12.5555556,6.55555556 Z" />
    </SvgIcon>
  );
};
