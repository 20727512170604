import React, { useCallback, useMemo } from 'react';
import {
  BenefactorExtendedPropsFragment,
  Order,
  OrderSortInput,
  SortEnumType,
  useBenefactorOrdersQuery,
} from 'schema/serverTypes';
import { ColumnConfig, DataConnection, useQueryVariables } from 'hooks';
import { AmountLabel, DataGrid } from 'components';
import { DataGridProps } from 'components/DataGrid/types';
import { toDateTimeString } from 'helpers';
import { getOrderStatus } from 'schema';
import { useStyles } from '../useStyles';
import { Typography } from '@material-ui/core';
import { CreditCard, ErrorOutline, Receipt } from '@material-ui/icons';
import { Link } from 'react-router-dom';

const getDataKey = (order: Order) => order.id;

interface BenefactorTransactionsProps {
  benefactor: BenefactorExtendedPropsFragment;
}

export const BenefactorTransactions = (props: BenefactorTransactionsProps) => {
  const classes = useStyles();

  const {
    benefactor: { id },
  } = props;

  const getOrderType = useCallback(
    (order: Order) => {
      const { paymentDetails, paymentIntent } = order;

      if (paymentIntent) {
        switch (paymentIntent.__typename) {
          case 'BankTransferPaymentIntent':
            return <Receipt className={classes.iconColor} />;
          case 'CloudPaymentsNewCardPaymentIntent':
            return <CreditCard className={classes.iconColor} />;
          case 'CloudPaymentsSbpPaymentIntent':
            return <img src="/images/qr-code-icon.svg" width={20} height={20} alt="qr" />;
        }
      } else if (paymentDetails) {
        switch (paymentDetails.__typename) {
          case 'BankTransferPaymentDetails':
            return <Receipt className={classes.iconColor} />;
          case 'CloudPaymentsCardFailedPaymentDetails':
            return <ErrorOutline className={classes.iconErrorColor} />;
          case 'CloudPaymentsCardSuccessfulPaymentDetails':
            return <CreditCard className={classes.iconColor} />;
          case 'CloudPaymentsSbpFailedPaymentDetails':
            return <ErrorOutline className={classes.iconErrorColor} />;
          case 'CloudPaymentsSbpSuccessfulPaymentDetails':
            return <img src="/images/qr-code-icon.svg" width={20} height={20} alt="qr" />;
        }
      }
    },
    [classes]
  );

  const config = useMemo(() => {
    const columns: ColumnConfig<Order, OrderSortInput>[] = [
      {
        id: 'type',
        header: 'Тип',
        style: { width: '5%' },
        render: (order: Order) => getOrderType(order),
      },
      {
        id: 'amount',
        header: 'Сумма',
        style: { width: '15%' },
        render: (order: Order) => (
          <Link className={classes.link} to={`/benefactors/manage/${id}/order/${order.id}`}>
            <AmountLabel value={order.amount} />
          </Link>
        ),
      },
      {
        id: 'created',
        header: 'Дата платежа',
        style: { width: '20%' },
        orderBy: (date) => ({
          created: { timestamp: date },
        }),
        render: (order: Order) => {
          return <>{toDateTimeString(new Date(order.created.timestamp as string))}</>;
        },
      },
      {
        id: 'status',
        header: 'Статус',
        render: (order: Order) => <Typography>{getOrderStatus(order)}</Typography>,
      },
      {
        id: 'message',
        header: 'Сообщение',
        render: (order: Order) => <Typography> {order.failureMessage} </Typography>,
      },
    ];
    return {
      columns,
    };
  }, [getOrderType, classes, id]);

  const defaultOrderBy: OrderSortInput = { created: { timestamp: SortEnumType.Desc } };

  const {
    onPage,
    variables,
    extractPagedData,
    columns,
    sortDirection,
    sortBy,
    onSort,
    onSearch,
  } = useQueryVariables<Order, OrderSortInput>(config, defaultOrderBy);

  const { data } = useBenefactorOrdersQuery({
    variables: { id, ...variables },
  });

  const entities = data?.benefactorById?.orders as DataConnection<Order>;
  const { data: transactions, count, startCursor, endCursor, page, rowsPerPage } = extractPagedData(
    entities
  );

  const gridProps: DataGridProps<Order, OrderSortInput> = {
    data: transactions,
    onPage,
    columns,
    getDataKey,
    count,
    startCursor: startCursor,
    endCursor: endCursor,
    page,
    sortDirection,
    sortBy,
    rowsPerPage,
    onSort,
    onSearch,
  };

  return <DataGrid<Order> {...gridProps} />;
};
