import React from 'react';
import { Backdrop, Fade, Modal } from '@material-ui/core';
import { useBackRoute } from 'hooks';
import { AwardCsvModalForm } from './AwardCsvModalForm';
import { useStyles } from '../useStyles';

export const AwardCsvModal = () => {
  const styles = useStyles();

  const { onCancel } = useBackRoute();

  return (
    <Modal
      open={true}
      onClose={onCancel}
      aria-labelledby="Загрузка файлов с промокодами"
      aria-describedby="Используйте эту форму для загрузки файлов с промокодами"
      className={styles.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={true}>
        <AwardCsvModalForm onClose={onCancel} />
      </Fade>
    </Modal>
  );
};
