import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Theme, Typography, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    row: {
      height: '42px',
      display: 'flex',
      alignItems: 'center',
      margin: theme.spacing(0, 0, 3),
    },
    spacer: {
      flexGrow: 1,
    },
  })
);

type ToolbarProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  className?: string;
  title: string;
  iconButton?: React.ReactNode;
};

export const TitledToolbar = (props: ToolbarProps) => {
  const { className, title, children, iconButton, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <Typography variant="h1">{title}</Typography>
        {iconButton && iconButton}
        <span className={classes.spacer} />
        {children}
      </div>
    </div>
  );
};
