import React from 'react';
import { createContext } from 'react';
import { AttendanceQueryVariables, Benefactor, FoundationEventAttendance, Maybe } from 'schema';

interface ValuesType {
  createVariables: AttendanceQueryVariables;
  attendances?: Maybe<
    Array<
      { __typename?: 'FoundationEventAttendance' } & Pick<
        FoundationEventAttendance,
        | 'id'
        | 'checkedInAt'
        | 'attendeeComment'
        | 'attendeeRating'
        | 'foundationComment'
        | 'foundationRating'
      > & {
          benefactor: { __typename?: 'Benefactor' } & Pick<
            Benefactor,
            'id' | 'displayName' | 'phoneNumber'
          >;
        }
    >
  >;
}

export type AttendanceQueryContext = ValuesType | undefined;

export const QueryVariablesContext = createContext<AttendanceQueryContext>(undefined);

export interface QueryContextProviderProps {
  values: AttendanceQueryContext;
  children: React.ReactNode;
}

export const QueryContextProvider = (props: QueryContextProviderProps) => {
  const { children, values } = props;
  return <QueryVariablesContext.Provider value={values}>{children}</QueryVariablesContext.Provider>;
};
