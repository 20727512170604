import React from 'react';
import { Benefactor, Document } from 'schema';
import { AutoFocusedForm, FilesUploadField, required, TextField } from 'components';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import { useEditBenefactorVolunteerInformationForm } from './useEditBenefactorVolunteerInformationForm';
import { Field } from 'react-final-form';
import { MutableButton } from 'ui/MutableButton';
import { RedButton } from 'components/Buttons';
import { useBackRoute } from 'hooks';
import { useStyles } from '../useStyles';
import { FieldArray } from 'react-final-form-arrays';

interface EditBenefactorFormProps {
  benefactor: Benefactor;
}

export const EditBenefactorVolunteerInformationForm = (props: EditBenefactorFormProps) => {
  const { benefactor } = props;
  const styles = useStyles();

  const { onCancel } = useBackRoute();

  const { onSubmit, initialValues, loading } = useEditBenefactorVolunteerInformationForm(
    benefactor,
    onCancel
  );

  return (
    <AutoFocusedForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, pristine }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card className={styles.primary}>
              <CardHeader title="Информация о волонтёрстве" />
              <Divider />
              <CardContent>
                <Grid container spacing={4}>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Field
                      name="experience"
                      label="Опыт волонтёрства"
                      component={TextField}
                      multiline
                      rows={4}
                      rowsMax={50}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Typography variant="h5">Список прикреплённых документов *</Typography>
                    <FieldArray<Document>
                      label="Список прикреплённых документов *"
                      name="documents"
                      component={FilesUploadField}
                      validate={required}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <MutableButton
                  className={styles.accessButton}
                  loading={loading}
                  disabled={pristine}
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  Сохранить
                </MutableButton>
                <RedButton
                  color="primary"
                  size="medium"
                  type="button"
                  variant="contained"
                  onClick={onCancel}
                >
                  Отменить
                </RedButton>
              </CardActions>
            </Card>
          </form>
        );
      }}
    />
  );
};
