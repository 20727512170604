import { useCallback, useEffect } from 'react';
import {
  CreateFoundationRepresentativeInput,
  FoundationByIdDocument,
  useCreateFoundationRepresentativeMutation,
} from 'schema/serverTypes';
import { toast } from 'react-toastify';

const initialValues: CreateFoundationRepresentativeInput = {
  displayName: '',
  email: '',
  foundationId: '',
};

export const useFoundationRepresentativeForm = (onClose: () => void, id: string) => {
  const [
    createFoundationRepresentativeMutation,
    { data, loading, error },
  ] = useCreateFoundationRepresentativeMutation();

  const onSubmit = useCallback(
    async (values: CreateFoundationRepresentativeInput) => {
      const { displayName, email } = values;
      const input: CreateFoundationRepresentativeInput = {
        displayName,
        email,
        foundationId: id,
      };

      await createFoundationRepresentativeMutation({
        variables: { input },
        refetchQueries: [
          {
            query: FoundationByIdDocument,
            variables: {
              id,
            },
          },
        ],
      });
    },
    [createFoundationRepresentativeMutation, id]
  );

  useEffect(() => {
    error && toast.error('Ошибка добавления администратора');
    data?.createFoundationRepresentative.errors &&
      toast.error(`${data?.createFoundationRepresentative.errors.map((error) => error.message)}`);
  }, [error, data]);

  useEffect(() => {
    if (data !== undefined) {
      onClose();
    }
  }, [data, onClose]);

  return { initialValues, onSubmit, loading };
};
