import React, { useRef, useCallback, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';
import useDebouncedCallback from 'use-debounce/lib/useDebouncedCallback';
import {
  WardFilterInput,
  WardPublicInformationFilterInput,
  useCitiesQuery,
} from 'schema/serverTypes';
import { SearchInput, SearchCheckboxes } from 'components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(1),
    },
    search: {
      paddingRight: theme.spacing(2),
    },
    selectLabel: {
      margin: '10px',
    },
    resetButton: {
      display: 'block',
      marginLeft: 'auto',
    },
  })
);

export type WardsSearchProps = {
  onSearch: (input: WardFilterInput | undefined) => void;
};

const getOptionLabel = (option: string) => option;

export const AddWardsSearch = (props: WardsSearchProps) => {
  const { onSearch } = props;
  const classes = useStyles();
  const nameRef = useRef<HTMLInputElement>();
  const selectedCities = useRef<string[]>([]);

  const getWhere = useCallback(() => {
    const fullName = nameRef.current?.value || '';
    if (fullName === '' && selectedCities.current.length === 0) {
      return undefined;
    }

    const filters: Array<WardPublicInformationFilterInput> = [];

    if (fullName !== '') {
      filters.push({
        name: {
          fullName: {
            contains: fullName,
          },
        },
      });
    }

    if (selectedCities.current.length) {
      filters.push({
        city: {
          in: selectedCities.current,
        },
      });
    }

    const where: WardFilterInput = {};

    if (!!filters.length) {
      where.publicInformation = { and: filters };
    }

    return where;
  }, []);

  const [handleOnNameChange] = useDebouncedCallback(() => {
    onSearch(getWhere());
  }, 1000);

  useEffect(() => onSearch(getWhere()), [onSearch, getWhere]);

  const { data } = useCitiesQuery();
  const cities = data?.cities ? (data?.cities.nodes?.map(({ name }) => name) as string[]) : [];

  const handleOnChange = useCallback(
    (cities: string[]) => {
      selectedCities.current = cities;
      onSearch(getWhere());
    },
    [onSearch, getWhere]
  );

  const handleOnReset = useCallback(() => {
    if (nameRef && nameRef.current) {
      nameRef.current.value = '';
    }
    selectedCities.current.length = 0;
    onSearch(undefined);
  }, [onSearch]);

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} xl={10} xs={12}>
          <SearchInput
            inputRef={nameRef}
            className={classes.search}
            placeholder="Поиск подопечного..."
            onChange={handleOnNameChange}
          />
        </Grid>
      </Grid>
      <Grid container spacing={4} alignItems="center">
        <Grid item lg={6} md={6} xl={6} xs={12}>
          <SearchCheckboxes
            options={cities}
            getOptionLabel={getOptionLabel}
            label={<h4>Все населенные пункты</h4>}
            onChange={handleOnChange}
            variant="outlined"
          />
        </Grid>
        <Grid item lg={6} md={6} xl={6} xs={12}>
          <Button
            className={classes.resetButton}
            variant="contained"
            size="medium"
            onClick={handleOnReset}
          >
            Сбросить фильтры
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
