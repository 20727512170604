import React from 'react';
import { Button, Card, CardContent } from '@material-ui/core';
import { VersionsGrid } from './VersionsGrid';
import { Link } from 'react-router-dom';
import { LayoutPageGrid } from 'ui/LayoutGrid/LayoutGrid';
import { usePermissionPage } from 'hooks';
import { useMeContext } from 'components';

export const VersionsPage = () => {
  const { user } = useMeContext();

  usePermissionPage('/wards', user?.permissions.clientVersions.manage);

  return (
    <LayoutPageGrid
      title="Версии клиентов"
      tools={
        <Button color="primary" variant="contained" component={Link} to="/versions/add">
          Добавить
        </Button>
      }
    >
      <Card>
        <CardContent>
          <VersionsGrid />
        </CardContent>
      </Card>
    </LayoutPageGrid>
  );
};
