import React from 'react';
import { PublishWardButton } from './PublishWardButton';
import { UnpublishSpecialProjectButton } from './UnpublishSpecialProjectButton';
import { SpecialProjectButtonProps } from '../types';

export const SpecialProjectPublicityButton = ({ specialProject }: SpecialProjectButtonProps) => {
  const { published } = specialProject;

  if (published) {
    return <UnpublishSpecialProjectButton />;
  }

  return <PublishWardButton />;
};
