import React from 'react';
import { Backdrop, createStyles, Fade, makeStyles, Modal, Theme } from '@material-ui/core';
import { useBackRoute } from 'hooks';
import { AddRatingForm } from './AddRatingForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    form: {
      minWidth: '450px',
      padding: '15px',
      backgroundColor: theme.palette.background.paper,
      border: '0px',
      borderRadius: '2px',
      boxShadow: theme.shadows[5],
    },
  })
);

export const AddRating = () => {
  const styles = useStyles();
  const { onCancel } = useBackRoute();
  return (
    <Modal
      open={true}
      onClose={onCancel}
      aria-labelledby="Рейтинг участника события"
      aria-describedby="Используйте эту форму для изменения рейтинка участника события"
      className={styles.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={true}>
        <div className={styles.form}>
          <AddRatingForm onClose={onCancel} />
        </div>
      </Fade>
    </Modal>
  );
};
