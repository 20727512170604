import React, { useEffect, useState } from 'react';
import { DialogActions, DialogContent, Typography } from '@material-ui/core';
import {
  OrderStatus,
  PlaceOrderPayload,
  useOrderStatusQuery,
} from '../../../../../../../../schema';
import { RedButton } from '../../../../../../../../components/Buttons';
import { useHistory, useLocation } from 'react-router-dom';

type Props = {
  placeOrderData: PlaceOrderPayload | undefined;
  handleClose: () => void;
};

export const ResultPlaceOrderStep = ({ placeOrderData, handleClose }: Props) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const [orderStatus, setOrderStatus] = useState<OrderStatus | undefined>();
  const { data: orderStatusData, loading: orderStatusLoading, stopPolling } = useOrderStatusQuery({
    variables: { id: placeOrderData?.order?.id },
    pollInterval: 2000,
  });

  useEffect(() => {
    if (!orderStatusLoading && orderStatusData) {
      const updatedStatus = orderStatusData.orderById?.status;
      setOrderStatus(updatedStatus);

      if (updatedStatus === OrderStatus.Completed || updatedStatus === OrderStatus.PaymentFailed) {
        setLoading(false);
        stopPolling();
      }
    }
  }, [orderStatusLoading, stopPolling, orderStatusData]);

  const handleClick = () => {
    if (placeOrderData?.order) {
      history.push(`${pathname}/order/${placeOrderData.order?.id}`);
      handleClose();
    } else {
      handleClose();
    }
  };

  useEffect(() => {
    if (placeOrderData?.order) {
      setLoading(true);
    }
  }, [placeOrderData]);

  return (
    <>
      <DialogContent>
        <DialogContent>
          <Typography variant="h4" align={'center'}>
            {loading ? (
              'Ожидание завершения заказа...'
            ) : placeOrderData?.order ? (
              <>
                {orderStatus === OrderStatus.Completed ? (
                  <Typography variant="h4" align={'center'}>
                    Заказ завершён успешно!
                  </Typography>
                ) : (
                  <Typography variant="h4" align={'center'}>
                    Заказ завершён неуспешно: {placeOrderData.order.failureMessage}
                  </Typography>
                )}
              </>
            ) : (
              <Typography variant="h4" align={'center'}>
                Не удалось разместить заказ:{' '}
                {placeOrderData?.errors && placeOrderData.errors[0].message}
              </Typography>
            )}
          </Typography>
        </DialogContent>
      </DialogContent>
      <DialogActions>
        <RedButton onClick={handleClick}>Закрыть</RedButton>
      </DialogActions>
    </>
  );
};
