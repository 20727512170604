import React from 'react';
import { AuditMarkerPropsFragment } from '../../schema';
import moment from 'moment';
import { PropertiesTable, PropertiesTableRow } from '../../ui/PropertiesTable';

interface AuditTableItem {
  caption: string;
  marker: AuditMarkerPropsFragment | null | undefined;
}

interface AuditTableProps {
  items: AuditTableItem[];
  filterNullItems?: boolean;
}

export const AuditTable = ({ items, filterNullItems }: AuditTableProps) => {
  const formatUserAndDate = (marker: AuditMarkerPropsFragment | null | undefined) => {
    if (marker === null || marker === undefined) {
      return null;
    }

    const formattedDate = moment(marker.timestamp).format('DD.MM.YYYY, HH:MM');
    const displayName = marker.user?.displayName ?? 'Неизвестный пользователь';

    return `${displayName} (${formattedDate})`;
  };

  const filteredItems = filterNullItems === false ? items : items.filter((v) => !!v.marker);

  const rows = filteredItems.map((item) => (
    <PropertiesTableRow
      key={item.caption}
      caption={item.caption}
      value={formatUserAndDate(item.marker)}
    />
  ));

  return <PropertiesTable>{rows}</PropertiesTable>;
};
