export default {
  root: {
    boxShadow: '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)'
  },
  title: {
    fontSize: '20px',
    letterSpacing: '0',
    lineHeight: 1.2,
  },
};
