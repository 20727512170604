import React from 'react';
import { Grid, MenuItem, makeStyles, Theme, createStyles } from '@material-ui/core';
import { TextField, SelectField, DateField, OneTimeNeedIcon } from 'components';
import { Field } from 'react-final-form';
import { NeedFormProps } from '../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    iconSelect: {
      width: '54px',
    },
  })
);

export const AddOneTimeNeedForm = (props: NeedFormProps) => {
  const classes = useStyles();

  const { required } = props;

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={2} md={2} xl={2} xs={2}>
          <Field
            name="oneTime.icon"
            component={SelectField}
            className={classes.iconSelect}
            validate={required}
          >
            <MenuItem value={0}>
              <OneTimeNeedIcon number={0} />
            </MenuItem>
            <MenuItem value={1}>
              <OneTimeNeedIcon number={1} />
            </MenuItem>
            <MenuItem value={2}>
              <OneTimeNeedIcon number={2} />
            </MenuItem>
          </Field>
        </Grid>
        <Grid item lg={10} md={10} xl={10} xs={10}>
          <Field
            name="oneTime.title"
            component={TextField}
            label="Название потребности"
            validate={required}
          />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item lg={6} md={6} xl={6} xs={6}>
          <Field
            name="oneTime.amount"
            component={TextField}
            label="Сумма потребности в ₽"
            validate={required}
          />
        </Grid>
        <Grid item lg={6} md={6} xl={6} xs={6}>
          <Field
            name="oneTime.targetDate"
            label="Дата закрытия"
            fullWidth={true}
            component={DateField}
            returnTime={false}
            validate={required}
            autoOk={true}
          />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Field name="oneTime.url" component={TextField} label="Ссылка на потребность" />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Field
            name="oneTime.description"
            component={TextField}
            label="Описание потребности"
            validate={required}
            multiline
            rows={5}
            rowsMax={50}
          />
        </Grid>
      </Grid>
    </div>
  );
};
