import React from 'react';
import { LayoutPageGrid } from 'ui/LayoutGrid';
import { AddOrEditFoundationForm } from './AddOrEditFoundationForm';
import { EditFoundationProps } from './types';
import { usePermissionPage } from 'hooks';
import { useMeContext } from 'components';

export const AddOrEditFoundationPage = ({ foundation, permission }: EditFoundationProps) => {
  const { user } = useMeContext();

  const path = foundation ? `/foundations/manage/${foundation.id}` : '/foundations';
  const title = `${foundation ? 'Редактирование' : 'Создание'} Фонда`;

  usePermissionPage(path, permission || user?.permissions.foundations.create);

  return (
    <LayoutPageGrid title={title}>
      <AddOrEditFoundationForm foundation={foundation} />
    </LayoutPageGrid>
  );
};
